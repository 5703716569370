<template>
  <div class="mb-8">
    <v-card class="mx-auto mb-3">
      <div id="filters">
        <template>
          <v-toolbar flat>
            <v-toolbar-title>
              <p colpr="white" class="mb-0">{{ $t("CERTIFICATIONS") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
			<div  class="d-flex align-center justify-space-between">
				<v-btn color="light2" class="zIndex mt-0 me-2 pa-0 white--text" @click="pullCertifications">{{ $t("PULL") }}</v-btn>
				<v-btn
					color="secondary"
					dark
					v-on="on"
					@click="addCert"
					class="mt-0 pa-0"
				>
					{{ $t("ADD_NEW") }}
				</v-btn>
			</div>
          </v-toolbar>
          <v-form ref="filterForm">
            <v-container>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                  <v-select
                          :items="academicYear"
                          v-model="defaultAcademicYearSearch"
                          item-value="id"
                          item-text="name"
                          :label="$t('ACADEMIC_YEAR')"
                          outlined
						  dense
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="6" sm="6" md="6" lg="3" v-if="showAdvancedSearch == false">
                  <v-btn color="light3" class="white--text" @click="showAdvancedSearch = true">{{$t("ADVANCED_SEARCH")}}</v-btn>
                </v-col>
                <v-col cols="6" xs="6" sm="6" md="6" lg="3" v-if="showAdvancedSearch">
                  <v-btn color="light3" class="white--text" @click="showAdvancedSearch = false; initializeFilter(true, false);">{{$t("BACK")}}</v-btn>
                </v-col>
               </v-row>
               <v-responsive>
               <v-row>
                   <v-col cols="12" sm="12" md="4" lg="4" v-if="showAdvancedSearch">
                     <v-text-field
                       v-model="certificateName"
                       @keydown.space="preventLeadingSpace"
                       @keyup.enter.native="initializeList"
                       outlined
                       clearable
                       :label="$t('CERTIFICATE_NAME')"
                       type="text"
                       maxlength="75"
                       counter="75"
                       :rules="searchCertificateRules"
					   dense
                     ></v-text-field>
                   </v-col>
                   <v-col cols="12" sm="12" md="4" lg="4" v-if="showAdvancedSearch">
                     <v-select
                             :items="serverTranslateArray(certificateTypesArEn, 'name')"
                             v-model="defaultCertificateTypeSearch"
                             item-value="id"
                             item-text="name"
                             :label="$t('CERTIFICATE_TYPE')"
                             outlined
							 dense
                     ></v-select>
                   </v-col>
                   <v-col cols="12" sm="12" md="4" lg="4" v-if="showAdvancedSearch">
                     <v-select
                             :items="serverTranslateArray(statusCertificates, 'name')"
                             v-model="defaultStatusSearch"
                             item-value="type"
                             item-text="name"
                             :label="$t('STATUS')"
                             outlined
							 dense
                     ></v-select>
                   </v-col>
                 </v-row>
              </v-responsive>
                 <v-card-actions>
                    <v-spacer></v-spacer>
                   <v-btn color="primary" dark @click="initializeList">
                     {{$t("SEARCH")}}
                   </v-btn>
                   <v-btn color="darken3" class="white--text" @click="reset(true)">{{ $t("RESET") }}</v-btn>
                 </v-card-actions>
            </v-container>
          </v-form>
        </template>
      </div>
    </v-card>
    <v-card class="mx-auto" outlined>
      <div id="inspire">
        <v-data-table
                :headers="headers"
                :items="serverTranslateArray(certificatesArEn, 'typeCertificateName')"
                class="elevation-1 pointer"
                :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-arrow-left',
            nextIcon: 'mdi-arrow-right',
            itemsPerPageOptions: [5, 10, 15, 50, 100]
          }"
                :options.sync="options"
                :items-per-page="5"
                @update:options="filterChanged" @click:row="viewCertificate"
                :loading="loading"
                :hide-default-footer="certificatesArEn.length > 0 ? false : true"
        >
          <template v-slot:item.actions="{ item }">

            <v-tooltip v-if="item.studentCount>0" bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon meduim class="mx-2"
                  >mdi-lock
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("LOCKED_CERTIFICATE") }}</span>
            </v-tooltip>
			<v-template v-else>
				<v-tooltip v-if="item.status" bottom>
					<template  v-slot:activator="{ on }">
						<v-btn icon v-on="on">
						<v-icon meduim color="primary" @click="editCertificateItem(item.id)">mdi-pencil</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("EDIT") }}</span>
				</v-tooltip>
				<v-tooltip v-if="item.status && item.coursesCount==0" bottom>
					<template  v-slot:activator="{ on }">
						<v-btn icon v-on="on">
						<v-icon
								meduim
								color="delete"
								class="mx-2"
								@click="deleteCertificateItem(item.id)"
						>mdi-delete</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("DELETE") }}</span>
				</v-tooltip>
				<v-tooltip bottom v-if="!item.status && item.studentCount==0">
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on">
						<v-icon meduim @click="toggleActivationItem(item)">toggle_off</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("OPEN") }}</span>
				</v-tooltip>
				<v-tooltip bottom v-if="item.status && item.coursesCount>0">
					<template v-slot:activator="{ on }">
						<v-btn icon v-on="on">
						<v-icon meduim color="green" @click="toggleActivationItem(item)">toggle_on</v-icon>
						</v-btn>
					</template>
					<span>{{ $t("CLOSE") }}</span>
				</v-tooltip>
			</v-template>
          </template>
          <template v-slot:no-data>
             <v-alert icon="warning" class="mt-2" color="light5">
                 {{$t("NO_DATA")}}
             </v-alert>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{$t("ACADEMIC_YEAR_CERTIFICATES")}}<span dir="ltr">{{ datatableTitle }}</span></v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
            </v-toolbar>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <pr-confirmDialog ref="confirm"></pr-confirmDialog>
  </div>
</template>
<script>
	import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
   import ConfirmDialog from "@/components/shared/ConfirmDialog";
	export default {
		name: "pr-certification",
		mixins: [serverTranslationsMixin],
		props: ["searchData"],
      components: {
        "pr-confirmDialog": ConfirmDialog
      },
		data() {
			return {
				showAdvancedSearch:false,
				certificateTypes: [{ name: "الكل|All", id: "all" }],
				certificateTypesEdit: [],
				academicYear: [],
				defaultStatusSearch: "all",
				defaultCertificateTypeSearch: "all",
				defaultAcademicYearSearch: Number,
				certificateName: "",
				certificatesArEn: [],
				certificateData: {
					arName: "",
					enName: "",
					totalDegree: "",
					certificationType: "",
					status: true,
					academicYear: ""
				},
				searchCertificateRules: [
					v =>
						 /^(?:[a-zA-Z0-9\s)\-(\u0621-\u064A]){0,75}$/.test(
							  v != undefined && v.trim()
						 ) || this.$t("PREVENT_SPECIAL_CHARACTER_FIELD")
				],
				btnSearchClicked: false,
				searchDataCheck: {},
				options: {
					page: 1
				},
				yearName:"",
				loading: false
			};
		},
		computed: {
			direction: function() {
				return this.$store.state.direction;
			},
			headers: function() {
				return [
					{
						text: this.$t("AR_NAME"),
						align: "start",
						sortable: true,
						value: "arName"
					},
					{ text: this.$t("EN_NAME"), value: "enName", sortable: true },
					{
						text: this.$t("CERTIFICATE_TYPE"),
						value: "typeCertificateName",
						sortable: true
					},
					{
						text: this.$t("TOTAL"),
						value: "totalDegree",
						sortable: true
					},
					{
						text: this.$t("ACTIONS"),
						value: "actions",
						sortable: false,
						width: "150px"
					}
				];
			},
			statusCertificates: function() {
				return [
					{
						name: "الكل|All",
						type: "all"
					},
					{
						name: "مفتوحة|Opened",
						type: true
					},
					{
						name: "مغلقة|Closed",
						type: false
					}
				];
			},
			certificateTypesArEn: function(data) {
				return this.searchData ? this.searchData.certificateTypes : data.certificateTypes;
			},
			datatableTitle(){
				return this.yearName ? this.yearName : "";
			}
		},
		watch: {
			direction: function() {
				if (this.$el.querySelector(".error--text")) {
					this.$refs.filterForm.validate();
				}
			}
		},
		mounted() {
			if (this.searchData) {
				this.certificateTypes = this.searchData.certificateTypes;
				this.defaultCertificateTypeSearch = this.searchData.defaultCertificateTypeSearch;
				this.academicYear = this.searchData.academicYear;
				this.defaultAcademicYearSearch = this.searchData.defaultAcademicYearSearch;
				this.defaultStatusSearch = this.searchData.defaultStatusSearch;
				this.certificateName = this.searchData.certificateName;
				this.yearName = this.searchData.yearName;
				this.certificateData.academicYear = this.defaultAcademicYearSearch;
				this.showAdvancedSearch = this.searchData.showAdvancedSearch;
				this.initializeList();
			} else {
				this.initializeFilter(true, true);
			}
		},
		methods: {
			initializeFilter(allowSearch, resetYears) {
				this.$http
					 .get("/api/certificates/list_certificates_filter/")
					 .then(
						  res => {
							  this.certificateName = "";
							  this.defaultCertificateTypeSearch = "all"
							  this.defaultStatusSearch = "all"
							  this.certificateTypes = this.certificateTypes.concat(
									res.data.data.certificates_categories
							  );
                       if (resetYears) {
   							  this.academicYear = res.data.data.years;
   							  this.defaultAcademicYearSearch = res.data.data.years.filter(
   									obj => obj.active == "1"
   							  )[0].id;
   							  this.yearName = this.academicYear.filter(
   									obj => obj.id == this.defaultAcademicYearSearch
   							  )[0].name;
   							  this.certificateData.academicYear = this.defaultAcademicYearSearch;
                       }
							  const searchData = {
								  certificateName: this.certificateName,
								  certificateTypeId: this.defaultCertificateTypeSearch,
								  certificateYearId: this.defaultAcademicYearSearch,
								  certificateStatus: this.defaultStatusSearch,
								  showAdvancedSearch:this.showAdvancedSearch
							  };
							  this.btnSearchClicked = true;
							  this.searchDataCheck = searchData;
						  },
						  err => {
							  this.$toasted.show(
									this.serverTranslate(err.response.data.message),
									{
										type: "error",
										icon: "error_outline",
										position: "bottom-left",
										duration: 5000,
										theme: "bubble"
									}
							  );
						  }
					 )
					 .then(() => {
						 if (allowSearch === true) this.initializeList();
					 });
			},
			initializeList() {
				if (this.$refs.filterForm.validate()) {
					this.loading = true;
					let searchData = {}
					if (this.showAdvancedSearch == true) {
						searchData = {
							certificateName: this.certificateName,
							certificateTypeId: this.defaultCertificateTypeSearch,
							certificateYearId: this.defaultAcademicYearSearch,
							certificateStatus: this.defaultStatusSearch,
                     isAdvanced :this.showAdvancedSearch,
                     showAdvancedSearch:this.showAdvancedSearch
						};
					} else {
						searchData = {
							certificateYearId: this.defaultAcademicYearSearch,
							isAdvanced :this.showAdvancedSearch,
                     showAdvancedSearch:this.showAdvancedSearch
						};
					}
					this.btnSearchClicked = true;
					this.searchDataCheck = searchData;
					this.yearName = this.academicYear.filter(
						 obj => obj.id == this.defaultAcademicYearSearch
					)[0].name;
					this.$http
						 .post("/api/certificates/search_certificates/", searchData)
						 .then(
							  res => {
								  this.certificatesArEn = [];
                          const certs = res.data.data
                          for (let i = 0; i < certs.length; i++) {
                             certs[i].totalDegree = certs[i].totalDegree.toFixed(2)
                          }
								  this.certificatesArEn = certs;
								  this.options = {
									  page: 1
								  };
								  this.loading = false;
							  },
							  err => {
								  this.certificatesArEn = err.response.data.data;
								  this.$toasted.show(
										this.serverTranslate(err.response.data.message),
										{
											type: "error",
											icon: "error_outline",
											position: "bottom-left",
											duration: 5000,
											theme: "bubble"
										}
								  );
							  }
						 );
				}
			},
			editCertificateItem(certificateID) {
				event.stopPropagation();
				if (this.checkChanging()) {
					this.filterChanged()
				}
				this.$router.push({name: "EditCertification",params: {id: certificateID, searchData:this.searchKeys()}})
			},
			deleteCertificateItem(itemId) {
				event.stopPropagation();
				if (this.checkChanging()) {
					this.filterChanged()
				}
				this.$refs.confirm
					 .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
					 .then(confirm => {
						 if (confirm) {
							 const id = { id: itemId };
							 const searchData = {
								 certificateName: this.certificateName,
								 certificateTypeId: this.defaultCertificateTypeSearch,
								 certificateYearId: this.defaultAcademicYearSearch,
								 certificateStatus: this.defaultStatusSearch
							 };
							 this.$http
								  .delete("/api/certificates/delete_certificates/", {
									  data: id,
									  searchData: searchData
								  })
								  .then(
										res => {
											this.$toasted.show(this.serverTranslate(res.data.message), {
												type: "success",
												icon: "check_circle_outline",
												theme: "bubble",
												position: "bottom-left",
												duration: 2000
											});
											this.initializeList();
											//this.certificatesArEn = res.data.data;
											//search again search_certificates
										},
										err => {
											this.$toasted.show(
												 this.serverTranslate(err.response.data.message),
												 {
													 type: "error",
													 icon: "error_outline",
													 theme: "bubble",
													 position: "bottom-left",
													 duration: 5000
												 }
											);
											if (err.response.status === 404) {
												this.initializeList();
											}
										}
								  );
						 }
					 });
			},
			toggleActivationItem(item) {
				event.stopPropagation();

				const searchData = {
					certificateName: this.certificateName,
					certificateTypeId: this.defaultCertificateTypeSearch,
					certificateYearId: this.defaultAcademicYearSearch,
					certificateStatus: this.defaultStatusSearch
				};
				const data = { status: !item.status, updateAll: false, id: item.id,searchData: searchData };
				this.$http.put("/api/certificates/update_open_close_certificate/", data).then(
					 res => {
						 this.$toasted.show(this.serverTranslate(res.data.message), {
							 type: "success",
							 icon: "check_circle_outline",
							 duration: 2000,
							 theme: "bubble",
							 position: "bottom-left"
						 });
						 this.initializeList();
					 },
					 err => {
						 this.$toasted.show(
							  this.serverTranslate(err.response.data.message),
							  {
								  type: "error",
								  icon: "error_outline",
								  theme: "bubble",
								  position: "bottom-left",
								  duration: 5000
							  }
						 );
						 if (err.response.status === 404) {
							 this.initializeList();
						 }
					 }
				);
			},
			reset(allowSearch) {
				this.certificateTypes = [{ name: "الكل|All", id: "all" }];
				this.certificateTypesEdit = [];
				this.certificateTypesArEn = [];
				this.certificatesArEn = [];
				this.academicYear = [];
				this.defaultStatusSearch = "all";
				this.defaultCertificateTypeSearch = "all";
				this.certificateName = "";
				this.initializeFilter(allowSearch, true);
			},
			preventLeadingSpace(e) {
				if (!e.target.value) e.preventDefault();
				else if (e.target.value[0] == " ")
					e.target.value = e.target.value.replace(/^\s*/, "");
			},
			checkChanging() {
				if( this.showAdvancedSearch ==true){
					if (
						 this.searchDataCheck.certificateName != this.certificateName ||
						 this.searchDataCheck.certificateTypeId !=
						 this.defaultCertificateTypeSearch ||
						 this.searchDataCheck.certificateYearId !=
						 this.defaultAcademicYearSearch ||
						 this.searchDataCheck.certificateStatus != this.defaultStatusSearch  ||
						 this.searchDataCheck.showAdvancedSearch !=this.showAdvancedSearch

					) {
						return true;
					}
					return false;
				} else {
					if (this.searchDataCheck.certificateYearId != this.defaultAcademicYearSearch ||
						 this.searchDataCheck.showAdvancedSearch !=this.showAdvancedSearch
					) {
						return true;
					}
					return false;
				}

			},
			filterChanged() {
				if (this.btnSearchClicked && this.checkChanging()) {
					this.defaultAcademicYearSearch = this.searchDataCheck.certificateYearId;
					this.showAdvancedSearch=this.searchDataCheck.showAdvancedSearch;
					if( this.showAdvancedSearch ==true){

						this.certificateName = this.searchDataCheck.certificateName;
						this.defaultCertificateTypeSearch = this.searchDataCheck.certificateTypeId;
						this.defaultStatusSearch = this.searchDataCheck.certificateStatus;
					}
					else{
						this.certificateName='';
						this.defaultCertificateTypeSearch ='all';
						this.defaultStatusSearch='all';

					}
					this.$toasted.info(this.$t("FILTER_CHANGED_MESSAGE"), {
						type: "info",
						icon: "info_outline",
						position: "bottom-left",
						theme: "bubble",
						duration: 5000
					});
					// this.options = {
					//   sortBy: ["id"],
					//   sortDesc: [true],
					//   page: 1
					// };
				}
			},
			pullCertifications(){
				this.$router.push({name:"PullCertifications", params:{searchData:this.searchKeys()}});
			},
			searchKeys() {
				return {
					certificateTypes: this.certificateTypes,
					defaultCertificateTypeSearch: this.defaultCertificateTypeSearch,
					academicYear: this.academicYear,
					defaultAcademicYearSearch: this.defaultAcademicYearSearch,
					defaultStatusSearch: this.defaultStatusSearch,
					certificateName: this.certificateName,
					yearName: this.yearName,
					showAdvancedSearch:this.showAdvancedSearch
				}
			},
			viewCertificate(item) {
            this.$router.push({name:"ViewCertification", params:{id:item.id, searchData:this.searchKeys()}});
			},
         addCert() {
            this.$router.push({name:"AddCertification"});
         }
		}
	};
</script>
