import router from "../router";

export default {
  state: {
    token: localStorage.getItem("token"),
    userName: localStorage.getItem("userName"),
    universityID: localStorage.getItem("scopeID"),
    facultyID: localStorage.getItem("facultyID"),
    facultyName: localStorage.getItem("facultyName"),
    branchID: localStorage.getItem("branchID"),
    branchName: localStorage.getItem("branchName"),
    scopeLevel: localStorage.getItem("scopeLevel") || 12,
  },
  mutations: {
    logout(state: any) {
      state.token = "";
      state.userName = "";
      state.facultyID = null;
      state.facultyName = "";
      state.branchID = null;
      state.branchName = "";
      state.scopeLevel = "";

      localStorage.removeItem("token");
      localStorage.removeItem("userName");
      localStorage.removeItem("facultyID");
      localStorage.removeItem("facultyName");
      localStorage.removeItem("branchID");
      localStorage.removeItem("branchName");
      localStorage.removeItem("scopeID");
      localStorage.removeItem("scopeName");
      localStorage.removeItem("scopeLevel");
      router.push("/login");
    },

    login(state: any, payload: any) {
      state.token = payload.token;
      localStorage.token = payload.token;
      localStorage.userName = payload.userName;
    },
    refreshToken(state: any, payload: any) {
      state.token = payload.token;
      localStorage.token = payload.token;
    }
  },
  actions: {},
  getters: {
    getToken: (state: any) => state.token,
    getAllScopeLevel(state: any) {
      return {
        branchID:state.branchID,
        facultyID:state.facultyID,
        universityID:state.universityID || localStorage.getItem("scopeID")
      }
    },
  }
};
