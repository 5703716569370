<template>
  <v-card class="mx-auto" outlined>
    <div id="inspire">
    <v-data-table
        :headers="headers"
        :items="serverTranslateArray(services,'serviceText')"
        sort-by="serviceText"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
          itemsPerPageOptions: [5, 10, 15, 50, 100]
        }"
        :items-per-page="10"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <p color="white" class="mb-0">{{ $t("SERVICES_LINKS") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="90vh">
              <template v-slot:activator="{ on }">
                <v-btn 
                color="secondary" 
                dark v-on="on"
                :small="!$vuetify.breakpoint.mdAndUp"
                :fab="!$vuetify.breakpoint.mdAndUp"
                @click="openDialog">
                  <template v-if="$vuetify.breakpoint.mdAndUp"> {{$t("ADD_NEW")}} </template>
                  <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-plus-circle</v-icon>
                </v-btn>
              </template>
              <v-card outlined>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title class="justify-center">
                    <span class="headline white--text text-thin">{{
                      formTitle
                    }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-alert v-show="showAlert" dense outlined type="error">{{
                        serverTranslate(errorMessages)
                      }}</v-alert>
                      <v-row>
                        <v-col cols="12" class="ma-0" sm="12" md="12" lg="12">
                            <v-select
                                :items="serverTranslateArray(serviceTypes, 'name')"
                                v-model="editedServiceItem.serviceType"
                                outlined
                                small-chips
                                :label="$t('SERVICE_TYPE')"
                                item-value="id"
                                item-text="name"
                                :rules="requiredRules"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="11" class="ma-0" sm="11" md="11" lg="11">
                          <v-text-field
                            ref="ipRef"
                            v-model="editedServiceItem.serviceIP"
                            :rules="ipRules"
                            :label="$t('SERVICE_IP')"
                            outlined
                            maxlength="255"
                            @keyup="checkIPValidation"
                            @keypress="checkIPValidation"
                            @keydown="checkIPValidation"
                            @change="checkIPValidation"
                            @mouseout="checkIPValidation"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-3" cols="1" sm="1" md="1" lg="1">
                            <v-tooltip :right="direction" :left="!direction" max-width="300">
                              <template v-slot:activator="{ on }">
                                <v-btn icon v-on="on">
                                  <v-icon :color="ipInfo? 'primary' : 'red'"> mdi-information-outline </v-icon>
                                </v-btn>
                              </template>
                              <span>{{ $t("IP_INFO_HINT") }}</span>
                            </v-tooltip>
                        </v-col>
                        <v-col cols="11" class="ma-0" sm="11" md="11" lg="11">
                          <v-text-field
                            ref="urlRef"
                            v-model="editedServiceItem.serviceUrl"
                            :rules="urlRules"
                            :label="$t('SERVICE_URL')"
                            outlined
                            @keydown.space="preventLeadingSpace"
                            maxlength="255"
                            @keypress="checkURLValidation"
                            @keyup="checkURLValidation"
                            @keydown="checkURLValidation"
                            @change="checkURLValidation"
                            @mouseout="checkURLValidation"
                          ></v-text-field>
                        </v-col>
                        <v-col class="mt-3" cols="1" sm="1" md="1" lg="1">
                          <v-tooltip :right="direction" :left="!direction" max-width="300">
                            <template v-slot:activator="{ on }">
                              <v-btn icon v-on="on">
                                <v-icon :color="urlInfo ?'primary' : 'red'"> mdi-information-outline </v-icon>
                              </v-btn>
                            </template>
                            <span>{{ $t("URL_INFO_HINT") }}</span>
                          </v-tooltip>
                        </v-col>
                        <v-col cols="12" class="ma-0" sm="12" md="12" lg="12">
                          <v-textarea
                            :label="$t('SERVICE_DESCRIPTION')"
                            v-model="editedServiceItem.serviceDescription"
                            counter="255"
                            maxlength="255"
                            outlined
                            rows="3"
                            row-height="25"
                            :rules="searchRules"
                            @keydown.space="preventLeadingSpace"
                          ></v-textarea>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="save">{{
                      $t("SAVE")
                    }}</v-btn>

                    <v-btn color="darken3" class="white--text" @click="close">
                        {{ $t("CANCEL") }}
                    </v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.serviceUrl="{item}">
          <a target="_blank" :href="item.serviceUrl">{{item.serviceUrl}}</a>
        </template>
        <template v-slot:item.serviceIP="{item}">
          <a target="_blank" :href="item.serviceIP">{{item.serviceIP}}</a>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon meduim color="primary" @click="editService(item)"
                  >mdi-pencil</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("EDIT") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:footer.page-text="props">
            {{ props.pageStart }} - {{ props.pageStop }} {{$t('PAGE_TEXT')}} {{ props.itemsLength }}
        </template>
        <template v-slot:no-data>
            <v-alert icon="warning" class="mt-2" color="light5">
            {{ $t("NO_DATA") }}
            </v-alert>
        </template>
    </v-data-table>
     
      <pr-confirmDialog ref="confirm"></pr-confirmDialog>
    </div>
  </v-card>
</template>
<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "pr-services",
  components: {
    "pr-confirmDialog": ConfirmDialog
  },
  mixins: [serverTranslationsMixin,globalMethodsMixin],
  data() {
    return {
      requiredRules: this.$store.state.Rules.requiredRules,
      searchRules: this.$store.state.Rules.searchRules,
      ipRules: this.$store.state.Rules.ipRules,
      urlRules: this.$store.state.Rules.urlRules,

      dialog: false,
      valid: true,
      showAlert: false,
      ipInfo: true,
      urlInfo: true,
      componentKey:0,
      errorMessages: "",
      services: [],
      serviceTypes: [],
      serviceIndex: -1,
      editedServiceItem: {
        id:"",
        serviceUrl:"",
        serviceIP:"",
        serviceType:"",
        serviceDescription: ""
      },
      defaultServiceItem: {
        id:"",
        serviceUrl:"",
        serviceIP:"",
        serviceType:"",
        serviceDescription: ""
      },
    };
  },
  computed: {
    formTitle() {
      return this.serviceIndex === -1
        ? this.$t("ADD_NEW") + " " + this.$t("SERVICES_LINKS")
        : this.$t("EDIT") + " " + this.$t("SERVICES_LINKS");
    },
    direction: function() {
      return this.$store.state.direction;
    },
    headers: function() {
      return [
        {
          text: this.$t("SERVICE_TYPE"),
          align: "start",
          sortable: true,
          value: "serviceText"
        },
        { text: this.$t("SERVICE_IP"), value: "serviceIP", sortable: true },
        { text: this.$t("SERVICE_URL"), value: "serviceUrl", sortable: true },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "150px"
        }
      ];
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
  },

  created() {
    this.initialize();
  },

  methods: {
    checkIPValidation() {
      this.ipInfo = !this.$refs.ipRef.validate() ? false : true;
      return this.ipInfo;
    },
    checkURLValidation() {
      this.urlInfo = !this.$refs.urlRef.validate() ? false : true;
      return this.urlInfo;
    },
    initialize() {
      this.$http.get("/api/services_url/list_services/").then(
        res => {
          this.services = res.data.data;
        },
        err => {
          this.showToasted("error",err.response.data.message);
        }
      );
    },

    editService(serviceItem) {
      this.serviceIndex = 1;
      this.editedServiceItem = Object.assign({}, serviceItem);
      this.dialog = true;
      this.openDialog();
    },

    openDialog () {
      this.$http.post("/api/general_data/retrieve_basic_attributes/",{catId:7}).then(
        res => {
          this.serviceTypes = res.data.data;
          if (this.serviceIndex == -1){
            this.editedServiceItem.serviceType = this.serviceTypes.filter(item => item.active != undefined).length > 0?
            this.serviceTypes.filter(item => item.active == 1)[0].id : "";
          }
        },
        err => {
          this.showToasted("error",err.response.data.message);
          this.close();
        }
      );
    },
    close() {
      this.$refs.form.resetValidation();
      this.dialog = false;
      this.showAlert = false;
      this.errorMessages = "";
      this.editedServiceItem = Object.assign({}, this.defaultServiceItem);
      this.serviceIndex = -1;
    },
    save() {
      if (this.$refs.form.validate()) {
        if (this.serviceIndex > -1) { // edit case
          this.updateService();
        } else { /// add case
          this.$http.post(
            "/api/services_url/add_service/",
            this.editedServiceItem
          ).then(
            res => {
              this.showToasted("success",res.data.message);
              this.close();
              this.initialize();
            },
            err => {
              this.errorMessages = err.response.data.message;
              this.showAlert = true;
              this.initialize();
            }
          );
        }
      }
    },
    updateService() {
      this.$http.put(
        "/api/services_url/edit_service/",
        this.editedServiceItem
      ).then(
        res => {
          this.showToasted("success",res.data.message);
          this.close();
          this.initialize();
        },
        err => {
          this.errorMessages = err.response.data.message;
          this.showAlert = true;
          this.initialize();
        }
      );
    },
  }
};
</script>
