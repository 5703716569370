<template>
    <v-app>
      <v-tabs v-model="activeTab" class="custom-tabs"    >
        <v-tab v-for="tab in tabs" :key="tab.name">
              {{ serverTranslate(tab.name)  }} 
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="activeTab" >
        <v-tab-item v-for="tab in tabs" :key="tab.name">
          <component :is="tab.component" :tab-name="tab.name"></component>
        </v-tab-item>
      </v-tabs-items>
    </v-app>
  </template>
  
  <script>
  import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
  import globalMethodsMixin from "@/mixins/serverTranslations";
  import ApplicationFeeRule from './ApplicationFeeRule.vue'
  import TestRule from './TestRule.vue'
  
  export default {
    name: 'Tabs',
    mixins: [serverTranslationsMixin, globalMethodsMixin],
    components: {
        ApplicationFeeRule,
        TestRule

        },
    data() {
      return {
        activeTab: 0,
        tabs: [
          { name:this.$t("APPLICATION_FEES"), component: 'ApplicationFeeRule' },
          // { name:"قاعدة اخرى|Second Rule", component: 'TestRule' }
        ]
      }
    }
  }
  </script>
  
  <style scoped>

  .custom-tabs {
    margin: 0; /* Remove any margin around each tab */
    padding: 0; /* Remove any padding around each tab */
    max-height: 50px; /* Set the maximum height for each tab */
  }

  </style>
  