<template v-if="allowEdit">
  <div>
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>
          <p colpr="white" class="mb-0">{{ $t(formTitle) }}</p>
        </v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-form ref="addEditForm" >
        <v-card-text class="pb-0">
          <v-container>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                  v-model="certificateData.arName"
                  @keydown.space="preventLeadingSpace"
                  :label="$t('AR_NAME')"
                  required
                  maxlength="75"
                  :rules="$store.state.Rules.arNameRules"
                  prepend-inner-icon="mdi-abjad-arabic"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                  v-model="certificateData.enName"
                  @keydown.space="preventLeadingSpace"
                  :label="$t('EN_NAME')"
                  required
                  maxlength="75"
                  :rules="$store.state.Rules.enNameRules"
                  prepend-inner-icon="mdi-alpha-e-circle-outline"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-select
                  :items="serverTranslateArray(certificationCategories, 'name')"
                  v-model="certificateData.certificationType"
                  item-value="id"
                  item-text="name"
                  :rules="$store.state.Rules.requiredRules"
                  prepend-inner-icon="mdi-certificate"
                  :label="$t('CERTIFICATE_TYPE')"
                ></v-select>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-select
                  :items="academicYears"
                  v-model="certificateData.academicYear"
                  item-value="id"
                  item-text="name"
                  :rules="$store.state.Rules.requiredRules"
                  :label="$t('ACADEMIC_YEAR')"
                  prepend-inner-icon="mdi-calendar-month"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                <v-text-field
                  v-model="certificateData.totalDegree"
                  :label="$t('TOTAL')"
                  type="number"
                  max="999999"
                  :rules="totalDegreeRules"
                  prepend-inner-icon="mdi-counter"
                ></v-text-field>
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" v-if="certificateData.id">
                 {{ $t("STATUS") }}
                 <v-icon
                   large
                   v-if="!certificateData.status"
                   @click="certificateData.status = !certificateData.status"
                   >toggle_off</v-icon
                 >
                 <v-icon
                   color="primary"
                   large
                   v-if="certificateData.status"
                   @click="certificateData.status = !certificateData.status"
                   >toggle_on</v-icon
                 >
              </v-col>
              <v-col cols="12" xs="12" sm="12" md="6" lg="6" v-else>
                {{ $t("STATUS") }}
                <v-btn icon v-on="on">
                  <v-icon meduim color="primary">toggle_on</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions class="justify-end pt-0">
          <v-btn color="primary" sdark @click="save">{{
            $t("SAVE")
          }}</v-btn>
          <v-btn color="darken3" class="white--text" @click="cancel">
            {{ $t("CANCEL") }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <pr-confirmDialog
      ref="confirm"
      :persistentDialog="true"
    ></pr-confirmDialog>
  </div>
</template>
<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import globalMethodsMixin from "@/mixins/globalMethods";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
export default {
  name: "pr-add-edit-certificate",
  mixins: [serverTranslationsMixin,globalMethodsMixin],
  props:{
    searchData:Object
  },
  components: {
    "pr-confirmDialog": ConfirmDialog
  },
  mounted() {
    if (this.certificateData.id) {
      this.getEditedCertificate();
   } else {
      this.getCertificatesControls();
   }
  },
  watch: {
    direction: function() {
      if (this.$el.querySelector(".error--text")) {
        this.$refs.addEditForm.validate();
      }
    }
  },
  computed: {
    direction: function() {
      return this.$store.state.direction;
   },
   formTitle() {
      return this.certificateData.id ? "EDIT_CERTIFICATE":"ADD_CERTIFICATE"
   }
  },
  data() {
    return {
      certificationCategories:[],
      academicYears:[],
      certificateData:{
        arName: "",
        enName: "",
        totalDegree: "",
        certificationType: "",
        status: true,
        academicYear: "",
        id:parseInt(this.$route.params.id),
        coursesCount:0,
        studentsCount:0
      },
      allowEdit:false,
      totalDegreeRules: [
        v => !!v || this.$t("REQUIRED_FIELD"),
        v => (v != undefined && v > 0) || this.$t("TOTAL_MIN"),
        v => (v != undefined && v <= 999999) || this.$t("TOTAL_MAX")
      ]
    };
  },
  methods:{
    getEditedCertificate() {
      const certData = {id:this.certificateData.id}
      this.$http.post("/api/certificates/get_edited_certificate/", certData)
      .then(
        res => {
           this.certificateData = res.data.data.certificate
           this.certificationCategories = res.data.data.certificationCategories
           this.academicYears = res.data.data.years
           this.allowEdit=true
        },
        err => {
          this.$toasted.show(
            this.serverTranslate(err.response.data.message),
            {
             type: "error",
             icon: "error_outline",
             theme: "bubble",
             position: "bottom-left",
             duration: 5000
            }
          );
          if (err.response.status === 404) {
            this.allowEdit = false;
            this.$router.push({name: "ListCertifications", params: {searchData: this.searchData}});
          }

        }
      )
    },
    getCertificatesControls() {
      this.$http.post("/api/certificates/list_certificates_controls/")
      .then(
        res => {
          this.academicYears = res.data.data.years
          this.certificationCategories = res.data.data.certificationCategories
       },
       err => {
          this.$toasted.show(
            this.serverTranslate(err.response.data.message),
            {
             type: "error",
             icon: "error_outline",
             theme: "bubble",
             position: "bottom-left",
             duration: 5000
            }
          );
       }
      )
    },
    cancel() {
      const certID = parseInt(this.$route.params.id)
      const searchD = this.searchData
      this.$refs.confirm
        .open("CONFIRMATION_MESSAGE", "CANCEL_SAVE_CERTIFICATION")
        .then(confirm => {
          if (confirm) {
             if (certID) {
                this.$router.push({name:"ViewCertification",
               params: {id:certID,searchData: searchD}});
            } else {
               this.$router.push({name: "ListCertifications", params: {searchData: searchD}});
            }
          }
        });
    },
    save() {
      if (this.$refs.addEditForm.validate()) {
        if (this.certificateData.id) {
          this.updateCertificateService();
        } else {
          this.addNewCertification();
        }
      }
    },
    updateCertificateService() {
      this.$http.put("/api/certificates/update_certificate/", this.certificateData)
      .then(
         res => {
            this.$toasted.show(this.serverTranslate(res.data.message), {
              type: "success",
              icon: "check_circle_outline",
              theme: "bubble",
              duration: 2000,
              position: "bottom-left"
            });
            this.$router.push({name:"ViewCertification", params:{id:this.certificateData.id, searchData:this.searchData}});
         },
         err => {
            this.$toasted.show(
              this.serverTranslate(err.response.data.message),
              {
                type: "error",
                icon: "error_outline",
                theme: "bubble",
                position: "bottom-left",
                duration: 5000
              }
            );
            if (err.response.status === 404) {
              this.$router.push({name: "ListCertifications", params: {searchData: this.searchData}});
            }
         }
      )
    },
    addNewCertification() {
      this.$http
        .post("/api/certificates/create_certificate/", this.certificateData)
        .then(
          res => {
            this.$toasted.show(this.serverTranslate(res.data.message), {
              type: "success",
              icon: "check_circle_outline",
              theme: "bubble",
              duration: 2000,
              position: "bottom-left"
            });
            this.certificateData.id = res.data.data.id
            this.$router.push({name:"ViewCertification", params:{id:this.certificateData.id}});
          },
          err => {
            this.errorMessages = err.response.data.message;
            this.$toasted.show(
              this.serverTranslate(err.response.data.message),
              {
                type: "error",
                icon: "error_outline",
                theme: "bubble",
                position: "bottom-left",
                duration: 5000
              }
            );
          }
        );
    }
  }
};
</script>
