import {
  NATIONAL_ID_REGEX,
  ONLY_LETTERS_REGEX,
  ONLY_LETTERS_AND_NUMBERS_REGEX,
  ONLY_ENGLISH_LETTERS_AND_NUMBERS_REGEX,
  ONLY_ARABIC_REGEX,
  ONLY_ENGLISH_REGEX,
  EMAIL_REGEX,
  CONTAIN_SIGNS_REGEX,
  SYMBOLS_REGEX,
  ONLY_NUMBERS_AND_SPACES_REGEX,
  ONLY_NUMBERS_AND_SLASHES_REGEX,
  CHARACTERS_NUMBERS_SPACES_REGEX,
  EG_PHONE_REGEX,
  EG_MOBILE_REGEX
} from "./regex";

/* eslint-disable @typescript-eslint/no-explicit-any */
export default class Validations {
  static required(value: any): boolean {
    return !(
      value === undefined ||
      value === null ||
      value.toString().trim() === ""
    );
  }

  /**
   * @param relatedValue a related boolean value, the value becomes
   * required if relatedValue is set to true or false
   */
  static requiredRelatedToBoolean(
    value: any,
    relatedValue: boolean | undefined
  ): boolean {
    if (relatedValue === true || relatedValue === false) {
      return this.required(value);
    }
    return true;
  }

  static lengthEqual(value: string | number, length: number): boolean {
    return value ? String(value).length === length : true;
  }

  static minLength(value: string, minLength: number): boolean {
    return value ? value.trim().length >= minLength : true;
  }

  static maxLength(value: string, maxLength: number): boolean {
    return value ? String(value).trim().length <= maxLength : true;
  }

  static lengthBetween(value: string, min: number, max: number) {
    return value
      ? value.trim().length >= min && value.trim().length <= max
      : true;
  }

  /**
   * @returns { Boolean } true if the value is falsy or the value matches
   * the given regex
   */
  static regexTest(value: string, regex: RegExp): boolean {
    if (regex && regex.lastIndex) {
      regex.lastIndex = 0;
    }
    return value ? regex.test(String(value)) : true;
  }

  static equal(value: any, compare: any): boolean {
    return value ? value === compare : true;
  }

  /**
   * @param exclusive if true, the given min value is excluded from the comparison.
   * false by default
   * @returns { Boolean } true if the value is falsy, is not a valid number,
   * or is greater than the given min value
   */
  static minValue(value: string, min: number, exclusive = false): boolean {
    if (value && !Number.isNaN(Number(value))) {
      return exclusive ? Number(value) > min : Number(value) >= min;
    }
    return true;
  }

  /**
   * @param exclusive if true, the given max value is excluded from the comparison.
   * false by default
   * @returns { Boolean } true if the value is falsy, is not a valid number,
   * or is less than the given max value
   */
  static maxValue(value: string, max: number, exclusive = false): boolean {
    if (value && !Number.isNaN(Number(value))) {
      return exclusive ? Number(value) < max : Number(value) <= max;
    }
    return true;
  }

  /**
   * @param beginning string or array of string of required beginnings
   * @returns { Boolean } true if the value begins with any of the given beginning values
   */
  static beginsWith(value: string, beginning: string | string[]): boolean {
    if (!value) return true;
    if (beginning instanceof Array) {
      return beginning.some((string) => value.startsWith(string));
    }
    return value.startsWith(beginning);
  }

  /**
   * @param exclusive if true, the given min and max values are excluded
   * from the comparison. false by default
   * @returns { Boolean } true if the value is falsy, is not a valid number,
   * or is greater than the given min value and less than the given max value
   */
  static valueBetween(
    value: string,
    min: number,
    max: number,
    exclusive = false
  ): boolean {
    if (value && !Number.isNaN(Number(value))) {
      return exclusive
        ? Number(value) > min && Number(value) < max
        : Number(value) >= min && Number(value) <= max;
    }
    return true;
  }
  /**
   * @returns { Boolean } true if the value is falsy or is an integer number
   */
  static isInteger(value: string | number, unsigned = false): boolean {
    if (!value) return true;
    if (String(value).includes(".")) return false;
    return unsigned
      ? Number.isInteger(Number(value)) &&
          !String(value).match(CONTAIN_SIGNS_REGEX)
      : Number.isInteger(Number(value));
  }

  /**
   * @param value the number value as a string
   * @param maxDecimalPlaces the maximum decimal places, no limit by default
   * @returns { Boolean } true if the value is falsy or is a float number,
   * a valid float must has at least one integer digit and one decimal digit (#.#)
   */
  static isFloat(value: string | number, maxDecimalPlaces = 1000): boolean {
    if (value !== null && value !== undefined && value !== "") {
      const regex = `^\\d*(?:\\.\\d{0,${maxDecimalPlaces}})?$`;
      return Boolean(String(value).match(regex));
    }
    return true;
  }

  /**
   * @returns { Boolean } true if the value is in the given array
   */
  static isIn(value: any, arr: any[]): boolean {
    return value ? arr.includes(value) : true;
  }

  /**
   * @param value the compared date
   * @param minDate the minimum date
   * @returns { Boolean } true if the value is falsy or is greater than the minimum
   */
  static minDate(value: string | Date, minDate: string | Date): boolean {
    if (!value) return true;

    return Date.parse(value.toString()) > Date.parse(minDate.toString());
  }

  /**
   * @param value the compared date
   * @param maxDate the minimum date
   * @returns { Boolean } true if the value is falsy or is less than the minimum
   */
  static maxDate(value: string | Date, maxDate: string | Date): boolean {
    if (!value) return true;

    return Date.parse(value.toString()) < Date.parse(maxDate.toString());
  }

  /**
   * @param value the compared date
   * @returns { Boolean } true if the value is falsy or the value is a future date
   */
  static isFutureDate(value: string | Date): boolean {
    if (!value) return true;

    return Date.parse(value.toString()) < Date.now();
  }

  /**
   * @param value the compared date
   * @param years number of years that the value should be within it
   * @returns { Boolean } true if the value is falsy or is greater than (current year - years)
   */
  static withinYears(value: string | Date, years: number): boolean {
    if (!value) return true;

    const valueAsDate = new Date(value.toString());
    const targetDate = new Date(Date.now());
    targetDate.setFullYear(targetDate.getFullYear() - years);
    return valueAsDate > targetDate;
  }

  /**
   * @param value the compared year string
   * @returns { Boolean } true if the value is falsy or is valid year format
   */
  static isValidYear(value: string): boolean {
    if (!value) return true;
    return (
      this.isInteger(value, true) &&
      this.lengthEqual(value, 4) &&
      (String(value).startsWith("19") || String(value).startsWith("20"))
    );
  }

  /**
   * @returns { Boolean } true if the value is falsy or is a valid national id
   */
  static isNationalID(value: string): boolean {
    return this.regexTest(value, NATIONAL_ID_REGEX);
  }

  /**
   * @returns { Boolean } true if the value is falsy or is a valid national id
   */
  static isPassport(value: string): boolean {
    return this.maxLength(value, 20) && this.isEnglishLettersAndNumbers(value);
  }

  /**
   * @param { string } value phone number as a string
   * @returns { Boolean } true if the value is falsy or is a valid phone number
   */
  static isEgPhone(value: string): boolean {
    if (!value) return true;
    return this.regexTest(value, EG_PHONE_REGEX);
  }

  /**
   * @param { string } value mobile number as a string
   * @returns { Boolean } true if the value is falsy or is a valid Egyptian mobile number
   */
  static isEgMobile(value: string): boolean {
    if (!value) return true;
    return this.regexTest(value, EG_MOBILE_REGEX);
  }

  /**
   * @returns { Boolean } true if the value is falsy or contains letters only
   */
  static isLettersOnly(value: string, allowAsterisk = false): boolean {
    if (allowAsterisk) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return this.regexTest(value, addAsteriskToRegex(ONLY_LETTERS_REGEX));
    }
    return this.regexTest(value, ONLY_LETTERS_REGEX);
  }

  /**
   * @returns { Boolean } true if the value is falsy or contains just arabic
   */
  static isArabicOnly(value: string, allowAsterisk = false): boolean {
    if (allowAsterisk) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return this.regexTest(value, addAsteriskToRegex(ONLY_ARABIC_REGEX));
    }
    return this.regexTest(value, ONLY_ARABIC_REGEX);
  }

  /**
   * @returns { Boolean } true if the value is falsy or contains just
   * english letters and spaces
   */
  static isEnglishOnly(value: string, allowAsterisk = false): boolean {
    if (allowAsterisk) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      return this.regexTest(value, addAsteriskToRegex(ONLY_ENGLISH_REGEX));
    }
    return this.regexTest(value, ONLY_ENGLISH_REGEX);
  }

  /**
   * @returns { Boolean } true if the value is falsy or contains just
   * letters or numbers and spaces
   */
  static isLettersAndNumbers(value: string, allowAsterisk = false): boolean {
    if (allowAsterisk) {
      return this.regexTest(
        value,
          // eslint-disable-next-line @typescript-eslint/no-use-before-define
        addAsteriskToRegex(ONLY_LETTERS_AND_NUMBERS_REGEX)
      );
    }
    return this.regexTest(value, ONLY_LETTERS_AND_NUMBERS_REGEX);
  }

  /**
   * @returns { Boolean } true if the value is falsy or contains just
   * english letters or numbers and spaces
   */
  static isEnglishLettersAndNumbers(
    value: string,
    allowAsterisk = false
  ): boolean {
    if (allowAsterisk) {
      return this.regexTest(
        value,
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        addAsteriskToRegex(ONLY_ENGLISH_LETTERS_AND_NUMBERS_REGEX)
      );
    }
    return this.regexTest(value, ONLY_ENGLISH_LETTERS_AND_NUMBERS_REGEX);
  }

  /**
   * @param domain the domain of the email (e.g., .edu.eg)
   * @returns { Boolean } true if the value is falsy or is a valid email
   */
  static isEmail(value, domain = ""): boolean {
    if (!value) return true;
    const isValid = this.regexTest(value, EMAIL_REGEX);
    if (!isValid) return false;
    return domain ? value.endsWith(domain) : true;
  }

  /**
   * @param { string } value string to be checked
   * @returns { Boolean } true if the value is falsy or does not start
   * with a special character
   */
  static doesNotStartWithSpecialCharacter(value: string): boolean {
    if (!value) return true;
    return !this.regexTest(value.trim()[0], new RegExp(SYMBOLS_REGEX));
  }

  static isNumbersAndSpaces(str: string): boolean {
    return this.regexTest(str, ONLY_NUMBERS_AND_SPACES_REGEX);
  }

  static isNumbersAndSlashes(str: string): boolean {
    return this.regexTest(str, ONLY_NUMBERS_AND_SLASHES_REGEX);
  }

  static isCharactersNumbersSpaces(str: string): boolean {
    return this.regexTest(str, CHARACTERS_NUMBERS_SPACES_REGEX);
  }
}

function addAsteriskToRegex(regexValue: RegExp): RegExp {
  const regexString = regexValue.source;
  const n = regexString.lastIndexOf("]");
  if (n < 0) return new RegExp(regexString);
  return new RegExp(
    regexString.substring(0, n) + "\\*" + regexString.substring(n)
  );
}
