<template>
  <div class="ml-0 px-0 d-flex align-center" v-if="showTooltip == 'true'">
    <v-tooltip bottom v-if="$vuetify.breakpoint.mdAndUp">
      <template v-slot:activator="{ on }">
        <v-btn @click="languageSwitcher" icon v-on="on" color="secondary">
           {{$t("_lang")}}
        </v-btn>
      </template>
      <span>{{ $t("CHANGE_LANGUAGE") }}</span>
    </v-tooltip>
    <v-btn @click="languageSwitcher" icon v-else color="secondary">
      {{$t("_lang")}}
    </v-btn>
  </div>
  <div v-else>
    <v-btn @click="languageSwitcher" icon class="secondary--text">
      <v-icon color="secondary">mdi-web</v-icon>
    </v-btn>
    <a
      @click="languageSwitcher"
      class="secondary--text font-weight-bold text-xsmall"
    >{{ $t("_lang") }}</a>
  </div>
</template>

<script>
import Vue from "vue";
export default {
  name: "pr-changeLanguage",
  props: ["showTooltip"],
  created() {
    localStorage.lang ? localStorage.lang : (localStorage.lang = "ar");
    const direction = localStorage.lang == "ar" ? true : false;
    this.$vuetify.rtl = direction;
    this.locale = localStorage.lang;
    this.$vuetify.lang.current = localStorage.lang;
    this.direction = direction;
    this.$emit("swichLanguage", this.direction);
    Vue.i18n.set(localStorage.lang);
    this.$store.commit("setLanguage", localStorage.lang);
  },
  data() {
    return {
      direction: this.$store.state.direction
    };
  },
  methods: {
    languageSwitcher: function() {
      this.direction = !this.direction;
      this.$vuetify.rtl = this.direction;
      localStorage.lang = this.direction ? "ar" : "en";
      this.locale = localStorage.lang;
      this.$vuetify.lang.current = localStorage.lang;
      Vue.i18n.set(localStorage.lang);
      this.$store.commit("setLanguage", localStorage.lang);
      this.$emit("swichLanguage", this.direction);
    }
  }
};
</script>
