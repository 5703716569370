<template>
  <div>
    <template v-if="scopeFullPath && $store.state.curLanguage">
        <span><strong> {{ $t("SCOPE_LABEL") }} </strong></span>
        <label>{{serverTranslate(universityName)}}</label>
        <template v-if="$store.state.Auth.facultyName">
            <v-icon dense class="mt-2 me-1" v-if="!$store.state.direction">mdi-arrow-right-bold</v-icon>
            <v-icon dense class="mt-2 me-1" v-else>mdi-arrow-left-bold</v-icon>
        </template>
        <label>{{serverTranslate($store.state.Auth.facultyName)}}</label>
        <template v-if="$store.state.Auth.branchName">
            <v-icon dense class="mt-2 ma-1" v-if="!$store.state.direction">mdi-arrow-right-bold</v-icon>
            <v-icon dense class="mt-2 ma-1" v-else>mdi-arrow-left-bold</v-icon>
        </template>
        <label>{{serverTranslate($store.state.Auth.branchName)}}</label>
    </template>
    <template v-else>
        <v-dialog
            v-model="dialog"
            scrollable
            max-width="90vh"
            :persistent="true"
        >
            <v-card>
                <v-card-title>{{$t('SCOPE')}}</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 400px;">
                    <v-treeview
                        dense
                        rounded
                        hoverable
                        activatable
                        item-key="hrId"
                        :items="serverTranslateArray(items,'name')"
                        :fullscreen="$vuetify.breakpoint.mobile"
                        @update:active="selectedScope"
                        return-object
                        class="mb-0 pointer"
                        :class="$vuetify.breakpoint.smAndDown
                                ? $vuetify.breakpoint.xs ?'text-thin':'text-tiny':''"
                    >
                    </v-treeview>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="setNewScope">{{
                      $t("SAVE")
                    }}</v-btn>

                    <!--<v-btn color="darken3" class="white&#45;&#45;text" @click="cancel">{{
                      $t("CANCEL")
                    }}</v-btn>-->
                </v-card-actions>
            </v-card>
        </v-dialog>
    </template>
  </div>
</template>
<script>
  import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
  import globalMethodsMixin from "@/mixins/globalMethods.ts";
  export default {
    name: "pr-scope-tree",
    mixins: [serverTranslationsMixin,globalMethodsMixin],
    props: ["scopeFullPath"],
    data(){
        return{
            items:[],
            dialog:true,
            universityID: this.$store.state.scopeID,
            universityName: this.$store.state.scopeName,
            facultyName:this.$store.state.Auth.facultyName,
            facultyID:this.$store.state.Auth.facultyID,
            branchName:this.$store.state.Auth.branchName,
            branchID:this.$store.state.Auth.branchID,
            scopeLevel:this.$store.state.Auth.scopeLevel,
            appLevels:JSON.parse("[" + this.$store.state.Scope.appLevels + "]")[0],
            errorMessage: this.$store.state.Scope.scopeErrorMessage,
            parent: false,
            activeItem:[],
        }
    },
    created() {
        this.getScopes();
    },
    methods:{
        getScopes:function() {
            this.$http.get("/api/general_data/get_scopes/")
            .then(
            res => {
              if (res) {
                this.items = res.data.data.scopes;
              }
            },
            err => {
                this.showToasted("error",err.response.data.message)
            });
        },
        selectedScope(selectedItem){
            this.activeItem = selectedItem;
            this.universityName = this.items[0].name;
            if (this.activeItem.length>0) {
                const item = this.activeItem[0];
                this.scopeLevel = item.appLevel;
                if(!this.appLevels || !this.appLevels.includes(parseInt(item.appLevel))) return this.showToasted("error",this.errorMessage);
                if(item.parent){ // university
                    this.facultyName = "";
                    this.facultyID = "";
                    this.branchName = "";
                    this.branchID = "";
                    this.universityID =  item.id;
                    this.parent = true;
                } else if(item.facultyId) { // branch
                    this.branchName = item.branchName;
                    this.branchID = item.id;
                    this.facultyID = item.facultyId;
                    this.facultyName = item.facultyName;
                    this.parent = false;
                } else { // faculty
                    this.branchName = "";
                    this.branchID = "";
                    this.facultyName = item.facultyName;
                    this.facultyID = item.id;
                    this.parent = false;
                }
            }
        },
        setNewScope() {
            if (this.activeItem.length === 0) return this.showToasted("error","اختر مجال|Select Scope");
            if(!this.appLevels.includes(this.scopeLevel)) return this.showToasted("error",this.errorMessage);
            if(this.branchID) {
                localStorage.setItem("branchID", this.branchID);
                localStorage.setItem("branchName", this.branchName);
                localStorage.setItem("facultyName",this.facultyName);
                localStorage.setItem("facultyID",this.facultyID);
            } else if(this.facultyID) {
                localStorage.removeItem("branchID");
                localStorage.removeItem("branchName");
                localStorage.setItem("facultyName",this.facultyName);
                localStorage.setItem("facultyID",this.facultyID);
            } else if(this.parent) {
                localStorage.setItem("scopeName", this.universityName);
                localStorage.setItem("scopeID", this.universityID);
                localStorage.removeItem("facultyName");
                localStorage.removeItem("facultyID");
                localStorage.removeItem("branchID");
                localStorage.removeItem("branchName");
            }
            localStorage.setItem("scopeLevel",this.scopeLevel);
            this.dialog = false;
            this.$store.state.Auth.facultyName = this.facultyName;
            this.$store.state.Auth.branchName = this.branchName;
            this.$store.state.Auth.branchID = this.branchID;
            this.$store.state.Auth.facultyID = this.facultyID;
            this.$store.state.scopeName = this.universityName;
            this.$emit("setScope", true);

        },
        cancel() {
            this.dialog = false;
            this.universityID = this.$store.state.scopeID;
            this.universityName = this.$store.state.scopeName;
            this.facultyName =this.$store.state.Auth.facultyName;
            this.facultyID = this.$store.state.Auth.facultyID;
            this.branchName = this.$store.state.Auth.branchName;
            this.branchID = this.$store.state.Auth.branchID;
        }
    }
  }
</script>
