<template>
  <v-card class="mx-auto" outlined>
    <div id="inspire">
      <v-data-table
        :headers="headers"
        :items="serverTranslateArrayKeys(calendars,['name','universityName','facultyName'])"
        sort-by="name"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
          itemsPerPageOptions: [5, 10, 15, 50, 100]
        }"
        :items-per-page="10"
        :hide-default-footer="calendars.length <= 0"
      >
        <template v-slot:item.universityName = {item}>
          <template v-if="item.facultyName">
            {{ item.facultyName }}
          </template>
          <template v-else>
            {{ item.universityName }}
          </template>
        </template>
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <p color="white" class="mb-0">{{ $t("CALENDAR_PERIODS") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="90vh">
              <template v-slot:activator="{ on }">
                <v-btn 
                color="secondary" 
                dark v-on="on"
                :small="!$vuetify.breakpoint.mdAndUp"
                :fab="!$vuetify.breakpoint.mdAndUp"
                @click="openDialog">
                  <template v-if="$vuetify.breakpoint.mdAndUp"> {{$t("ADD_NEW")}} </template>
                  <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-plus-circle</v-icon>
                </v-btn>
                <v-btn
                  color="primary"
                  class="mx-2"
                  :small="!$vuetify.breakpoint.mdAndUp"
                  :fab="!$vuetify.breakpoint.mdAndUp"
                  @click="showScopeTreeFn()">
                    <template v-if="$vuetify.breakpoint.mdAndUp"> {{$t('SCOPE')}} </template>
                    <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-navigation</v-icon>
                </v-btn>
              </template>
              <v-card outlined>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title class="justify-center">
                    <span class="headline white--text text-thin">{{
                      formTitle
                    }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-alert v-show="showAlert" dense outlined type="error">{{
                        serverTranslate(errorMessages)
                      }}</v-alert>
                      <v-row>
                        <v-col cols="12">
                          <pr-scope key="componentKey" :scopeFullPath="true"/>
                        </v-col>
                        <v-col cols="12" class="ma-0" sm="12" md="12" lg="12">
                            <v-select
                                :items="serverTranslateArray(events, 'name')"
                                v-model="editedCalendarItem.eventId"
                                outlined
                                small-chips
                                :label="$t('EVENT_NAME')"
                                item-value="id"
                                item-text="name"
                                :rules="requiredRules"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" class="ma-0" sm="6" md="6" lg="6">
                          <v-menu
                              v-model="startDateMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                          >
                              <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  v-model="editedCalendarItem.startDate"
                                  :label="$t('START_DATE')"
                                  prepend-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  
                              ></v-text-field>
                              </template>
                              <v-date-picker 
                              v-model="editedCalendarItem.startDate"
                              :max="maxDate"
                              :min="minDate"
                              @change="resetEndDateValidation"
                              @input="startDateMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" class="ma-0" sm="6" md="6" lg="6">
                          <v-menu
                              v-model="endDateMenu"
                              :close-on-content-click="false"
                              :nudge-right="40"
                              transition="scale-transition"
                              offset-y
                              min-width="290px"
                          >
                            <template v-slot:activator="{ on, attrs }">
                              <v-text-field
                                  ref="endDate"
                                  v-model="editedCalendarItem.endDate"
                                  :label="$t('END_DATE')"
                                  prepend-icon="event"
                                  readonly
                                  v-bind="attrs"
                                  v-on="on"
                                  :rules="endDateRules"
                                  
                              ></v-text-field>
                            </template>
                            <v-date-picker 
                              v-model="editedCalendarItem.endDate"
                              :max="maxDate"
                              :min="minDate"
                              @change="resetEndDateValidation"
                              @input="endDateMenu = false"></v-date-picker>
                          </v-menu>
                        </v-col>
                        <v-col cols="12" class="ma-0" xs="12" sm="6" md="6" lg="6">
                          <v-select
                              :items="years"
                              v-model="editedCalendarItem.yearId"
                              item-value="id"
                              item-text="name"
                              :label="$t('ACADEMIC_YEAR')"
                              :rules="requiredRules"
                              outlined
                          ></v-select>
                        </v-col>
                        <v-col cols="12" class="ma-0" xs="12" sm="6" md="6" lg="6">
                          <v-select
                              :items="serverTranslateArray(semesters, 'name')"
                              v-model="editedCalendarItem.semesterId"
                              item-value="id"
                              item-text="name"
                              :label="$t('ACADEMIC_SEMESTER')"
                              :rules="requiredRules"
                              clearable
                              outlined
                          ></v-select>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-btn
                      color="primary"
                      class="mx-2"
                      :small="!$vuetify.breakpoint.mdAndUp"
                      :fab="!$vuetify.breakpoint.mdAndUp"
                      @click="showScopeTreeFn()">
                        <template v-if="$vuetify.breakpoint.mdAndUp"> {{$t('SCOPE')}} </template>
                        <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-navigation</v-icon>
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="save">{{
                      $t("SAVE")
                    }}</v-btn>

                    <v-btn color="darken3" class="white--text" @click="close">{{
                      $t("CANCEL")
                    }}</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon meduim color="primary" @click="editCalendarEvent(item)"
                  >mdi-pencil</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("EDIT") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon
                  meduim
                  color="delete"
                  class="mx-2"
                  @click="deleteCalendarEvent(item)"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("DELETE") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:footer.page-text="props">
            {{ props.pageStart }} - {{ props.pageStop }} {{$t('PAGE_TEXT')}} {{ props.itemsLength }}
        </template>
        <template v-slot:no-data>
            <v-alert icon="warning" class="mt-2" color="light5">
            {{ $t("NO_DATA") }}
            </v-alert>
        </template>
      </v-data-table>
      <pr-confirmDialog ref="confirm"></pr-confirmDialog>
      <pr-scope :key="componentKey" v-if="showScopeTree == true" v-on:setScope="onScopeChanged" :scopeFullPath="false"/>
    </div>
  </v-card>
</template>
<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import scope from "@/components/shared/ScopeTree.vue"

export default {
  name: "pr-certification-types",
  components: {
    "pr-confirmDialog": ConfirmDialog,
    "pr-scope":scope
  },
  mixins: [serverTranslationsMixin,globalMethodsMixin],
  data() {
    return {
      requiredRules: this.$store.state.Rules.requiredRules,
      endDateMenu: false,
      startDateMenu: false,
      dialog: false,
      valid: true,
      showAlert: false,
      showScopeTree:  false,
      componentKey:0,
      errorMessages: "",
      years: [],
      semesters: [],
      calendars: [],
      calendatsIds:[],
      events: [],
      calendarIndex: -1,
      editedCalendarItem: {
        yearId: this.$store.getters.getActiveAcademicYear.id,
        semesterId: "",
        eventId:"", 
        startDate: new Date().toISOString().substr(0, 10),
        endDate:new Date().toISOString().substr(0, 10),
      },
      defaultCalendarItem: {
        yearId: this.$store.getters.getActiveAcademicYear.id,
        semesterId: "", 
        eventId:"", 
        startDate: new Date().toISOString().substr(0, 10),
        endDate:new Date().toISOString().substr(0, 10),
      },
    };
  },
  computed: {
    formTitle() {
      return this.calendarIndex === -1
        ? this.$t("ADD_NEW") + " " + this.$t("CALENDAR_PERIODS")
        : this.$t("EDIT") + " " + this.$t("CALENDAR_PERIODS");
    },
    direction: function() {
      return this.$store.state.direction;
    },
    endDateRules() {
      return  [
          v =>
            new Date(this.editedCalendarItem.endDate) >= new Date(this.editedCalendarItem.startDate) ||
            this.$t("DATE_FROM_TO_VALIDATION")
        ]
    },
    headers: function() {
      return [
        {
          text: this.$t("EVENT_NAME"),
          align: "start",
          sortable: true,
          value: "name"
        },
        { text: this.$t("START_DATE"), value: "startDate", sortable: true },
        { text: this.$t("END_DATE"), value: "endDate", sortable: true },
        { text: this.$t("ACADEMIC_YEAR"), value: "yearName", sortable: true },
        { text: this.$t("SCOPE_LABEL").slice(0, -2),value: "universityName", sortable: true},
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "150px"
        }
      ];
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      this.$http.get("/api/calendar/list_calendar_data/").then(
        res => {
          this.calendars = res.data.data.events;
          for (let i = 0; i < this.calendars.length; i++) {
            this.calendatsIds.push(this.calendars[i]['id'])
          }
        },
        err => {
          this.showToasted("error",err.response.data.message);
        }
      );
    },
    deleteCalendarEvent(calendarItem) {
            this.$refs.confirm
        .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
        .then(confirm => {
          if (confirm) {
            const id = { calenderEventID: calendarItem.id };
            this.$http.delete("/api/calendar/delete_calendar_event/", { data: id }).then(
              res => {
                this.showToasted("success", res.data.message)
                this.initialize();
              },
              err => {
                 this.showToasted("error", err.response.data.message)
                if (err.response.status === 404) {
                  this.initialize();
                }
              }
            );
          }
        });


        //delete
    },

    openDialog () {
      this.$http.get("/api/calendar/get_calendar_filters/").then(
        res => {
          this.years = res.data.data.years;
          this.semesters = res.data.data.semesters;
          this.events = res.data.data.events;
        },
        err => {
          this.showToasted("error",err.response.data.message);
          this.close();
        }
      );
    },

    close() {
      this.$refs.form.resetValidation();
      this.dialog = false;
      this.showAlert = false;
      this.errorMessages = "";
      this.editedCalendarItem = Object.assign({}, this.defaultCalendarItem);
      this.calendarIndex = -1;
    },

    resetEndDateValidation() {
      if (
        new Date(this.editedCalendarItem.endDate) >= new Date(this.editedCalendarItem.startDate)
      ) {
        this.$refs.endDate.resetValidation();
      } else {
        this.$refs.form.validate()
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        this.editedCalendarItem.semesterId = this.editedCalendarItem.semesterId? this.editedCalendarItem.semesterId : "";
        if (this.editedCalendarItem.id > -1) { // edit case
          this.updateCalendarEvent();
        } else { /// add case
          this.$http.post(
            "/api/calendar/add_calendar_event/",
            this.editedCalendarItem
          ).then(
            res => {
              this.showToasted("success",res.data.message);
              this.close();
              this.initialize();
            },
            err => {
              this.errorMessages = err.response.data.message;
              this.showAlert = true;
              this.initialize();
            }
          );
        }
      }
    },

    editCalendarEvent(calendarItem) {
      this.calendarIndex = this.calendars.indexOf(calendarItem.id);
      this.dialog = true;
      this.openDialog()
      this.editedCalendarItem.id=calendarItem.id
      this.editedCalendarItem.eventId = calendarItem.eventId
      this.editedCalendarItem.yearId = calendarItem.yearId
      this.editedCalendarItem.semesterId=calendarItem.semesterId
      this.editedCalendarItem.startDate = calendarItem.startDate
      this.editedCalendarItem.endDate = calendarItem.endDate
    },

    updateCalendarEvent() {
      this.$http.put(
        "/api/calendar/edit_calendar_event/",
        this.editedCalendarItem
      ).then(
        res => {
          this.showToasted("success",res.data.message);
          this.close();
          this.initialize();
        },
        err => {
          this.errorMessages = err.response.data.message;
          this.showAlert = true;
          this.initialize();
        }
      );
    },
    showScopeTreeFn(){
      this.showScopeTree = true;
      this.componentKey +=1;
    },
    onScopeChanged(changed) {
      if (changed) this.initialize();
    },
  }
};
</script>
