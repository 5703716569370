var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-content',{staticClass:"background-img"},[_c('v-container',{attrs:{"fill-height":""}},[_c('v-layout',{attrs:{"align-space-between":"","justify-center":"","fill-height":""}},[_c('v-card',{attrs:{"width":_vm.$vuetify.breakpoint.mdAndUp ? '50%' : '100%'}},[_c('v-card-title',{style:(_vm.backgroundColor)},[_c('v-list-item',{staticClass:"justify-center"},[_c('v-avatar',{attrs:{"size":"130"}},[_c('img',{attrs:{"src":_vm.scopeLogo}})])],1),_c('v-list-item',{staticClass:"d-flex justify-center"},[_c('div',{class:[
                _vm.$vuetify.breakpoint.mdAndUp
                  ? 'head1-size '
                  : 'head1-sm-size ',
                _vm.lang == 'ar' ? ' headAr ' : ' headEn '
              ]},[_vm._v(" "+_vm._s(_vm.serverTranslate(_vm.systemName))+" ")])]),_c('v-list-item',{staticClass:"d-flex justify-center"},[_c('div',{class:[
                _vm.$vuetify.breakpoint.mdAndUp
                  ? 'head2-size '
                  : 'head2-sm-size ',
                _vm.lang == 'ar' ? ' headAr ' : ' headEn '
              ]},[_vm._v(" "+_vm._s(_vm.serverTranslate(_vm.scopeName))+" ")])])],1),_c('v-card-content',[_c('v-form',{ref:"form",staticClass:"mx-7",on:{"submit":_vm.login}},[_c('v-text-field',{attrs:{"autofocus":"","maxlength":"70","rules":_vm.usernameRules,"placeholder":_vm.$t('USERNAME'),"required":"","prepend-inner-icon":"person","color":"secondary"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}},model:{value:(_vm.userData.username),callback:function ($$v) {_vm.$set(_vm.userData, "username", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"userData.username"}}),_c('v-text-field',{staticClass:"mb-7",attrs:{"maxlength":"20","rules":_vm.passwordRules,"placeholder":_vm.$t('PASSWORD'),"type":"password","required":"","prepend-inner-icon":"lock","color":"secondary"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.login.apply(null, arguments)}},model:{value:(_vm.userData.password),callback:function ($$v) {_vm.$set(_vm.userData, "password", $$v)},expression:"userData.password"}}),_c('pr-alerts',{attrs:{"error":_vm.serverTranslate(_vm.error),"snackbar":_vm.snackbar}})],1)],1),_c('v-card-actions',{staticClass:"justify-space-around row align-center"},[_c('v-btn',{staticClass:"white--text secondary title",on:{"click":_vm.login}},[_vm._v(_vm._s(_vm.$t("LOGIN")))])],1),_c('v-card-actions',{staticClass:"justify-space-around row align-center"},[_c('v-btn',{staticClass:"white--text secondary px-0 py-0",attrs:{"small":"","text":""},on:{"click":_vm.forgotPassword}},[_vm._v(_vm._s(_vm.$t("FORGOT_PASSWORD")))]),_c('pr-changeLanguage',{attrs:{"showTooltip":"false"},on:{"swichLanguage":_vm.onLanguageChanged}})],1)],1),_c('pr-forgotPassword',{ref:"forgotPassword"})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }