<template>
    <div>
        <v-card v-if="failedData && failedData.length > 0">
            <v-toolbar dark color="primary" class="justify-end">
              <v-toolbar-title>{{$t("PULLING_STEPPER.STEP4.MSG")}}</v-toolbar-title>
                <v-spacer></v-spacer>

            </v-toolbar>
            <v-divider></v-divider>
            <div class="d-flex justify-end pa-2">
              <download-excel
                class   = "btn btn-default"
                :data   = "excelData"
                :fields = "excelHeader"
                worksheet = "sheet1"
                :name= "$t('PULLING_STEPPER.FILE_NAME') + '.xls'">

                <v-btn class="mx-2" color="secondary"
                    :small="!$vuetify.breakpoint.mdAndUp"
                    :fab="!$vuetify.breakpoint.mdAndUp"
                >
                    <span v-if="$vuetify.breakpoint.mdAndUp">{{$t('EXPORT')}}</span>
                    <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-cloud-download</v-icon>
                </v-btn>

              </download-excel>
            </div>
                <v-simple-table fixed-header >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>
                              {{$t("PULLING_STEPPER.MSG")}}
                          </th>
                          <th class="text-left" v-for="(item,index) in failedHeader" :key="index">
                              {{item}}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(innerData,index) in failedData.slice(0,20)" :key="index">
                          <td class="text-left" v-for="(item,innerIndex) in innerData" :key="innerIndex">
                              {{item.value}}
                          </td>
                        </tr>
                      </tbody>
                        <tfoot>
                            <tr v-if="failedData.length > 20 ">
                                <td :colspan="failedHeader.length" class="align-center">
                                    <span class="h1 d-flex justify-center font-weight-black">
                                        {{$t("PULLING_STEPPER.MORE_DATA",{num:failedData.slice(20).length})}}
                                    </span>
                                </td>
                            </tr>
                        </tfoot>
                    </template>
                </v-simple-table>
        </v-card>
         <v-alert v-else dense outlined type="success">
               {{$t("SUCCESS")}}
         </v-alert>
         <v-row>
              <v-col cols="12" class="justify-end d-flex">
                  <v-btn color="error"
                         @click="EndPulling()"
                         class="ma-1">{{$t("END")}}
                  </v-btn>
              </v-col>
          </v-row>
    </div>
</template>
<script>
    import { EventBus } from "@/plugins/bus";
     export default {
         name: "pr-step4",
         props:["failedData","failedHeader","excelHeader","excelData"],
         data(){
             return {}
         },
         methods:{
             EndPulling(){
                 this.$router.push("/home");
             }
         }
     }
</script>