<template>
  <v-app app>
    <div ref="container" class="light6 main-container">
      <router-view />
    </div>
    <pr-footer></pr-footer>
  </v-app>
</template>

<script>
import Vue from "vue";
import Footer from "@/components/layout/Footer.vue";
export default Vue.extend({
   name: "App",
   components: {
      "pr-footer": Footer
   },
})
</script>
