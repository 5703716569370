
    <div class="chart-container">
      <canvas ref="canvas" ></canvas>
    </div>


  
  <script>
  import { Bar , mixins} from 'vue-chartjs'
  const { reactiveProp } = mixins;
  export default {
    name: 'MultiBarChart',
    extends: Bar,
    mixins: [reactiveProp],
    props: {
        chartData: {
        type: Object,
        required: true
        }
    },
    data() {

      const dataValues = this.chartData.datasets.map(dataset => dataset.data).flat();
       const minValue = Math.min(...dataValues);
     const maxValue = Math.max(...dataValues);
     this.chartData.datasets.forEach(dataset => {
            dataset.barPercentage = 1; // Controls the width of the bars
            dataset.categoryPercentage = .9; // Controls the spacing between the bars
        });
      return {
      
        options: {
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            xAxes: [
              {

                stacked: false
              }
            ],
            yAxes: [
              {
                stacked: false,
                type: 'logarithmic', // Use logarithmic scale for Y-axis
                 min: 0,
                 max: maxValue,
                 stepSize: 10,
                 ticks: {
                              min: undefined, // Set dynamically based on data
                             max: undefined, // Set dynamically based on data
                              autoSkip: true, // Automatically skip ticks to prevent label overlap
                              autoSkipPadding: 20, // Increase if labels are still overlapping
                              maxTicksLimit: 10, // Adjust based on your actual data range
                              callback: function (value) {
                                if (value === 0) return ''; // Avoid displaying zero on logarithmic scale
                                return value.toLocaleString();
                                }
                        }
            }
            ]
          }
        }
      }
    },
    mounted() {
      this.renderChart(this.chartData, this.options)

    },
    methods: {
     getBase64Image() {
            const canvas = this.$refs.canvas;
        return canvas ? canvas.toDataURL('image/png') : '';
        }
    },
    
    watch: {
      chartData: {
        deep: true,
        immediate: true,
        handler(newData) {
            if (newData && newData.datasets) {

                const dataValues = newData.datasets.map(dataset => dataset.data).flat()
                const minValue = Math.min(...dataValues);
                const maxValue = Math.max(...dataValues);

                this.options.scales.yAxes[0].ticks.min = minValue;
                this.options.scales.yAxes[0].ticks.max = maxValue;

                this.renderChart(newData, this.options);
            }
        }
    }
    }
  }
  </script>
  
  <style scoped>
  .chart-container {
    position: relative;
    width: 100%;
    height: 350px; /* Adjust the height as needed */
  }
  </style>
  