<template>
   <div>
      <v-card>
         <v-card-title>
            {{$t("STUDENT_NOMINATE_APPLICATION")}}
         </v-card-title>
         <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-bind="attrs" v-on="on" color="primary" dark @click="runApprovalRule" :disabled="!ruleId">
               {{ $t("APPLY_ACCEPTANCE_RULE") }}
            </v-btn>
          

         </v-card-actions>
         <v-card-content>
            <v-form ref="filterForm">
               <v-container>
                  <v-row>
                     <v-col cols="12" xs="9" sm="9" md="9" lg="9">
                        <v-select
                           v-model="facultyId"
                           :items="serverTranslateArray(faculties, 'facultyName')"
                           :label="$t('FACULTY')"
                           item-value="facultyId"
                           item-text="facultyName"
                           @change="showRules"
                           outlined
                           class="col-9 d-inline-flex"
                        ></v-select>
                     </v-col>
                     <v-col>
                         <v-tooltip color="primary" class="justify-end" bottom>
                           <template v-slot:activator="{ on, attrs }">
                              <v-icon large color="primary"
                               v-bind="attrs" v-on="on">mdi-information-outline</v-icon>
                           </template>
                           <div>
                              {{$t("APPROVAL_RULES")}}
                              <br/>
                              <span class="subheading secondary--text">{{$t("ALLOWED_STUDENTCOUNT")}}: </span>{{studentNumber}}
                              <br/>
                              <span class="subheading secondary--text">{{$t("DEFAULT_STUDENTS_COUNT")}}: </span>{{totalDegree}}
                           </div>
                         </v-tooltip>

                     </v-col>
                  </v-row>

               </v-container>
            </v-form>

         </v-card-content>
         <pr-confirmDialog ref="confirm"></pr-confirmDialog>
      </v-card>
      <v-card class="mt-5">
         <v-tabs
            v-model="tab"
            centered
            icons-and-text
         >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#tab-1"
            class="secondary--text"
            >
               {{$t("ACCEPT_CANDIDATES")}}
               <v-icon>mdi-account-check</v-icon>
            </v-tab>
            <v-tab href="#tab-2" class="secondary--text">
               {{$t("REJECT_CANDIDATES")}}
               <v-icon>mdi-account-cancel</v-icon>
            </v-tab>
          
         </v-tabs>
         <div class="d-flex justify-right  my-2 mx-2">
            <v-btn  color="darken3" class="white--text"  v-if="acceptedStuWithoutLimit && acceptedStuWithoutLimit.length > 0" @click="acceptApplications(acceptedStuWithoutLimit)">
               {{ $t("ACCEPT_APPLICATIONS") }}
            </v-btn>
            <v-btn  color="darken3"  class="white--text" v-if="rejectedStuWithoutLimit && rejectedStuWithoutLimit.length > 0" @click="rejectApplications(rejectedStuWithoutLimit)">
               {{ $t("REJECT_APPLICATIONS") }}
            </v-btn>
         </div>
         <v-tabs-items v-model="tab">
            <v-tab-item
               value="tab-1"
            >
               <v-card flat>
                  <v-card-content>
                     <v-data-table
                       :headers="headers"
                       :items="serverTranslateArrayKeys(acceptedStu, ['studentName', 'facultyName', 'branch'])"
                       :options.sync="options1"
                       :server-items-length="totalPagesAccept"
                       :loading="loading"
                       :items-per-page="10"
                       :footer-props="{
                         showFirstLastPage: true,
                         firstIcon: 'mdi-arrow-collapse-left',
                         lastIcon: 'mdi-arrow-collapse-right',
                         prevIcon: 'mdi-arrow-left',
                         nextIcon: 'mdi-arrow-right',
                         itemsPerPageOptions: [5,10,15,50,100],
                       }"
                       class="elevation-1 pointer"
                       :hide-default-footer="acceptedStu.length > 0 ? false : true"
                       @click:row="openApplicationDetails($event)"
                     ></v-data-table>
                  </v-card-content>
               </v-card>
            </v-tab-item>
            <v-tab-item
               value="tab-2"
            >
            <v-card flat>
               <v-card-content>
                  <v-data-table
                    :headers="headers"
                    :items="serverTranslateArrayKeys(rejectedStu, ['studentName', 'facultyName', 'branch'])"
                    :options.sync="options2"
                    :server-items-length="totalPagesReject"
                    :loading="loading"
                    :items-per-page="10"
                    :footer-props="{
                      showFirstLastPage: true,
                      firstIcon: 'mdi-arrow-collapse-left',
                      lastIcon: 'mdi-arrow-collapse-right',
                      prevIcon: 'mdi-arrow-left',
                      nextIcon: 'mdi-arrow-right',
                      itemsPerPageOptions: [5,10,15,50,100],
                    }"
                    class="elevation-1 pointer"
                    :hide-default-footer="rejectedStu.length > 0 ? false : true"
                    @click:row="openApplicationDetails($event)"
                  ></v-data-table>
               </v-card-content>
            </v-card>
            </v-tab-item>
         </v-tabs-items>

         <ShowApplicationDetails />
      </v-card>
   </div>
</template>
<script>
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import ShowApplicationDetails from "@/components/modules/studentData/ManageApplications/ShowApplicationDetails";
import { EventBus } from "@/plugins/bus";

export default {
   mixins: [serverTranslationsMixin, globalMethodsMixin],
   components: {
      "pr-confirmDialog": ConfirmDialog,
        ShowApplicationDetails
   },
   mounted() {
      this.$store.state.Auth.facultyID = null;
      this.getFacultiesWithRules()
        // eslint-disable-next-line @typescript-eslint/no-this-alias
       const mv = this;
        EventBus.$on("updateRunListAfterAction", ()=> {
           if (this.tab === 'tab-1') {
            mv.getAccepted(false);
          } else {
             mv.getRejected(false);
          }
       });
   },
   data() {
      return {
         faculties:[],
         facultyId:"",
         studentNumber:0,
         totalDegree:0,
         ruleId:false,//Tables
          tab:"tab-1",
         loading:false,
         options1:{},
         options2:{},
         statusStudent:1,
         acceptedStu:[],
         acceptedStuWithoutLimit:[],
         rejectedStuWithoutLimit:[],
         rejectedStu:[],
         totalPagesAccept:0,
         totalPagesReject:0,
      }
   },
   methods: {
      getFacultiesWithRules() {
         const rules = {
         "hasRules":true
         }
         this.$http.post("/api/general_data/list_faculities_with_rules/", rules)
         .then(
            res => {
               this.faculties = res.data.data.faculties;
            },
         err => {
            this.showToasted("error", err.response.data.message)
         })
      },
      showRules() {
         this.studentNumber = this.faculties.find(
            e => e.facultyId == this.facultyId
         ).studentNumber
         this.totalDegree = this.faculties.find(
            e => e.facultyId == this.facultyId
         ).totalDegree
         this.ruleId = this.faculties.find(
            e => e.facultyId == this.facultyId
         ).ruleId
         /***************Reset Table options*******************/
         this.tab = "tab-1";
         this.getAccepted(true);
      },
      resetSearchView(){
         this.tab = "tab-1";
         this.getAccepted(true);
      },
     acceptApplications(acceptedStuWithoutLimit){
          this.$refs.confirm
         .open("CONFIRMATION_MESSAGE", "ACCEPT_ALL_APPLICATION_CONFIRM")
         .then(confirm => {
           if (confirm) {
             const data={
                  "appStudentData":acceptedStuWithoutLimit,
                   "statusStudentUpdated":"2"
             }
             this.$http.put("/api/approval_rules/edit_status_all_recommended/", data)
               .then(
                  res => {
                     this.showToasted("success", res.data.message)
                     this.getAccepted();
                  },
                  err => {
                     this.showToasted("error", err.response.data.message)
                  }
               )

           }
         })

     },
     rejectApplications(rejectedStuWithoutLimit){
            this.$refs.confirm
         .open("CONFIRMATION_MESSAGE", "REFUSE_ALL_APPLICATIONS_CONFIRM")
         .then(confirm => {
           if (confirm) {
             const data={
                  "appStudentData":rejectedStuWithoutLimit,
                   "statusStudentUpdated":"3"
             }
             this.$http.put("/api/approval_rules/edit_status_all_recommended/", data)
               .then(
                  res => {
                     this.showToasted("success", res.data.message)
                     this.getAccepted();
                  },
                  err => {
                     this.showToasted("error", err.response.data.message)
                  }
               )

           }
         })


     },
      runApprovalRule() {

         this.$refs.confirm
         .open("CONFIRMATION_MESSAGE", "APPLY_RULE_CONFIRM")
         .then(confirm => {
            if (confirm) {
                this.resetSearchView();
               const data = {
                  "facultyId":this.facultyId,
                  "ruleId":this.ruleId,
                  "yearId":this.$store.getters.getActiveAcademicYear.id,
                  "semesterId":this.$store.getters.getActiveAcademicSemester.id
               }
               this.$http.post("/api/approval_rules/apply_rules/", data)
               .then(
                  res => {
                     this.showToasted("success", res.data.message)
                     this.getAccepted();
                  },
                  err => {
                     this.showToasted("error", err.response.data.message)
                  }
               )
            }
         })
      },
      getAccepted(useDefaultOption=true) {
         if(useDefaultOption) {
            this.options1.page = 1;
            this.options1.sortBy = [];
            this.options1.sortDesc = [];
            this.options1.itemsPerPage = 10;
         }
         if(!this.facultyId)
             return false;
          this.loading = true;
          return new Promise((resolve, reject) => {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options1
            const data = {
               "statusStudent":1,
               "facultyId":this.facultyId,
               "yearId":this.$store.getters.getActiveAcademicYear.id,
               "semesterId":this.$store.getters.getActiveAcademicSemester.id,
               "page":page,
               "pageSize":itemsPerPage,
               "orderType":"asc",
               "orderBy":"applyDate"
            }
            if (sortDesc && sortDesc.length === 1 && sortBy.length === 1) {
               data["orderType"] = sortDesc[0] ? "asc" : "desc"
               data["orderBy"] = sortBy[0]
            }
            this.acceptedStu = []
            this.$http.post("/api/approval_rules/get_all_recommended/", data)
            .then(
               res => {
                  this.acceptedStu = res.data.data.studentsData.results;
                  this.acceptedStuWithoutLimit=res.data.data.allData;
                  this.totalPagesAccept = res.data.data.studentsData.total
                  this.loading = false
                  resolve({
                   "items":this.acceptedStu,
                   "total":this.totalPagesAccept,
                 });
                   this.rejectedStu = []
                   this.rejectedStuWithoutLimit=[]
               },
               err => {
                  this.loading = false
                  this.showToasted("error", err.response.data.message)
               }
            )
         })
      },
      getRejected(useDefaultOption = true) {
          if(useDefaultOption) {
              this.options2.page = 1;
              this.options2.sortBy = [];
              this.options2.sortDesc = [];
              this.options2.itemsPerPage = 10;
          }
          if(!this.facultyId)
              return false;

          this.loading = true
          return new Promise((resolve, reject) => {
            const { sortBy, sortDesc, page, itemsPerPage } = this.options2
            const data = {
               "statusStudent":0,
               "facultyId":this.facultyId,
               "yearId":this.$store.getters.getActiveAcademicYear.id,
               "semesterId":this.$store.getters.getActiveAcademicSemester.id,
               "page":page,
               "pageSize":itemsPerPage,
               "orderType":"asc",
               "orderBy":"applyDate"
            }
            if (sortDesc && sortDesc.length === 1 && sortBy.length === 1) {
               data["orderType"] = sortDesc[0] ? "asc" : "desc"
               data["orderBy"] = sortBy[0]
            }
            this.rejectedStu = []
            this.$http.post("/api/approval_rules/get_all_recommended/", data)
            .then(
               res => {
                  this.rejectedStu = res.data.data.studentsData.results
                  this.rejectedStuWithoutLimit=res.data.data.allData;
                  this.totalPagesReject = res.data.data.studentsData.total
                  this.loading = false
                  resolve({
                   "items":this.rejectedStu,
                   "total":this.totalPagesReject,
                 });
                   this.acceptedStu = []
                   this.acceptedStuWithoutLimit=[]
               },
               err => {
                  this.loading = false
                  this.showToasted("error", err.response.data.message)
               }
            )

         })
      },
      openApplicationDetails($event) {
         const applicationId = $event.applyId;
         const studentId = $event.studentId;
         EventBus.$emit('showApplicationDetails', applicationId, studentId, false)
      }
   },
    watch:{
       tab(){
          if (this.tab === 'tab-1') {
            this.getAccepted(true);
          } else {
             this.getRejected(true);
          }
       },
       options1() {
            this.getAccepted(false);
      },
       options2() {
          this.getRejected(false)
      },
    },
    computed: {
     direction: function() {
       return this.$store.state.direction;
     },
     headers: function() {
       return [
         {
           text: this.$t("STUDENT_NAME"),
           align: "start",
           value: "studentName",
           sortable: false,
         },
         {
            text: this.$t("FACULTY_NAME"),
            value: "facultyName",
            sortable: false
         },
         {
            text: this.$t("BRANCH"),
            value: "branch",
            sortable: false
         },
         { text: this.$t("TOTAL_DEGREE"),
           value: "totalDegree",
           sortable: true
         },
         { text: this.$t("APPLY_DATE"),
           value: "applyDate",
           sortable: true
         },
         { text: this.$t("STUDENT_NID"),
           value: "studentIdentity",
           sortable: false,
         }
       ];
     }
},
};
</script>
