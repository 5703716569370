<template>
  <v-dialog
    v-model="dialog"
    max-width="90vh"
    :persistent="true"
  >
    <v-card>
      <v-toolbar  class="mb-2" dark :color="options.color" dense flat>
        <v-toolbar-title 
        class="white--text  font-weight-bold justify-center"
        :class="$vuetify.breakpoint.smAndDown
        ? $vuetify.breakpoint.xs ?'text-thin':'text-tiny':''"
        >
            {{faculty}}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-simple-table class="border-0" height="400" fixed-header>
            <template v-slot:default>
                <thead>
                    <tr>
                        <th>{{$t("BRANCH")}}</th>
                        <th>{{$t("STUDENTS_COUNT")}}</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="item in branches" :key="item.branchId">
                        <td>{{ item.branchName }}</td>
                        <td>{{ item.studentsNumbers }}</td>
                    </tr>
                </tbody>
            </template>
        </v-simple-table>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.native="agree">{{ $t("CLOSE") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  props: {
      branches: Array,
      faculty: String
    },
  name: "pr-students-branches-dialog",
  data: () => ({
    dialog: false,
    options: {
      color: "primary",
      zIndex: 200,
    }
  }),
  created() {
      this.dialog =true;
  },
  methods: {
    agree() {
      this.dialog = false;
    }
  }
};
</script>
