<template>
<div>
   <v-footer class="pr-4 justify-center light5 darken3--text" app>
  <!-- <v-footer absolute class="py-4 pr-4 justify-center light6 " no-gutters> -->
    <div class="text-center">
      {{ $t("COPY_RIGHT") }}
      © 2019-2020 -
      <a href="http://www.intlaqcit.com/" target="_blank">{{ $t("CITC") }}</a>
<!--      <a href="http://www.mans.edu.eg/" target="_blank">{{ // $t("MANS") }} -</a>-->
      <v-bottom-sheet v-model="sheet" inset>
<!--        <template v-slot:activator="{ on }">-->
<!--          <a href="#" v-on="on">{{ $t("CONTACT_US") }}</a>-->
<!--        </template>-->
        <v-sheet class="text-center title secondary--text">
          <v-container fill-height fluid v-if="direction">
            <v-row justify="center" class="headline"
              >للتواصل مع مسئولي القبول والتسجيل  {{serverTranslate(scopeName)}}</v-row
            >
            <div class="row title mx-2">
              يمكنكم الاتصال على الأرقام التالية:
            </div>
            <v-list-item-group>
              <v-list-item class="body-4 darken4--text white" disabled>
              02-33318495 <br>
              02-33318481 <br>
              02-33318498
              </v-list-item>
              <v-list-item class="body-4 darken4--text white" disabled>
              أو الاتصال من خلال الخط الساخن 16541 خلال الفترة من 09 صباحا وحتى 09 مساء
              </v-list-item>
            </v-list-item-group>
          </v-container>
          <v-container fill-height fluid v-else>
            <v-row justify="center" class="headline">
               To communicate with the admission and registration officials of the Egyptian E-Learning University
              </v-row>
            <div class="row title mx-2">
              You can call the following numbers:
            </div>
            <v-list-item-group>
              <v-list-item class="body-4 darken4--text white" disabled>
              02-33318495 <br>
              02-33318481 <br>
              02-33318498
              </v-list-item>
              <v-list-item class="body-4 darken4--text white" disabled>
                 Or call the hotline 16541 from 09 am to 09 pm
              </v-list-item>
            </v-list-item-group>
          </v-container>
        </v-sheet>
      </v-bottom-sheet>
    </div>
  </v-footer>
</div>
</template>
<script>
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
export default {
  mixins: [serverTranslationsMixin, globalMethodsMixin],
   computed: {
       direction: function() {
         return this.$store.state.direction;
       },
       scopeName() {
         return this.$store.state.scopeName
      }
    }
};
</script>
