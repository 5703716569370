<template>
   <v-dialog
   v-model="dialog"
   max-width="70vh"
   @keydown.esc="cancel"
   :persistent="true"
   >
   <v-card>
      <v-card-title class="justify-center">
         <span class="headline white--text text-thin">{{
            $t('ADD_COURSE')
         }}</span>
      </v-card-title>
      <v-form ref="addCertificateCourse">
         <v-card-text
         class="secondary--text pa-4 pb-8 font-weight-bold"
         >
         <v-autocomplete
         ref="courseList"
         :items="serverTranslateArray(courses, 'name')"
         v-model="course"
         item-value="id"
         item-text="name"
         return-object
         :search-input.sync="courseName"
         :label="$t('WITH_COURSE_NAME')"
         :rules="courseNameRules"
         @keydown="getCourses(0)"
         @keydown.space="preventLeadingSpace"
         clearable
         @click="getCourses(0)"
         ></v-autocomplete>
         <v-text-field
           v-model="totalDegree"
           :label="$t('COURSE_TOTAL')"
           type="number"
           max="999999"
           :rules="totalDegreeRules"
         ></v-text-field>
         <v-checkbox
            :value="addToTotal"
            :label="$t('ADD_TO_TOTAL')"
            v-model="addToTotal"
            :false-value="0"
            :true-value="1"
            color="primary">
         </v-checkbox>
      </v-card-text>
      <v-card-actions class="pt-0">
         <v-spacer></v-spacer>
         <v-btn color="primary" @click.native="saveCourse">{{ $t("SAVE") }}</v-btn>
         <v-btn color="darken3" class="white--text" @click.native="cancel">{{ $t("CANCEL") }}</v-btn>
      </v-card-actions>
   </v-form>
</v-card>
</v-dialog>
</template>
<script>
import serverTranslations from "@/mixins/serverTranslations";
import globalMethodsMixin from "@/mixins/globalMethods.ts";
export default {
   name: "pr-addCourse",
   props:["certificateId"],
   computed: {
      direction: function() {
         return this.$store.state.direction;
      }
   },
   mixins: [serverTranslations, globalMethodsMixin],
   watch: {
      direction: function() {
         if (this.$el.querySelector(".error--text")) {
            this.$refs.addCertificateCourse.validate();
         }
      },
      course() {
         if (!this.course) return this.getCourses(1);
      },
   },
   data() {
      return {
         dialog: false,
         resolve: null,
         reject: null,
         courses:[],
         course:"",
         courseRef: this.$refs.courseList,
         courseName:"",
         addToTotal:0,
         totalDegree:"",
         totalDegreeRules: [
           v => !!v || this.$t("REQUIRED_FIELD"),
           v => (v != undefined && v > 0) || this.$t("TOTAL_MIN"),
           v => (v != undefined && v <= 999999) || this.$t("TOTAL_MAX")
        ],
        courseNameRules:[
           this.$store.state.Rules.required,

        ]
      }
   },
   methods: {
      getCourses(isInit) {
         this.courseName = this.courseName ? this.courseName : "";
         const courseData = {}
         courseData["active"] = 1
         courseData["certificateId"] = this.certificateId
         courseData["courseName"] = this.courseName.length<2?"":this.courseName.slice(0,-1);
         if (isInit == 1 || (isInit == 0 && (courseData["courseName"].length >= 3 || !courseData["courseName"]))) {
            this.$http.post("/api/courses/auto_complete_courses/", courseData)
            .then(
               res => {
                  this.courses = res.data.data.data
               },
               err => {
                  this.showToasted("error", err.response.data.message)
               }
            );
         }
      },
      open() {
         this.dialog = true;
         return new Promise((resolve, reject) => {
            this.getCourses(1);
            this.resolve = resolve;
            this.reject = reject;
         });
      },
      saveCourse() {
         if (this.$refs.addCertificateCourse.validate()) {
            const courseData = {}
            courseData["courseId"] = parseInt(this.course.id)
            courseData["courseDegree"] = parseFloat(this.totalDegree)
            courseData["addToTotal"] = this.addToTotal?this.addToTotal:0;
            courseData["certificateId"] = parseInt(this.certificateId)
            this.$http.post("/api/certificates/add_certificate_course/", courseData)
            .then(
               res => {
                  this.showToasted("success", res.data.message)
                  this.$refs.addCertificateCourse.reset();
                  this.courses=[];
                  this.resolve(true);
                  this.dialog = false;
               },
               err => {
                  this.showToasted("error", err.response.data.message)
               }
            )
         }
      },
      cancel() {
         this.resolve(false);
         this.dialog = false;
         this.courses=[];
         this.$refs.addCertificateCourse.reset();
      }
   }
};
</script>
