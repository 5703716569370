<template>
    <v-form ref="FormStep1">
        <v-row>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                  <v-select
                          :items="years"
                          v-model="yearId"
                          item-value="yearId"
                          item-text="yearName"
                          :label="$t('ACADEMIC_YEAR')"
                          outlined
                          :rules="$store.state.Rules.requiredRules"
                          @change="changeCertificateType();changeInputes()"
                  ></v-select>
            </v-col>
            <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                  <v-select
                          :items="serverTranslateArray(certificatesCategories,'name')"
                          v-model="certificateTypeId"
                          item-value="id"
                          item-text="name"
                          @change="changeCertificateType();changeInputes()"
                          :rules="$store.state.Rules.requiredRules"
                          :label="$t('CERTIFICATE_TYPE')"
                          outlined
                  ></v-select>
            </v-col>
             <v-col cols="12" sm="12" md="12" lg="6">
                     <v-select
                             :items="serverTranslateArray(certificates, 'certificateName')"
                             v-model="certificateId"
                             item-value="certificateID"
                             item-text="certificateName"
                             :label="$t('CERTIFICATE_NAME')"
                             :rules="$store.state.Rules.requiredRules"
                             outlined
                             @change="changeInputes()"
                     ></v-select>
                   </v-col>
        </v-row>
         <v-row>
              <v-col cols="12" class="justify-end d-flex">

                  <v-btn color="error" @click="cancelPulling()"
                         class="ma-1">{{$t("CANCEL")}}</v-btn>
                  <v-btn class="ma-1"
                      color="primary"
                      @click="nextStep()"

                    >
                      {{$t("PULLING_STEPPER.NEXT")}}
                    </v-btn>

              </v-col>
          </v-row>
    </v-form>
</template>

<script>
    import { EventBus } from "@/plugins/bus";
    import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
    import globalMethodsMixin from "@/mixins/globalMethods";
    export default {
        name:"pr-step1",
        mixins: [serverTranslationsMixin,globalMethodsMixin],
        data(){
            return {
                years:[],
                certificatesCategories:[],
                certificates:[],
                yearId:"",
                certificateTypeId : "",
                certificateId:""
            }
        },
        methods:{
            cancelPulling(){
              EventBus.$emit("CancelMap");
            },
          nextStep(){
              if (this.$refs.FormStep1.validate())
                EventBus.$emit("changeStepperIndex",2);
          },
          changeInputes(){
              if(!this.yearId || !this.certificateTypeId || !this.certificateId) return false;
              this.$emit("changeData",{yearId:this.yearId,certificateTypeId:this.certificateTypeId,
                        certificateId:this.certificateId});
          },
          changeCertificateType(){
              if(!this.yearId || !this.certificateTypeId)
                  return false;
            this.$http.post("/api/certificates/courses_certificates_filter/",
                {certificateTypeID: this.certificateTypeId, certificateStatus: false, yearID: this.yearId})
             .then(res => {
                   res = res.data;
                    this.certificates = res.data;
                 },
                err =>{
                 this.$toasted.show(
                        this.serverTranslate(err.response.data.message),
                        {
                            type: "error",
                            icon: "error_outline",
                            position: "bottom-left",
                            duration: 5000,
                            theme: "bubble"
                        }
                 );

                });
          }
        },
        created(){
          this.$http.get("/api/upload_students_data/get_upload_students_period/")
             .then(
                 res=>{
                    res = res.data;
                    this.years = res.data.years ? res.data.years : [];
                    this.certificatesCategories = res.data.certificateCategories ? res.data.certificateCategories : [];
                 },
                 err=>{
                     this.$toasted.show(this.serverTranslate(err.response.data.message),
                        {
                            type: "error",
                            icon: "error_outline",
                            position: "bottom-left",
                            duration: 5000,
                            theme: "bubble"
                        }
                      );
                 }
             );
        }
    }
</script>