<template>
  <div>
    <div class="mt-2" v-if="error != ''">
      <v-alert type="error">{{ $t(error) }}</v-alert>
    </div>
    <v-snackbar
      color="primary"
      top
      :right="snackdir == 'right'"
      :left="snackdir == 'left'"
      v-model="snackbar"
    >
      {{ $t(snackbarText) }}
      <v-btn text @click="snackbar = false">
        {{ $t("CLOSE") }}
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  props: ["error"],
  data() {
    return {
      snackdir: this.$store.state.direction,
      snackbar: false,
      snackbarText: "SUCCESSED_LOGIN"
    };
  }
};
</script>
