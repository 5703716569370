const translationsEn = {
  VALIDATIONS: {
    EMAIL: "invalid mail " ,
    MAX_LENGTH: "Max length is {max} letter",
    INVALID_NATIONAL_ID: "Invalid national id",


  },
  _lang: "عربي",
  LANGUAGE_NAME: "English",
  USERNAME: "Username",
  PASSWORD: "Password",
  LOGIN: "Login",
  SYSTEM_NAME: "Council of Private Universities",
  COPY_RIGHT: "Copyright",
  CITC: "Intlaq for Communications and Information Technology",
  CITC_BRIEF: "CITC",
  MANS: "Mansoura University",
  CONTACT_US: "Contact Us",
  CHANGE_LANGUAGE: "Change Language",
  ACCOUNT: "Account",
  NOTIFICATIONS: "Notifications",
  SCOPE: "Change Scope",
  ACADEMIC_YEAR_SEMESTER: "Change The Academic Semester and Year",
  PROFILE: "Profile",
  LOGOUT: "Logout",
  FORGOT_PASSWORD: "Forgot Password?",
  CLOSE: "Close",
  SUCCESSED_LOGIN: "Successed Login",
  USERNAME_MAX: "Username mustn't Exceed 70 Characters",
  USERNAME_REQUIRED: "Username is Required",
  PASSWORD_MAX: "Password mustn't Exceed 20 Characters",
  PASSWORD_REQUIRED: "Password is Required",
  ACADEMIC_SEMESTER: "Semester",
  ACADEMIC_YEAR: "Academic Year",
  PERIORITY:'periotity',
  SAVE: "Save",
  SEND: "Send",
  SEND_FEEDBACK: "Send",
  CANCEL: "Cancel",
  CREDENTIAL_ERROR: "Username or Password is Wrong",
  NOT_FOUND_SERVER_ERROR: "Bad Request, Please Call Customer Service",
  SECTORS: "Sectors",
  AR_NAME: "Arabic Name",
  EN_NAME: "English Name",
  IS_ACTIVE: "Is Active",
  ADD_NEW: "Add",
  EDIT: "Edit",
  DELETE: "Delete",
  ADD_SECTOR: "Add Sector",
  YES: "Yes",
  NO: "No",
  COUNT: "Count Per Page",
  SUCCESS: "Saved Successfully",
  REQUIRED: "{field} is Required",
  REQUIRED_FIELD: "This Field is Required",
  ACTIVE: "Active",
  NOT_ACTIVE: "Not Active",
  ACTIVATION: "Activation",
  NO_DATA: "No Available Data",
  ARABIC_FIELD: "Enter Arabic Letters Only",
  ENGLISH_FIELD: "Enter English Letters Only",
  ADD_CERTIFICATE_TYPE: "Add Certification Type",
  EDIT_CERTIFICATE_TYPE: "Edit Certification Type",
  CERTIFICATE_TYPES: "Certification Types",
  ACTIONS: "Actions",
  MAX_FIELD: "Max Length of this Field must be less than ",
  MIN_FIELD: "Max Length of this Field must be greater than ",
  CONFIRM: "Confirm",
  CONFIRMATION_MESSAGE: "Confirmation Message",
  CONFIRMATION_UPDATE_RULE:"are you sure you want to update rule even if it applied and transfere system approved and denied to underReview ?",
  DELETE_CONFIRMATION: "Do you want to perform the deletion?",
  PREVENT_NUMBERS_IN_FIRST: "This Field mustn't start with Numbers",
  EDIT_SECTOR: "Edit Sector",
  ACTIVATE: "Activate",
  DEACTIVATE: "De-activate",
  NEED_HELP: "Do you need help ?",
  CERTIFICATIONS: "Certifications",
  CERTIFICATE_NAME: "Certification Name",
  CERTIFICATE_TYPE: "Certification Type ",
  STATUS: "Status",
  REASON:"Reason",
  APPLICATION_TITLE:
    "Application for admission to the academic year {appYear}, {appSemeter} semester",
  IMPORTANT_NOTES: "Important Notes",
  IMPORTANT_NOTES_TEXT:
    "This card is merely a notification to the student at the college to which it is submitted " +
    "and is not considered a document for the student’s enrollment in it." ,

  UserData: {
    PROFILE: "Profile",
    PROFILE_EDIT: "Edit Profile",
    PERSONAL_DATA: "Personal Information",
    CONTACT: "Contact Information",
    CERTIFICATE: "Qualification Data",
    AVATAR: "Profile Picture",
    AR_NAME: "Name in Arabic",
    EN_NAME: "Name in English",
    NATIONALITY: "Nationality Country",
    NATIONAL_ID: "National Id",
    PASSPORT: "Passport",
    GENDER: "Gender",
    MALE: "Male",
    FEMALE: "Female",
    BIRTHDAY: "Birthday",
    PHONE: "Phone Number",
    MOBILE: "Mobile Number",
    ADDRESS: "Address",
    BIRTHPLACE:"Birth Place",
    COUNTRY: "Country",
    STATE: "Governorate / State",
    CITY: "City",
    EMAIL: "E-mail",
    ACCOUNT_STATUS: "Account Status ",
    APPLICATIONS_COUNT:"Applications Count",
    USER_NAME:"User Name",
    CERTIFICATE_TYPE: "Certification Type",
    CERTIFICATE_DEPT: "Department",
    CERTIFICATE_YEAR: "Year",
    CERTIFICATE_RESULT: "Total / GPA",
    CERTIFICATE_PERC: "Percentage",
    CERTIFICATE_RESULT_MAX: "Total / GPA should be less than or equal 9999",
    CERTIFICATE_EGY_MAX: "Total / GPA should be less than or equal 410",
    CERTIFICATE_EQ_TYPE: "Equation Certification",
    CERTIFICATE_OTHER: "Certification Name",
    CERTIFICATE_FOREIGN_TYPE: "Certification Name",
    CANCEL_CONFIRMATION: "Do you want to go to the profile page?",
    PIC_DELETE_CONFIRMATION:
      "Do you want to delete and change  picture?",
    SAVE_CONFIRMATION:
      "Do you want to save and change profile data with the provided data?",
    CHANGE_PASSWORD: "Change Password",
    NEW_PASSWORD: "New Password",
    CONFIRM_NEW_PASSWORD: "Confirm New Password",
  },
  YEAR: "Year",
  RESET: "Reset",
  PRINT:'print',
  DOWNLOAD:'download',
  SEARCH: "Search",
  VIEW: "View",
  TOTAL: "Certification Total Degree",
  OPENED: "Open",
  CLOSED: "Closed",
  ROWS_PER_PAGE_TEXT: "Rows per page:",
  PAGE_TEXT: "Of",
  ADD_CERTIFICATE: "Add Certificate",
  EDIT_CERTIFICATE: "Edit Certificate",
  PREVENT_SPECIAL_CHARACTER_FIELD:
      "This field must not contain special character",
  FILTER_CHANGED_MESSAGE:
      "The previous search result was displayed to change the search values without pressing the search button",
  CANCEL_SAVE_CERTIFICATION:
      "Are you sure you want to cancel saving certificate ? ",
  OPEN: "Open",
  COURSE_NAME: "Course Name",
  COURSE_DEGREE: "Course Degree",
  COURSES: "Courses",
  WITHOUT_CERTIFICATE: "Without Certificate",
  WITH_CERTIFICATE: "With Certificate",
  OPEN_CERTIFICATE: "Open certificate",
  CLOSED_CERTIFICATE: "Closed Certificate",
  CERTIFICATE_STATUS: "Certificate Status",
  TOTAL_MIN: "Certification total degree should be more than 0",
  DELETE_COURSE_CERTIFICATE: "Delete The Certificate from This Course",
  TOTAL_MAX: "Certification total degree shouldn't be more than 999999",
  ACADEMIC_YEAR_CERTIFICATES: "Academic year certificates: ",
  ACADEMIC_YEAR_COURSES: "Academic year Courses: ",
  PULL_CERTIFICATIONS: "Pull Certifications",
  PULL_CERTIFICATIONS_INFO_CONFIRMATION: "Certificates will be pulled with its courses for ",
  PULL_CERTIFICATIONS_DELETE_CONFIRMATION: "With the deletion of certificates on the same type of pulled certificate for the academic year: ",
  PULL_EVEN_IF: " even if there are courses for them",
  PULL_CERTIFICATIONS_MESSAGE: "Delete certificates that were previously added on",
  PULL: "Pull",
  FROM: "From ",
  TO: "To ",
  SHOW: "Show",
  BACK: "Back",
  ACADEMIC_YEAR_CLOSED_CERTIFICATES: "Closed certificates for academic year: ",
  ACADEMIC_YEAR_FROM_TO_VALIDATION: "Academic year mustn't equal another academic year",
  ADVANCED_SEARCH: "Advance Search",
  ALL: "All",
  CERTIFICATE_NUMBER: "Number of certificates",
  STUDENTS_COUNT: "Students Count",
  COURSES_COUNT: "Courses Count",
  CERTIFICATE_DATA: "Certificate Data",
  SEARCH_BY: "Search By",
  CERTIFICATE_RELATED_TO_COURSE: "Certificates Related to Course",
  COURSES_RELATED_TO_CERTIFICATE: "Courses Related to Certificate",
  COURSE_DETAILS: "Course Details",
  COURSE_NAME_AR: "Course Name in Arabic",
  COURSE_NAME_EN: "Course Name in English",
  EDIT_COURSE: "Edit Course",
  ADD_COURSE: "Add Course",
  LOCKED_CERTIFICATE: "Can not delete,update or open certificate as students uploaded ",
  FOR: "for",

  ADD_APPROVAL_RULE: "Add New Approval Rule",
  EDIT_APPROVAL_RULE: "Edit the Approval Rule",
  APPROVAL_RULE_ADDED: "Approval rule has been added successfully",
  APPROVAL_RULE: "Approval Rule",
  APPROVAL_RULES: "Approval Rules",

  APPROVAL_RULES_GRID_TITLE: "Approval Rules for the academic year {year}, {semester} semester",
  ACADANIC_YEAR: " academic year ",
  FACULTY: "Faculty",
  COUNT_ACCEPTED_STUDENTS:'Accepted students count',
  COUNT_REFUSED_STUDENTS: 'Refused students count',
  COUNT_DRAFT_STUDENTS:'Under review students count',
  COUNT_UNDER_REVIEW_STUDENTS:'students count',
  COUNT_UNDER_REVIEW_NOFEES_STUDENTS:'Under review students count',
  COUNT_PULLED_SIS_STUDENTS:'Pulled students count',
  COUNT_REFUSED_AS_ACCEPTED_IN_OTHER_STUDENTS:'accepted in other faculty students count',
  COUNT_WAITING_LIST_STUDENTS:'Waiting List students count',
  COUNT_INCOMPLETE_STUDENTS:'Incomplete students count',
  COUNT_STUDENT:'Students count',
  OVER_ALL_COUNT_STUDENT:'Over all students count',
  CERTIFICATE: "Certificate",
  SPECIALIZATION:'specialization',
  UNIVERSITY: "University",
  SEMESTER: "Semester",
  TOTAL_DEGREE: "Total Degree",
  CERTIFIED_DEGREE: "Certified Degree",
  STUDENTS_NUMBERS: "Available Places",
  STUDENTS_NUMBERS_FOR: "Students Number for ",
  UNIVERSITY_NAME: "University",
  FACULTY_NAME: "Faculty",
  YEAR_NAME: "Year",
  SEMESTER_NAME: "Semester",
  ALLOWED_TOTALDEGREE: "Allowed Total Degree",
  ALLOWED_STUDENTCOUNT: "Allowed Student Count",
  RECOMMENDED_ACCEPTED: "Recommended Accepted",
  RECOMMENDED_REFUSED: "Recommended Refused",

  NUMBER_FIELD: "This field must be consists of numbers only",
  INT_NUMBER_FIELD: "This field must be consists of integer numbers only",
  TOTAL_DEGREE_MAX: "Total degree should be less than or equal 9999",
  STUDENTS_NUMBERS_MAX: "Students number should be bigger than 0 and less than or equal 100000",
  BRANCHES_NUMBERS_MAX: "Students number for branches shouldn't be bigger than total students numbers",
  COMPLETE_DATA_ERROR: "Please, fill the following information correctly to save",
  STUDENT_NOMINATE_APPLICATION:"Student Nominate Application",
  MANAGE_STUDENTS_APPLICATIONS:"Manage Students Applications",
  MANAGE_STUDENTS_DATA:" Students Data Management ",
  DEFAULT_STUDENTS_COUNT:"Default acceptance grade",
  APPLY_ACCEPTANCE_RULE:"Apply Acceptance Rule",
  ACCEPT_APPLICATIONS:"Accept all Applications",
  REJECT_APPLICATIONS:"Refuse all Applications",
  APPLY_ACCEPTANCE_RULE_TITLE:"when apply the rule Applications will be categorized to (accepted,rejected) according the rule without changing the Application status",
  APPLY_RULE_CONFIRM:"Are You Want To Apply Confirmation Rule?",
  ACCEPT_ALL_APPLICATION_CONFIRM:"Are you sure that all applications nominated for admission will be accepted ?",
  REFUSE_ALL_APPLICATIONS_CONFIRM:"Are you sure that all applications nominated for rejection will be rejected?",
  HELP:"For Help",
  ACCEPT_CANDIDATES:"Accept Candidates",
  REJECT_CANDIDATES:"Reject Candidates",

  CASES: [
      " Draft",
      " Under Reviewing",
      " Accept",
      " Reject",
      " To Be Completed",
      " Recommended Accepted",
      " Recommended Refused",
      " Under Reviewing without fees",
      " pulled to student affairs system ",
      "Reject (due to accepted in another faculty)"
    ],
  DRAFT_STATUS:"Draft",
  UNDER_REVIEW_STATUS:" Under Reviewing",
  ACCEPTED_STATUS:"Accept",
  REFUSED_STATUS:"Reject",
  TO_BE_COMPLETED:"To Be Completed",
  RECOMMENDED_ACCEPTED_STATUS:"Recommended Accepted",
  RECOMMENDED_REFUSED_STATUS:"Recommended Refused",
  UNDER_REVIEW_NOFEES_STATUS:"Under Reviewing Without Fees",
  PULLED_TO_SIS_STATUS:"pulled to student affairs system",
  REFUSED_AS_ACCEPTED_IN_OTHER_STUDENTS:"accepted in other faculty",
  WAITING_LIST:"waiting list ",
  APPLICATION_CASES:"ِApplication Status",
  ALL_COUNT_STUDENT:"Students Total",
  SHOW_CHART:"Display Chart",
  IDENTITY:"Identity Code",
  APPID:"demand number ",
  WithNAMETEXT:"Search with student's name",
  WithIDENTITYTEXT:"Search with Identity",
  WITHAPPID:"Search with Application Id",
  APPLICATIONS:"All Applications",
  STUDENT_NAME:"Student Name",
  APPLY_DATE:"Application Date",
  APPLY_APPLICATION_DATE:"Application Date",
  STUDENT_NID:"Student NID",
  END:"End",
  SHOW_APPLICATION_DETAILS:"Show Application Details",
  IMAGE_ATTACHED: "Attached Image",

    ADMISSION_DATA: {
    APPLICATION_ID: "Application ID",
    APPLICATION_DATE: "Application Date",
    APPLICATION_TIME:"Application Time",
    NEXT: "Next",
    UPLOAD_IMG: "Change Image",
    BACK: "Back",
    SAVE: "Send",
    DRAFT: "Save as draft",
    TERMS:
      "I certify that all the information shown in the application statement is correct and completely in accordance with my personal documents and I bear responsibility for any errors in it without any responsibility on the system or the auditors",
    GO_TO_APP_LIST_CONFIRMATION:
      "Do you want to go to your Applications List ?",
    IMAGE_ATTACHED: "Attached Image",
    APP_STATUS: "Application Status",
    APP_STATUSES: [
      "Draft",
      "Under Reviewing",
      "Accept",
      "Reject",
      "To Be Completed",
      "Recommended Accepted",
      "Recommended Refused",
      "Under Reviewing(No fees needed)",
      "Pulled To  Student Affairs System",
      "Reject (due to accepted in another faculty)",
    ],
    STEP1: {
      NAME: "Personal Data",
      AR_FULLNAME: "Full Name in Arabic",
      EN_FULLNAME: "Full Name in English",
      ID: "Identity number",
      IDTYPE: "",
      ID_IMAGE: "Identification Image",
      NATIONALID: "National ID",
      PASSPORT: "Passport",
      IDHINT: "National Id or passport",
      TYPE: "Gender",
      FEMALE: "Female",
      PHONE: "Mobile Number",
      TELEPHONE: "Telephone Number",
      MALE: "Male",
      EMAIL: "E-Mail",
      BIRTHDATE: "Birth Date",
      IMAGE: "Personal Image",
      COUNTRY: "Country",
      STATE: "province/state",
      CITY: "City",
      NATIIONALITY: "Country of Nationality",
      ADDRESS: "Address",
      BIRTHPLACE:"Birth Place",
      CONTACTS: "Contact Details",
      PERSONAL_DATA: "Personal Data",
      BIRTH_CERT:"Birth Certificate",
      GUARDIAN_CARD:"Guardian Card",
      MILITARY_FORM:"Military Form"
    },
    STEP2: {
      NAME: "Qualificationَ",
      CERTIFICATION: "Certification Type",
      MAJOR: "Major",
      YEAR: "Certificate Year",
      SUM: "Sum",
      OTHER_SUM: "Approval Total",
      EQUIVALENT_CERT_TYPE: "Equivalent Certification Type",
      CERT_FROM_COUNTRY: "From Country",
      CERTIFICATE_IMAGE: "Certification Image",
      CERTIFICATE_FILE: "Certifications  file ",
      CERTIFICATE_NAME: "Certification Name",
      CERTIFICATE_IMAGE2: "Certification Image2",
      CERTIFICATE_IMAGE3: "Certification Image3",
      RECEIPT_IMAGE: "payment receipt image ",
      STU_RESIDENCE_IMAGE: "Student Residence Image",
      TOTAL_MIN: "Certification total degree should be more than 0",
      TOTAL_MAX: "Certification total degree shouldn't be more than 999999"
    },
    STEP3: {
      NAME: "Application",
      ACDYEAR: "Academic Yearِ",
      SEMESTER: "Semester",
      FACULTY: "Faculty",
      BRANCH:"Branch",
      QUOTA:"QUOTA",
      MESSAGE:
        "Select Faculty and check below terms and condition to complete your application",
      TERMS: "Terms and Conditions",
      CHECK: "Confirm",
      SAVE: "Send",
      CANCEL: "Cancel",
      NEXT_CONFIRMATION:"The application data will be saved as a draft so that you can pay the application fee online and submit your application.Accept?"

    },
    STEP4: {
      NAME: "Online Payment",
      EXPIRYPERIOD:"Available until",
      TABLETITLE:"Payment Details",
      PERIOD:"5 hours",
      PAID:"Paid",
      REFERENCENUM:"Reference Number",
      PAIDDATE:"Payment Date",
      PAYMENT_TIME:"Payment Time",
      TITLE: "In order to be able to submit the application, please pay the application fee online via Fawry or Banque Misr",
      PAYMENT_PRICE:"The payment data due is",
      TAXS:"in addition to commission payment",
      TECHNICAL_SUPPORT:"If a problem occurs, please call the following numbers:",
      ONLINE_PAYMENT:"To pay online",
      CLICK_HERE:"Click here",
      LE:"L.E",
      HAS_REFRENCE_NOT_PAID:
      "your order # {paymentNum} is pending please pay the fees The validity of the reference number is 3 days from date {paymentDate} You can pay the fees from any endpoint payment machine or through (Visa/Bank Misr) to pay through them",
      HAS_REFRENCE_PAID: "your order  # {paymentNum} has been paied successfully",
      EXPIRED:"your order # {paymentNum} is expired",
      NOTE:"Note: In the case of extracting a reference number and it has not been paid within 3 days, you can re-request a reference number to pay again through the link that appears in front of you and another number will be created so that you can pay on it"

    }
  },
  NATIONALITY: "Nationality Country",
  APPLICATION_ACTIONS: "Actions",
  APPLICATION_STATUS:"",
  ACCEPT_APPLICATION: "Accept Application",
  ACCEPT_APPLICATION_CONFIRM: "Are you sure you want accept application?",
  REJECT_APPLICATION: "Reject Application",
  WAITING_APPLICATION: "Waiting Application",
  REJECT_APPLICATION_CONFIRM: "Are you sure you want reject application?",
  WAITING_APPLICATION_CONFIRM: "Are you sure you want set application waiting?",
  FEEDBACK_APPLICATION: "Send Feedback",
  FEEDBACK_APPLICATION_CONFIRM: "Are you sure you want send feedback?",
  MESSAGE: "Message",
  SENDING_GROUP:"mass sending",
  SENDING_GROUP_NOTIFICATION:"Sending Group Notification",
  SENDING_NOTIFICATION:"Sending Notification",
  GROUP_CONTROL:"Group Control",
  MAINTAIN_APPLICATION_STATUS:"Maintain Application Status",
  COMPLETION_REQUIRED:"Completion Required",
  NOTIFICATIONS_MESSAGE:"Note: The notification will be sent collectively to all students on all pages",
  DATA_LOADING: "Loading ...",
  EDIT_SUBMITTED_TOTAL: "Edit Approval Total",
  EDIT_QUOTA:"Edit QUOTA",
  EDIT_STUDENT_EMAIL: "Edit student amail",
  EDIT_STUDENT_CARD_ID: "Edit student card id",
  noSpecialLetter:"Identity Number must contains only English letter and numbers",
  BRANCH:"Branch",
  APPLICATIONS_LIST_TITLE:"Applied Students academic year {year} semester {semester}",
  APPLICATION_EXPORTING_TITLE_STUDENT:"Accepted Students and Transfered to SIS",
  APPLICATION_EXPORTING_TITLE:"Accepted Students and Transfered to SIS academic year {year} semester {semester}",
  STATUS_STUDENTS_AFTER_EXPORTING:"Students accepted/rejected to the private university system",
  SELECT_ALL:"Chosse All",
  EXPORT_TO_PRIVATE_UNIVERSITY:"Export to private universities",
  STUDENTS_COUNT_IN_BRANCH: "View the number of students at the faculty branches",
  ACCEPT:"Accept",
  FEES_PAYMENT:"Pay the application fee for students",
  APPLICATION_NUM:"Application No.",
  PAY_FEES:"{title} The Application Fee",
  APPLICATION_FEES:"Application Fees",
  PAY_FEE_CONFIRM:"Are you sure you want to {pay} the fee of the application No. {applicationId} for student {studentName} who has a Identity card No. {idCard}",
  STUDENT_NUM:"Student No.",
  PAYMENT_STATUS:"Payment Status",
  OTHER:"Other",
  HIDE_SHOW_COLUMN:"Hide and Show Table's Columns",
  REPORT:"Report",
  EXPORT:"Export",
  DEPOSIT_DATE:"Deposit Date",
  DEPOSIT_NUM:"Deposit No.",
  DEPOSIT_IMAGE:"Deposit Image",
  IMAGE_SIZE_VALIDATION: "Image size should be less than or equal 5 MB",
  IMAGE_EXTENTION_VALIDATION: "This Field must be Contains Image with Other Extensions",
  EXCEL_EXTENTION_VALIDATION: "This Field must be Contains Excel File",
  PAID:"Paid",
  NOT_PAID:"Not Paid",
  NOT_FOUND_FEES:"No Fees",
  FOUND_FEES :"Fees Found",
  ONLINE_PAYMENT:"Online Electronic Payment",
  OFFLINE_PAYMENT:"Offline Payment (upload receipt)",
  FAWRY:"fawry",
  BANK:"bank",
  PAYMENT_METHOD:" payment method",
  APPLICATION_FEES_VALUE:"application fees Value",
  DELETE_FEE_CONFIRM:"Are you sure you want to delete the fee of the application No. {applicationId} for student {studentName} who has a Identity card No. {idCard}",
  PAY:"pay",
  REPORT_APP_STATUS:"Report of Application Status",
  APP_STATUS_LIST_TITLE:"Students ({status}) academic year {year} semester {semester}",
  APPLIED_STUDENT_REPORT_TITLE:"Report of Admission Students",
  STATISTIC_APP_NAME:'General Statistic ',
  EVENT_NAME: "Event Name",
  START_DATE:"Start Date",
  END_DATE:"End Date",
  CALENDAR_PERIODS:"Calendar Periods",
  EDIT_STUDENT_DATA:"Edit Student Data",
  NEWS:"News",
  NEW_TEXT_ARABIC:"News in Arabic",
  NEW_TEXT_ENGLISH:"News in English",
  ORDER_NEW:"order New",
  WITH_COURSE_NAME:"Search With Course Name",
  CONF_ACCEPT_APP:"Are you sure you want to accept this application?",
  CONF_ACCEPT_APP_NEGLECT_REFUSE_OTHER:"Are you sure you want to accept this application AND REFUSE OTHER ALL APPLICATIONS?",
  CONF_ACCEPT_APP_NEGLECT_OTHER:"Are you sure you want to accept this application and refuse other student accepted applications in another faculties ?",
  CONF_REFUSE_APP:"Are you sure you want to refuse this application?",
  DATE_FROM_TO_VALIDATION:"Enddate must be greater than Startdate",
  ADD_TO_TOTAL:"The Course Degree is added To the Certificate's Total",
  COURSE_DEGREE_MAX:"Total degree should be less than or equal 999999.99",
  FLOAT_NUMBER_VALIDATION:"The number after the decimal point must be no more than two digits",
  PROCESSING:"Data Processing",
  PULLING_STEPPER:{
    NEXT:"Next",
    PREVIOUS:"Previous",
    SELECT:"select The corresponding item",
    ERROR:"there is incorrect data you should review it first",
    SHOW:"Show Data",
    MSG:"Message",
    CONSTRAIN:"You should enter all these fields ",
    CONFIRM_ERROR:"There is not correct data do you want to save correct data Only?",
    EXSIT:"There is saved data for this certification ",
    REUSE:"Use previous certification data ",
    FILE_NAME:"Not saved data",
    CANCEL_MSG:"Do you really need to cancel mapping ?",
    MORE_DATA:"there is other {num} item",
    FILE_SIZE:"File size should be less than or equal 6 MB",
    ROWS_COUNT:"File Should contain at least two rows",
    RULES:{
      MAX:"{field} must be less than {max}" ,
      MIN:"{field} must be greater than {min}",
      INTEGER:"{field} must be integer",
      FLOAT:"{field} must be float",
      VALID_COURSE:"The student's course scores must be enter with valid values"
    },
    STEP1:{
      NAME:"Certification details"
    },
    STEP2:{
      NAME:"Upload file",
      INFO:"File extinction must be one of this (xls|.xlsx.) and must be at least 6MB and contain at least tow rows",
      FILE_PLACEHOLDER:"choose file",
      FILE:"File",
      SHOW_DATA:"show data"
    },
    STEP3:{
      NAME:"select item"
    },
    STEP4:{
      NAME:"Save Confirmation",
      MSG:"Not Saved Data"
    }
  },
  UPDATE_APPLY:"update application status to  ",
  CONVERT_APPLICATION_CHECK:"Send Application to Student for Editing",
  SEND_MAIL_CHECK:"Send Mail to Student for Editing",
  SERVICES_LINKS: "Services' Links",
  SERVICE_TYPE:"Service Type",
  SERVICE_IP:"Service IP",
  SERVICE_URL:"Service URL",
  SERVICE_DESCRIPTION:"Service Description",
  STUDENTS_MAILS:"Students mails",
  SUBJECT_MESSAGE:"subject ",
  TEXT_MESSAGE:"text",
  SHOURTCUTS:"shourtcuts",
  CATEGORY_MESSAGE:"category",
  SEND_MAIL_MESSAGE:"send Mail To Student",
  IP_VALIDATION:"Enter valid IP Address",
  URL_VALIDATION:"Enter valid URL Link",
  COURSE_TOTAL:"Course Total",
  SCOPE_LABEL: "Scope: ",
  IP_INFO_HINT: "IP Address should be starts with http:// or https:// and if it is ended with port it must be consists of 4 or 5 numbers in port and not allowed /",
  URL_INFO_HINT: "URL must be starts with http:// or https:// and if it is is ended with port it must be consists of 4 or 5 numbers in port and not allowed / and url can be ip address or url domain",
  COURSE_DEGREE_STATUS:"Added to Total",
  COURSE_DEGREE_NOT_ADDED:"Course degree not added to total",
  COURSE_DEGREE_ADDED:"Course degree added to total",
  INACTIVE_FACULTY:"Inactive Faculty",
  INACTIVE_COURSE:"Inactive Course",
  CERTIFICATE_NAME_AR: "Certificate Name in Arabic",
  CERTIFICATE_NAME_EN: "Certificate Name in English",
  NID: "National ID",
  EMAIL: "E-Mail",
  SEND_NEW_PASSWORD: "Send New Password",
  NID_VALIDATION: "The national number is incorrect",
  EMAIL_VALIDATION:"Please follow this email format user@domain.xxx and account name must not exceed 64 characters",
  MIN_VALIDATION:"The number must be at least 1",
  CONFIRM_CANCEL:"Do You Want To Cancel And Go Back?",
  CHANGE_PASSWORD:"Change Password",
  OLD_PASSWORD:"Old Password",
  NEW_PASSWORD:"New Password",
  CONFIRM_NEW_PASSWORD:"Confirm Old Password",
  PASSWORD_VALIDATION:
      "Enter complex password at least one capital letter, a number and a special character",
  CONFIRM_PASSWORD_VALIDATION: "Password does not match",
};
export default translationsEn;
