<template>
    <div>
      <h2></h2>
      <p></p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TabContent2'
  }
  </script>
  
  <style scoped>
  /* Your styles here */
  </style>