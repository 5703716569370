<template>
   <div class="mb-8">
      <v-card class="mx-auto mb-2">
         <v-toolbar flat>
            <v-toolbar-title>
               <p color="white" class="mb-0">{{ $t("CERTIFICATE_DATA") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
         </v-toolbar>
         <v-container class="center pa-0">
            <v-row class="center">
               <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <label
                  class="text-no-wrap primary--text font-weight-bold "
                  :class="
                  + $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                  'd-table-row'
                  ">{{ $t('CERTIFICATE_NAME_AR') }}</label>
                  <span
                  :class="
                  $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                  ? 'd-table-cell'
                  : 'd-table-row'
                  ">{{certificateData.arName}}</span>
               </v-col>
               <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <label
                  class="text-no-wrap primary--text font-weight-bold "
                  :class="
                  + $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                  'd-table-row'
                  ">{{$t("CERTIFICATE_NAME_EN")}}</label>
                  <span
                  :class="
                  $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                  ? 'd-table-cell'
                  : 'd-table-row'
                  ">{{certificateData.enName}}</span>
               </v-col>
            </v-row>
            <v-row class="center">
               <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <label
                  class="text-no-wrap primary--text font-weight-bold "
                  :class="
                  + $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                  'd-table-row'
                  ">{{ $t('ACADEMIC_YEAR') }}</label>
                  <span
                  :class="
                  $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                  ? 'd-table-cell'
                  : 'd-table-row'
                  ">{{certificateData.academicYearName}}</span>
               </v-col>
               <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <label
                  class="text-no-wrap primary--text font-weight-bold "
                  :class="
                  + $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                  'd-table-row'
                  ">{{$t("CERTIFICATE_TYPE")}}</label>
                  <span
                  :class="
                  $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                  ? 'd-table-cell'
                  : 'd-table-row'
                  ">{{serverTranslate(certificateData.certificationTypeName)}}</span>
               </v-col>
            </v-row>
            <v-row class="center">
               <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <label
                  class="text-no-wrap primary--text font-weight-bold "
                  :class="
                  + $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                  'd-table-row'
                  ">{{ $t('STATUS') }}</label>
                  <span
                  v-if="certificateData.status"
                  :class="
                  $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                  ? 'd-table-cell'
                  : 'd-table-row'
                  ">{{$t('OPEN_CERTIFICATE')}}</span>
                  <span
                  v-else
                  :class="
                  $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                  ? 'd-table-cell'
                  : 'd-table-row'
                  ">{{$t('CLOSED_CERTIFICATE')}}</span>
               </v-col>
               <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <label
                  class="text-no-wrap primary--text font-weight-bold "
                  :class="
                  + $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                  'd-table-row'
                  ">{{$t("TOTAL")}}</label>
                  <span
                  :class="
                  $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                  ? 'd-table-cell'
                  : 'd-table-row'
                  ">{{certificateData.totalDegree.toFixed(2)}}</span>
               </v-col>
            </v-row>
            <v-row class="center">
               <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <label
                  class="text-no-wrap primary--text font-weight-bold "
                  :class="
                  + $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                  'd-table-row'
                  ">{{$t("STUDENTS_COUNT")}}</label>
                  <span
                  :class="
                  $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                  ? 'd-table-cell'
                  : 'd-table-row'
                  ">{{certificateData.studentCount}}</span>
               </v-col>
               <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <label
                  class="text-no-wrap primary--text font-weight-bold "
                  :class="
                  + $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                  'd-table-row'
                  ">{{$t("COURSES_COUNT")}}</label>
                  <span
                  :class="
                  $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                  ? 'd-table-cell'
                  : 'd-table-row'
                  ">{{certificateData.coursesCount}}</span>
               </v-col>
            </v-row>
            <v-card-actions class="justify-end pt-0 me-2">
               <template v-if="certificateHasStatus">
                  <v-btn color="primary" class="white--text" @click="editCert">
                     {{ $t("EDIT") }}
                  </v-btn>
               </template>
               <template v-if="courseHasData">
                  <v-btn color="delete" class="white--text" @click="deleteCertificate">{{
                     $t("DELETE")
                  }}</v-btn>
               </template>
               <v-btn color="darken3" class="white--text" @click="back">{{
                  $t("BACK")
               }}</v-btn>
            </v-card-actions>
         </v-container>
      </v-card>
      <v-card class="mx-auto" outlined>
         <v-data-table
            :headers="courseHeaders"
            :items="serverTranslateArray(courses, 'courseName')"
            class="elevation-1"
            sort-by="courseName"
            :hide-default-footer="courses.length > 0 ? false : true"
            :items-per-page="5"
            :footer-props="{
               showFirstLastPage: true,
               firstIcon: 'mdi-arrow-collapse-left',
               lastIcon: 'mdi-arrow-collapse-right',
               prevIcon: 'mdi-arrow-left',
               nextIcon: 'mdi-arrow-right',
               itemsPerPageOptions: [5,10,15,50,100],
            }"
         >
            <template v-slot:top>
               <v-toolbar flat>
               <v-toolbar-title>{{ $t("COURSES_RELATED_TO_CERTIFICATE") }}</v-toolbar-title>
               <v-spacer></v-spacer>
               <v-btn color="secondary" v-if="certificateData.status" @click="addCourse">{{$t("ADD_COURSE")}}</v-btn>
               </v-toolbar>
            </template>
            <template v-slot:item.addToTotal="{ item }">
               <v-tooltip v-if="item.addToTotal == 1" top>
                  <template v-slot:activator="{ on }">
                     <v-btn icon v-on="on">
                        <v-icon meduim color="green" class="mx-2">mdi-check-outline</v-icon>
                     </v-btn>
                  </template>
                  <span>{{ $t("COURSE_DEGREE_ADDED") }}</span>
               </v-tooltip>
               <v-tooltip v-else top>
                  <template v-slot:activator="{ on }">
                     <v-btn icon v-on="on">
                        <v-icon meduim color="red" class="mx-2">mdi-close-outline</v-icon>
                     </v-btn>
                  </template>
                  <span>{{ $t("COURSE_DEGREE_NOT_ADDED") }}</span>
               </v-tooltip>
            </template>
            <template v-slot:item.actions="{ item }" v-on:click.stop>
               <v-tooltip bottom>
                  <template v-if="item.inactiveCourse===0" v-slot:activator="{ on }">
                     <v-btn icon v-on="on">
                        <v-icon
                        meduim
                        class="mx-2"
                        @click="deleteCourse(item.courseId)"
                        >mdi-cancel</v-icon
                        >
                     </v-btn>
                  </template>
                  <span>{{ $t("INACTIVE_COURSE") }}</span>
               </v-tooltip>
               <template v-if="certificateData.status">
                  <v-tooltip bottom>
                     <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                           <v-icon meduim color="primary" @click="editCourseDegree(item.courseId)"
                           >mdi-pencil</v-icon
                           >
                        </v-btn>
                     </template>
                     <span>{{ $t("EDIT") }}</span>
                  </v-tooltip>
                  <v-tooltip bottom>
                     <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                           <v-icon
                           meduim
                           color="delete"
                           class="mx-2"
                           @click="deleteCourse(item.courseId)"
                           >mdi-delete</v-icon
                           >
                        </v-btn>
                     </template>
                     <span>{{ $t("DELETE") }}</span>
                  </v-tooltip>
               </template>
            </template>
            <template v-slot:footer.page-text="props">
               {{ props.pageStart }} - {{ props.pageStop }} {{$t('PAGE_TEXT')}} {{ props.itemsLength }}
            </template>
            <template slot="no-data">
               <v-alert :value="courseHasData" icon="warning" class="mt-2" color="light5">
                  {{
                     $t("NO_DATA")
                  }}
               </v-alert>
            </template>
         </v-data-table>
      </v-card>

      <v-dialog v-model="editDialog" max-width="70vh">
         <v-card>
            <v-form ref="courseForm">
               <v-card-title class="justify-center">
                  <span class="headline white--text text-thin">{{
                     $t('EDIT_COURSE')
                  }}</span>
               </v-card-title>
               <v-card-text>
                  <v-container>
                     <v-alert v-show="showAlert" dense outlined type="error">{{
                        serverTranslate(errorMessages)
                     }}</v-alert>
                     <v-row>
                        <v-col cols="12" class="pa-0 pb-1">
                           <label
                           class="text-no-wrap primary--text font-weight-bold "
                           :class="
                           $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'pe-3 d-table-cell' :
                           'd-table-row'
                           ">
                           {{ $t("COURSE_NAME") }}</label>
                           <span
                           :class="
                           $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                           ? 'd-table-cell'
                           : 'd-table-row'
                           ">
                           {{serverTranslate(courseData.courseName)}}
                        </span>
                     </v-col>
                  </v-row>
                  <v-row>
                           <v-col cols="7" class="pa-0 pe-3">
                              <label
                                 class="text-no-wrap primary--text font-weight-bold "
                                 :class="
                                 $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'pe-3 d-table-cell' :
                                 'd-table-row'
                                 ">
                                 {{ $t("COURSE_TOTAL") }}</label>
                              <v-text-field class="pt-0 mt-0"
                        color="primary"
                        v-model="courseData.courseDegree"
                        @keydown.space="preventLeadingSpace"
                        required
                        :rules="$store.state.Rules.courseDegreeRules"
                        ></v-text-field>
                     </v-col>
                           <v-col cols="12" class="pa-0 font-weight-bold">
                              <v-checkbox
                                 :value="courseData.addToTotal"
                                 :label="$t('ADD_TO_TOTAL')"
                                 v-model="courseData.addToTotal"
                                 :false-value="0"
                                 :true-value="1"
                                 color="primary">
                              </v-checkbox>
                     </v-col>
                  </v-row>
               </v-container>
            </v-card-text>
            <v-card-actions class="justify-end">
               <v-btn color="primary" dark @click="updateCourse">{{
                  $t("SAVE")
               }}</v-btn>
               <v-btn color="darken3" class="white--text" @click="editDialog = false;errorMessages='';showAlert=false;">{{
                  $t("CANCEL")
               }}</v-btn>
            </v-card-actions>
         </v-form>
         </v-card>
      </v-dialog>
      <pr-confirmDialog
      ref="confirm"
      :persistentDialog="addEditDialog"
      ></pr-confirmDialog>
      <pr-addCourse ref="addCourse" :certificateId="certificateID"/>
   </div>
</template>
<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import globalMethodsMixin from "@/mixins/globalMethods";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import AddCourse from "@/components/modules/systemInfo/certifications/AddCourse";
export default {
   name: "pr-view-certificate",
   components: {
      "pr-confirmDialog": ConfirmDialog,
      "pr-addCourse":AddCourse
   },
   mixins: [serverTranslationsMixin,globalMethodsMixin],
   props: ["searchData"],
   computed: {
      direction: function() {
         return this.$store.state.direction;
      },
      activeAcademicYear() {
         return this.$store.getters.getActiveAcademicYear;
      },
      courseHeaders: function() {
         let fields = [
            {
               text: this.$t("COURSE_NAME"),
               align: "start",
               value: "courseName",
               width: "33%"
            },
            {
               text: this.$t("COURSE_DEGREE"),
               align: "start",
               value: "courseDegree",
               width: "33%"
            },
            {
               text: this.$t("COURSE_DEGREE_STATUS"),
               align:"start",
               value: "addToTotal",
               width:"33%"
            }

         ];
         fields = this.certificateData.status || this.inactiveCourse>0
         ? fields.concat({
            text: this.$t("ACTIONS"),
            value: "actions",
            sortable: false,
            width: "150px"
         }): fields;
         return fields;
      }
   },
   data() {
      return {
         courses:[],
         certificateID:parseInt(this.$route.params.id),
         certificateData:{
            courseHasData:"",
            certificateHasStatus:"",
            academicYearId: "",
            academicYearName: "",
            arName: "",
            certificationType: "",
            certificationTypeName:"",
            certificationTypeArName: "",
            certificationTypeEnName: "",
            coursesCount: 0,
            enName: "",
            id: 0,
            status: true,
            studentCount: 0,
            totalDegree: 0
         },
         showAlert:false,
         errorMessages: "",
         editDialog: false,
         courseData: {
            courseName:"",
            courseDegree:"",
            addToTotal:0,
            courseId:0
         },
         inactiveCourse:0
      }
   },
   mounted() {
      this.viewCertificate()
   },
   methods: {
      back() {
         this.$router.replace({
            name: "ListCertifications",
            params: { searchData: this.searchData }
         });
      },
      deleteCertificate() {
         this.$refs.confirm
         .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
         .then(confirm => {
            if (confirm) {
               const id = { id: this.certificateID };
               this.$http
               .delete("/api/certificates/delete_certificates/", {
                  data: id,
                  searchData: this.searchData
               })
               .then(
                  res => {
                     this.$toasted.show(this.serverTranslate(res.data.message), {
                        type: "success",
                        icon: "check_circle_outline",
                        theme: "bubble",
                        position: "bottom-left",
                        duration: 2000
                     });
                     this.$router.push({name:"ListCertifications",params:{searchData:this.searchData}});
                  },
                  err => {
                     this.$toasted.show(
                        this.serverTranslate(err.response.data.message),
                        {
                           type: "error",
                           icon: "error_outline",
                           theme: "bubble",
                           position: "bottom-left",
                           duration: 5000
                        }
                     );
                     if (err.response.status === 404) {
                        this.$router.push({name:"ListCertifications",params:{searchData:this.searchData}});
                     }
                  }
               );
            }
         });
      },
      viewCertificate() {

         this.courses = [];
         const certData = {
            certificateID: this.certificateID
         };
         this.$http
         .post("/api/certificates/certificates_courses_view/", certData)
         .then(
            res => {
               this.certificateData = res.data.data.certificateData
               this.certificateHasStatus = res.data.data.certificateData.status===true  ? true : false;
               if (res.data.data.courses.length > 0) {
                  this.courseHasData = false;
                  const coursesD = res.data.data.courses
                  for (let i = 0; i < coursesD.length; i++) {
                     coursesD[i].courseDegree = coursesD[i].courseDegree.toFixed(2)
                     this.inactiveCourse +=  coursesD[i].inactiveCourse === 1 
                     ? this.inactiveCourse+1 : this.inactiveCourse;
                  }
                  this.courses = coursesD
               } else {
                  this.courseHasData = true;
               }
            },
            err => {

               this.courseHasData = true;
               this.certificateHasStatus==false;
               this.showToasted("error",err.response.data.message);
               if (err.response.status == 404) {
                  this.$router.push({name:"ListCertifications",params:{searchData:this.searchData}});
               }
            }
         );
      },
      editCert() {
         this.$router.push({name:'EditCertification',params:{id:this.certificateData.id,searchData:this.searchData}})
      },
      editCourseDegree(courseId) {
         //
         const data = {courseId:courseId,certificateId:this.certificateData.id}
         this.$http.post("/api/certificates/get_certificate_course/",data).then(
            res => {
               this.editDialog = true;
               this.courseData = res.data.data
               this.courseData.courseId = this.courseData.courseID;
               delete this.courseData.courseID;
            },
            err => {
               this.errorMessages = err.response.data.message;
               this.showAlert = true;
               if (err.response.status === 404) {
                  this.showToasted("error",err.response.data.message);
                  this.editDialog = false;
               }
               this.viewCertificate();
            }
         );
      },
      updateCourse() {
         if (this.$refs.courseForm.validate()) {
            const data = {
               certificateId:this.certificateData.id,
               certificateTotal:this.certificateData.totalDegree,
               ...this.courseData}
               this.$http.put("/api/certificates/update_certificate_course/",data).then(
                  res => {
                     this.editDialog = false;
                     this.viewCertificate();
                  },
                  err => {
                     this.errorMessages = err.response.data.message;
                     this.showAlert = true;
                     if (err.response.status === 404) {
                     this.showToasted("error",err.response.data.message);
                        this.editDialog = false;
                     this.viewCertificate();
                  }
                  }
               );
            }
         },
         deleteCourse(courseId) {
            this.$refs.confirm
            .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
            .then(confirm => {
               if (confirm) {
                  const data = {courseId:courseId,certificateId:this.certificateData.id}
                  this.$http.delete("/api/certificates/delete_certificate_course/",{data}).then(
                     res => {
                        this.showToasted("success",res.data.message);
                        this.viewCertificate();
                     },
                     err => {
                        this.showToasted("error",err.response.data.message);
                        this.viewCertificate();
                     }
                  );
               }
            });
         },
         addCourse() {
            this.$refs.addCourse.open()
            .then(confirm => {
               if (confirm) {
                  this.viewCertificate()
               }
            });
         }
      }
   };
   </script>
