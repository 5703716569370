<template>
  <div class="mb-8">
    <v-card class="mx-auto mb-3">
      <div id="filters">
        <template>
          <v-toolbar flat>
            <v-toolbar-title>
              <p class="mb-0">{{ $t("SENDING_GROUP_NOTIFICATION") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-btn
              v-if="!$vuetify.breakpoint.mdAndUp"
              color="secondary"
              dark
              :to="{name:'StudentNominate'}"
          >{{ $t("STUDENT_NOMINATE_APPLICATION") }}
          </v-btn>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" class="ma-0" sm="12" md="12" lg="4">
                  <v-select
                      :items="serverTranslateArray(this.status, 'name')"
                      v-model="appStatus"
                      outlined
                      small-chips
                      :label="$t('APPLICATION_CASES')"
                      item-value="mongo_id"
                      item-text="name"
                  >
                  </v-select>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="12" class="pa-0 my-0" xs="12" sm="12" md="12" lg="12">
                  <v-radio-group class="ma-0" v-model="searchWithId" row>
                    <v-radio :label="$t('STUDENT_NAME')" value="1"></v-radio>
                    <v-radio :label="$t('IDENTITY')" value="2"></v-radio>
                    <v-radio :label="$t('APPID')" value="3"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col class="py-0" v-if="searchWithId==='2'" cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-text-field
                      v-model="identityNum"
                      counter="25"
                      :rules="$store.state.Rules.noSpecialLetter"
                      :label="$t('WithIDENTITYTEXT')"
                      outlined
                  ></v-text-field>
                </v-col>

                <v-col class="py-0"
                       v-if="searchWithId==='3'" cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-text-field
                      v-model="appId"
                      counter="25"
                      :rules="$store.state.Rules.noSpecialLetter"
                      :label="$t('WITHAPPID')"
                      outlined
                  ></v-text-field>
                </v-col>


                <v-col class="py-0"
                       v-if="searchWithId==='1'"
                       cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-autocomplete
                      :items="students"
                      v-model="studentId"
                      item-value="id"
                      item-text="name"
                      outlined
                      :loading="searchIsLoading"
                      :search-input.sync="studentSearch"
                      return-object
                      no-filter
                      clearable
                      :label="$t('WithNAMETEXT')"
                  ></v-autocomplete>
                </v-col>

              </v-row>


              <v-card-actions class="pa-0">
                <v-container class="px-0">
                  <v-row>
                    <v-col cols="auto" class="pa-1">

                      <v-btn
                          color="primary"
                          class="mx-2"
                          @click="showScopeTreeFn()">
                        {{ $t('SCOPE') }}
                        <v-icon right>mdi-navigation</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="pa-1">
                        <download-excel
                            :fields="serverTranslateDictKeyName(jsonFields)"
                            worksheet="Students Worksheet"
                            name="StudentsApplicationsNotifications.xls"
                            :title="[title]"
                            :fetch="fetchExportedData"
                        >
                        <v-btn color="secondary"
                            :small="!$vuetify.breakpoint.mdAndUp"
                            :fab="!$vuetify.breakpoint.mdAndUp"
                        >
                            <span v-if="$vuetify.breakpoint.mdAndUp">{{$t('EXPORT')}}</span>
                            <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-cloud-download</v-icon>
                        </v-btn>
                        </download-excel>
                     </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="auto" class="pa-1">
                      <v-btn color="primary" dark @click="searchAppliactions">
                        {{ $t("SEARCH") }}
                      </v-btn>

                    </v-col>
                    <v-col class="pa-1 mx-2" cols="auto">
                      <v-btn
                          color="darken3"
                          class="white--text"
                          @click="reset"
                      >
                        {{ $t("RESET") }}
                      </v-btn>
                    </v-col>
                    <v-col cols="auto" class="pa-1 ">
                      <v-btn
                          color="primary"
                          class="ma-1"
                          @click="openMessageDialog"
                          :disabled="!sendingApplicationsNotEmpty"
                      >
                        {{ $t("GROUP_CONTROL") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-container>
          </v-form>
        </template>
      </div>
    </v-card>
    <v-card class="mx-auto" outlined>
      <div id="inspire">

        <v-data-table
            :headers="headers"
            :items="applications"
            class="elevation-1 "
            :footer-props="{
                 showFirstLastPage: true,
                 firstIcon: 'mdi-arrow-collapse-left',
                 lastIcon: 'mdi-arrow-collapse-right',
                 prevIcon: 'mdi-arrow-left',
                 nextIcon: 'mdi-arrow-right',
                 itemsPerPageOptions: [20, 40, 60, 80, 100]
                 }"
            :items-per-page="20"
            :loading="loading"
            :options.sync="appOptions"
            :server-items-length="total"
            :hide-default-footer="applications.length > 0 ? false : true"
            :hide-default-header="true"

            :fixed-header="true"
        >

          <template v-slot:header="{ props }">
            <tr>

              <th class="d-flex mb-2  align-items-center " >

                <v-checkbox
                    v-model="selectAll"
                    @change="toggleSelectAll"
                    class="text-center"
                ></v-checkbox>
                <label class="align-self-center mt-2">{{ $t("SELECT_ALL") }}</label>

              </th>
              <th v-for="header in props.headers" :key="header.text" class="text-center">
                {{ header.text }}
              </th>
            </tr>
          </template>

          <template v-slot:item="{ item }">
            <tr>
              <td class="text-center" style="width:5%;" >
                <v-checkbox
                    v-model="item.selected"
                    :label="null"
                    :input-value="item.selected"
                    @change="checkItem(item)"
                ></v-checkbox>
              </td>
              <td class="text-center">{{ serverTranslate(item.studentName) }}</td>
              <td class="text-center">{{ item.identityNumber}}</td>
              <td class="text-center">{{item.appNum}}</td>
              <td class="text-center">{{ serverTranslate(item.facultyName) }}</td>
              <td class="text-center">{{ item.certifiedTotal}}</td>

              <td class="text-center">
                {{
                  item.applicationStatus >= 0 ?
                      $t("CASES")[item.applicationStatus] : ""
                }}
              </td>
              <td class="text-center">
                <ul>
                  <li v-for="(i, index) in item.messages" :key="index">
                    {{ "(" + i.msgDate + ") / " + i.msgText }}
                  </li>
                </ul>
              </td>

            </tr>
          </template>


          <template v-slot:no-data>
            <v-alert icon="warning" class="mt-2" color="light5">
              {{ $t("NO_DATA") }}
            </v-alert>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title> {{ title }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:footer.page-text="props">
            {{ props.pageStart }} - {{ props.pageStop }} {{ $t('PAGE_TEXT') }} {{ props.itemsLength }}
          </template>

        </v-data-table>
      </div>
    </v-card>
    <v-dialog
        v-model="openDialog"
        max-width="150vh"
        @keydown.esc="closeSendingDialog"
        @click:outside="closeSendingDialog"
    >
      <v-card outlined >
        <v-form ref="formMessage" v-model="valid" lazy-validation>
          <v-card-title class="justify-center">
          <span class="headline white--text text-thin">
            {{ $t("FEEDBACK_APPLICATION") }}
          </span>
          </v-card-title>

          <v-card-text>
            <v-container>

              <v-alert icon="warning" class="mt-2" color="light5">
                {{ $t("NOTIFICATIONS_MESSAGE") }}
              </v-alert>
              <v-alert v-show="showAlert" outlined type="error">
                {{ serverTranslate(errorMessages) }}
              </v-alert>

              <v-row>
                <v-col cols="12" >
                  <label class="my-0">{{$t("UPDATE_APPLY")}}</label>
                  <v-radio-group class="mx-4"
                  :rules="requiredRules"
                  v-model="applicationStatusOption"
                  column  >
                    <v-radio
                      :label="$t('MAINTAIN_APPLICATION_STATUS')"
                      color="blue"
                      value=""
                    ></v-radio>
                    <v-radio
                      :label="$t('ACCEPT_APPLICATION')"
                      color="blue"
                      :value="APPLICATION_STATUS.ACCEPTED"
                    ></v-radio>
                    <v-radio
                    :label="$t('REJECT_APPLICATION')"
                    color="blue"
                    :value="APPLICATION_STATUS.REFUSED"
                  ></v-radio>
                  <v-radio
                  :label="$t('COMPLETION_REQUIRED')"
                  color="blue"
                  :value="APPLICATION_STATUS.FEEDBACK"
                ></v-radio>
                    <v-radio
                  :label="$t('WAITING_LIST')"
                  color="blue"
                  :value="APPLICATION_STATUS.WAITING_LIST"
                ></v-radio>
                </v-radio-group>
                  <!-- <v-checkbox
                      ref="feedbackCheckbox"
                      v-model="feedbackCheckbox"
                      true-value="1"
                      false-value="0"
                      :label="$t('CONVERT_APPLICATION_CHECK')"
                      class="d-inline-block my-2"
                  /> -->
                  <v-checkbox
                      ref="sendMailToStudent"
                      v-model="sendMailToStudent"
                      true-value="1"
                      false-value="0"
                      :label="$t('SEND_MAIL_CHECK')"
                      class="d-inline-block my-2"
                  />
                  <v-textarea
                      ref="notificationMessage"
                      v-model="notificationMessage"
                      :rules="MessageRules"
                      :label="$t('MESSAGE')"
                      maxlength="500"
                      counter
                      clearable
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="primary"
                dark
                @click="sendAllNotifications"
                :disabled="sentNotification"
            >
              {{ $t("SEND_FEEDBACK") }}
            </v-btn>
            <v-btn color="darken3" class="white--text" @click="closeSendingDialog">
              {{ $t("CANCEL") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>


    <pr-scope :key="componentKey" v-if="showScopeTree == true" v-on:setScope="onScopeChanged" :scopeFullPath="false"/>
    <pr-confirmDialog ref="confirm"></pr-confirmDialog>
  </div>
</template>
<script>
import serverTranslations from "@/mixins/serverTranslations";
import scope from "@/components/shared/ScopeTree.vue"
import {EventBus} from "@/plugins/bus";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import Validations from "@/components/shared/validations";
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import {APPLICATION_STATUS} from "@/enums.ts";

export default {
  name: "List",
  components: {
    "pr-scope": scope,
    "pr-confirmDialog": ConfirmDialog
  },
  mixins: [serverTranslations, globalMethodsMixin],

  mounted() {


    this.getFilters();
    this.searchAppliactions(true);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const mv = this;
    EventBus.$on("updateRunListAfterAction", () => {
      mv.searchAppliactions();
    });
  },
  watch: {
    searchWithId() {
      this.studentId = null;
      this.students = [];
      this.studentSearch = "";
      this.identityNum = "";
    },
    appOptions: {
      handler() {
        this.searchAppliactions();
      },
      deep: true,
    },
    studentSearch(val) {

      if (!val || val.length < 3) {
        this.students = [];
        return false;
      }
      if (this.searchIsLoading) return;
      const phraseLang = this.isRTL(val) ? "ar" : "en"
      const data = {
        searchData: val,
        semesterId: this.activeAcademicSemester.id,
        yearId: this.activeAcademicYear.id,
        appStatus: this.appStatus.toString()
      }
      this.searchIsLoading = true;
      this.$http.post("/api/students_admission_apps/search_student/", data)
          .then(
              res => {
                res = res.data.data;
                this.students = res.students.map(item => {
                  return {"id": item.studentId, "name": this.serverTranslateCustom(item.studentName, phraseLang)}
                });
              },
              err => {
                this.showToasted("error", err.response.data.message)
              }
          )
          .then(() => (this.searchIsLoading = false));

    },
  },
  computed: {
    isSearchedWithName() {
      return this.searchWithId === "1";

    },
    isSearchedWithAppId() {
      return this.searchWithId === "3";
    },
    jsonFields() {
            const fields = {
                "اسم الطالب|Student Name": 'studentName',
                'الرقم القومى|identity': 'identityNumber',
                'الكلية|Faculty': 'facultyName',
                'المجموع المعتمد|certifiedTotal': 'certifiedTotal',
                'رقم الطلب|application Id': 'appNum',
                'حالة الطلب|application status':'applicationStatus',
                'الاشعارات|Notifications':'messages'
            }
           
            return fields;
        },
    /*   status() {
        const statusList = this.$t("CASES");
        if (localStorage.getItem("scopeID") == 2) {
          delete (statusList[1])
        }
        const list = []
        list.push({"id": "all", "name": this.$t('ALL')});
        statusList.forEach((item, index) => {
          if (7 >= index > 0 || index == 8) {
            list.push({"id": index, "name": item});
          }
        })
        return list;
      }, */
    activeAcademicYear() {
      return this.$store.getters.getActiveAcademicYear;
    },
    activeAcademicSemester() {
      return this.$store.getters.getActiveAcademicSemester;
    },
    title() {
      return this.$t('APPLICATIONS_LIST_TITLE', {
        year: this.filterItems.yearName,
        semester: this.serverTranslate(this.filterItems.semesterName)
      });
    },
    headers() {
      return [
        {
          text: this.$t("STUDENT_NAME"),
          align: "center",
          sortable: true,
          value: "studentName"
        },
        {
          text: this.$t("IDENTITY"),
          align: "center",
          sortable: false,
          value: "identityNumber"
        },
        {
          text: this.$t("APPID"),
          align: "center",
          sortable: false,
          value: "appNum"
        },

        {
          text: this.$t("FACULTY"),
          align: "center",
          sortable: true,
          value: "facultyName"
        },
        {
          text: this.$t("CERTIFIED_DEGREE"),
          align: "center",
          sortable: true,
          value: "certifiedTotal"
        },

        {
          text: this.$t("APPLICATION_CASES"),
          align: "center",
          sortable: true,
          value: "applicationStatus"
        },
        {
          text: this.$t("NOTIFICATIONS"),
          align: "center",
          sortable: false,
          value: "messages"
        },

      ];
    }, sendingApplicationsNotEmpty() {
      return Array.isArray(this.sendingApplications) && this.sendingApplications.length > 0;
    }
  },
  data() {
    return {
      valid: true,
      loading: false,
      openDialog: false,
      filterItems: {
        facultyId: 0,
        yearId: 0,
        yearName: "",
        semesterId: 0,
        semesterName: "",
        certificateTypeFilter: ''
      },
      APPLICATION_STATUS,
      semesters: [],
      years: [],
      scopes: [],
      currentYear: {},
      currentSemester: {},
      studentsNumbers: null,
      studentSearch: "",
      searchWithId: "1",
      searchWithAppId: "1",
      appStatus: "all",
      searchIsLoading: false,
      students: [],
      applications: [],
      identityNum: "",
      appId: "",
      total: 0,
      selectedItems: [],
      selectAll: false, // Whether all items are selected
      //feedbackCheckbox: "0",
      applicationStatusOption:"",
      sendMessageGroup: "1",
      sendMailToStudent: "0",
      sendingApplications: [],
      appOptions: {},
      studentId: "",
      showScopeTree: false,
      componentKey: 0,
      errorMessages: "",
      showAlert: false,
      sentNotification: false,
      MessageRules: [
        v => this.isNotEmpty(v) || this.$t("REQUIRED_FIELD"),
        v => (!!v && v.trim().length > 10) || this.$t("MIN_FIELD") + "10",
        v => (!!v && v.trim().length <= 500) || this.$t("MAX_FIELD") + "500"
      ],
      notificationMessage: ""
    };
  },
  methods: {
    async fetchExportedData() {
      const data =this.searchedData();
      data.pageSize=-1
            const response = await this.$http.post("/api/students_admission_apps/list_applications/",data)
            const results = response.data.data.results;
          // Process the messages field to combine into a single string
            const processedResults = results.map(item => {
              const messages = item.messages.map(msg => `(${msg.msgDate}) / ${msg.msgText}`).join(' | ');
              return {
                ...item,
                messages // Update the messages field
              };
            });

            const exportedData = this.serverTranslateArrayKeys(processedResults,
              ['studentName', 'identityNumber', 'appNum', 'facultyName','certifiedTotal', 'applicationStatus', 'messages']);
            
              if (response.status !== 200) return this.showToasted("error",this.$t("NOT_FOUND_SERVER_ERROR"))
                this.loading = false;
              return exportedData;

        },
    checkItem(item) {
      const { studentId, appNum, selected } = item;
        const outputObj = { studentId, applyId: appNum, selected };
        if (outputObj.selected) {
          const itemToAdd = { ...outputObj };
          delete itemToAdd.selected;
          this.selectedItems.push(itemToAdd);
        } else {
          const index = this.selectedItems.findIndex(
            (selectedItem) => selectedItem.studentId === outputObj.studentId &&
                              selectedItem.applyId === outputObj.applyId
          );
          if (index !== -1) {
            this.selectedItems.splice(index, 1);
          }
        }
    },
    toggleSelectAll() {
         const isChecked = this.selectAll;
         this.selectedItems = [];
            if (isChecked) {
                const keysToKeep = ['studentId', 'appNum'];
               const listAppPaging= this.applications
                 listAppPaging.forEach((app) => {
                  const filteredApp = {};
                    keysToKeep.forEach((key) => {
                      if (key === 'appNum') {
                        filteredApp['applyId'] = app[key];
                      } else {
                        filteredApp[key] = app[key];
                      }
                     });
                    this.selectedItems.push(filteredApp);
                  });


            } 
            this.applications.forEach((item) => {
                item.selected = isChecked;
            });

    },

    showScopeTreeFn() {
      this.showScopeTree = true;
      this.componentKey += 1;
    },
    getFilters(facultyId = null) {
      return this.$http
          .post("/api/students_admission_apps/list_application_filters/")
          .then(response => {
                const data = response.data.data;
                const modifiedStatus = [{
                  name: this.$t('ALL'),
                  id: 'all',
                  'mongo_id': 'all',
                  'ordering': 0
                }, ...data.applicationStatus]
                this.status = modifiedStatus;
                this.years = data.years;
                this.semesters = data.Semesters;
                this.currentYear = this.years.filter(year => parseInt(year.active) === 1)[0];
                this.currentSemester = this.semesters.filter(semester => parseInt(semester.active) === 1)[0];
                this.filterItems.yearId = this.currentYear.id || 0;
                this.filterItems.yearName = this.currentYear.name || "";
                this.filterItems.semesterId = this.currentSemester.id || 0;
                this.filterItems.semesterName = this.currentSemester.name || "";


              },
              err => {
                this.showToasted("error", err.response.data.message);
              })
          .then(() => (this.loading = false));
    },

    initializeList() {
      return new Promise((resolve, reject) => {
        const data = this.searchedData();
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$http
              .post("/api/students_admission_apps/list_applications/", data)
              .then(
                  res => {
                    this.applications = [];
                    const items = res.data.data.results;
                    const itemsWithSelected = items.map(item => ({
                      ...item,
                      selected: false
                    }));
                    const total = res.data.data.total;
                    const sendingApplications = res.data.data.sendingApplications;
                    this.sendingApplications = sendingApplications;
                    resolve({
                      itemsWithSelected,
                      total,
                      sendingApplications
                    });
                  },
                  err => {
                    this.applications = [];
                    this.$toasted.show(this.serverTranslate(err.response.data.message),
                        {
                          type: "error",
                          icon: "error_outline",
                          position: "bottom-left",
                          duration: 5000,
                          theme: "bubble"
                        }
                    );
                  }
              );
          this.loading = false;
        }, 1000);
      });
    },

    searchedData() {
      this.loading = true;
      this.selectAll=false
      this.selectedItems=[]
      const {sortBy, sortDesc, page, itemsPerPage} = this.appOptions
      const data = {
        studentId: !this.studentId || !this.studentId.id ? "" : this.studentId.id,
        yearId: this.filterItems.yearId || this.activeAcademicYear.id || 0,
        semesterId: this.filterItems.semesterId || this.activeAcademicSemester.id || 0,
        identityType: this.searchWithId || "",
        searchText: this.identityNum || this.appId || "",
        appStatus: (this.appStatus && this.appStatus != 'all' && Array.isArray(this.appStatus) ? this.appStatus.flat() : ""),
        // appStatus: (([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'all'].includes(this.appStatus)) ? this.appStatus : ''),
        notificationFlag: true,
        page: page,
        pageSize: itemsPerPage
      }
      if (sortBy.length === 1 && sortDesc.length === 1) {
        data.orderType = sortDesc[0] ? "asc" : "desc";
        data.orderBy = sortBy[0]
      }
      return data
    },
    searchAppliactions(resetPageNum = false) {
      if (resetPageNum) {
        this.appOptions.page = 1;
        this.appOptions.sortBy = [];
        this.appOptions.sortDesc = [];
      }
      this.initializeList()
          .then(data => {
            this.applications = data.itemsWithSelected;
            this.total = data.total;
          });
    },
    reset() {
      this.filterItems.yearId = this.currentYear.id;
      this.filterItems.semesterId = this.currentSemester.id;
      //this.filterItems.certificateTypeFilter = '';
      this.studentId = null;
      this.students = [];
      this.identityNum = "";
      this.searchWithId = "1";
      this.appStatus = "all"
      this.studentSearch = "";
      this.searchAppliactions(true);
    },
    openMessageDialog() {
      this.resetSendingForm();
      //     this.$refs.formMessage.resetValidation();

      this.openDialog = true;
      this.showAlert = false;
      this.errorMessages = "";
    },
    onScopeChanged(changed) {
      if (changed)
        this.searchAppliactions(true);
    },
    closeSendingDialog() {
      this.resetSendingForm();
      this.$refs.formMessage.resetValidation();
      this.openDialog = false;
      this.showAlert = false;
      this.errorMessages = "";
    },
    resetSendingForm() {
      this.notificationMessage = '';
      //this.feedbackCheckbox = "0";
      this.applicationStatusOption=""
      this.sendMailToStudent = "0";
      //   this.$refs.formMessage.resetValidation();
    },

    isNotEmpty(str) {
      return !(!str || 0 === str.length || /^\s*$/.test(str));
    },


    sendAllNotifications() {
      //  this.sentNotification = true;
      if (this.$refs.formMessage.validate()) {
        let selectedApplications = [];
        if (this.selectedItems && this.selectedItems.length) {
          selectedApplications = this.selectedItems;
        } else {
          selectedApplications = this.sendingApplications;
        }
        this.$http
            .post("/api/students_admission_apps/send_notifications/", {
              message: this.notificationMessage,
              //feedbackCheck: parseInt(this.feedbackCheckbox),
              applicationStatusOption:this.applicationStatusOption,
              sendingApplications: selectedApplications,
              sendMessageGroup: this.sendMessageGroup,
              sendMailToStudent:this.sendMailToStudent

            })
            .then(response => {
              this.closeSendingDialog();
              this.showToasted("success", response.data.message);
              this.searchAppliactions(true);

              //   this.sentNotification = false;

            })
            .catch(errors => {
              // this.dialog = true;
              this.closeSendingDialog();
              this.showToasted("error", errors.response.data.message);
            });
      } else {
        this.showAlert = true;
        this.errorMessages = this.$t("COMPLETE_DATA_ERROR");
      }


    }
  }
};
</script>
  