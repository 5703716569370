var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mx-auto",attrs:{"id":"studentAccepted"}},[_c('v-data-table',{staticClass:"elevation-1 pointer",attrs:{"headers":_vm.headers,"items":_vm.applications,"footer-props":{
           showFirstLastPage: true,
           firstIcon: 'mdi-arrow-collapse-left',
           lastIcon: 'mdi-arrow-collapse-right',
           prevIcon: 'mdi-arrow-left',
           nextIcon: 'mdi-arrow-right',
           itemsPerPageOptions: [5, 10, 15, 50, 100]
           },"items-per-page":15,"loading":_vm.loading,"options":_vm.appOptions,"server-items-length":_vm.total,"hide-default-header":true,"fixed-header":true,"hide-default-footer":_vm.applications.length > 0 ? false : true},on:{"update:options":function($event){_vm.appOptions=$event}},scopedSlots:_vm._u([{key:"header",fn:function({ props }){return [_c('tr',[_c('th',{staticClass:"d-flex mb-2 align-items-center"},[_c('v-checkbox',{staticClass:"text-center",on:{"change":_vm.toggleSelectAll},model:{value:(_vm.selectAll),callback:function ($$v) {_vm.selectAll=$$v},expression:"selectAll"}}),_c('label',{staticClass:"align-self-center mt-2"},[_vm._v(_vm._s(_vm.$t("SELECT_ALL")))])],1),_vm._l((props.headers),function(header){return _c('th',{key:header.text,staticClass:"text-center"},[_vm._v(" "+_vm._s(header.text)+" ")])})],2)]}},{key:"item",fn:function({ item }){return [_c('tr',[_c('td',{staticClass:"text-center"},[_c('v-checkbox',{attrs:{"label":null,"input-value":item.selected},on:{"change":function($event){return _vm.checkItem(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})],1),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.serverTranslate(item.studentName)))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(item.nationalId))]),_c('td',{staticClass:"text-center"},[_vm._v(_vm._s(_vm.serverTranslate(item.facultyName)))]),_c('td',{staticClass:"text-center"},[_vm._v(" "+_vm._s(item.applicationStatus >= 0 ? _vm.$t("CASES")[item.applicationStatus] : "")+" ")])])]}},{key:"top",fn:function(){return [_c('div',{staticClass:"d-flex"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"custom-title"},[_vm._v(" "+_vm._s(_vm.title))])],1),_c('v-btn',{staticClass:"mx-4 my-4",attrs:{"color":"primary"},on:{"click":_vm.exportToPrivateUniversity}},[_vm._v(_vm._s(_vm.$t("EXPORT_TO_PRIVATE_UNIVERSITY")))])],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }