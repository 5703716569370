<template>
  <v-card class="mx-auto" outlined>
    <div id="inspire">
      <v-data-table
        :headers="headers"
        :items="desserts"
        sort-by="arName"
        class="elevation-1"
        :hide-default-footer="true"
        :disable-pagination="true"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <p color="white" class="mb-0">{{ $t("CERTIFICATE_TYPES") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="90vh">
              <template v-slot:activator="{ on }">
                <v-btn color="secondary" dark v-on="on">{{
                  $t("ADD_NEW")
                }}</v-btn>
              </template>
              <v-card outlined>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title class="justify-center">
                    <span class="headline white--text text-thin">{{
                      $t(formTitle)
                    }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-alert v-show="showAlert" dense outlined type="error">{{
                        serverTranslate(errorMessages)
                      }}</v-alert>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="editedItem.arName"
                            @keydown.space="preventLeadingSpace"
                            :rules="arNameRules"
                            :label="$t('AR_NAME')"
                            prepend-inner-icon="mdi-abjad-arabic"
                            required
                            maxlength="75"
                            autocomplete="false"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            :rules="enNameRules"
                            required
                            v-model="editedItem.enName"
                            @keydown.space="preventLeadingSpace"
                            :label="$t('EN_NAME')"
                            maxlength="75"
                            autocomplete="false"
                            prepend-inner-icon="mdi-alpha-e-circle-outline"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          {{ $t("ACTIVATION") }}
                          <v-icon
                            large
                            v-if="!editedItem.active"
                            @click="editedItem.active = !editedItem.active"
                            >toggle_off</v-icon
                          >
                          <v-icon
                            color="green"
                            large
                            v-if="editedItem.active"
                            @click="editedItem.active = !editedItem.active"
                            >toggle_on</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="save">{{
                      $t("SAVE")
                    }}</v-btn>

                    <v-btn color="darken3" class="white--text" @click="close">{{
                      $t("CANCEL")
                    }}</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon meduim color="primary" @click="editCertificateItem(item)"
                  >mdi-pencil</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("EDIT") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon
                  meduim
                  color="delete"
                  class="mx-2"
                  @click="deleteCertificateItem(item)"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("DELETE") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="!item.active">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon meduim @click="toggleActivationItem(item)"
                  >toggle_off</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("ACTIVATE") }}</span>
          </v-tooltip>

          <v-tooltip bottom v-if="item.active">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon
                  meduim
                  color="green"
                  @click="toggleActivationItem(item)"
                  >toggle_on</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("DEACTIVATE") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <p>{{ $t("NO_DATA") }}</p>
        </template>
      </v-data-table>
      <pr-confirmDialog ref="confirm"></pr-confirmDialog>
    </div>
  </v-card>
</template>
<script>
import Axios from "axios";
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
export default {
  name: "pr-certification-types",
  components: {
    "pr-confirmDialog": ConfirmDialog
  },
  mixins: [serverTranslationsMixin],
  data() {
    return {
      dialog: false,
      valid: true,
      showAlert: false,
      errorMessages: "",
      desserts: [],
      editedIndex: -1,
      editedItem: {
        arName: "",
        enName: "",
        active: true,
        id: ""
      },
      defaultItem: {
        arName: "",
        enName: "",
        active: true
      },
      arNameRules: [
        v => !!v || this.$t("REQUIRED_FIELD"),
        v =>
          /^[\u0621-\u064A0-9-\s(-?)\s]+[^*\s]$/.test(
            v != undefined && v.trim()
          ) || this.$t("ARABIC_FIELD"),
        v =>
          !(v != undefined && v.slice(0, 1) >= "0" && v.slice(0, 1) <= "9") ||
          this.$t("PREVENT_NUMBERS_IN_FIRST"),
        v => (v != undefined && v.trim().length > 4) || this.$t("MIN_FIELD") + "5"
      ],
      enNameRules: [
        v => !!v || this.$t("REQUIRED_FIELD"),
        v =>
          /^[a-zA-Z0-9(-?)\s]+[^*\s]$/.test(v != undefined && v.trim()) ||
          this.$t("ENGLISH_FIELD"),
        v =>
          !(v != undefined && v.slice(0, 1) >= "0" && v.slice(0, 1) <= "9") ||
          this.$t("PREVENT_NUMBERS_IN_FIRST"),
        v => (v != undefined && v.trim().length > 4) || this.$t("MIN_FIELD") + "5"
      ]
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1
        ? "ADD_CERTIFICATE_TYPE"
        : "EDIT_CERTIFICATE_TYPE";
    },
    direction: function() {
      return this.$store.state.direction;
    },
    headers: function() {
      return [
        {
          text: this.$t("AR_NAME"),
          align: "start",
          sortable: true,
          value: "arName"
        },
        { text: this.$t("EN_NAME"), value: "enName", sortable: true },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "150px"
        }
      ];
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    }
  },

  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      Axios.get("/api/certificates/list_certificate_categories/").then(
        res => {
          this.desserts = res.data.data;
        },
        err => {
          this.$toasted.show(this.serverTranslate(err.response.data.message), {
            type: "error",
            icon: "error_outline",
            position: "bottom-left",
            duration: 5000,
            theme: "bubble"
          });
        }
      );
    },

    editCertificateItem(item) {
      this.editedIndex = this.desserts.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.dialog = true;
    },

    deleteCertificateItem(item) {
      this.$refs.confirm
        .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
        .then(confirm => {
          if (confirm) {
            const id = { id: item.id };
            Axios.delete("/api/certificates/api_certificate_categories/", {
              data: id
            }).then(
              res => {
                this.$toasted.show(this.serverTranslate(res.data.message), {
                  type: "success",
                  icon: "check_circle_outline",
                  position: "bottom-left",
                  duration: 2000,
                  theme: "bubble"
                });
                this.desserts = res.data.data;
              },
              err => {
                this.$toasted.show(
                  this.serverTranslate(err.response.data.message),
                  {
                    type: "error",
                    icon: "error_outline",
                    position: "bottom-left",
                    duration: 5000,
                    theme: "bubble"
                  }
                );
                if (err.response.status === 404) {
                  this.initialize();
                }
              }
            );
          }
        });
    },

    toggleActivationItem(item) {
      const data = { active: !item.active, updateAll: false, id: item.id };
      Axios.put("/api/certificates/api_certificate_categories/", data).then(
        res => {
          this.$toasted.show(this.serverTranslate(res.data.message), {
            type: "success",
            icon: "check_circle_outline",
            duration: 2000,
            position: "bottom-left",
            theme: "bubble"
          });
          this.desserts = res.data.data;
        },
        err => {
          this.$toasted.show(this.serverTranslate(err.response.data.message), {
            type: "error",
            icon: "error_outline",
            position: "bottom-left",
            duration: 5000,
            theme: "bubble"
          });
          this.initialize();
        }
      );
    },

    close() {
      this.$refs.form.resetValidation();

      this.dialog = false;
      (this.showAlert = false),
        (this.errorMessages = ""),
        (this.editedItem = Object.assign({}, this.defaultItem));
      this.editedIndex = -1;
      this.$refs.form.reset();
    },

    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] == " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateCertificateService();
        } else {
          Axios.post(
            "/api/certificates/api_certificate_categories/",
            this.editedItem
          ).then(
            res => {
              this.desserts = res.data.data;
              this.$toasted.show(this.serverTranslate(res.data.message), {
                type: "success",
                icon: "check_circle_outline",
                position: "bottom-left",
                duration: 2000,
                theme: "bubble"
              });
              this.close();
            },
            err => {
              this.errorMessages = err.response.data.message;
              this.showAlert = true;
              this.initialize();
            }
          );
        }
      }
    },
    updateCertificateService() {
      const editedItem = Object.assign({}, this.editedItem);
      editedItem.updateAll = true;
      Axios.put(
        "/api/certificates/api_certificate_categories/",
        editedItem
      ).then(
        res => {
          this.$toasted.show(this.serverTranslate(res.data.message), {
            type: "success",
            icon: "check_circle_outline",
            position: "bottom-left",
            duration: 2000,
            theme: "bubble"
          });
          this.close();
          this.desserts = res.data.data;
        },
        err => {
          this.errorMessages = err.response.data.message;
          this.showAlert = true;
          this.initialize();
        }
      );
    }
  }
};
</script>
