<template>
  <div class="mb-8">
    <v-card class="mx-auto mb-3">
      <div id="filters">
        <template>
          <v-toolbar flat>
            <v-toolbar-title>
              <p class="mb-0">{{ $t("APPLICATIONS") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-btn
              v-if="!$vuetify.breakpoint.mdAndUp"
              color="secondary"
              dark
              :to="{name:'StudentNominate'}"
          >{{ $t("STUDENT_NOMINATE_APPLICATION") }}
          </v-btn>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" class="ma-0" sm="12" md="12" lg="4">
                  <v-select
                      :items="serverTranslateArray(this.status, 'name')"
                      v-model="appStatus"
                      outlined
                      small-chips
                      :label="$t('APPLICATION_CASES')"
                      item-value="mongo_id"
                      item-text="name"
                  >
                  </v-select>
                </v-col>
                <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                  <v-select
                      :items="serverTranslateArray(this.years, 'name')"
                      v-model="filterItems.yearId"
                      item-value="id"
                      item-text="name"
                      :label="$t('ACADEMIC_YEAR')"
                      outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                  <v-select
                      :items="serverTranslateArray(this.semesters, 'name')"
                      v-model="filterItems.semesterId"
                      item-value="id"
                      item-text="name"
                      :label="$t('ACADEMIC_SEMESTER')"
                      outlined
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                  <v-select
                      :items="serverTranslateArray(this.certificateCategoriesList, 'name')"
                      v-model="filterItems.certificatesCategoryFilter"
                      item-value="id"
                      item-text="name"
                      :label="$t('CERTIFICATE')"
                      outlined
                      @change="selectCertType()"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                  <v-select
                      :items="serverTranslateArray(this.certificateTypesList, 'name')"
                      v-model="filterItems.certificateTypeFilter"
                      item-value="id"
                      item-text="name"
                      :label="$t('SPECIALIZATION')"
                      outlined
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="ma-0" sm="6" md="6" lg="6">
                  <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                      <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :label="$t('APPLY_APPLICATION_DATE') +$t('FROM')"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          v-model="startApplicationDate"
                          :rules="startDateRules"
                          clearable
                      ></v-text-field>
                      </template>
                      <v-date-picker 
                      :max="maxDate"
                      :min="minDate"
                      v-model="startApplicationDate"
                      @input="startDateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="ma-0" sm="6" md="6" lg="6">
                  <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          ref="endDate"
                          v-model="endApplicationDate"
                          :label="$t('APPLY_APPLICATION_DATE') +$t('TO')"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="endDateRules"
                          clearable
                          
                      ></v-text-field>
                    </template>
                    <v-date-picker 
                    v-model="endApplicationDate"
                      :max="maxDate"
                      :min="minDate"
                      @input="endDateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>

              </v-row>
              <v-row>
                <v-col cols="12" class="pa-0 my-0" xs="12" sm="12" md="12" lg="12">
                  <v-radio-group class="ma-0" v-model="searchWithId" row>
                    <v-radio :label="$t('STUDENT_NAME')" value="1"></v-radio>
                    <v-radio :label="$t('IDENTITY')" value="2"></v-radio>
                    <v-radio :label="$t('APPID')" value="3"></v-radio>
                  </v-radio-group>
                </v-col>

                <v-col class="py-0" v-if="searchWithId==='2'" cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-text-field
                      v-model="identityNum"
                      counter="25"
                      :rules="$store.state.Rules.noSpecialLetter"
                      :label="$t('WithIDENTITYTEXT')"
                      outlined
                  ></v-text-field>
                </v-col>

                <v-col class="py-0"
                       v-if="searchWithId==='3'" cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-text-field
                      v-model="appId"
                      counter="25"
                      :rules="$store.state.Rules.noSpecialLetter"
                      :label="$t('WITHAPPID')"
                      outlined
                  ></v-text-field>
                </v-col>


                <v-col class="py-0"
                       v-if="searchWithId==='1'"
                       cols="12" xs="12" sm="12" md="12" lg="12">
                  <v-autocomplete
                      :items="students"
                      v-model="studentId"
                      item-value="id"
                      item-text="name"
                      outlined
                      :loading="searchIsLoading"
                      :search-input.sync="studentSearch"
                      return-object
                      no-filter
                      clearable
                      :label="$t('WithNAMETEXT')"
                  ></v-autocomplete>
                </v-col>

              </v-row>
              <v-card-actions class="pa-0">
                <v-container class="px-0">
                  <v-row>
                    <v-col cols="auto" class="pa-1">

                      <v-btn
                          color="primary"
                          class="mx-2"
                          @click="showScopeTreeFn()">
                        {{ $t('SCOPE') }}
                        <v-icon right>mdi-navigation</v-icon>
                      </v-btn>
                    </v-col>

                    <v-spacer></v-spacer>

                    <v-col cols="auto" class="pa-1">
                      <v-btn color="primary" dark @click="searchAppliactions">
                        {{ $t("SEARCH") }}
                      </v-btn>

                    </v-col>
                    <v-col class="pa-1 mx-2" cols="auto">
                      <v-btn
                          color="darken3"
                          class="white--text"
                          @click="reset"
                      >
                        {{ $t("RESET") }}
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-container>
          </v-form>
        </template>
      </div>
    </v-card>
    <v-card class="mx-auto" outlined>
      <div id="inspire">

        <v-data-table
            :headers="headers"
            :items="applications"
            class="elevation-1 pointer"
            :footer-props="{
               showFirstLastPage: true,
               firstIcon: 'mdi-arrow-collapse-left',
               lastIcon: 'mdi-arrow-collapse-right',
               prevIcon: 'mdi-arrow-left',
               nextIcon: 'mdi-arrow-right',
               itemsPerPageOptions: [5, 10, 15, 50, 100]
               }"
            :items-per-page="5"
            :loading="loading"
            :options.sync="appOptions"
            :server-items-length="total"
            @click:row="viewApplication"
            :hide-default-footer="applications.length > 0 ? false : true"
        >
          <template v-slot:item.studentName="{ item }">
            {{ serverTranslate(item.studentName) }}
          </template>

          <template v-slot:item.facultyName="{ item }">
            {{ serverTranslate(item.facultyName) }}
          </template>
          <template v-slot:item.certificateType="{ item }">
            {{ serverTranslate(item.certificateType) }} - {{ serverTranslate(item.certificateName) }}
          </template>

          <template v-slot:item.studentTotal="{ item }">
            {{ item.studentTotal }}
          </template>

          <template v-slot:item.certifiedTotal="{ item }">
            {{ item.certifiedTotal }}
          </template>

          <template v-slot:item.applicationStatus="{ item }">
            {{
              item.applicationStatus >= 0 ?
                  $t("CASES")[item.applicationStatus] : ""
            }}
          </template>

          <template v-slot:no-data>
            <v-alert icon="warning" class="mt-2" color="light5">
              {{ $t("NO_DATA") }}
            </v-alert>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title> {{ title }}</v-toolbar-title>
            </v-toolbar>
          </template>
          <template v-slot:footer.page-text="props">
            {{ props.pageStart }} - {{ props.pageStop }} {{ $t('PAGE_TEXT') }} {{ props.itemsLength }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip v-if=" ['1','3','7','9'].includes(item.applicationStatus)" top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon meduim color="green" class="mx-2" @click.stop="acceptRefuse(1, item)">mdi-check-outline
                  </v-icon>
                </v-btn>
              </template>
              <span>{{ $t("ACCEPT_APPLICATION") }}</span>
            </v-tooltip>
            <v-tooltip v-if="  ['1','2','7','9'].includes(item.applicationStatus)" top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click.stop="acceptRefuse(0, item)">
                  <v-icon meduim color="red" class="mx-2">mdi-close-outline</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("REJECT_APPLICATION") }}</span>
            </v-tooltip>

            <v-tooltip v-if="item.applicationStatus>0" top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="printApplication(item.applyId)">
                  <v-icon small color="blue" >print</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("PRINT") }}</span>
            </v-tooltip>

          </template>
        </v-data-table>
      </div>
    </v-card>
    <pr-ApplicationDetails/>
    <pr-scope :key="componentKey" v-if="showScopeTree == true" v-on:setScope="onScopeChanged" :scopeFullPath="false"/>
    <pr-confirmDialog ref="confirm"></pr-confirmDialog>
  </div>
</template>

<script>
import serverTranslations from "@/mixins/serverTranslations";
import ShowApplicationDetails from "@/components/modules/studentData/ManageApplications/ShowApplicationDetails";
import scope from "@/components/shared/ScopeTree.vue"
import {EventBus} from "@/plugins/bus";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import globalMethodsMixin from "@/mixins/globalMethods.ts";

export default {
  name: "List",
  components: {
    "pr-scope": scope,
    "pr-ApplicationDetails": ShowApplicationDetails,
    "pr-confirmDialog": ConfirmDialog
  },
  mixins: [serverTranslations, globalMethodsMixin],

  mounted() {
    this.getFilters();
    this.searchAppliactions(true);
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const mv = this;
    EventBus.$on("updateRunListAfterAction", () => {
      mv.searchAppliactions();
    });
  },
  watch: {
    searchWithId() {
      this.studentId = null;
      this.students = [];
      this.studentSearch = "";
      this.identityNum = "";
    },
    appOptions: {
      handler() {
        this.searchAppliactions();
      },
      deep: true,
    },
    studentSearch(val) {

      if (!val || val.length < 3) {
        this.students = [];
        return false;
      }
      if (this.searchIsLoading) return;
      const phraseLang = this.isRTL(val) ? "ar" : "en"
      const data = {
        searchData: val,
        semesterId: this.activeAcademicSemester.id,
        yearId: this.activeAcademicYear.id,
        appStatus: this.appStatus
      }
      this.searchIsLoading = true;
      this.$http.post("/api/students_admission_apps/search_student/", data)
          .then(
              res => {
                res = res.data.data;
                this.students = res.students.map(item => {
                  return {"id": item.studentId, "name": this.serverTranslateCustom(item.studentName, phraseLang)}
                });
              },
              err => {
                this.showToasted("error", err.response.data.message)
              }
          )
          .then(() => (this.searchIsLoading = false));

    },
  },
  computed: {
    isSearchedWithName() {
      return this.searchWithId === "1";

    },
    isSearchedWithAppId() {
      return this.searchWithId === "3";
    },
    endDateRules() {
      return  [
          v =>
            new Date(this.endApplicationDate) >= new Date(this.startApplicationDate) ||
            this.$t("DATE_FROM_TO_VALIDATION")
        ]
    },
    startDateRules(){
      return [
        v => !this.endApplicationDate || new Date(v) <= new Date(this.endApplicationDate) || this.$t("DATE_FROM_TO_VALIDATION")

      ];
    },
    activeAcademicYear() {
      return this.$store.getters.getActiveAcademicYear;
    },
    activeAcademicSemester() {
      return this.$store.getters.getActiveAcademicSemester;
    },
    title() {
      return this.$t('APPLICATIONS_LIST_TITLE', {
        year: this.filterItems.yearName,
        semester: this.serverTranslate(this.filterItems.semesterName)
      });
    },
    headers() {
      return [
        {
          text: this.$t("STUDENT_NAME"),
          align: "center",
          sortable: true,
          value: "studentName"
        },
        {
          text: this.$t("IDENTITY"),
          align: "center",
          sortable: false,
          value: "identityNumber"
        },
        {
          text: this.$t("APPID"),
          align: "center",
          sortable: false,
          value: "appNum"
        },
        {
          text: this.$t("ADMISSION_DATA.APPLICATION_DATE"),
          align: "center",
          sortable: true,
          value: "applyDate"
        },

        {
          text: this.$t("FACULTY"),
          align: "center",
          sortable: true,
          value: "facultyName"
        },
        {
          text: this.$t("CERTIFICATE"),
          align: "center",
          sortable: true,
          value: "certificateType"
        },
        {
          text: this.$t("TOTAL_DEGREE"),
          align: "center",
          sortable: true,
          value: "studentTotal"
        },
           {
          text: this.$t("CERTIFIED_DEGREE"),
          align: "center",
          sortable: true,
          value: "certifiedTotal"
        },
        {
          text: this.$t("APPLICATION_CASES"),
          align: "center",
          sortable: true,
          value: "applicationStatus"
        },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false
        }
      ];
    }
  },
  data() {
    return {
      valid: true,
      loading: false,
      endDateMenu: false,
      startDateMenu: false ,
      startApplicationDate:"",
      endApplicationDate:"",
      filterItems: {
        facultyId: 0,
        yearId: 0,
        yearName: "",
        semesterId: 0,
        semesterName: "",
        certificatesCategoryFilter: '',
        certificateTypeFilter: ''
      },

      semesters: [],
      years: [],
      scopes: [],
      certificateCategoriesList: [],
      certificateTypesList: [],
      currentYear: {},
      currentSemester: {},
      studentsNumbers: null,
      studentSearch: "",
      searchWithId: "1",
      searchWithAppId: "1",
      status: [] ,
      appStatus: "all",
      searchIsLoading: false,
      students: [],
      applications: [],
      identityNum: "",
      appId: "",
      total: 0,
      appOptions: {},
      studentId: "",
      showScopeTree: false,
      componentKey: 0
    };
  },
  methods: {
    printApplication(itemId) {
      this.$router.push({
        name: "PrintApplication",
        params: {
          id: itemId
        }
      });
    },
    selectCertType() {
      return this.$http
          .post("/api/students_admission_apps/get_certificate_type_filter/", {
            categoryId: this.filterItems.certificatesCategoryFilter || 0
          })
          .then(response => {
                const data = response.data.data;
                this.certificateTypesList = data.certificatesTypes['basic_category_items']
              },
              err => {
                this.showToasted("error", err.response.data.message);
              })
          .then(() => (this.loading = false));
    },
    viewApplication(item) {
      EventBus.$emit('showApplicationDetails', item.applyId, item.studentId, false);
    },
    showScopeTreeFn() {
      this.showScopeTree = true;
      this.componentKey += 1;
    },
    getFilters(facultyId = null) {
   
      return this.$http
          .post("/api/students_admission_apps/list_application_filters/", {
            facultyId: facultyId || "",
            yearId: this.activeAcademicYear.id || 0,
            semesterId: this.activeAcademicSemester.id || 0
          })


          .then(response => {
                const data = response.data.data;
                const modifiedStatus= [{ name:this.$t('ALL'),id:'all', 'mongo_id': 'all' ,'ordering':0}, ...data.applicationStatus]
                this.semesters = data.Semesters;
                this.years = data.years;
                this.status = modifiedStatus;
                this.appStatus = "all"
                this.certificateCategoriesList = data.certificateCategories['basic_category_items'];
                this.currentYear = this.years.filter(year => parseInt(year.active) === 1)[0];
                this.filterItems.yearId = this.currentYear.id || 0;
                this.filterItems.yearName = this.currentYear.name || "";
                this.currentSemester = this.semesters.filter(semester => parseInt(semester.active) === 1)[0];
                this.filterItems.semesterId = this.currentSemester.id || 0;
                this.filterItems.semesterName = this.currentSemester.name || "";
              },
              err => {
                this.showToasted("error", err.response.data.message);
              })
          .then(() => (this.loading = false));
    },

    initializeList() {
      return new Promise((resolve, reject) => {
        const data = this.searchedData();
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$http
              .post("/api/students_admission_apps/list_applications/", data)
              .then(
                  res => {
                    this.applications = [];
                    const items = res.data.data.results;
                    const total = res.data.data.total;
                    resolve({
                      items,
                      total,
                    });
                  },
                  err => {
                    this.applications = [];
                    this.$toasted.show(this.serverTranslate(err.response.data.message),
                        {
                          type: "error",
                          icon: "error_outline",
                          position: "bottom-left",
                          duration: 5000,
                          theme: "bubble"
                        }
                    );
                  }
              );
          this.loading = false;
        }, 1000);
      });
    },

    searchedData() {
      this.loading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.appOptions
      const data = {
        studentId: !this.studentId || !this.studentId.id ? "" : this.studentId.id,
        yearId: this.filterItems.yearId || this.activeAcademicYear.id || 0,
        semesterId: this.filterItems.semesterId || this.activeAcademicSemester.id || 0,
        identityType: this.searchWithId || "",
        searchText: this.identityNum || this.appId||"",
        appStatus:(this.appStatus && this.appStatus!='all' &&Array.isArray(this.appStatus) ?this.appStatus.flat():"") ,
        //appStatus: (([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 'all'].includes(this.appStatus)) ? this.appStatus : ''),
        certificateTypeId: this.filterItems.certificateTypeFilter || "",
        certificateCategoryId: this.filterItems.certificatesCategoryFilter || "",
        startApplicationDate:this.startApplicationDate,
        endApplicationDate:this.endApplicationDate,
        page: page,
        pageSize: itemsPerPage
      }
      if (sortBy.length === 1 && sortDesc.length === 1) {
        data.orderType = sortDesc[0] ? "asc" : "desc";
        data.orderBy = sortBy[0]
      }
      return data
    },
    searchAppliactions(resetPageNum = false) {
      if (resetPageNum) {
        this.appOptions.page = 1;
        this.appOptions.sortBy = [];
        this.appOptions.sortDesc = [];
      }
      this.initializeList()
          .then(data => {
            this.applications = data.items;
            this.total = data.total;
          });
    },
    reset() {
      this.filterItems.yearId = this.currentYear.id;
      this.filterItems.semesterId = this.currentSemester.id;
      this.filterItems.certificatesCategoryFilter = '';
      this.filterItems.certificateTypeFilter = '';
      this.studentId = null;
      this.students = [];
      this.identityNum = "";
      this.searchWithId = "1";
      this.appStatus = "all"
      this.studentSearch = "";
      this.startApplicationDate="",
      this.endApplicationDate="",

      this.searchAppliactions(true);
    },
    onScopeChanged(changed) {
      if (changed)
        this.searchAppliactions(true);
    },
    acceptRefuse(state, item) {
      let confMsg = ""
      if (state === 1 && item.appsCount > 1 && item.appsAcceptedCount > 0) {
        confMsg = "CONF_ACCEPT_APP_NEGLECT_OTHER"
      } else if (state === 1 && item.appsCount > 1) {
        confMsg = "CONF_ACCEPT_APP_NEGLECT_REFUSE_OTHER"
      } else if (state === 1) {
        confMsg = "CONF_ACCEPT_APP"
      } else {
        confMsg = "CONF_REFUSE_APP"
      }
      this.$refs.confirm
          .open("CONFIRMATION_MESSAGE", confMsg)
          .then(res => {
                if (res) {
                  this.$http
                      .put("/api/students_admission_apps/accept_reject_application/", {
                        applicationId: item.applyId,
                        studentId: item.studentId,
                        action: state,
                        studentName: this.serverTranslate(item.studentName)
                      })
                      .then(response => {
                        this.showToasted("success", response.data.message);
                        this.initializeList()
                            .then(data => {
                              this.applications = data.items;
                              this.total = data.total;
                            });
                      })
                      .catch(errors => {
                        this.showToasted("error", errors.response.data.message);
                      });
                }
              },
              err => {
                this.showToasted("error", err.response.data.message)
              })
    }
  }
};
</script>
