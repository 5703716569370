<template>
  <div class="mb-8">
    <v-card class="mx-auto mb-2">
      <div id="filters">
        <template>
          <v-toolbar flat>
            <v-toolbar-title>
              <p color="white" class="mb-0">{{ $t("COURSES") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <template>
              <v-btn color="secondary" dark class="mb-2" @click="addCourse">{{
                  $t("ADD_NEW")
              }}</v-btn>
            </template>
            <pr-add-edit-course ref="dialogCourse" v-on:updatedCourse="updatedCourse"></pr-add-edit-course>
          </v-toolbar>
          <v-form ref="filterForm">
            <v-container>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                  <v-text-field
                    ref="courseName"
                    v-model="courseName"
                    @keydown.space="preventLeadingSpace"
                    @keypress.enter.native.prevent="search(true)"
                    outlined
                    clearable
                    dense
                    :label="direction === true
                    ? $t('SEARCH_BY')+$t('COURSE_NAME')
                    : $t('SEARCH_BY')+ ' ' +$t('COURSE_NAME')"
                    type="text"
                    maxlength="50"
                    counter="50"
                    :rules="searchCoursesRules"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                  <v-radio-group v-model="courseStatus" height="10" row>
                    <v-radio
                      @click="widthoutCertificateFilter(2)"
                      :label="$t('ALL')"
                      color="primary"
                      :value="2"
                    ></v-radio>
                    <v-radio
                      @click="defaultCertificateFilter"
                      :label="$t('WITH_CERTIFICATE')"
                      color="primary"
                      :value="1"
                    ></v-radio>
                    <v-radio
                      @click="widthoutCertificateFilter(0)"
                      :label="$t('WITHOUT_CERTIFICATE')"
                      color="primary"
                      :value="0"
                    ></v-radio>
                  </v-radio-group>
                </v-col>
              </v-row>
              <template v-if="courseStatus === 1">
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-select
                      @change="onChangeCertificateFilter"
                      :items="years"
                      v-model="yearSelected"
                      item-value="id"
                      item-text="name"
                      :label="$t('ACADEMIC_YEAR')"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-select
                      @change="onChangeCertificateFilter"
                      :items="
                        serverTranslateArray(certificateTypesArEn, 'name')
                      "
                      v-model="certificateTypeSelected"
                      item-value="id"
                      item-text="name"
                      :label="$t('CERTIFICATE_TYPE')"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-select
                      @change="onChangeCertificateFilter"
                      :items="serverTranslateArray(statusCertificates, 'name')"
                      v-model="certificateStatusSelected"
                      item-value="type"
                      item-text="name"
                      :label="$t('CERTIFICATE_STATUS')"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                  <v-col cols="12" xs="12" sm="12" md="6" lg="6">
                    <v-select
                      :items="
                        serverTranslateArray(certificateNamesArEn, 'certificateName')
                      "
                      v-model="certificateNamesSelected"
                      item-value="certificateID"
                      item-text="certificateName"
                      :label="$t('CERTIFICATE_NAME')"
                      outlined
                      dense
                    ></v-select>
                  </v-col>
                </v-row>
              </template>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="search(true)">{{
                  $t("SEARCH")
                }}</v-btn>
                <v-btn color="darken3" class="white--text" @click="reset">{{ $t("RESET") }}</v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </template>
      </div>
    </v-card>
    <v-card class="mx-auto" outlined>
      <div id="inspire">
        <v-data-table
          :headers="headers"
          :items="courses"
          :options.sync="options"
          :server-items-length="totalPages"
          :items-per-page="5"
          :hide-default-footer="courses.length > 0 ? false : true"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-arrow-left',
            nextIcon: 'mdi-arrow-right',
            itemsPerPageOptions: [5,10,15,50,100],
          }"
          @update:options="filterChanged"
          :loading="loading"
          class="elevation-1 pointer"
          @click:row="courseDetails"
        >
          <template v-if="courseStatus === 1" v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>{{$t("ACADEMIC_YEAR_COURSES")}}<span dir="ltr">{{ datatableTitle }}</span></v-toolbar-title>
              <v-divider class="mx-2" inset vertical></v-divider>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }" v-on:click.stop>
            <v-tooltip bottom v-if="item.active === 0">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon meduim @click="toggleActivationItem(item)"
                    >toggle_off</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("ACTIVATE") }}</span>
            </v-tooltip>

            <v-tooltip bottom v-else>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon
                    meduim
                    color="green"
                    @click="toggleActivationItem(item)"
                    >toggle_on</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("DEACTIVATE") }}</span>
            </v-tooltip>
            <v-tooltip v-if="item.certificatesNum === 0" bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon meduim color="primary" @click="editCourseItem(item)"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("EDIT") }}</span>
            </v-tooltip>
            <v-tooltip v-if="item.certificatesNum === 0" bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon
                    meduim
                    color="delete"
                    class="mx-2"
                    @click="deleteCourseItem(item)"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("DELETE") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-alert icon="warning" class="mt-2" color="light5">
                {{
                $t("NO_DATA")
                }}
            </v-alert>
          </template>
          <template v-slot:footer.page-text="props">
            {{ props.pageStart }} - {{ props.pageStop }} {{$t('PAGE_TEXT')}} {{ props.itemsLength }}
          </template>
        </v-data-table>
        <pr-confirmDialog ref="confirm"></pr-confirmDialog>
      </div>
    </v-card>
  </div>
</template>
<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import globalMethodsMixin from "@/mixins/globalMethods";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import AddEditCourse from "./AddEditCourse";

export default {
  name: "pr-courses",
  components: {
    "pr-confirmDialog": ConfirmDialog,
    "pr-add-edit-course": AddEditCourse
  },
  mixins: [serverTranslationsMixin, globalMethodsMixin],
  props:["searchData"],
  data() {
    return {
      loading: true,
      options: {},
      totalPages:0,
      courseName: "",
      certificates:[],
      courseStatus: 2,
      certificateStatusSelected: true,
      all: [{ certificateName: "الكل|All", certificateID: "all" }],
      certificateTypes: [],
      certificateNames: [],
      certificatesArEn: [],
      years: [],
      certificateTypeSelected: "all",
      certificateNamesSelected: "all",
      yearSelected: Number,
      yearName:"",
      certificateName: "",
      courses: [],
      courseData: {
        arName: "",
        enName: "",
        active: 1
      },
      searchCoursesRules: this.$store.state.Rules.searchRules,
      btnSearchClicked: false,
      searchDataCheck: {},
    };
  },
  computed: {
    direction: function() {
      return this.$store.state.direction;
    },
    headers: function() {
      return [
        {
          text: this.$t("AR_NAME"),
          align: "start",
          sortable: true,
          value: "arName"
        },
        { text: this.$t("EN_NAME"), value: "enName", sortable: true },
        { text: this.$t("CERTIFICATE_NUMBER"),
          value: "certificatesNum",
          sortable: true
        },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "150px"
        }
      ];
    },
    statusCertificates: function() {
      return [
        {
          name: "مفتوحة|Open",
          type: true
        },
        {
          name: "مغلقة|Closed",
          type: false
        }
      ];
    },
    certificateTypesArEn: function(data) {
      return data.certificateTypes;
    },
    certificateNamesArEn: function(data) {
      return data.certificateNames;
    },
    datatableTitle(){
      return this.courseStatus === 1 ? this.yearName : "";
    }
  },

  watch: {
    direction: function() {
      if (this.$el.querySelector(".error--text")) {
        this.$refs.filterForm.validate();
      }
    },
    options: {
      handler () {
        this.search();
      },
      deep: true,
    },
  },

  mounted() {
    if (this.searchData) {
      this.getSearchkeys();
      this.initializeList()
      .then(data => {
        this.courses = data.items;
        this.totalPages = data.total;
      });
    } else {
        this.widthoutCertificateFilter();
    }
  },
  methods: {
    defaultCertificateFilter() {
      this.courseStatus = 1;
      this.$http
        .get("/api/courses/courses_default_filters/")
        .then(
          res => {
            const data = res.data.data;
            const all =  [{ name: "الكل|All", id: "all" }]
            this.certificateTypes = all.concat(
              data.certificationCategories
            );
            this.certificateNames = this.all.concat(data.certification);
            this.years = data.years;
            this.yearSelected = data.years.filter(
              obj => obj.active == "1"
            )[0].id;
          },
          err => {
           this.showToasted("error",err.response.data.message);
          }
        )
        .then(() => {this.search(true)});
    },
    onChangeCertificateFilter() {
      const filterData = {
        certificateTypeID: this.certificateTypeSelected,
        certificateStatus: this.certificateStatusSelected,
        yearID: this.yearSelected
      };
      this.$http
        .post("/api/certificates/courses_certificates_filter/", filterData)
        .then(
          res => {
            const data = res.data.data;
            this.certificateNames = this.all.concat(data);
          },
          err => {
            this.showToasted("error",err.response.data.message);
          }
        );
    },
    widthoutCertificateFilter(value=2) {
      this.courseStatus = value;
      this.search(true);
      this.yearSelected = null;
      this.certificateStatusSelected = true;
      this.certificateNamesSelected = "all";
      this.certificateTypeSelected = "all";
    },
    initializeList() {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const searchData = {
          flagCourse: this.courseStatus,
          courseName: this.courseName,
          page: page,
          pageSize: itemsPerPage
        };
        if (this.courseStatus === 1) {
          searchData.certificateYearId = this.yearSelected;
          searchData.certificateTypeId = this.certificateTypeSelected;
          searchData.certificateStatus = this.certificateStatusSelected;
          searchData.certificateId = this.certificateNamesSelected;
          this.yearName = this.years.filter(
            obj => obj.id == this.yearSelected)[0].name;
        }
        if (sortBy.length === 1 && sortDesc.length === 1) {
          searchData.orderType = sortDesc[0] ? "asc" : "desc";
          searchData.orderBy = sortBy[0]
        }
        this.btnSearchClicked = true;
        this.searchDataCheck = searchData;
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$http
          .post("/api/courses/search_courses/", searchData)
          .then(
            res => {
              const items =  res.data.data.results;
              const total = res.data.data.total;
              resolve({
                items,
                total,
              });
            },
            err => {
              this.courses = err.response.data.data;
              this.showToasted("error",err.response.data.message);
            }
          );
          this.loading = false;
        }, 1000);
      });
    },
    setSearchKeys() {
      if (this.courseStatus === 1) {
        return {
          yearSelected: this.yearSelected,
          certificateTypeSelected: this.certificateTypeSelected,
          certificateStatusSelected: this.certificateStatusSelected,
          certificateNamesSelected: this.certificateNamesSelected,
          years: this.years,
          certificateTypes: this.certificateTypes,
          certificateStatus: this.certificateStatus,
          certificateNames: this.certificateNames,
          yearName: this.years.filter(
            obj => obj.id == this.yearSelected)[0].name,
          flagCourse: this.courseStatus,
          courseName: this.courseName
        }
      }
      return {
        flagCourse: this.courseStatus,
        courseName: this.courseName
      }
    },
    getSearchkeys() {
      if (this.searchData.flagCourse === 1) {
        this.yearSelected =this.searchData.yearSelected;
        this.certificateTypeSelected = this.searchData.certificateTypeSelected;
        this.certificateStatusSelected = this.searchData.certificateStatusSelected;
        this.certificateNamesSelected = this.searchData.certificateNamesSelected;
        this.years = this.searchData.years;
        this.certificateTypes = this.searchData.certificateTypes;
        this.certificateStatus = this.searchData.certificateStatus;
        this.certificateNames = this.searchData.certificateNames;
        this.yearName = this.searchData.yearName;
      }
      this.courseStatus= this.searchData.flagCourse;
      this.courseName= this.searchData.courseName;
    },
    courseDetails(row) {
        this.searchData = this.setSearchKeys();
        this.$router.push({name:"ViewCourse",params:{id:row.id,searchData:this.searchData}})
    },
    toggleActivationItem(item) {
      const data = { active: item.active === 0 ? 1 : 0, id: item.id};
      this.$http.put("/api/courses/activate_deactivate_course/", data).then(
        res => {
          this.search(true);
          this.showToasted("success",res.data.message);
        },
        err => {
          this.showToasted("error",err.response.data.message);
          this.search(true);
        }
      );
      event.stopPropagation();
    },
    addCourse() {
      this.$refs.dialogCourse
        .open("ADD_COURSE","add");
    },
    editCourseItem(item) {
      if (this.checkChanging()) this.filterChanged();
      this.courseData = {
        id: item.id,
        arName: item.arName,
        enName: item.enName,
        active: item.active
      };
      this.$refs.dialogCourse
      .open("EDIT_COURSE","edit",this.courseData);
      event.stopPropagation();
    },
    deleteCourseItem(item) {
      if (this.checkChanging()) this.filterChanged();
      this.$refs.confirm
      .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
      .then(confirm => {
        if (confirm) {
          const data = {
            id: item.id
          };
          this.$http
          .delete("/api/courses/delete_course/", {
            data: data,
          })
          .then(
            res => {
              this.showToasted("success",res.data.message);
              this.search(true);
            },
            err => {
              this.showToasted("error",err.response.data.message);
              if (err.response.status === 404) {
                this.search (true);
              }
            }
          );
        }
      });
      event.stopPropagation();
    },
    reset() {
      this.courseName = "";
      this.$refs.courseName.resetValidation();
      if (this.courseStatus === 0 || this.courseStatus === 2) {
        this.widthoutCertificateFilter(this.courseStatus);
      } else {
        this.certificateStatusSelected = true;
        this.certificateTypeSelected = "all";
        this.certificateNamesSelected = "all";
        this.yearSelected = this.years.filter(
            obj => obj.active == "1"
        )[0].id;
        this.search(true);
      }
    },
    checkChanging() {
      if (
        this.courseStatus === this.searchDataCheck.flagCourse &&
        (this.courseStatus === 0 || this.courseStatus === 2) &&
        this.courseName === this.searchDataCheck.courseName
      ) {
        this.btnSearchClicked = false;
        return false;
      } else if (
        this.searchDataCheck.flagCourse != this.courseStatus ||
        this.searchDataCheck.courseName != this.courseName ||
        this.searchDataCheck.certificateYearId != this.yearSelected ||
        this.searchDataCheck.certificateTypeId !=
          this.certificateTypeSelected ||
        this.searchDataCheck.certificateStatus !=
          this.certificateStatusSelected ||
        this.searchDataCheck.certificateId != this.certificateNamesSelected
      ) {
        return true;
      }
      return false;
    },
    filterChanged() {
      if (this.btnSearchClicked && this.checkChanging()) {
        this.courseStatus = this.searchDataCheck.flagCourse;
        this.courseName = this.searchDataCheck.courseName;
        if (this.searchDataCheck.flagCourse === 1) {
          this.yearSelected = this.searchDataCheck.certificateYearId;
          this.certificateTypeSelected = this.searchDataCheck.certificateTypeId;
          this.certificateStatusSelected = this.searchDataCheck.certificateStatus;
          this.certificateNamesSelected = this.searchDataCheck.certificateId;
        }
        this.$toasted.info(this.$t("FILTER_CHANGED_MESSAGE"), {
          type: "info",
          icon: "info_outline",
          position: "bottom-left",
          theme: "bubble",
          duration: 5000
        });
      }
    },
    search (resetPageNum=false) {
      if (resetPageNum) {
        this.options.page = 1;
        this.options.sortBy = [];
        this.options.sortDesc = [];
      }
      this.initializeList()
      .then(data => {
        this.courses = data.items;
        this.totalPages = data.total;
      });
    },
    updatedCourse(value) {
      this.search(true);
    },
  }
};
</script>
