<template>
    <div>
        <v-overlay :value="overlay">
            <p>{{$t("PROCESSING")}}</p>
        </v-overlay>
        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="primary" class="justify-end">
              <v-toolbar-title>{{$t("PULLING_STEPPER.STEP2.SHOW_DATA")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>

                  <div class="d-flex justify-end pa-2">
                      <download-excel
                        class   = "btn btn-default"
                        :data   = "excelFailedToPassData"
                        :fields = "excelFailedToPassHeader"
                        worksheet = "sheet1"
                        :name= "$t('PULLING_STEPPER.FILE_NAME') + '.xls'">

                        <v-btn class="mx-2" color="secondary"
                            :small="!$vuetify.breakpoint.mdAndUp"
                            :fab="!$vuetify.breakpoint.mdAndUp"
                        >
                            <span v-if="$vuetify.breakpoint.mdAndUp">{{$t('EXPORT')}}</span>
                            <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-cloud-download</v-icon>
                        </v-btn>

                    </download-excel>
                  </div>
                <v-simple-table fixed-header >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th>
                              {{$t("PULLING_STEPPER.MSG")}}
                          </th>
                          <th class="text-left" v-for="(item,index) in failedHeader" :key="index">
                              {{item}}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(innerData,index) in failedToPassData.slice(0,20)" :key="index">
                          <td class="text-left" v-for="(item,innerIndex) in innerData" :key="innerIndex">
                              {{item.value}}
                          </td>
                        </tr>
                      </tbody>
                        <tfoot>
                            <tr v-if="failedToPassData.length > 20 ">
                                <td :colspan="failedHeader.length" class="align-center">
                                    <span class="h1 d-flex justify-center font-weight-black">
                                        {{$t("PULLING_STEPPER.MORE_DATA",{num:failedToPassData.slice(20).length})}}
                                    </span>
                                </td>
                            </tr>
                        </tfoot>
                    </template>
                </v-simple-table>
          </v-card>
        </v-dialog>

        <v-stepper v-model="stepperIndex">
            <!----------------------------------Header Start------------------------------------------->
            <v-stepper-header>
              <v-stepper-step :complete="stepperIndex > 1" step="1">{{$t("PULLING_STEPPER.STEP1.NAME")}}</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="stepperIndex > 2" step="2">{{$t("PULLING_STEPPER.STEP2.NAME")}}</v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step :complete="stepperIndex > 3" step="3">{{$t("PULLING_STEPPER.STEP3.NAME")}}</v-stepper-step>
              <v-divider ></v-divider>
              <v-stepper-step  :complete="stepperIndex > 4"   step="4">{{$t("PULLING_STEPPER.STEP4.NAME")}}</v-stepper-step>
            </v-stepper-header>
            <!----------------------------------Header End------------------------------------------->
            <v-stepper-items>
              <v-stepper-content step="1">

                  <pr-step1 @changeData="changeFilterData"></pr-step1>

              </v-stepper-content>
              <v-stepper-content step="2">

                <!---------------------------------STEP2--------------------------->
                <pr-step2 @change="handelSecondaryFileData($event)"></pr-step2>
                <!---------------------------------END STEP2 ----------------------->

              </v-stepper-content>
              <v-stepper-content step="3">

                  <v-alert dense outlined type="error"
                   v-if="failedToPassData && failedToPassData.length > 0 ">
                       {{$t("PULLING_STEPPER.ERROR")}}
                      <v-icon color="primary" @click="dialog = true;">mdi-eye</v-icon>
                  </v-alert>

                  <!--Step3-->
                  <pr-step3 @SavedMappedData="SavedMappedData" :filterData="filterData" :header="header"></pr-step3>
              </v-stepper-content>
             <v-stepper-content  step="4">
                  <pr-step4 v-if="saved"
                            :failedData="failedToPassData"
                            :failedHeader="failedHeader"
                            :excelHeader="excelFailedToPassHeader"
                            :excelData="excelFailedToPassData"
                  ></pr-step4>
              </v-stepper-content>

            </v-stepper-items>
          </v-stepper>

        <pr-confirmDialog ref="confirm"></pr-confirmDialog>
        </div>
</template>

<script>
    import { EventBus } from "@/plugins/bus";
    import PrStep1 from "./Step1";
    import PrStep2 from "./Step2";
    import PrStep3 from "./Step3";
    import PrStep4 from "./Step4";
    import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
    import globalMethodsMixin from "@/mixins/globalMethods";
    import ConfirmDialog from "@/components/shared/ConfirmDialog";
export default {
    name: "pr-Pulling",
    mixins: [serverTranslationsMixin,globalMethodsMixin],
    components: {
        PrStep3,
        PrStep2,
        PrStep1,
        PrStep4,
        "pr-confirmDialog": ConfirmDialog,
    },
    watch:{
      saved(){
          if(this.saved)
              this.stepperIndex = 4;
      }
    },
     data(){
        return {
            stepperIndex : 1 ,
            fileData: [],
            header:[],
            filterData:{yearId:"",certificateTypeId:"", certificateId:""},
            mappedData:[],
            failedToPassData: [],
            failedHeader:[],
            validData:[],
            dialog :false,
            map:{yearId:"",certificateTypeId:"", certificateId:"",data:null},
            overlay:false,
            saved:false,
            courseIds:[]
        }
    },
    created(){
         EventBus.$on("CancelMap",()=>{
            this.$refs.confirm
                    .open("CONFIRMATION_MESSAGE", "PULLING_STEPPER.CANCEL_MSG")
                    .then(confirm => {
                        if (confirm) {
                            this.$router.push("/home");
                        }
                    });
        });
        EventBus.$on("changeStepperIndex",index=>{
            this.stepperIndex = index
        });
    },
    methods:{
         SavedMappedData($event){
            this.mappedData = $event.mappedHeader;
            this.courseIds = $event.courseIds;
            if(!this.mappedData || this.mappedData.length == 0) return false;
            this.failedToPassData = [];
            this.failedHeader = [];
            this.validData = [] ;
            this.overlay = true;
            this.validateData().then(()=>{
                this.confirmSend();
                this.overlay = false;
            }).catch(()=>{
                this.overlay = false;
            });
        },
        changeFilterData($event){
            /*********************RESET ALL DATA****************************///
            this.filterData = $event;
            this.failedToPassData = [] ;
            this.validData = [] ;
            this.failedHeader =  [];
            this.fileData = [];
            this.header = [];

            if(this.filterData.yearId && this.filterData.certificateId && this.filterData.certificateTypeId){
                 EventBus.$emit("resetFile");
            }
        },
        handelSecondaryFileData(data){
            this.header = data[0];
            this.fileData = data.slice(1);
            this.failedToPassData = [] ;
            this.validData = [] ;
            this.failedHeader =  [];
        },
        validateValue(name,rule,value){
            if(rule.required && (value == undefined || String(value) == '' || value == null))
                return this.$t("REQUIRED",{field:this.serverTranslate(name)});
            else  if (rule.max && String(value).length < rule.min)
                return this.$t("PULLING_STEPPER.RULES.MIN",{field:this.serverTranslate(name),min:rule.min});
            else if(rule.max && String(value).length > rule.max)
                return this.$t("PULLING_STEPPER.RULES.MAX",{field:this.serverTranslate(name),max:rule.max});
            else if (rule.type == "integer" && !/^(?:-?[1-9]\d*|0)?(?:\d+)?$/.test(value))
                return  this.$t("PULLING_STEPPER.RULES.INTEGER",{field:this.serverTranslate(name)});
            else if (rule.type == "text" && !/^(?:[a-zA-Z0-9\s)\-(\u0621-\u064A]){0,}$/.test(value))
                return  `${this.serverTranslate(name)} ${this.$t("PREVENT_SPECIAL_CHARACTER_FIELD")}` ;
            else if(rule.type == "float" && !/^(?:-?[1-9]\d*|0)?(?:\.\d+)?$/.test(value))
                return  this.$t("PULLING_STEPPER.RULES.FLOAT",{field:this.serverTranslate(name)});
            return false;
        },
        validateData(){
            return new Promise((resolve,reject)=>{
                if(!this.filterData.certificateId || !this.filterData.yearId ||!this.filterData.certificateTypeId)
                return true;

            this.failedHeader = this.mappedData.map(item => this.serverTranslate(item.name));
            const mappedIndexes = this.mappedData.map(item=>item.index);

            this.fileData.forEach((rowData)=>{
                let studentData = {};
                let validCourseDegree = false;
                for(const mapItem of this.mappedData){
                   const validationMsg = this.validateValue(mapItem.name,mapItem.rules,rowData[mapItem.index]);
                   if(!validCourseDegree && this.courseIds.indexOf(mapItem.key) > -1 && parseFloat(rowData[mapItem.index]) >= 0)
                        validCourseDegree = true;

                    if(validationMsg){
                        const failedData = [{value:validationMsg}];
                        rowData.forEach((item,index)=>{
                            if(mappedIndexes.indexOf(index) > -1)
                                failedData.push({value:item,index:index});
                        });
                        this.failedToPassData.push(failedData);
                        studentData = null;
                        validCourseDegree = true;
                        break;
                    }
                   studentData[mapItem.key] = rowData[mapItem.index];
                }
                if(!validCourseDegree){
                     const failedData = [{value:this.$t('PULLING_STEPPER.RULES.VALID_COURSE')}];
                        this.mappedData.forEach((item,index)=>{
                            if(mappedIndexes.indexOf(item.index) > -1)
                                failedData.push({value:rowData[item.index],index:item.index});
                        });
                        this.failedToPassData.push(failedData);
                        studentData = null;
                }
                if(studentData && studentData != {})
                    this.validData.push(studentData)
            });
            if(this.failedToPassData && this.failedToPassData.length > 0)
                this.$vuetify.goTo(0);
                if(this.validData && this.validData.length > 0)
                    resolve();
                else
                    reject();
            });

        },
        confirmSend(){
             if (this.failedToPassData && this.failedToPassData.length > 0  ) {
                this.$refs.confirm
                    .open("CONFIRMATION_MESSAGE", "PULLING_STEPPER.CONFIRM_ERROR")
                    .then(confirm => {
                        if (confirm) {
                            this.sendData();
                        }
                    });
            }
            else
                this.sendData()
        },
        sendData(){
             this.$http.post("/api/upload_students_data/save_egyption_secondary_students_data/",
                    {
                        data:this.validData,
                        certificateId:this.filterData.certificateId,
                        yearId:this.filterData.yearId,
                        certificateTypeId:this.filterData.certificateTypeId
                    })
                 .then(res => {
                        this.saved = true;
                        this.$toasted.show(res.data.message, {
                        type: "success",
                        icon: "check_circle_outline",
                        theme: "bubble",
                        duration: 2000,
                        position: "bottom-left"
                      });

                 })
                 .catch(err=>{
                        this.$vuetify.goTo(0);
                        this.saved = true;
                        const data = err.response.data.data;
                        if(data){
                            data.map(mapItem=>{
                               const newItem = [];
                               newItem.push({value:this.serverTranslate(mapItem["errorMessage"])})
                               this.mappedData.forEach(item=>{
                                  newItem.push({index:item.index,value:mapItem[item.key]});
                               });
                               this.failedToPassData.push(newItem);
                            });
                        }
                        this.$toasted.show(
                            this.serverTranslate(err.response.data.message), {
                                type: "error",
                                icon: "error_outline",
                                position: "bottom-left",
                                duration: 5000,
                                theme: "bubble"
                            }
                        );
             });
        }

    },
    computed: {
        excelFailedToPassHeader(){
            const fields = {};
            fields[this.$t("PULLING_STEPPER.MSG")] = "msg";

            this.mappedData.map(item => {
                fields[this.serverTranslate(item.name)] = item.key;
            });
            return fields;
        },
        excelFailedToPassData(){
            const mappedStudentsData =  this.failedToPassData.map((studentsList)=>{
                const mappedStudentData = {};
                studentsList.forEach((item,index)=>{
                    if(item.index == undefined )
                        mappedStudentData["msg"] = item.value;
                    else {
                        const  mapItem = this.mappedData.find(mapItem=> mapItem.index == item.index);
                        mappedStudentData[mapItem.key] = item.value;
                    }

                });
                return mappedStudentData;
            });
            return mappedStudentsData;
        }
    }
}
</script>