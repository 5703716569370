import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import Login from "@/components/LoginComponent/Login.vue";
import Home from "@/components/HomeComponent/HomePage.vue";
import Sectors from "@/components/modules/systemInfo/SectorsComponent/Sectors.vue";
import certificationTypes from "@/components/modules/systemInfo/certificationTypes/certificationTypes.vue";
import Certifications from "@/components/modules/systemInfo/certifications/Certifications.vue";
import Courses from "@/components/modules/systemInfo/courses/Courses.vue";
import AddEditCertification from "@/components/modules/systemInfo/certifications/AddEditCertification.vue";
import ViewCertification from "@/components/modules/systemInfo/certifications/ViewCertification.vue";
import PullCertifications from "@/components/modules/systemInfo/certifications/PullCertifications.vue";
import ListCertifications from "@/components/modules/systemInfo/certifications/ListCertifications.vue";
import SystemInfo from "@/components/modules/systemInfo/SystemInfo.vue";
import ListCourses from "@/components/modules/systemInfo/courses/ListCourses.vue";
import ViewCourse from "@/components/modules/systemInfo/courses/ViewCourse.vue";
import ApprovalRulesApp from "@/components/modules/approvalRules/App.vue";
import ListApprovalRules from "@/components/modules/approvalRules/List.vue";
import ManageApplications from "@/components/modules/studentData/ManageApplications/ManageApplications.vue";
import ManageApplicationsList from "@/components/modules/studentData/Applications/List.vue";
import TabsCpnu from "@/components/modules/studentData/ManageLinkingOtherSystem/TabsCpnu.vue";
import StudentNominate from "@/components/modules/studentData/ManageApplications/StudentNominate.vue";
import StudentDataIndex from "@/components/modules/studentData/Index.vue";
import FeesPayment from "@/components/modules/studentData/feesPayment/FeesPayment.vue";
import ReportIndex from "@/components/modules/StudentsReportsAndStatistics/Index.vue";
import AllStudentsReport from "@/components/modules/StudentsReportsAndStatistics/allStudents/Report.vue";
import ApplicationStatus from "@/components/modules/StudentsReportsAndStatistics/applicationsStatus/ApplicationsStatusReport.vue";
import PullingStudentData from "@/components/modules/PullingStudentData/App.vue"
import AdministrationIndex from "@/components/modules/administration/Index.vue";
import Calendar from "@/components/modules/administration/calendar/Calendar.vue";
import Services from "@/components/modules/administration/services/Services.vue";
import Mails from "@/components/modules/administration/mails/Mails.vue";
import GeneralStatistic from "@/components/modules/StudentsReportsAndStatistics/GeneralStatistic.vue";
import News from "@/components/modules/administration/news/News.vue"
import MainRules from "@/components/modules/administration/SystemRules/MainRules.vue"
import SendingNotifications from "@/components/modules/studentData/SendingNotifications/SendingNotifications.vue";
import PrintApplication from "@/components/modules/studentData/Applications/PrintApplication.vue";
import StudentDataManagement from "@/components/modules/studentData/StudentDataManagement/StudentDataManagement.vue";
import StudentDataManagementList from "@/components/modules/studentData/StudentDataManagement/StudentDataManagementList.vue";

Vue.use(VueRouter);
const loginGuard = (to: any, from: any, next: (dist?: any) => void) => {
    if (store.getters.getToken) {
        next({path: "/home"});
        return;
    }
    next();
};
const homeGuard = (to: any, from: any, next: (dist?: any) => void) => {
    if (!store.getters.getToken) {
        next({path: "/login"});
        return;
    }
    next();
};

const routes = [
    {
        path: "",
        redirect: "/home"
    },
    {
        path: "/home",
        component: Home,
        name:"home",
        beforeEnter: homeGuard,
        children: [
            {
                path: "SystemInfo",
                component: SystemInfo,
                children: [
                    {
                        path: "sectors",
                        component: Sectors
                    },
                    {
                        path: "Certifications",
                        component: Certifications,
                        children: [
                            {
                                path: "",
                                props: true,
                                name: "ListCertifications",
                                component: ListCertifications
                            },
                            {
                                name: "PullCertifications",
                                path: "PullCertifications",
                                props: true,
                                component: PullCertifications
                            },
                            {
                                name: "AddCertification",
                                path: "AddCertification",
                                component: AddEditCertification
                            },
                            {
                                name: "EditCertification",
                                path: "EditCertification/:id",
                                props: true,
                                component: AddEditCertification
                            },
                            {
                                name: "ViewCertification",
                                path: "ViewCertification/:id",
                                props: true,
                                component: ViewCertification
                            }
                        ]
                    },
                    {
                        path: "courses",
                        component: Courses,
                        children: [
                            {
                                path: "",
                                name: "ListCourses",
                                props: true,
                                component: ListCourses
                            },
                            {
                                path: "ViewCourse/:id",
                                name: "ViewCourse",
                                props: true,
                                component: ViewCourse
                            }
                        ]
                    },
                    {
                        path: "certificationsType",
                        component: certificationTypes
                    }
                ]
            },
            {
                path: "AcadimicCourses/courses",
                component: Courses
            },
            {
                path: "ApprovalRules",
                component: ApprovalRulesApp,
                children: [
                    {
                        path: "",
                        name: "ListApprovalRules",
                        props: true,
                        component: ListApprovalRules
                    }
                ]
            },
            {
                path: "StudentData",
                component: StudentDataIndex,
                children: [
                    {
                        path: "FeesPayment",
                        component: FeesPayment
                    },
                     {
                      name:"StudentNominate",
                      path:"StudentNominate",
                      component:StudentNominate
                    },
                    {
                        path:"ManageApplications",
                        component:ManageApplications,
                        children:[
                           {
                              name:"ManageApplicationsList",
                              path:"",
                              component:ManageApplicationsList
                           }
                        ]
                    },
                    {
                        path:"StudentDataManagement",
                        component:StudentDataManagement,
                        children:[
                           {
                              name:"StudentDataManagementList",
                              path:"",
                              component:StudentDataManagementList
                           }
                        ]
                    },
                    {
                        name:"ManageLinkingOtherSystem",
                        path:"ManageLinkingOtherSystem",
                        component:TabsCpnu
                      },
                

                    
                    {
                        path:"PullingStudentData",
                        component:PullingStudentData
                    },
                    {
                        path:"SendingNotifications",
                        component:SendingNotifications,
                    },
                    {
                    path: "ViewApplication/Print/:id?",
                    name: "PrintApplication",
                    component: PrintApplication
                  }
                    
                ]
            },
            {
                path:"ReportsAndStatistics",
                component:ReportIndex,
                children:[
                   {
                      name:"AllStudents",
                      path:"AllStudents",
                      component: AllStudentsReport
                   },
                   {
                       name: "ApplicationStatus",
                       path: "ApplicationStatus",
                       component: ApplicationStatus
                   },
                    {
                        path: "GeneralStatistic",
                        name: "GeneralStatistic",
                        component: GeneralStatistic
                    }
                ]
            },
            {
                path: "Administration",
                component: AdministrationIndex,
                children: [
                    {
                        path: "Calendar",
                        component: Calendar
                    },
                    {
                        path: "Services",
                        name: "Service",
                        component: Services
                    },
                    {
                        path: "Mails",
                        name: "Mail",
                        component: Mails
                    },
                    {
                        
                        path: "News",
                        name: "New",
                        component: News
                    },
                    { 
                        path: "MainRules",
                        name: "MainRules",
                        component: MainRules
                    }

                    


                ]
            }
        ]
    },
    {
        path: "/login",
        component: Login,
        beforeEnter: loginGuard
    },
    {
        path: "*",

        redirect: "/"
    }
];
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {
    if (to.path == "/login" || to.path == "/home") {
        store.commit("changeActiveAppId", {
            path: "home"
        });
        next();
        return;
    }
    const route = to.path.split("/");
    route.splice(0, 2);
    const path = route[0] + (route[1] ? ("/" + route[1]) : "");
    store.commit("changeActiveAppId", {
        path
    });
    store.commit("getOpenPanel", {
        panel: route[0]
    });
    next();
});

export default router;
