var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('div',{attrs:{"id":"inspire"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.serverTranslateArrayKeys(_vm.listAcceptedRejected,['studentName']),"sort-by":"name","footer-props":{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              itemsPerPageOptions: [5, 10, 15, 50, 100]
            },"items-per-page":15,"hide-default-footer":_vm.listAcceptedRejected.length <= 0}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }