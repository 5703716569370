<template>
   <v-container> 
    <v-row>
    <!-- Column for the button -->
    <v-col cols="2"> 
      <v-btn
        color="primary"
        class="mx-2 my-4"
        :small="!$vuetify.breakpoint.mdAndUp"
        :fab="!$vuetify.breakpoint.mdAndUp"
        @click="showScopeTreeFn()"
      >
        <template v-if="$vuetify.breakpoint.mdAndUp">
          {{$t('SCOPE')}}
        </template>
        <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-navigation</v-icon>
      </v-btn>
      <pr-scope :key="componentKey" ref="prScopeComponent"  v-on:setScope="onScopeChanged" v-if="showScopeTree == true"  :scopeFullPath="false"/>
    </v-col>

    </v-row>
    <v-row>
      <!-- Column for v-tabs -->
      <v-col cols="12" class="ma-0" sm="12" md="12" lg="12">
        <v-tabs v-model="tab" color="primary"   density="compact"   >
          <!-- Define your v-tabs content here -->
          <v-tab href="#tab-1" class="px-2"  >
            {{$t("APPLICATION_EXPORTING_TITLE_STUDENT")}}
          </v-tab>
          <v-tab href="#tab-2" class="mx-12">
            <span class="tab-title">
              {{$t("STATUS_STUDENTS_AFTER_EXPORTING")}}
            </span>
          </v-tab>
         
          <v-tabs-items v-model="tab">
            <v-tab-item value="tab-1">
              <export-to-cpnu @changeTabRequest="changeTabInParent"></export-to-cpnu>
            </v-tab-item>
            <v-tab-item value="tab-2">
              <list_accept_reject_cpnu></list_accept_reject_cpnu>
            </v-tab-item>
          </v-tabs-items>
        </v-tabs>
      </v-col>
      
  
    </v-row>
  </v-container> 
</template>
<script>
import ManageLinkingOtherSystem from "@/components/modules/studentData/ManageLinkingOtherSystem/ManageLinkingOtherSystem.vue"; 
import ListAcceptedRejectCpnu from "@/components/modules/studentData/ManageLinkingOtherSystem/ListAcceptedRejectCpnu.vue"; 

import scope from "@/components/shared/ScopeTree.vue"
import { EventBus } from "@/plugins/bus";

export default {
    name:"tabs",
    components:{
     "export-to-cpnu":ManageLinkingOtherSystem, 
     "list_accept_reject_cpnu":ListAcceptedRejectCpnu,
     "pr-scope":scope, 
//       "pr-confirmDialog": ConfirmDialog
   },

 data() {
      return {
        tab:"tab-1",
        showScopeTree:false,
         componentKey:0


      }
    },
   computed: {
  /*   title(){
         return   this.$t('APPLICATION_EXPORTING_TITLE' ,{
            year:this.yearName ,
            semester: this.serverTranslate(this.semesterName)
         });

   } */
},
watch:{
      tab(){
          if (this.tab === 'tab-1') {
                    EventBus.$emit('updateRunLinkListAfterAction');
              } else {
                EventBus.$emit('updateRunListAcceptedRejectedAfterAction');
                }
            }
      },
methods: {
  showScopeTreeFn(){
    this.showScopeTree = true;
    this.componentKey +=1;
  },
  onScopeChanged(changed) {
    if (changed) {
        // Call the onScopeChanged method in the child component
        EventBus.$emit('onScopeChangedInChild');
      }
    },
    changeTabInParent(newTab) {
      // Handle the tab change in the parent component
      this.tab = newTab;
    },
}
}



</script>
 <style scoped>
.tab-title {
  white-space: nowrap; 


}


</style> 