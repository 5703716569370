var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mb-8"},[_c('v-card',{staticClass:"mx-auto mb-2"},[_c('div',{attrs:{"id":"courses"}},[[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_c('p',{staticClass:"mb-0",attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.$t("COURSE_DETAILS")))])]),_c('v-spacer')],1),_c('v-form',{ref:"courseForm"},[_c('v-container',{staticClass:"center pa-0"},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"6"}},[_c('label',{staticClass:"text-no-wrap primary--text font-weight-bold",class:+ _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                  'd-table-row'},[_vm._v(_vm._s(_vm.$t("COURSE_NAME_AR")))]),_c('span',{class:_vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm
                  ? 'd-table-cell'
                  : 'd-table-row'},[_vm._v(_vm._s(_vm.courseData.arName))])]),_c('v-col',{attrs:{"cols":"12","xs":"12","sm":"6","md":"6","lg":"6"}},[_c('label',{staticClass:"text-no-wrap primary--text font-weight-bold",class:+ _vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                  'd-table-row'},[_vm._v(_vm._s(_vm.$t("COURSE_NAME_EN")))]),_c('span',{class:_vm.$vuetify.breakpoint.lg || _vm.$vuetify.breakpoint.md || _vm.$vuetify.breakpoint.sm
                  ? 'd-table-cell'
                  : 'd-table-row'},[_vm._v(_vm._s(_vm.courseData.enName))])])],1),_c('v-card-actions',[_c('v-spacer'),(_vm.showBtn)?[_c('v-btn',{attrs:{"color":"primary","dark":""},on:{"click":_vm.editCourse}},[_vm._v(_vm._s(_vm.$t("EDIT")))]),_c('v-btn',{attrs:{"color":"error","dark":""},on:{"click":_vm.deleteCourse}},[_vm._v(_vm._s(_vm.$t("DELETE")))])]:_vm._e(),_c('v-btn',{staticClass:"white--text",attrs:{"color":"darken3"},on:{"click":_vm.back}},[_vm._v(_vm._s(_vm.$t("BACK")))])],2)],1)],1)],_c('pr-add-edit-course',{ref:"dialogCourse",attrs:{"searchData":_vm.searchData},on:{"updatedCourse":_vm.updatedCourse}})],2)]),_c('v-card',{staticClass:"mx-auto",attrs:{"outlined":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.serverTranslateArray(_vm.certificates,'name'),"options":_vm.options,"server-items-length":_vm.totalPages,"items-per-page":5,"hide-default-footer":_vm.certificates.length > 0 ? false : true,"footer-props":{
        showFirstLastPage: true,
        firstIcon: 'mdi-arrow-collapse-left',
        lastIcon: 'mdi-arrow-collapse-right',
        prevIcon: 'mdi-arrow-left',
        nextIcon: 'mdi-arrow-right',
        itemsPerPageOptions: [5,10,15,50,100],
      },"loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t("CERTIFICATE_RELATED_TO_COURSE")))]),_c('v-divider',{staticClass:"mx-2",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.actions",fn:function({ item }){return [(!item.certificateStatus)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{staticClass:"mx-2",attrs:{"meduim":""}},[_vm._v("mdi-lock ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t("CLOSED_CERTIFICATE")))])]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{staticClass:"mt-2",attrs:{"icon":"warning","color":"light5"}},[_vm._v(" "+_vm._s(_vm.$t("NO_DATA"))+" ")])]},proxy:true},{key:"footer.page-text",fn:function(props){return [_vm._v(" "+_vm._s(props.pageStart)+" - "+_vm._s(props.pageStop)+" "+_vm._s(_vm.$t('PAGE_TEXT'))+" "+_vm._s(props.itemsLength)+" ")]}}])})],1),_c('pr-confirmDialog',{ref:"confirm"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }