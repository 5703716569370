<template>
  <div class="mb-8">
    <v-card class="mx-auto mb-3">
      <div id="filters">
        <template>
          <v-toolbar flat>
            <v-toolbar-title>
              <p class="mb-0">{{ $t('APPLIED_STUDENT_REPORT_TITLE') }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-divider></v-divider>
          <v-form ref="form">
            <v-container>
              <v-row>
                <v-col cols="12" class="ma-0" xs="12" sm="12" md="6" lg="6">
                  <v-select
                      :items="serverTranslateArray(this.years, 'name')"
                      v-model="filterItems.yearId"
                      item-value="id"
                      item-text="name"
                      :label="$t('ACADEMIC_YEAR')"
                      outlined
                      @change="allowExport=false"
                  ></v-select>
                </v-col>
                <v-col cols="12" class="ma-0" xs="12" sm="12" md="6" lg="6">
                  <v-select
                      :items="serverTranslateArray(this.semesters, 'name')"
                      v-model="filterItems.semesterId"
                      item-value="id"
                      item-text="name"
                      :label="$t('ACADEMIC_SEMESTER')"
                      outlined
                      @change="allowExport=false"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                  <v-select
                      :items="serverTranslateArray(this.certificateCategoriesList, 'name')"
                      v-model="filterItems.certificatesCategoryFilter"
                      item-value="id"
                      item-text="name"
                      :label="$t('CERTIFICATE')"
                      outlined
                      @change="selectCertType()"
                  ></v-select>
                </v-col>

                <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                  <v-select
                      :items="serverTranslateArray(this.certificateTypesList, 'name')"
                      v-model="filterItems.certificateTypeFilter"
                      item-value="id"
                      item-text="name"
                      :label="$t('SPECIALIZATION')"
                      outlined
                  ></v-select>
                </v-col>
              </v-row>

              <v-row>
                <v-col cols="12" class="ma-0" sm="6" md="6" lg="6">
                  <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :label="$t('APPLY_APPLICATION_DATE') +$t('FROM')"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          v-model="startApplicationDate"
                          :rules="startDateRules"
                          clearable
                      ></v-text-field>
                    </template>
                    <v-date-picker
                        :max="maxDate"
                        :min="minDate"
                        v-model="startApplicationDate"
                        @input="startDateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="ma-0" sm="6" md="6" lg="6">
                  <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          ref="endDate"
                          v-model="endApplicationDate"
                          :label="$t('APPLY_APPLICATION_DATE') +$t('TO')"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="endDateRules"
                          clearable

                      ></v-text-field>
                    </template>
                    <v-date-picker
                        v-model="endApplicationDate"
                        :max="maxDate"
                        :min="minDate"
                        @input="endDateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>

              </v-row>

              <v-card-actions class="pa-0">
                <v-container class="px-0">
                  <v-row>
                    <v-col cols="auto" class="pa-1">
                      <v-btn
                          color="primary"
                          class="mx-2"
                          :small="!$vuetify.breakpoint.mdAndUp"
                          :fab="!$vuetify.breakpoint.mdAndUp"
                          @click="showScopeTreeFn()">
                        <template v-if="$vuetify.breakpoint.mdAndUp"> {{ $t('SCOPE') }}</template>
                        <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-navigation</v-icon>
                      </v-btn>
                    </v-col>

                    <v-col cols="auto" class="pa-1">
                      <download-excel v-if="allowExport"
                                      :fields="transformedFields"
                                      worksheet="Students Worksheet"
                                      name="StudentsData.xls"
                                      :title="[$t('REPORT')+ ' ' +title,secondTitle]"
                                      :fetch="fetchExportedData"
                      >
                        <v-btn color="secondary"
                               :small="!$vuetify.breakpoint.mdAndUp"
                               :fab="!$vuetify.breakpoint.mdAndUp"
                        >
                          <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('EXPORT') }}</span>
                          <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-cloud-download</v-icon>
                        </v-btn>
                      </download-excel>
                    </v-col>
                    <v-spacer></v-spacer>
                    <v-col cols="auto" class="pa-1">
                      <v-btn
                          :small="!$vuetify.breakpoint.mdAndUp"
                          :fab="!$vuetify.breakpoint.mdAndUp"
                          color="primary" dark @click="searchAppliactions"
                      >
                        <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t("SEARCH") }}</span>
                        <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-account-search</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="pa-1 mx-2" cols="auto">
                      <v-btn
                          :small="!$vuetify.breakpoint.mdAndUp"
                          :fab="!$vuetify.breakpoint.mdAndUp"
                          color="darken3"
                          class="white--text"
                          @click="reset"
                      >
                        <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t("RESET") }}</span>
                        <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-delete-empty</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-actions>
            </v-container>
          </v-form>
        </template>
      </div>
    </v-card>
    <v-card class="mx-auto">
      <v-toolbar flat v-if="$vuetify.breakpoint.mdAndUp">
        <v-row>
          <v-col cols="12" md="8" lg="8" class="pt-10">
            <v-toolbar-title :class="!$vuetify.breakpoint.mdAndUp?'text-thin':''">
              {{ title }}
            </v-toolbar-title>
          </v-col>
          <v-col cols="12" md="4" lg="4">
            <v-select class="ma-3 pt-4 mb-0"
                      v-model="value"
                      :items="headers"
                      :label="$t('HIDE_SHOW_COLUMN')"
                      :placeholder="$t('HIDE_SHOW_COLUMN')"
                      multiple
                      append-outer-icon="mdi-filter"
                      return-object
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span
                    v-if="index === 1"
                    class="grey--text caption"
                >(+{{ value.length - 1 }} {{ $t('OTHER') }})</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-toolbar>
      <v-row v-else>
        <v-col cols="12" md="8" lg="8" class="pb-0 ps-6">
          <v-toolbar-title :class="!$vuetify.breakpoint.mdAndUp?'text-thin':''">
            {{ title }}
          </v-toolbar-title>
        </v-col>
        <v-col cols="12" md="4" lg="4">
          <v-select class="ma-3 pt-4 mb-0"
                    v-model="value"
                    :items="headers"
                    :label="$t('HIDE_SHOW_COLUMN')"
                    :placeholder="$t('HIDE_SHOW_COLUMN')"
                    multiple
                    append-outer-icon="mdi-filter"
                    return-object
          >
            <template v-slot:selection="{ item, index }">
              <v-chip v-if="index === 0">
                <span>{{ item.text }}</span>
              </v-chip>
              <span
                  v-if="index === 1"
                  class="grey--text caption"
              >(+{{ value.length - 1 }} {{ $t('OTHER') }})</span>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-data-table
          :headers="selectedHeaders"
          :items="serverTranslateArrayKeys(applications,
                ['studentName','faculty','branch','gender',
                'country','state','city','semester','applicationStatus',
                'paymentStatus','certificateTypeEquation','certificateType','certificateCountry','major'])"
          class="elevation-1"
          :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-arrow-left',
                    nextIcon: 'mdi-arrow-right',
                    itemsPerPageOptions: [5, 10, 15, 50, 100]
                }"
          :items-per-page="10"
          :loading="loading"
          :options.sync="appOptions"
          :server-items-length="total"
          :hide-default-footer="selectedHeaders.length === 0"
      >
        <template v-slot:item.mobile="{ item }">
          <span dir='ltr'>{{ item.mobile }}</span>
        </template>
        <template v-slot:item.telephone="{ item }">
          <span dir='ltr'>{{ item.telephone }}</span>
        </template>
        <template v-slot:item.birthdate="{ item }">
          {{ reverseString(item.birthdate, '-') }}
        </template>
        <template v-slot:item.submissionDate="{ item }">
                <span dir='ltr' v-if="item.submissionDate">
                    {{ reverseString(item.submissionDate, '-') }}
                    <template v-if="item.submissionTime.indexOf(':')>-1">
                            {{ time12Hr(item.submissionTime) }}
                    </template>
                </span>
        </template>
        <template v-slot:no-data>
          <v-alert icon="warning" class="mt-2" color="light5">
            {{ $t("NO_DATA") }}
          </v-alert>
        </template>
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} - {{ props.pageStop }} {{ $t('PAGE_TEXT') }} {{ props.itemsLength }}
        </template>
      </v-data-table>
    </v-card>
    <pr-scope :key="componentKey" v-if="showScopeTree == true" v-on:setScope="onScopeChanged" :scopeFullPath="false"/>
  </div>
</template>

<script>
import serverTranslations from "@/mixins/serverTranslations";
import globalMethods from "@/mixins/globalMethods";
import scope from "@/components/shared/ScopeTree.vue"
import Axios from "axios";

export default {
  name: "pr-fees-payment",
  components: {
    "pr-scope": scope
  },
  mixins: [serverTranslations, globalMethods],
  mounted() {
    this.getFilters();
    this.searchAppliactions(true);
    this.value = this.headers;
  },
  data() {
    return {
      endDateMenu: false,
      startDateMenu: false,
      startApplicationDate: "",
      endApplicationDate: "",
      allowExport: false,
      semesters: [],
      years: [],
      value: [],
      certificateCategoriesList: [],
      certificateTypesList: [],
      displayHeaderSelect: false,
      loading: false,
      applications: [],
      exportedApplications: [],
      total: 0,
      appOptions: {},
      showScopeTree: false,
      componentKey: 0,
      activeAcademicYear: this.$store.getters.getActiveAcademicYear,
      activeAcademicSemester: this.$store.getters.getActiveAcademicSemester,
      filterItems: {
        yearId: this.$store.getters.getActiveAcademicYear.id,
        yearName: this.$store.getters.getActiveAcademicYear.name,
        semesterId: this.$store.getters.getActiveAcademicSemester.id,
        semesterName: this.$store.getters.getActiveAcademicSemester.name || 0,
        certificatesCategoryFilter: '',
        certificateTypeFilter: ''
      },
    };
  },
  watch: {
    appOptions: {
      handler() {
        this.searchAppliactions();
      },
      deep: true,
    },
    direction: function () {
      this.selectedHeaders = this.value;
    }
    , selectedHeaders(newVal) {
      this.allowExport = newVal.length > 0;
    }
  },
  computed: {
    endDateRules() {
      return [
        v =>
            new Date(this.endApplicationDate) >= new Date(this.startApplicationDate) ||
            this.$t("DATE_FROM_TO_VALIDATION")
      ]
    },
    startDateRules() {
      return [
        v => !this.endApplicationDate || new Date(v) <= new Date(this.endApplicationDate) || this.$t("DATE_FROM_TO_VALIDATION")

      ];
    },
    direction: function () {
      return this.$store.state.direction;
    },
    selectedHeaders: {
      get() {
        const item = this.sortByKey(this.value, 'id');
        return this.value.length > 0 && this.value.length !== this.headers.length ? item : (this.value.length == 0 ? [] : this.headers);
      },
      set(value) {
        this.value.forEach(element => {
          if (element.id === this.headers[element.id].id) {
            element.text = this.headers[element.id].text;
          }
        });
      }
    },
    transformedFields() {
      const selectedHeaders = this.selectedHeaders
      const translatedFields = {};
      selectedHeaders.forEach(header => {
        const valueExists = Object.values(this.jsonFields).includes(header.value);
        if (valueExists) {
          const key = Object.keys(this.jsonFields).find(k => this.jsonFields[k] === header.value);
          translatedFields[key] = header.value;
        }
      });
      return this.serverTranslateDictKeyName(translatedFields);

    },
    title() {
      return this.$t('APPLICATIONS_LIST_TITLE', {
        year: this.filterItems.yearName,
        semester: this.serverTranslate(this.filterItems.semesterName)
      });
    },
    secondTitle() {
      const facultyName = this.$store.state.Auth.facultyName
          ? " / " + this.serverTranslate(this.$store.state.Auth.facultyName) : '';
      const branchName = this.$store.state.Auth.branchName
          ? " / " + this.serverTranslate(this.$store.state.Auth.branchName) : '';
      const scopeFullPath = this.serverTranslate(this.$store.state.scopeName) + facultyName + branchName;
      return scopeFullPath;
    },
    jsonFields() {
      const fields = {
        "اسم الطالب|Student Name": 'studentName',
        'رقم الطالب|Student No.': 'studentId',
        'رقم الطلب|Application No.': 'applyId',
        'رقم الهوية|Identity No.': 'identityNumber',
        'تاريخ الميلاد|Birthdate': 'birthdate',
        'النوع|Gender': 'gender',
        'رقم الهاتف|Telephone': 'telephone',
        'رقم المحمول|Mobile': 'mobile',
        'البريد الإلكتروني|E-mail': 'e-mail',
        'الدولة|Country': 'country',
        'المحافظة/المقاطعة|State': 'state',
        'المدينة|City': 'city',
        'العام الأكاديمي|Academic Year': 'academicYear',
        'الفصل الدراسي|Semester': 'semester',
        'الكلية|Faculty': 'faculty',
        'الفرع|Branch': 'branch',
        'حالة الطلب|Application Status': 'applicationStatus',
        'تاريخ الطلب|Application Date': 'appDate',
        'وقت الطلب|Application  Time': 'appTime',
        'حالة الدفع|Payment Status': 'paymentStatus',
        'نوع الشهادة|Certificate Type': 'certificateType',
        'بلدالشهادة|Certificate Country': 'certificateCountry',
        'نوع شهادة المعادلة|Certificate Equation Type': 'certificateTypeEquation',
        'التخصص|Major': 'major',
        'سنة الحصول على المؤهل|Prequal Year': 'prequalYear',
        'المجموع الكلي|Score': 'score',
        'المجموع المعتمد|Approval Total': 'submittedTotal',
      }
      const scopeLength = (this.secondTitle.match(/\//g) || []).length;
      if (scopeLength == 2) {
        delete fields['الكلية|Faculty'];
        delete fields['الفرع|Branch'];
      } else if (scopeLength == 1) {
        delete fields['الكلية|Faculty'];
      }
      return fields;
    },
    headers() {
      const fields = [
        {
          id: 0,
          text: this.$t("STUDENT_NAME"),
          align: "start",
          sortable: true,
          value: "studentName",
          width: "250px"
        },
        {
          id: 1,
          text: this.$t("STUDENT_NUM"),
          align: "start",
          sortable: true,
          value: "studentId"
        },
        {
          id: 2,
          text: this.$t("APPLICATION_NUM"),
          align: "start",
          sortable: true,
          value: "applyId"
        },
        {
          id: 3,
          text: this.$t("IDENTITY"),
          align: "start",
          sortable: false,
          value: "identityNumber"
        },
        {
          id: 4,
          text: this.$t("ADMISSION_DATA.STEP1.BIRTHDATE"),
          align: "start",
          sortable: false,
          value: "birthdate"
        },
        {
          id: 5,
          text: this.$t("ADMISSION_DATA.STEP1.TYPE"),
          align: "start",
          sortable: true,
          value: "gender",
          width: "20px"
        },
        {
          id: 6,
          text: this.$t("ADMISSION_DATA.STEP1.TELEPHONE"),
          align: "start",
          sortable: false,
          value: "telephone"
        },
        {
          id: 7,
          text: this.$t("ADMISSION_DATA.STEP1.PHONE"),
          align: "start",
          sortable: false,
          value: "mobile"
        },
        {
          id: 8,
          text: this.$t("ADMISSION_DATA.STEP1.EMAIL"),
          align: "start",
          sortable: false,
          value: "e-mail",
          width: "200px"
        },
        {
          id: 9,
          text: this.$t("ADMISSION_DATA.STEP1.COUNTRY"),
          align: "start",
          sortable: true,
          value: "country"
        },
        {
          id: 10,
          text: this.$t("ADMISSION_DATA.STEP1.STATE"),
          align: "start",
          sortable: true,
          value: "state"
        },
        {
          id: 11,
          text: this.$t("ADMISSION_DATA.STEP1.CITY"),
          align: "start",
          sortable: true,
          value: "city"
        },
        {
          id: 12,
          text: this.$t("ACADEMIC_YEAR"),
          align: "start",
          sortable: true,
          value: "academicYear"
        },
        {
          id: 13,
          text: this.$t("ACADEMIC_SEMESTER"),
          align: "start",
          sortable: true,
          value: "semester"
        },
        {
          id: 14,
          text: this.$t("FACULTY"),
          align: "start",
          sortable: true,
          value: "faculty"
        },
        {
          id: 15,
          text: this.$t("BRANCH"),
          align: "start",
          sortable: true,
          value: "branch"
        },
        {
          id: 16,
          text: this.$t("APPLICATION_CASES"),
          align: "start",
          sortable: true,
          value: "applicationStatus"
        },
        {
          id: 17,
          text: this.$t("ADMISSION_DATA.APPLICATION_DATE"),
          align: "start",
          sortable: true,
          value: "appDate",
          width: "200px"
        },
        {
          id: 18,
          text: this.$t("ADMISSION_DATA.APPLICATION_TIME"),
          align: "start",
          sortable: true,
          value: "appTime",
          width: "200px"
        },
        {
          id: 19,
          text: this.$t("PAYMENT_STATUS"),
          align: "start",
          sortable: true,
          value: "paymentStatus"
        },

        {
          id: 20,
          text: this.$t("CERTIFICATE_TYPE"),
          align: "start",
          sortable: true,
          value: "certificateType"
        },
        {
          id: 21,
          text: this.$t("ADMISSION_DATA.STEP2.MAJOR"),
          align: "start",
          sortable: true,
          value: "major"
        },
        {
          id: 22,
          text: this.$t("ADMISSION_DATA.STEP2.EQUIVALENT_CERT_TYPE"),
          align: "start",
          sortable: true,
          value: "certificateTypeEquation"
        },
        
        {
          id: 23,
          text: this.$t("CERTIFICATE_COUNTRY"),
          align: "start",
          sortable: true,
          value: "certificateCountry"
        },
     
        {
          id: 24,
          text: this.$t("ADMISSION_DATA.STEP2.YEAR"),
          align: "start",
          sortable: true,
          value: "prequalYear"
        },
        {
          id: 25,
          text: this.$t("TOTAL_DEGREE"),
          align: "start",
          sortable: true,
          value: "score"
        },
        {
          id: 26,
          text: this.$t("ADMISSION_DATA.STEP2.OTHER_SUM"),
          align: "start",
          sortable: true,
          value: "submittedTotal"
        },
      ];
      const scopeLength = (this.secondTitle.match(/\//g) || []).length;
      if (scopeLength == 2) {
        fields.splice(14, 2);
      } else if (scopeLength == 1) {
        fields.splice(14, 1);
      }
      return fields;
    },
  },
  methods: {
    showScopeTreeFn() {
      this.showScopeTree = true;
      this.componentKey += 1;
    },
    selectCertType() {
      return this.$http
          .post("/api/students_admission_apps/get_certificate_type_filter/", {
            categoryId: this.filterItems.certificatesCategoryFilter || 0
          })
          .then(response => {
                const data = response.data.data;
                this.certificateTypesList = data.certificatesTypes['basic_category_items']
              },
              err => {
                this.showToasted("error", err.response.data.message);
              })
          .then(() => (this.loading = false));
    },
    getFilters() {
      return this.$http
          .post("/api/students_admission_apps/list_application_filters/")
          .then(response => {
            const data = response.data.data;
            this.certificateCategoriesList = data.certificateCategories['basic_category_items'];
            this.semesters = data.Semesters;
            this.years = data.years;
          });
    },
    changeTitle() {
      this.filterItems.yearName = this.years.filter(obj => obj.id == this.filterItems.yearId)[0].name;
      this.filterItems.semesterName = this.semesters.filter(obj => obj.id == this.filterItems.semesterId)[0].name;
    },
    initializeList() {
      return new Promise((resolve, reject) => {
        const data = this.searchedData();
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$http
              .post("/api/students_admission_reports/list_admission_student/", data)
              .then(
                  res => {
                    this.applications = [];
                    const items = res.data.data.results;
                    const total = res.data.data.total;
                    resolve({
                      items,
                      total,
                    });
                  },
                  err => {
                    this.applications = [];
                    this.showToasted("error", this.serverTranslate(err.response.data.message));
                  }
              );
          this.loading = false;
        }, 1000);
      });
    },
    searchedData() {
      this.loading = true;
      const {sortBy, sortDesc, page, itemsPerPage} = this.appOptions
      const data = {
        page: page,
        pageSize: itemsPerPage,
        activeAcademicYear: this.filterItems.yearId,
        activeAcademicSemester: this.filterItems.semesterId,
        startApplicationDate: this.startApplicationDate,
        endApplicationDate: this.endApplicationDate,
        certificateTypeId: this.filterItems.certificateTypeFilter || "",
        certificateCategoryId: this.filterItems.certificatesCategoryFilter || "",
      }
      if (sortBy.length === 1 && sortDesc.length === 1) {
        data.orderType = sortDesc[0] ? "asc" : "desc";
        data.orderBy = sortBy[0]
      }
      return data
    },
    searchAppliactions(resetPageNum = false) {
      if (resetPageNum) {
        this.appOptions.page = 1;
        this.appOptions.sortBy = [];
        this.appOptions.sortDesc = [];
      }
      this.initializeList()
          .then(data => {
            this.applications = data.items;
            this.total = data.total;
            this.changeTitle();
            //  if (this.applications.length > 0) { this.allowExport = true;}

          });
    },
    reset() {
      this.filterItems.yearId = this.activeAcademicYear.id;
      this.filterItems.semesterId = this.activeAcademicSemester.id;
      this.filterItems.certificatesCategoryFilter = '';
      this.filterItems.certificateTypeFilter = '';
      this.searchAppliactions(true);
    },
    onScopeChanged(changed) {
      this.value = this.headers;
      if (changed) this.searchAppliactions(true);
    },
    async fetchExportedData() {
      const response = await this.$http.post("/api/students_admission_reports/list_admission_student/", {
        pageSize: -1,
        activeAcademicYear: this.activeAcademicYear.id,
        activeAcademicSemester: this.activeAcademicSemester.id,
        startApplicationDate: this.startApplicationDate,
        endApplicationDate: this.endApplicationDate,
        certificateTypeId: this.filterItems.certificateTypeFilter || "",
        certificateCategoryId: this.filterItems.certificatesCategoryFilter || "",
      })
      const exportedData = this.serverTranslateArrayKeys(response.data.data.results,
          ['studentName', 'faculty', 'branch', 'gender',
            'country', 'state', 'city', 'semester', 'applicationStatus',
            'paymentStatus', 'certificateTypeEquation', 'certificateType', 'certificateCountry', 'major'])

      if (response.status !== 200) return this.showToasted("error", this.$t("NOT_FOUND_SERVER_ERROR"))
      return exportedData;

    },
  }
};
</script>