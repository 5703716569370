<template>
    <div ref="top">
                <v-alert
                      v-if="map && map.data"
                      text
                      outlined
                      type="info"
                    >
                      {{$t("PULLING_STEPPER.EXSIT")}}
                      <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                          <v-btn small   @click="reuseMap" color="primary">
                               <v-icon
                                  dark
                                  v-bind="attrs"
                                  v-on="on"
                                >mdi-backup-restore</v-icon>
                          </v-btn>

                      </template>
                      <span>{{$t("PULLING_STEPPER.REUSE")}}</span>
                   </v-tooltip>

                  </v-alert>
         <v-alert dense outlined type="error"
           v-show="msg" ref="msg">
               {{msg}}
         </v-alert>
        <v-card
            class="mx-auto overflow-y-auto"
            max-height="500"
            >
            <div class="container">
                    <v-row v-for="item in mappedHeader"
                    class="d-flex justify-start" :key="item.key">
                        <v-col cols="12" lg="3" md="4">
                                {{item.name}}
                        </v-col>
                        <v-col cols="12" lg="7" md="6">
                            <v-autocomplete
                                    v-model="item.key"
                                    :placeholder="$t('PULLING_STEPPER.SELECT')"
                                    :items="serverTranslateArray(allHeader,'name')"
                                    item-value="key"
                                    item-text="name"
                                        @change="changeKey()"
                                        dense
                                        outlined
                                        clearable
                                    >
                                    </v-autocomplete>
                            </v-col>
                        </v-row>
                </div>

  </v-card>
         <v-row>
              <v-col cols="12" class="justify-end d-flex">
                  <v-btn color="error"
                         @click="cancelPulling()"
                         class="ma-1">{{$t("CANCEL")}}</v-btn>
                  <v-btn class="ma-1"
                      color="secondary"
                      @click="previous()"
                    >
                      {{$t("PULLING_STEPPER.PREVIOUS")}}
                    </v-btn>
                  <v-btn class="ma-1"
                      color="primary" @click="save"
                    >
                      {{$t("SAVE")}}
                    </v-btn>


              </v-col>
          </v-row>

    </div>
</template>
<script>
    import { EventBus } from "@/plugins/bus";
    import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
    import globalMethodsMixin from "@/mixins/globalMethods";
    export default {
        name:"pr-step3",
        props:["filterData","header"],
        mixins: [serverTranslationsMixin,globalMethodsMixin],
         data(){
             return {
                 allHeader:[],
                 coursesIds:[],
                 mappedHeader:[],
                 preMappedItem:[],
                 msg:"",
                 map:{yearId:"",certificateTypeId:"", certificateId:"",data:null},
             }
         },
        methods:{
            reuseMap(){
                if(this.map && this.map.data){
                    this.map.data.forEach(item=>{
                       const mapItem = this.mappedHeader.find(mapItem=>mapItem.name == item.name );
                       mapItem.key = item.key;
                    });
                    this.changeKey();
                }
            },
            cancelPulling(){
              EventBus.$emit("CancelMap");
            },
             changeKey(){
               this.allHeader.filter(item=>{
                 if(this.mappedHeader.find(h=>h.key == item.key))
                     item.disabled = true;
                 else
                     item.disabled = false;
               });
               /******************Handel LocalStorage**********************/
                if(!this.map)
                    this.map = {yearId:this.filterData.yearId,
                                certificateTypeId:this.filterData.certificateTypeId,
                                certificateId:this.filterData.certificateId,
                                data:this.mappedHeader.filter(item=>item.key)
                                                    .map(item=>{return  {index:item.index,key:item.key,name:item.name}})};
                else
                    this.map.data = this.mappedHeader.filter(item=>item.key)
                                                .map(item=>{return  {index:item.index,key:item.key,name:item.name}});
                let mapping = localStorage.getItem("mapping");
                if(mapping){
                    mapping = JSON.parse(mapping);
                    mapping = mapping.filter(item=>  item.yearId != this.filterData.yearId ||
                                                     item.certificateTypeId != this.filterData.certificateTypeId ||
                                                     item.certificateId != this.filterData.certificateId);
                }else
                    mapping = [];
                mapping.push(this.map);

                localStorage.setItem("mapping",JSON.stringify(mapping));
            /******************Handel LocalStorage**********************/
             },
             previous(){
                EventBus.$emit("changeStepperIndex",2);
            },
            save(){
                 let isValid = true;
                 this.msg = "";
                 let data = [];
                 this.allHeader.forEach(item=> {
                     let correspondingItem = null;
                     correspondingItem  = this.mappedHeader.find(d=>d.key == item.key);
                     if(item.rules.required && !correspondingItem){
                         isValid = false
                         this.msg += this.serverTranslate(item.name) + ","
                     }
                     if (!correspondingItem) return false;
                 });
                 if (isValid){
                     data = this.mappedHeader.filter(item=>item.key);
                     data = data.map(item=>{
                         let correspondingItem = null;
                         correspondingItem  = this.allHeader.find(d=>d.key == item.key);
                         return  {index:item.index,rules:correspondingItem.rules,
                             name:correspondingItem.name,key:correspondingItem.key};
                     });
                     this.msg = "";
                 }
                 else {
                     data = [];
                     if (this.msg){
                         this.msg = this.$t("PULLING_STEPPER.CONSTRAIN") + "[" +this.msg.slice(0, -1) + "]";
                         this.$vuetify.goTo(0);
                     }
                 }
                  this.$emit("SavedMappedData",{mappedHeader:data,courseIds:this.courseIds});
            }
        },
        watch:{
            header(){
                this.mappedHeader = [];
                this.allHeader.map(item=>{
                    item.disabled = false;
                    return item;
                });
                 let mapping = localStorage.getItem("mapping");
                if(mapping) {

                    mapping = JSON.parse(mapping)
                    this.map = mapping.find(item => item.yearId == this.filterData.yearId &&
                                                    item.certificateTypeId == this.filterData.certificateTypeId &&
                                                    item.certificateId == this.filterData.certificateId &&
                                                    (item.data.filter(savedItem=>{return this.header.indexOf(savedItem.name) != -1})).length > 0 ) ;
                }
                this.mappedHeader = this.header.map((item,index) =>{return  {name:item,index:index,key:null,disabled:true};});
            },
            filterData(){
                /*********************RESET DATA WHEN CHANGE FILTER********************************/
                 this.allHeader = [];
                 this.courseIds = [];
                 this.mappedHeader = [];
                 this.msg = "";

                if(!this.filterData.certificateId)
                    return false;
                 this.$http.post("/api/upload_students_data/get_uploaded_headers/",
                    {certificateId:this.filterData.certificateId})
                 .then(res => {
                        res = res.data;
                        this.allHeader = [] ;
                        this.allHeader.push(...res.data.allHeaders);
                        this.courseIds = res.data.dynamicHeaders.length > 0 ? res.data.dynamicHeaders.map(item=>item.key) : [];
                     },
                    err => {
                        this.$toasted.show(
                            this.serverTranslate(err.response.data.message),
                            {
                                type: "error",
                                icon: "error_outline",
                                position: "bottom-left",
                                duration: 5000,
                                theme: "bubble"
                            }
                        );
                    });
            }
        }
    }
</script>