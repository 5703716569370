import Vue from "vue";
import Vuetify from "vuetify/lib";
import colors from "vuetify/lib/util/colors";
import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are
import "@mdi/font/css/materialdesignicons.css";
import ar from "vuetify/es5/locale/ar";
import en from "vuetify/es5/locale/en";

Vue.use(Vuetify);

export default new Vuetify({
  rtl: true,
  icons: {
    iconfont: "mdi" || "mdiSvg" || "md" || "fa" || "fa4" || "faSvg"
  },
  lang: {
    locales: { en, ar },
    current: "en"
  },
  theme: {
    themes: {
      light: {
         primary: "#00AFB9",
         secondary: "#272C38",
         darken3: "#5E6A74",
         darken4: "#323232",
         light2: "#03939b",
         light3: "#43c9d0",
         light4: "#F2FDFE",
         light5: "#F0F2F8",
         light6: "#F5F5F5",
         delete: "#ff7171",
         error:"#ce343c"
      }
    }
  }
});
