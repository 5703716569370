<template>
    <v-dialog v-model="dialog" max-width="90vh">
        <v-card>
        <v-form ref="courseForm">
            <v-card-title class="justify-center">
            <span class="headline white--text text-thin">{{
                $t(title)
            }}</span>
            </v-card-title>
            <v-card-text>
            <v-container>
                <v-alert v-show="showAlert" dense outlined type="error">{{
                serverTranslate(errorMessages)
                }}</v-alert>
                <v-row>
                <v-col cols="12" class="pa-1">
                    <v-text-field
                    :label="$t('AR_NAME')"
                    v-model="courseData.arName"
                    @keydown.space="preventLeadingSpace"
                    autofocus
                    required
                    minlength="5"
                    maxlength="50"
                    :rules="$store.state.Rules.arNameRules"
                    prepend-inner-icon="mdi-abjad-arabic"
                    ></v-text-field>
                </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" class="pa-1">
                        <v-text-field
                        v-model="courseData.enName"
                        @keydown.space="preventLeadingSpace"
                        :label="$t('EN_NAME')"
                        required
                        maxlength="50"
                        minlength="5"
                        :rules="$store.state.Rules.enNameRules"
                        prepend-inner-icon="mdi-alpha-e-circle-outline"
                        ></v-text-field>
                    </v-col>
                    <v-col cols="12">
                        {{ $t("ACTIVATION") }}
                        <v-icon
                        large
                        v-if="courseData.active === 0"
                        @click="courseData.active = 1"
                        >toggle_off</v-icon
                        >
                        <v-icon
                        color="green"
                        large
                        v-else
                        @click="courseData.active = 0"
                        >toggle_on</v-icon
                        >
                    </v-col>
                </v-row>
            </v-container>
            </v-card-text>
            <v-card-actions class="justify-end">
            <v-btn color="primary" dark @click="save">{{
                $t("SAVE")
            }}</v-btn>
            <v-btn color="darken3" class="white--text" @click="close">{{
                $t("CANCEL")
            }}</v-btn>
            </v-card-actions>
        </v-form>
        </v-card>
    </v-dialog>
</template>
<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import globalMethodsMixin from "@/mixins/globalMethods";

export default {
    name:"pr-add-edit-course",
    mixins: [serverTranslationsMixin, globalMethodsMixin],
    data: () => ({
        dialog: false,
        showAlert:false,
        errorMessages: "",
        resolve: null,
        reject: null,
        title: null,
        type: "",
        courseData: {
            arName: "",
            enName: "",
            active: 1
        }
    }),
    props:["searchData"],
    watch:{
        dialog(val) {
        val || this.close();
        }
    },
    methods:{
        open(title, type, courseData) {
            this.dialog = true;
            this.title = title;
            this.type=type;
            if (courseData) {
                this.courseData = Object.assign(this.courseData, courseData);
            }
            return new Promise((resolve, reject) => {
                this.resolve = resolve;
                this.reject = reject;
            });
        },
        save() {
            if (this.$refs.courseForm.validate()) {
                if (this.type === "add") {
                    this.addCourseService();
                } else {
                    this.updateCourseService();
                }
                this.resolve(true);
           }
        },
        close() {
            this.resolve(false);
            this.dialog = false;
            this.showAlert = false;
            this.errorMessages = "";
            this.courseData=[];
            this.$refs.courseForm.resetValidation();
            this.courseData =  {
                arName: "",
                enName: "",
                active: 1
            }
        },
        updateCourseService() {
            const editedItem = Object.assign({}, this.courseData);
            this.$http.put("/api/courses/update_course/",editedItem).then(
                res => {
                    this.showToasted("success",res.data.message);
                    this.close();
                    this.$emit('updatedCourse',res.data.data);
                },
                err => {
                    if (err.response.status === 404) {
                        this.showToasted("error",err.response.data.message);
                        if (this.searchData) {
                            this.$router.push({name:"ListCourses", params: {searchData:this.searchData}})
                        }
                    }
                    else {
                        this.errorMessages = err.response.data.message;
                        this.showAlert = true;
                    }
                }
            );
        },
        addCourseService() {
            const addedItem = Object.assign({}, this.courseData);
              this.$http.post("/api/courses/create_course/", addedItem).then(
                response => {
                    this.showToasted("success",response.data.message);
                    this.close();
                    this.$emit('updatedCourse',true);
                },
                err => {
                  this.errorMessages = err.response.data.message;
                  this.showAlert = true;

                }
              );
        },

    }
}
</script>
