import Vue from "vue";
const that = Vue.prototype;
const monthThirtyOneDay = ["01", "03", "05", "07", "08", "10", "12"];
const monthThirtyDay = ["04", "06", "09", "11"];
const governmentCode = [
  "01",
  "02",
  "03",
  "04",
  "11",
  "12",
  "13",
  "14",
  "15",
  "16",
  "17",
  "18",
  "19",
  "21",
  "22",
  "23",
  "24",
  "25",
  "26",
  "27",
  "28",
  "29",
  "31",
  "32",
  "33",
  "34",
  "35",
  "88"
]; // 88 مولود خاج مصر
function isNotEmpty(str) {
  return !(!str || 0 === str.length || /^\s*$/.test(str));
}

const requiredField = v => isNotEmpty(v) || that.$t("REQUIRED_FIELD");

export default {
  state: {
    passwordRulse: [
      requiredField,
      v =>
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/.test(v) ||
          that.$t("PASSWORD_VALIDATION"),
      v => (v != undefined && v.length > 5) || that.$t("MIN_FIELD") + "6"
    ],
    searchRules: [
      v =>
        /^(?:[a-zA-Z0-9\s)\-(\u0621-\u064A]){0,}$/.test(
          v != undefined && v.trim()
        ) || that.$t("PREVENT_SPECIAL_CHARACTER_FIELD")
    ],
    requiredRules: [
      requiredField
    ],
    required: requiredField,
     arFieldRule :[ v =>
      (v && /^[\u0621-\u064A0-9-\s(-?)\s]+[^*=\s]$/.test(v.trim())) || that.$t("ARABIC_FIELD")],
     enFieldRule :[ v =>
      (v && /^[a-zA-Z0-9(-?)\s]+[^*\s]$/.test(v.trim())) || that.$t("ENGLISH_FIELD")],
    arNameRules: [
      requiredField,
      v =>
        !(v != undefined && v.slice(0, 1) >= "0" && v.slice(0, 1) <= "9") ||
        that.$t("PREVENT_NUMBERS_IN_FIRST"),
      v =>
        /^[\u0621-\u064A0-9-\s(-?)\s]+[^*=\s]$/.test(
          v != undefined && v.trim()
        ) || that.$t("ARABIC_FIELD"),
      v => (v != undefined && v.trim().length > 4) || that.$t("MIN_FIELD") + "5"
    ],
    enNameRules: [
      requiredField,
      v =>
        !(v != undefined && v.slice(0, 1) >= "0" && v.slice(0, 1) <= "9") ||
        that.$t("PREVENT_NUMBERS_IN_FIRST"),
      v =>
        /^[a-zA-Z0-9(-?)\s]+[^*\s]$/.test(v != undefined && v.trim()) ||
        that.$t("ENGLISH_FIELD"),
      v => (v != undefined && v.trim().length > 4) || that.$t("MIN_FIELD") + "5"
    ],
    numberField: [
      v => isNotEmpty(v) ? /^(?:[1-9]\d*|0)?(?:\.\d+)?$/.test(v) || that.$t("NUMBER_FIELD") : true,
    ],
    totalRules: [
      requiredField,
      v => isNotEmpty(v) ? /^(?:[1-9]\d*|0)?(?:\.\d+)?$/.test(v) || that.$t("NUMBER_FIELD") : true,
      v => isNotEmpty(v) ? parseFloat(v) <= 999999.99 || that.$t("TOTAL_DEGREE_MAX") : true,
      v => isNotEmpty(v) ? /^(?:[1-9]\d*|0)?(?:\.\d{1,2})?$/.test(v) || that.$t("FLOAT_NUMBER_VALIDATION") : true
    ],
    courseDegreeRules: [
      requiredField,
      v => isNotEmpty(v) ? /^(?:[1-9]\d*|0)?(?:\.\d+)?$/.test(v) || that.$t("NUMBER_FIELD") : true,
      v => isNotEmpty(v) ? parseFloat(v) <= 999999.99 || that.$t("COURSE_DEGREE_MAX") : true,
      v => isNotEmpty(v) ? /^(?:[1-9]\d*|0)?(?:\.\d{1,2})?$/.test(v) || that.$t("FLOAT_NUMBER_VALIDATION") : true
    ],
    studentsNumbersRules: [
      requiredField,
      v => isNotEmpty(v) ? /^(?:[1-9]\d*|0)?(?:\d+)?$/.test(v) || that.$t("INT_NUMBER_FIELD") : true,
      v => isNotEmpty(v) ? (parseFloat(v) <= 100000 && parseFloat(v) > 0) || that.$t("STUDENTS_NUMBERS_MAX") : true
    ],
    noSpecialLetter:[
        v =>{
          if(v && ! /^[a-zA-Z0-9]*$/.test(v))
            return that.$t('noSpecialLetter');
          return true;
        }
    ],
    photoRules: [
      v => {
        if (!!v && v instanceof File) {
          const extensions = /(\.apng|\.bmp|\.gif|\.jpg|\.jpeg|\.jfif|\.pjpeg|\.pjp|\.png|\.webp)$/i;
          return !(v.size / 1000000 <= 5)
            ? that.$t("IMAGE_SIZE_VALIDATION")
            : !extensions.test(v.name)
            ? that.$t("IMAGE_EXTENTION_VALIDATION")
            : true;
        }
        return true;
      }
    ],
    ipRules: [
      requiredField,
      v => 
        /^(http:\/\/|https:\/\/)(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)(:[0-9]{4,5})?$/.test(v && v.trim()) 
        || that.$t("IP_VALIDATION")
    ],
    urlRules: [
      requiredField,
      v => /^(http|https):\/\/(([a-z0-9]|[a-z0-9][a-z0-9-]*[a-z0-9])\.)*([a-z0-9]|[a-z0-9][a-z0-9-]*[a-z0-9])(:[0-9]+)?$/igm.test(v)
      || that.$t("URL_VALIDATION")
    ],
    NIDRulse: [
      requiredField,
      v =>
          isNotEmpty(v)
              ? (v !== undefined &&
                  v.slice(3, 5) > "00" &&
                  v.slice(3, 5) <= "12" &&
                  v.slice(5, 7) > "00" &&
                  v.slice(5, 7) <= "31" &&
                  governmentCode.includes(v.slice(7, 9)) &&
                  v.slice(12, 13) >= "0" &&
                  /^(2|3)[0-9]{13}/.test(v)) ||
              that.$t("NID_VALIDATION")
              : true,
      v =>
          isNotEmpty(v)
              ? v !== undefined &&
              ((v.slice(3, 5) == "02" && v.slice(5, 7) <= "29") ||
                  (monthThirtyOneDay.includes(v.slice(3, 5)) &&
                      v.slice(5, 7) <= "31") ||
                  (monthThirtyDay.includes(v.slice(3, 5)) &&
                      v.slice(5, 7) <= "30") ||
                  that.$t("NID_VALIDATION"))
              : true,
      function (v) {
        if (v != "" && v) {
          let day = '';
          let month = '';
          let year = "";
          day = v.slice(5, 7);
          month = v.slice(3, 5);
          if (v.slice(0, 1) === "2") {
            year = "19" + v.slice(1, 3);
          } else {
            year = "20" + v.slice(1, 3);
          }
          const dob = year + "-" + month + "-" + day;
          return new Date(dob).toISOString() <= new Date().toISOString() || that.$t("FUTURE_DATE")
        } else {
          return ""
        }
      }
    ],
    emailRules: [
      requiredField,
      v =>
          /^[A-Za-z0-9._%-]{1,64}@[A-Za-z0-9._%-]{1,251}\.[a-z]{2,3}$/.test(v) ||
          that.$t("EMAIL_VALIDATION")
    ]
  }
};
