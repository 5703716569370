<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            {{ $t("IMAGE_ATTACHED") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>

          <v-tooltip right>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="downloadImage">
                    <v-icon small color="white">mdi-download</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("DOWNLOAD") }}</span>
            </v-tooltip>

          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>

        <v-card-text class="py-5">
          <LightGallery
              :images="[src]"
              :index="index"
              :disable-scroll="true"
              @close="index = null"
          />

          <div
              style="cursor: zoom-in;"
              v-for="(thumb, thumbIndex) in [src]"
              :key="thumbIndex"
              @click="index = thumbIndex"
          >
            <v-img :src="thumb" aspect-ratio="1.4" contain></v-img>
          </div>
        </v-card-text>

        <!--<v-divider></v-divider>-->


        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="dialog = false">
            {{ $t("CLOSE") }}
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import {LightGallery} from "vue-light-gallery";

export default {
  name: "ad-show-files",
  props: ["src", "on"],
  components: {
    LightGallery
  },
  data() {
    return {
      dialog: false,
      index: null
    };
  },
  created() {
    this.dialog = true;
  },
  watch: {
    dialog() {
      this.on = this.dialog;
    },
    on(newVal) {
      this.dialog = newVal;
      if (!this.dialog) this.$emit("close");
    }
  },
  methods: {
    downloadImage() {
      const base64Image = this.src
      // Create a temporary link element
      const link = document.createElement('a');
      link.href = base64Image;
      link.download = 'image.png';

      // Append the link to the document body
      document.body.appendChild(link);

      // Trigger the download
      link.click();

      // Remove the link from the document body
      document.body.removeChild(link);
    }
  }
};
</script>
