<template>
  <div>
    <v-card class="mx-auto mb-3">
      <div id="filters">
        <template>
          <v-toolbar flat>
            <v-toolbar-title>
              <p color="white" class="mb-0">{{ $t("PULL_CERTIFICATIONS") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-form ref="filterForm">
            <v-container>
              <v-row>
                <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <v-select
                    :items="serverTranslateArray(certificateTypesArEn, 'name')"
                    v-model="certificateTypeSelected"
                    item-value="id"
                    item-text="name"
                    :label="$t('CERTIFICATE_TYPE')"
                    required
                    :rules="$store.state.Rules.requiredRules"
                    outlined
                    dense
                    clearable
                    @change="showPullBtn=false"
                  ></v-select>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <v-select
                    ref="fromAcademicYear"
                    :items="fromAcademicYear"
                    v-model="fromAcademicYearSelected"
                    item-value="id"
                    item-text="name"
                    :label="$t('FROM') + $t('ACADEMIC_YEAR')"
                    required
                    :rules="fromAcademicYearRules"
                    dense
                    outlined
                    @change="resetAcademicValidation"
                    clearable
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <v-select
                    ref="toAcademicYear"
                    :items="toAcademicYear"
                    v-model="toAcademicYearSelected"
                    item-value="id"
                    item-text="name"
                    :label="$t('TO') + $t('ACADEMIC_YEAR')"
                    required
                    :rules="toAcademicYearRules"
                    outlined
                    @change="resetAcademicValidation"
                    dense
                    clearable
                  ></v-select>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" dark @click="showCertifications">
                  {{
                  $t("SHOW")
                  }}
                </v-btn>
                <v-btn
                    v-if="showPullBtn"
                    color="secondary preventZIndex"
                    @click="openPullDialog"
                    >
                    {{
                    $t("PULL")
                    }}
                </v-btn>
                <v-btn color="darken3" class="white--text" @click="back">{{ $t("BACK") }}</v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </template>
      </div>
    </v-card>
    <v-card class="mx-auto" v-if="fromYearName" outlined>
        <div id="pullData">
            <v-data-table
            :headers="headers"
            :items="certificatesArEn"
            hide-default-footer
            disable-pagination
            sort-by="id"
            class="elevation-1"
            >
            <template v-slot:top>
                <v-toolbar flat>
                <v-toolbar-title
                  :class="$vuetify.breakpoint.lg || $vuetify.breakpoint.md?'text-small primary--text' :
                  'caption primary--text mx-n2'"
                >
                    {{$t("ACADEMIC_YEAR_CLOSED_CERTIFICATES")}}
                    <span dir="ltr">{{ datatableTitle }}</span>
                </v-toolbar-title>
                <v-divider class="mx-2" inset vertical></v-divider>
                </v-toolbar>
            </template>
            <template v-slot:no-data>
                <v-alert icon="warning" class="mt-2" color="light5">
                  {{
                  $t("NO_DATA")
                  }}
                </v-alert>
            </template>
            </v-data-table>
            <v-dialog v-model="dialog" max-width="90vh" persistent>
                <v-card>
                    <v-card-title class="justify-center">
                        <span class="headline white--text text-thin">
                        {{
                        $t("PULL_CERTIFICATIONS")
                        }}
                        </span>
                    </v-card-title>
                    <v-form ref="pullForm">
                        <v-container class="mb-0 pb-0">
                          <v-alert
                            class="mb-1"
                            v-show="showAlert"
                            dense
                            outlined
                            type="error"
                          >
                            {{
                              serverTranslate(errorMessages)
                            }}
                          </v-alert>
                        </v-container>
                        <v-card-text class="pt-1 pb-0">
                            <span v-if="certificateTypeSelected"
                                class="font-weight-bold primary--text"
                            >
                                {{
                                    $t("PULL_CERTIFICATIONS_MESSAGE")
                                }}
                                {{
                                  $t("CERTIFICATE_TYPE")
                                }}
                                :
                                {{ serverTranslate(this.certificateTypesArEn.filter(
                                  obj => obj.id == this.certificateTypeSelected)[0].name)
                                }}
                                {{ $t('FOR') }}
                                {{
                                  $t('ACADEMIC_YEAR')
                                }}
                                :
                                {{
                                    reverseString(toYearName, "-")
                                }}
                            </span>
                            <v-radio-group class="font-weight-bold" v-model="pullStatus" height="10" row>
                                <v-radio @click="deleteCertificates(0)" :label="$t('NO')" :value="0"></v-radio>
                                <v-radio @click="deleteCertificates(1)" :label="$t('YES')" :value="1" :disabled="disabled"></v-radio>
                            </v-radio-group>
                        </v-card-text>
                        <v-card-actions class="justify-end pt-0">
                            <v-btn color="primary" sdark @click="pullCertifications">{{ $t("SAVE") }}</v-btn>
                            <v-btn color="darken3" class="white--text" @click="close">{{ $t("CANCEL") }}</v-btn>
                        </v-card-actions>
                    </v-form>
                </v-card>
            </v-dialog>
        </div>
      <pr-confirmDialog ref="confirm" :confDialog="confDialog" :persistentDialog="true"></pr-confirmDialog>
    </v-card>
  </div>
</template>
<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
export default {
  name: "pr-pull-certification",
  components: {
    "pr-confirmDialog": ConfirmDialog
  },
  mixins: [serverTranslationsMixin,globalMethodsMixin],
  props:["searchData"],
  data() {
    return {
      dialog: false,
      fromAcademicYear: [],
      toAcademicYear: [],
      fromAcademicYearSelected: null,
      toAcademicYearSelected: null,
      certificateTypesArEn: [],
      certificateTypeSelected: null,
      certificatesArEn: [],
      fromYearName: "",
      toYearName: "",
      pullStatus: 0,
      toAcademicYearRules: [
        v =>
          this.toAcademicYearSelected !== this.fromAcademicYearSelected ||
          this.$t("ACADEMIC_YEAR_FROM_TO_VALIDATION")
      ],
      confDialog: false,
      errorMessages: "",
      showAlert: false,
      showPullBtn: false,
      disabled: false
    };
  },
  created() {
    this.initializeFilter();
  },
  computed: {
    datatableTitle: function() {
      return this.fromYearName ? this.fromYearName : "";
    },
    headers: function() {
      return [
        {
          text: this.$t("AR_NAME"),
          align: "start",
          sortable: true,
          value: "arName"
        },
        { text: this.$t("EN_NAME"), value: "enName", sortable: true },
        {
          text: this.$t("TOTAL"),
          value: "totalDegree",
          sortable: true
        }
      ];
    },
    fromAcademicYearRules: function() {
      return [
        this.$store.state.Rules.required,
        v =>
          this.toAcademicYearSelected !== this.fromAcademicYearSelected ||
          this.$t("ACADEMIC_YEAR_FROM_TO_VALIDATION")
      ];
    },
    direction: function() {
      return this.$store.state.direction;
    },
  },
  watch: {
    direction: function() {
      if (this.$el.querySelector(".error--text")) {
        this.$refs.filterForm.validate();
      }
    },
    dialog(val) {
      val || this.close();
    }
  },
  methods: {
    back() {
      this.$router.replace({name:"ListCertifications", params:{searchData:this.searchData}})
    },
    initializeFilter() {
      this.$http
        .post("/api/certificates/certificate_pulling_filters/", {
          type: "pull"
        })
        .then(
          res => {
            this.certificateTypesArEn = res.data.data.certificateCategories;
            this.fromAcademicYear = res.data.data.fromYears;
            this.toAcademicYear = res.data.data.toYears;
          },
          err => {
            this.$toasted.show(
              this.serverTranslate(err.response.data.message),
              {
                type: "error",
                icon: "error_outline",
                position: "bottom-left",
                duration: 5000,
                theme: "bubble"
              }
            );
          }
        );
    },
    showCertifications() {
      this.toAcademicYearRules = [];
      if (this.$refs.filterForm.validate()) {
        const data = {
          certificateTypeId: this.certificateTypeSelected,
          certificateYearId: this.fromAcademicYearSelected
        };
        this.fromYearName = this.fromAcademicYear.filter(
          obj => obj.id == this.fromAcademicYearSelected
        )[0].name;
        this.$http
          .post(
            "/api/certificates/certificate_pulling_view_certificates/",
            data
          )
          .then(
            res => {
              this.certificatesArEn = res.data.data.certificateData;
              this.showPullBtn = this.certificatesArEn.length === 0 ? false : true;
              this.disabled = false;
            },
            err => {
              this.$toasted.show(
                this.serverTranslate(err.response.data.message),
                {
                  type: "error",
                  icon: "error_outline",
                  position: "bottom-left",
                  duration: 5000,
                  theme: "bubble"
                }
              );
            }
          );
      }
    },
    resetAcademicValidation: function() {
      if (
        this.fromAcademicYearSelected &&
        this.toAcademicYearSelected &&
        this.fromAcademicYearSelected !== this.toAcademicYearSelected
      ) {
        this.$refs.toAcademicYear.resetValidation();
        this.$refs.fromAcademicYear.resetValidation();
      }
      this.showPullBtn = false;
    },
    deleteCertificates(value) {
      this.pullStatus = value;
      this.showAlert = false;
    },
    openPullDialog() {
      this.toAcademicYearRules = this.fromAcademicYearRules
      if ( this.toAcademicYearSelected && this.$refs.filterForm.validate() ) {
        this.dialog = true;
        this.pullStatus = 0;
        this.errorMessages = "";
        this.showAlert = false;
        this.toYearName = this.toAcademicYear.filter(
            obj => obj.id == this.toAcademicYearSelected
        )[0].name;
      }
    },
    pullCertifications(){
        this.dialog = false;
        const fromYearName = localStorage.lang === "ar" ? this.reverseString(this.fromYearName,"-") : this.fromYearName;
        const toYearName = localStorage.lang === "ar" ? this.reverseString(this.toYearName, "-") : this.toYearName;
        const certificateTypeName = this.serverTranslate(this.certificateTypesArEn.filter(
                        obj => obj.id == this.certificateTypeSelected)[0].name)
        let confirmMessage = "";
        this.pullStatus === 1
        ? (confirmMessage =
            this.$t("PULL_CERTIFICATIONS_INFO_CONFIRMATION") + " " + this.$t("CERTIFICATE_TYPE")
             + ": " + certificateTypeName + " " + this.$t("FOR") + " " + this.$t("ACADEMIC_YEAR")
             + " " + fromYearName.toString()
             + " " +
            this.$t("PULL_CERTIFICATIONS_DELETE_CONFIRMATION") +
            toYearName + " " + this.$t("PULL_EVEN_IF"))
        : (confirmMessage =
            this.$t("PULL_CERTIFICATIONS_INFO_CONFIRMATION") + " " + this.$t("CERTIFICATE_TYPE")
            + ": " + certificateTypeName + " " + this.$t("FOR") + " " + this.$t("ACADEMIC_YEAR")
            + "  " + fromYearName.toString()
            + " " +
            this.$t("TO") + " " +
            this.$t("ACADEMIC_YEAR")
            + ": " +
            toYearName);
        this.$refs.confirm
        .open("CONFIRMATION_MESSAGE", confirmMessage, { width: "520px" })
        .then(confirm => {
          this.confirmation(confirm);
        });
    },
    confirmation(confirm) {
      if (confirm) {
          const data = {
              certificatesCategoryId: this.certificateTypeSelected,
              fromYearId: this.fromAcademicYearSelected,
              toYearId: this.toAcademicYearSelected,
              deleteFlag: this.pullStatus
          }
          this.$http
          .post(
              "/api/certificates/pull_certificates/",
              data
          )
          .then(
              res => {
                  this.$toasted.show(this.serverTranslate(res.data.message), {
                      type: "success",
                      icon: "check_circle_outline",
                      theme: "bubble",
                      position: "bottom-left",
                      duration: 2000
                  });
                  this.$router.push("./");
              },
              err => {
                  this.dialog = true;
                  this.errorMessages = err.response.data.message;
                  this.showAlert = true;
                  if (err.response.status === 400){
                    this.disabled = err.response.data.data.studentCount > 0 ? true : false;
                  }
              }
          );
      } else {
          this.dialog = true;
      }
    },
    close() {
        this.dialog = false;
        this.errorMessages = "";
        this.showAlert = false;
    }
  }
};
</script>
