<template>
    <div class="mb-8">
        <v-card class="mx-auto mb-3">
            <div id="filters">
                <template>
                <v-toolbar flat>
                    <v-toolbar-title>
                    <p class="mb-0">{{ $t("REPORT_APP_STATUS") }}</p>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-divider></v-divider>
                <v-form ref="form">
                    <v-container>
                    <v-row>
                        <v-col cols="12" class="ma-0" sm="12" md="12" lg="4">
                            <v-select
                                :items="serverTranslateArray(this.status, 'name')"
                                v-model="filterItems.appStatus"
                                outlined
                                small-chips
                                :label="$t('APPLICATION_CASES')"
                                item-value="mongo_id"
                                item-text="name"
                                @change="allowExport=false"
                            >
                            </v-select>
                        </v-col>
                        <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                        <v-select
                            :items="serverTranslateArray(this.years, 'name')"
                            v-model="filterItems.yearId"
                            item-value="id"
                            item-text="name"
                            :label="$t('ACADEMIC_YEAR')"
                            outlined
                            @change="allowExport=false"
                        ></v-select>
                        </v-col>
                        <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                        <v-select
                            :items="serverTranslateArray(this.semesters, 'name')"
                            v-model="filterItems.semesterId"
                            item-value="id"
                            item-text="name"
                            :label="$t('ACADEMIC_SEMESTER')"
                            outlined
                            @change="allowExport=false"
                        ></v-select>
                        </v-col>
                    </v-row>
                    <v-card-actions class="pa-0">
                        <v-container class="px-0">
                            <v-row>
                            <v-col cols="auto" class="pa-1">
                                <v-btn
                                color="primary"
                                class="mx-2"
                                :small="!$vuetify.breakpoint.mdAndUp"
                                :fab="!$vuetify.breakpoint.mdAndUp"
                                @click="showScopeTreeFn()">
                                    <template v-if="$vuetify.breakpoint.mdAndUp"> {{$t('SCOPE')}} </template>
                                    <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-navigation</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col cols="auto" class="pa-1">
                                <download-excel v-if="allowExport"
                                    :fields="serverTranslateDictKeyName(jsonFields)"
                                    worksheet="Students Worksheet"
                                    name="StudentsApplicationsData.xls"
                                    :title="[$t('REPORT')+ ' ' +title,secondTitle]"
                                    :fetch="fetchExportedData"
                                >
                                <v-btn color="secondary"
                                    :small="!$vuetify.breakpoint.mdAndUp"
                                    :fab="!$vuetify.breakpoint.mdAndUp"
                                >
                                    <span v-if="$vuetify.breakpoint.mdAndUp">{{$t('EXPORT')}}</span>
                                    <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-cloud-download</v-icon>
                                </v-btn>
                                </download-excel>
                            </v-col>
                            <v-spacer></v-spacer>
                            <v-col cols="auto" class="pa-1">
                                <v-btn 
                                    :small="!$vuetify.breakpoint.mdAndUp"
                                    :fab="!$vuetify.breakpoint.mdAndUp"
                                    color="primary" dark @click="searchAppliactions"
                                >
                                <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t("SEARCH") }}</span>
                                <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-account-search</v-icon>
                                </v-btn>
                            </v-col>
                            <v-col class="pa-1 mx-2" cols="auto">
                                <v-btn
                                    :small="!$vuetify.breakpoint.mdAndUp"
                                    :fab="!$vuetify.breakpoint.mdAndUp"
                                    color="darken3"
                                    class="white--text"
                                    @click="reset"
                                >
                                <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t("RESET") }}</span>
                                <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-delete-empty</v-icon>
                                </v-btn>
                            </v-col>
                        </v-row>
                        </v-container>
                    </v-card-actions>
                    </v-container>
                </v-form>
                </template>
            </div>
        </v-card>
        <v-card class="mx-auto">
            <v-toolbar flat v-if="$vuetify.breakpoint.mdAndUp">
                <v-row>
                    <v-col cols="12" md="8" lg="8" class="pt-10">
                        <v-toolbar-title :class="!$vuetify.breakpoint.mdAndUp?'text-thin':''"> 
                            {{ title }}
                        </v-toolbar-title>
                    </v-col>
                    <v-col cols="12" md="4" lg="4">
                        <v-select  class="ma-3 pt-4 mb-0"
                                v-model="value"
                                :items="headers"
                                :label="$t('HIDE_SHOW_COLUMN')"
                                :placeholder="$t('HIDE_SHOW_COLUMN')"
                                multiple
                                append-outer-icon="mdi-filter"
                                return-object
                            >
                            <template v-slot:selection="{ item, index }">
                                <v-chip v-if="index === 0">
                                <span>{{ item.text }}</span>
                                </v-chip>
                                <span
                                v-if="index === 1"
                                class="grey--text caption"
                                >(+{{ value.length - 1 }} {{$t('OTHER')}})</span>
                            </template>
                        </v-select>
                    </v-col>
                </v-row>
            </v-toolbar>
            <v-row v-else>
                <v-col cols="12" md="8" lg="8" class="pb-0 ps-6">
                    <v-toolbar-title :class="!$vuetify.breakpoint.mdAndUp?'text-thin':''"> 
                        {{ title }}
                    </v-toolbar-title>
                </v-col>
                <v-col cols="12" md="4" lg="4">
                    <v-select  class="ma-3 pt-4 mb-0"
                            v-model="value"
                            :items="headers"
                            :label="$t('HIDE_SHOW_COLUMN')"
                            :placeholder="$t('HIDE_SHOW_COLUMN')"
                            multiple
                            append-outer-icon="mdi-filter"
                            return-object
                        >
                        <template v-slot:selection="{ item, index }">
                            <v-chip v-if="index === 0">
                            <span>{{ item.text }}</span>
                            </v-chip>
                            <span
                            v-if="index === 1"
                            class="grey--text caption"
                            >(+{{ value.length - 1 }} {{$t('OTHER')}})</span>
                        </template>
                    </v-select>
                </v-col>
            </v-row>
            <v-data-table
                :headers="selectedHeaders"
                :items="serverTranslateArrayKeys(applications,
                ['studentName','faculty','branch','gender',
                'country','state','city','semester','applicationStatus',
                'paymentStatus','certificateTypeEquation','certificateType','major','nationality'])"
                class="elevation-1"
                :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-arrow-left',
                    nextIcon: 'mdi-arrow-right',
                    itemsPerPageOptions: [5, 10, 15, 50, 100]
                }"
                :items-per-page="10"
                :loading="loading"
                :options.sync="appOptions"
                :server-items-length="total"
                :hide-default-footer="applications.length > 0 ? false : true"
            >
            <template v-slot:item.mobile="{ item }"> 
                <span dir='ltr'>{{item.mobile}}</span>
            </template>
            <template v-slot:item.telephone="{ item }"> 
                <span dir='ltr'>{{item.telephone}}</span>
            </template>
            <template v-slot:item.birthdate="{ item }">
               {{ reverseString(item.birthdate,'-') }}
            </template>
            <template v-slot:item.certificateType="{ item }">
                    <template v-if="item.certificateTypeEquation">
                        {{ item.certificateTypeEquation }} 
                    </template>
                    <template v-else>
                        {{ item.certificateType }}
                    </template>
            </template>
            <template v-slot:no-data>
                <v-alert icon="warning" class="mt-2" color="light5">
                {{ $t("NO_DATA") }}
                </v-alert>
            </template>
            <template v-slot:footer.page-text="props">
                {{ props.pageStart }} - {{ props.pageStop }} {{$t('PAGE_TEXT')}} {{ props.itemsLength }}
            </template>
            </v-data-table>
        </v-card>
        <pr-scope :key="componentKey" v-if="showScopeTree == true" v-on:setScope="onScopeChanged" :scopeFullPath="false"/>
    </div>
</template>

<script>
import serverTranslations from "@/mixins/serverTranslations";
import globalMethods from "@/mixins/globalMethods";
import scope from "@/components/shared/ScopeTree.vue"
import Axios from "axios";
export default {
  name: "pr-application-status-report",
  components:{
        "pr-scope":scope
    },
  mixins: [serverTranslations,globalMethods],
    mounted() {
        this.getFilters();
        this.searchAppliactions(true);
        this.value = this.headers;
    },
    data() {
        return {
            value: [],
            filterItems: {
                yearId: this.$store.getters.getActiveAcademicYear.id,
                yearName: this.$store.getters.getActiveAcademicYear.name,
                semesterId: this.$store.getters.getActiveAcademicSemester.id,
                semesterName: this.$store.getters.getActiveAcademicSemester.name || 0,
                appStatus: ["0"],
                appStatusName:""
            },
            allowExport: false,
            semesters: [],
            years: [],
            status:[],
            displayHeaderSelect:false,
            loading: false,
            applications: [],
            exportedApplications: [],
            total:0,
            appOptions: {},
            showScopeTree:  false,
            componentKey:0,
        };
    },
    watch:{
        appOptions: {
            handler () {
            this.searchAppliactions();
            },
        deep: true,
        },
        direction:function () {
            this.selectedHeaders = this.value;
        }
    },
    computed: {
        activeAcademicYear() {
            return this.$store.getters.getActiveAcademicYear;
        },
        activeAcademicSemester() {
            return this.$store.getters.getActiveAcademicSemester;
        },
      /*   status(){
            const statusList = this.$t("CASES");
            const list = []
            statusList.forEach((item,index)=>{
              if(7>index>0 || index==8 )
                list.push({"id":""+index,"name":item});
            })
            return list;
        }, */
        direction:function () {
           return this.$store.state.direction;
        },
        selectedHeaders: {
            get() {
                const item = this.sortByKey(this.value,'id');
                return this.value.length > 0 && this.value.length !== this.headers.length ? item : this.headers;
            },
            set(value) {
                this.value.forEach(element => {
                    if (element.id === this.headers[element.id].id) {
                       element.text = this.headers[element.id].text;
                    }
                });
            }
        },
        title(){
            return this.$t('APP_STATUS_LIST_TITLE' ,{
                status: this.filterItems.appStatusName,
                year: this.filterItems.yearName,
                semester: this.serverTranslate(this.filterItems.semesterName)
            });
        },
        secondTitle() {
            const facultyName = this.$store.state.Auth.facultyName 
            ? " / " + this.serverTranslate(this.$store.state.Auth.facultyName) : '';
            const branchName = this.$store.state.Auth.branchName
            ? " / " + this.serverTranslate(this.$store.state.Auth.branchName) : '';
            const scopeFullPath = this.serverTranslate(this.$store.state.scopeName) + facultyName + branchName;
            return scopeFullPath;
        },
        jsonFields() {
            const fields = {
                "اسم الطالب|Student Name": 'studentName',
                'رقم المحمول|Mobile': 'mobile',
                'البريد الإلكتروني|E-mail': 'e-mail',
                'تاريخ الميلاد|Birthdate': 'birthdate',
                'الجنسية|Nationality': 'nationality',
                'الكلية|Faculty': 'faculty',
                'الفرع|Branch': 'branch',
                'حالة الدفع|Payment Status': 'paymentStatus',
                'نوع الشهادة|Certificate Type': 'certificateType',
                'نوع شهادة المعادلة|Certificate Equation Type': 'certificateTypeEquation',
                'التخصص|Major': 'major',
                'سنة الحصول على المؤهل|Prequal Year': 'prequalYear',
                'المجموع المعتمد|Approval Total': 'submittedTotal',
            }
            const scopeLength = (this.secondTitle.match(/\//g) || []).length;
            if (scopeLength == 2) {
                delete fields['الكلية|Faculty'];
                delete fields['الفرع|Branch'];
            } else if (scopeLength == 1) {
                delete fields['الكلية|Faculty'];
            }
            return fields;
        },
        studentStatus(){
            const statusList = this.serverTranslateArray([
                {id:"1",name: "تحت المراجعة|Under Reviewing"},
                {id:"2",name: "المقبولين|Accepted"},
                {id:"3",name: "المرفوضين|Rejected"},
                {id:"4",name: "طلبات غير مكتملة|Incomplete Applications"},
                {id:"5",name: "المرشحين للقبول|Recommened Accept"},
                {id:"6",name: "المرشحين للرفض|Recommened Refused"},
                {id:"7",name: "تحت المراجعة|Under Reviewing"},
            ],'name')
            return statusList;
        },
        headers() {
            const fields = [
                {
                    id:0,
                    text: this.$t("STUDENT_NAME"),
                    align: "start",
                    sortable: true,
                    value: "studentName",
                    width:"250px"
                },
                {
                    id:1,
                    text: this.$t("ADMISSION_DATA.STEP1.PHONE"),
                    align: "start",
                    sortable: false,
                    value: "mobile"
                },
                {
                    id:2,
                    text: this.$t("ADMISSION_DATA.STEP1.EMAIL"),
                    align: "start",
                    sortable: false,
                    value: "e-mail",
                    width:"200px"
                },
                {
                    id:3,
                    text: this.$t("ADMISSION_DATA.STEP1.BIRTHDATE"),
                    align: "start",
                    sortable: false,
                    value: "birthdate"
                },
                {
                    id:4,
                    text: this.$t("ADMISSION_DATA.STEP1.NATIIONALITY"),
                    align: "start",
                    sortable: false,
                    value: "nationality"
                },
                {
                    id:5,
                    text: this.$t("FACULTY"),
                    align: "start",
                    sortable: true,
                    value: "faculty"
                },
                {
                    id:6,
                    text: this.$t("BRANCH"),
                    align: "start",
                    sortable: true,
                    value: "branch"
                },
                {
                    id:7,
                    text: this.$t("PAYMENT_STATUS"),
                    align: "start",
                    sortable: true,
                    value: "paymentStatus"
                },
                {
                    id:8,
                    text: this.$t("CERTIFICATE_TYPE"),
                    align: "start",
                    sortable: true,
                    value: "certificateType"
                },
                {
                    id:9,
                    text: this.$t("ADMISSION_DATA.STEP2.MAJOR"),
                    align: "start",
                    sortable: true,
                    value: "major"
                },
                {
                    id:10,
                    text: this.$t("ADMISSION_DATA.STEP2.YEAR"),
                    align: "start",
                    sortable: true,
                    value: "prequalYear"
                },
                {
                    id:11,
                    text: this.$t("ADMISSION_DATA.STEP2.OTHER_SUM"),
                    align: "start",
                    sortable: true,
                    value: "submittedTotal"
                },
            ];
            const scopeLength = (this.secondTitle.match(/\//g) || []).length;
            if (scopeLength == 2) {
                fields.splice(5, 2);
            } else if (scopeLength == 1) {
                fields.splice(5, 1);
            }
            return fields;
        },
    },
    methods: {
        showScopeTreeFn(){
            this.showScopeTree = true;
            this.componentKey +=1;
        },
        getFilters() {
            return this.$http
                .post("/api/students_admission_apps/list_application_filters/")
                .then(response => {
                    const data = response.data.data;
                    this.semesters = data.Semesters;
                    this.years = data.years;
                    this.status = data.applicationStatus;
                });
        },
        initializeList() {
            return new Promise((resolve, reject) => {
            const data =this.searchedData();
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.$http
                .post("/api/students_admission_reports/list_admission_student_applications/",data)
                .then(
                res=>{
                    this.applications= [];
                    const items = res.data.data.results;
                    const total = res.data.data.total;
                    resolve({
                    items,
                    total,
                    });
                },
                err => {
                    this.applications = [];
                    this.showToasted("error",this.serverTranslate(err.response.data.message));
                }
            );
                this.loading = false;
            }, 1000);
        });
        },
        searchedData (){
            this.loading = true;
            const { sortBy, sortDesc, page, itemsPerPage } = this.appOptions
            const data = {
                page: page,
                pageSize: itemsPerPage,
                academicYear:this.filterItems.yearId,
                academicSemester:this.filterItems.semesterId,
                appStatus: this.filterItems.appStatus
            }
            if (sortBy.length === 1 && sortDesc.length === 1) {
                data.orderType = sortDesc[0] ? "asc" : "desc";
                data.orderBy = sortBy[0]
            }
            return data
        },
        changeTitle() {
            const statusArray=this.status;
            if(this.filterItems.appStatus)
            {
                const appStatusSelectedIds=this.filterItems.appStatus.flat();
                const selectedStatusItems = statusArray.filter(item => {
                    return appStatusSelectedIds.some(id => item.mongo_id.includes(id));
                    });
                                
                this.filterItems.appStatusName=this.serverTranslate(selectedStatusItems[0].name)
            }
        
            //this.filterItems.appStatusName = this.studentStatus.filter(obj => obj.id == this.filterItems.appStatus)[0].name;
            this.filterItems.yearName = this.years.filter(obj => obj.id == this.filterItems.yearId)[0].name;
            this.filterItems.semesterName = this.semesters.filter(obj => obj.id == this.filterItems.semesterId)[0].name;
        },
        searchAppliactions (resetPageNum=false) {
            if (resetPageNum) {
                this.appOptions.page = 1;
                this.appOptions.sortBy = [];
                this.appOptions.sortDesc = [];
            }
            this.initializeList()
            .then(data => {
                this.applications = data.items;
                this.total = data.total;
                this.changeTitle();
                if (this.applications.length > 0) { this.allowExport = true;}
            });
        },
        reset() {
            this.filterItems.yearId = this.activeAcademicYear.id;
            this.filterItems.semesterId = this.activeAcademicSemester.id;
            this.filterItems.appStatus = ["0"]
            this.searchAppliactions(true);
        },
        onScopeChanged(changed) {
            this.value = this.headers;
            if (changed) this.searchAppliactions(true);
        },
        async fetchExportedData(){
            const response = await this.$http.post("/api/students_admission_reports/list_admission_student_applications/",{
                pageSize:-1,
                academicYear:this.filterItems.yearId,
                academicSemester:this.filterItems.semesterId,
                appStatus:  (this.filterItems.appStatus&& Array.isArray(this.filterItems.appStatus) ?this.filterItems.appStatus.flat():"") 
                })
            const exportedData = this.serverTranslateArrayKeys(response.data.data.results,
                ['studentName','faculty','branch','nationality','semester',
                'paymentStatus','certificateTypeEquation','certificateType','major'])
            if (response.status !== 200) return this.showToasted("error",this.$t("NOT_FOUND_SERVER_ERROR"))
            return exportedData;

        },
    }
};
</script>