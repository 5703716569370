<style>
/*.thisTbl th + th { border-left:1px solid #dddddd; }*/
.thisTbl th {
  border-left: 1px solid #dddddd;
}

.thisTbl td {
  border-left: 1px solid #dddddd;
}

/*.thisTbl td + td { border-left:1px solid #dddddd; }*/
</style>
<template>
  <v-card class="mx-auto mb-3">
  
    <div id="filters">
      <template>
        <v-toolbar flat>
          <v-toolbar-title>
            <p class="mb-0">{{ $t("STATISTIC_APP_NAME") }}</p>
          </v-toolbar-title>
          <v-spacer></v-spacer>
        </v-toolbar>
        <v-divider></v-divider>
        <v-form ref="form">
          <v-container>
            <v-row>
              <v-col cols="12" class="ma-0" sm="12" md="12" lg="4">
                <v-select
                  :items="serverTranslateArray(status, 'name')"
                  v-model="filterItems.appStatus"
                  outlined
                  multiple
                  :rules="requiredRule"
                  small-chips
                  :label="$t('APPLICATION_CASES')"
                  item-value="mongo_id"
                  item-text="name"
                  clearable
                >
                </v-select>
              </v-col>
              <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                <v-select
                  :items="serverTranslateArray(this.years, 'name')"
                  v-model="filterItems.yearId"
                  item-value="id"
                  item-text="name"
                  :label="$t('ACADEMIC_YEAR')"
                  outlined
                  clearable
                ></v-select>
              </v-col>
              <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                <v-select
                  :items="serverTranslateArray(this.semesters, 'name')"
                  v-model="filterItems.semesterId"
                  item-value="id"
                  item-text="name"
                  :label="$t('ACADEMIC_SEMESTER')"
                  outlined
                  clearable
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                <v-select
                  :items="
                    serverTranslateArray(this.certificateCategoriesList, 'name')
                  "
                  v-model="filterItems.certificatesCategoryFilter"
                  item-value="id"
                  item-text="name"
                  :label="$t('CERTIFICATE')"
                  outlined
                  @change="selectCertType()"
                  clearable
                ></v-select>
              </v-col>

              <v-col cols="12" class="ma-0" xs="12" sm="12" md="12" lg="4">
                <v-select
                  :items="
                    serverTranslateArray(this.certificateTypesList, 'name')
                  "
                  v-model="filterItems.certificateTypeFilter"
                  item-value="id"
                  item-text="name"
                  multiple
                  :label="$t('SPECIALIZATION')"
                  outlined
                  clearable
                ></v-select>
              </v-col>
            </v-row>


             <v-row>
                <v-col cols="12" class="ma-0" sm="6" md="6" lg="6">
                  <v-menu
                      v-model="startDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                      <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          :label="$t('APPLY_APPLICATION_DATE') +$t('FROM')"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          v-model="startApplicationDate"
                          :rules="startDateRules"
                          clearable
                      ></v-text-field>
                      </template>
                      <v-date-picker
                      :max="maxDate"
                      :min="minDate"
                      v-model="startApplicationDate"
                      @input="startDateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" class="ma-0" sm="6" md="6" lg="6">
                  <v-menu
                      v-model="endDateMenu"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                          ref="endDate"
                          v-model="endApplicationDate"
                          :label="$t('APPLY_APPLICATION_DATE') +$t('TO')"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :rules="endDateRules"
                          clearable

                      ></v-text-field>
                    </template>
                    <v-date-picker
                    v-model="endApplicationDate"
                      :max="maxDate"
                      :min="minDate"
                      @input="endDateMenu = false"></v-date-picker>
                  </v-menu>
                </v-col>

              </v-row>



            <v-card-actions class="pa-0">
              <v-container class="px-0">
                <v-row>
                  <v-spacer></v-spacer>
                  <v-col cols="auto" class="pa-1">
                    <v-btn
                      :small="!$vuetify.breakpoint.mdAndUp"
                      :fab="!$vuetify.breakpoint.mdAndUp"
                      color="primary"
                      dark
                       @click="initializeList"
                   
                    >
                      <span v-if="$vuetify.breakpoint.mdAndUp">{{
                        $t("VIEW")
                      }}</span>
                      <v-icon :right="$vuetify.breakpoint.mdAndUp"
                        >mdi-account-search
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="pa-1 mx-2" cols="auto">
                    <v-btn
                      :small="!$vuetify.breakpoint.mdAndUp"
                      :fab="!$vuetify.breakpoint.mdAndUp"
                      color="darken3"
                      class="white--text"
                      @click="reset"
                    >
                      <span v-if="$vuetify.breakpoint.mdAndUp">{{
                        $t("RESET")
                      }}</span>
                      <v-icon :right="$vuetify.breakpoint.mdAndUp"
                        >mdi-delete-empty
                      </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>

                <!-- print button  -->
                <v-row>
                  <v-col class="pa-1 mx-2 " cols="auto">
                    <v-btn
                      :small="!$vuetify.breakpoint.mdAndUp"
                      :fab="!$vuetify.breakpoint.mdAndUp"
                      color="darken3"
                      class="white--text"
                      @click="print"
                    >
                      <span v-if="$vuetify.breakpoint.mdAndUp">{{
                        $t("PRINT")
                      }}</span>

                      <v-icon :right="$vuetify.breakpoint.mdAndUp"
                        >mdi-domain
                      </v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="pa-1 mx-2 ma-0"  cols="auto">
                    <v-checkbox v-model="showChart" :label="$t('SHOW_CHART')"></v-checkbox>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-container>
        </v-form>
      </template>

      <!-- start table -->
      <v-card class="mx-auto">
        <v-toolbar flat v-if="$vuetify.breakpoint.mdAndUp">
          <v-row>
            <v-col cols="12" md="8" lg="8" class="pt-10">
              <v-toolbar-title
                :class="!$vuetify.breakpoint.mdAndUp ? 'text-thin' : ''"
              >
                {{ $t(STATISTIC_APP_NAME) }}
              </v-toolbar-title>
            </v-col>
            <!-- <v-col cols="12" md="4" lg="4">
              <v-select
                class="ma-3 pt-4 mb-0"
                v-model="value"
                :items="headers"
                :label="$t('HIDE_SHOW_COLUMN')"
                :placeholder="$t('HIDE_SHOW_COLUMN')"
                multiple
                append-outer-icon="mdi-filter"
                return-object
              >
                <template v-slot:selection="{ item, index }">
                  <v-chip v-if="index === 0">
                    <span>{{ item.text }}</span>
                  </v-chip>
                  <span v-if="index === 1" class="grey--text caption"
                    >(+{{ value.length - 1 }} {{ $t("OTHER") }})</span
                  >
                </template>
              </v-select>
            </v-col> -->
          </v-row>
        </v-toolbar>
        <v-row v-else>
          <v-col cols="12" md="8" lg="8" class="pb-0 ps-6">
            <v-toolbar-title
              :class="!$vuetify.breakpoint.mdAndUp ? 'text-thin' : ''"
            >
              {{ $t(STATISTIC_APP_NAME) }}
            </v-toolbar-title>
          </v-col>
          <!-- <v-col cols="12" md="4" lg="4">
            <v-select
              class="ma-3 pt-4 mb-0"
              v-model="value"
              :items="headers"
              :label="$t('HIDE_SHOW_COLUMN')"
              :placeholder="$t('HIDE_SHOW_COLUMN')"
              multiple
              append-outer-icon="mdi-filter"
              return-object
            >
              <template v-slot:selection="{ item, index }">
                <v-chip v-if="index === 0">
                  <span>{{ item.text }}</span>
                </v-chip>
                <span v-if="index === 1" class="grey--text caption"
                  >(+{{ value.length - 1 }} {{ $t("OTHER") }})</span
                >
              </template>
            </v-select>
          </v-col> -->
        </v-row>
        <v-data-table
          :headers="filteredFields"
        :items="serverTranslateArrayKeys(filteredDataItems,['facultyName'])"
          id="app"
          ref="printableTable"
          class="elevation-1 thisTbl "
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-arrow-left',
            nextIcon: 'mdi-arrow-right',
            itemsPerPageOptions: [5, 10, 15, 50, 100]
          }"
          :items-per-page="10"
          :loading="loading"
          :options.sync="appOptions"
          :server-items-length="total"
          :hide-default-footer="true"
        >
          <template v-slot:no-data>
            <v-alert icon="warning" class="mt-2" color="light5">
              {{ $t("NO_DATA") }}
            </v-alert>
          </template>
          <template v-slot:footer.page-text="props">
            {{ props.pageStart }} - {{ props.pageStop }} {{ $t("PAGE_TEXT") }}
            {{ props.itemsLength }}
          </template>
        </v-data-table>
      </v-card>

     
      <div v-if="showChart">
          <multi-bar-chart :chart-data="chartData" ref="chart"></multi-bar-chart>
          </div>

          
   
    </div>
  
  </v-card>
 

</template>

<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import globalMethodsMixin from "@/mixins/globalMethods";
import Validations from "@/components/shared/validations";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import MultiBarChart from '@/components/shared/MultiBarChart'
const statusToFieldMap ={
        "0": "draft",
        "1": "underReview",
        "2": "acceptedCount",
        "3": "refusedCount",
        "4": "incomplete",
        "5": "recommendedAccepted",
        "6": "recommendedRefused",
        "7":"underReview",
     //   "7": "underReviewWithoutFees",
        "8": "pulledToSIS",
        "9": "refusedAsAcceptedInOther",
        "10": "waitingList",
      };
export default {
  mixins: [serverTranslationsMixin, globalMethodsMixin],
  components: {"multi-bar-chart": MultiBarChart },
  data() {
    return {
      startDateMenu: false ,
      endDateMenu: false,
      startApplicationDate:"",
      endApplicationDate:"",
      loading: false,
      showChart:false,
      dataItems: [],
      total: 0,
      requiredRule: Validations.required(),
      filterItems: {
        yearId: this.$store.getters.getActiveAcademicYear.id,
        yearName: this.$store.getters.getActiveAcademicYear.name,
        semesterId: this.$store.getters.getActiveAcademicSemester.id,
        semesterName: this.$store.getters.getActiveAcademicSemester.name || 0,
        appStatus: "",
        appStatusName: "",
        certificatesCategoryFilter: "",
        certificateTypeFilter: ""
      },
      semesters: [],
      years: [],
      faculties: [],
      statusApp:[],
      certificateCategoriesList: [],
      certificateTypesList: [],
      currentYear: {},
      currentSemester: {},
      value: [],
      chartData: {
        labels: [],
        datasets: []
      },

     
    };
  },


  computed: {
   endDateRules() {
      return  [
          v =>
            new Date(this.endApplicationDate) >= new Date(this.startApplicationDate) ||
            this.$t("DATE_FROM_TO_VALIDATION")
        ]
    },
   startDateRules(){
      return [
        v => !this.endApplicationDate || new Date(v) <= new Date(this.endApplicationDate) || this.$t("DATE_FROM_TO_VALIDATION")

      ];
    },

    activeAcademicYear() {
      return this.$store.getters.getActiveAcademicYear;
    },
    activeAcademicSemester() {
      return this.$store.getters.getActiveAcademicSemester;
    },

    headers() {
      const fields = [
        {
          id: 0,
          text: this.$t("FACULTY"),
          align: "start",
          sortable: true,
          value: "facultyName",
          width: "300px"
        },
        {
          id: 1,
          text: this.$t("COUNT_ACCEPTED_STUDENTS"),
          align: "start",
          sortable: false,
          value: "acceptedCount"
        },
        {
          id: 2,
          text: this.$t("COUNT_REFUSED_STUDENTS"),
          align: "start",
          sortable: false,
          value: "refusedCount"
        },
        {
          id: 3,
          text: this.$t("RECOMMENDED_ACCEPTED"),
          align: "start",
          sortable: false,
          value: "recommendedAccepted"
        },
        {
          id: 4,
          text: this.$t("RECOMMENDED_REFUSED"),
          align: "start",
          sortable: false,
          value: "recommendedRefused"
        },
        {
          id: 5,
          text: this.$t("COUNT_DRAFT_STUDENTS"),
          align: "start",
          sortable: false,
          value: "draft"
        },
        {
          id: 6,
          text: this.$t("COUNT_UNDER_REVIEW_STUDENTS"),
          align: "start",
          sortable: false,
          value: "underReview"
        },
      /*   {
          id: 7,
          text: this.$t("COUNT_UNDER_REVIEW_NOFEES_STUDENTS"),
          align: "start",
          sortable: false,
          value: "underReviewWithoutFees"
        }, */
        {
          id: 8,
          text: this.$t("COUNT_PULLED_SIS_STUDENTS"),
          align: "start",
          sortable: false,
          value: "pulledToSIS"
        },
          {
          id: 9,
          text: this.$t("COUNT_REFUSED_AS_ACCEPTED_IN_OTHER_STUDENTS"),
          align: "start",
          sortable: false,
          value: "refusedAsAcceptedInOther"
        },
          {
          id: 10,
          text: this.$t("COUNT_WAITING_LIST_STUDENTS"),
          align: "start",
          sortable: false,
          value: "waitingList"
        },
        {
          id: 11,
          text: this.$t("COUNT_INCOMPLETE_STUDENTS"),
          align: "start",
          sortable: false,
          value: "incomplete"
        },
        {
          id: 12,
          text: this.$t("OVER_ALL_COUNT_STUDENT"),
          align: "start",
          sortable: false,
          value: "allCount"
        }
      ];
      return fields;
    },

    selectedHeaders: {
      get() {
        const item = this.sortByKey(this.value, "id");
        return this.value.length > 0 &&
          this.value.length !== this.headers.length
          ? item
          : this.headers;
      },
      set(value) {
        this.value.forEach(element => {
          if (element.id === this.headers[element.id].id) {
            element.text = this.headers[element.id].text;
          }
        });
      }
    },
      filteredFields() {
      if (this.filterItems.appStatus.length === 0) {
        return this.selectedHeaders;
      }
    
     

      let selectedFieldIds = ["facultyName"]; // Always include the faculty name field
      this.filterItems.appStatus.forEach(statusItem => {
             if (Array.isArray(statusItem)) {
            // If statusItem is an array, loop through each status within it

               statusItem.forEach(status => {
              if (statusToFieldMap[status]) {
                selectedFieldIds = selectedFieldIds.concat(statusToFieldMap[status]);
              }
            });
          } else {
            // If statusItem is a single status code, handle it directly
            if (statusToFieldMap[statusItem]) {
              selectedFieldIds = selectedFieldIds.concat(statusToFieldMap[statusItem]);
            }
          }

      });
        
      if (this.filterItems.appStatus.length > 1) {
         selectedFieldIds.push("allCount");
      }
      return this.selectedHeaders.filter(field => selectedFieldIds.includes(field.value));
    },
    filteredDataItems() {
      const dataItemsTranslate=(this.dataItems)

      if (this.filterItems.appStatuslength === 0) {
        return dataItemsTranslate;
      }
      return dataItemsTranslate.map(item => {
        const filteredItem = {};
        this.filteredFields.forEach(field => {
          if (item[field.value]) {
            filteredItem[field.value] = item[field.value];
          }
        });
        let selectedFields=[];
         if (Array.isArray(this.filterItems.appStatus) && this.filterItems.appStatus.length > 1) {
            selectedFields = [...new Set(
                this.filterItems.appStatus
                    .flat() 
                    .map(status => statusToFieldMap[status])
                    .filter(field => field)
            )];
         }
         else{
            selectedFields= [...new Set(Object.values(statusToFieldMap))];
          }
          filteredItem.allCount = selectedFields.reduce((sum, field) => sum + (parseInt(item[field]) || 0), 0);
        return filteredItem;
      });
    },
    direction: function() {
      return this.$store.state.direction;
    }
  },
  watch: {
    direction: function() {
      this.selectedHeaders = this.value;
    },
    filteredDataItems(newItems) {
      this.updateChartData(newItems);
    }


   
  },
  mounted() {
    this.getFilters();
    this.initializeList();
    this.value = this.headers;
  },
  methods: {
 
    print() {

      const tableHTML = this.$refs.printableTable.$el;
    
        // Get the HTML element for the chart, if available
        const chartImage = this.$refs.chart  ? this.$refs.chart.getBase64Image(): '';

        // Call the printTable function with the HTML content of the table and optionally the chart
        this.printTable(tableHTML, chartImage, this.direction);
   
   },
  reset() {
          this.filterItems.yearId = this.activeAcademicYear.id;
          this.filterItems.semesterId = this.activeAcademicSemester.id;
          this.filterItems.appStatus = ""
          this.filterItems.certificatesCategoryFilter=""
          this.filterItems.certificateTypeFilter="",
          this.startApplicationDate="",
          this.endApplicationDate=""
      },
    getFilters(facultyId = null) {
      return this.$http
        .post("/api/students_admission_apps/list_application_filters/", {
          facultyId: facultyId || "",
          yearId: this.activeAcademicYear.id || 0,
          semesterId: this.activeAcademicSemester.id || 0
        })
        .then(
          response => {
            const data = response.data.data;
            this.semesters = data.Semesters;
            this.years = data.years;
           this.status =   data.applicationStatus;
           this.statusApp=data.applicationStatus
            //this.status = data.applicationStatus.includes(',') ? data.applicationStatus.split(',') : data.applicationStatus;
            this.faculties = data.faculties;
            this.certificateCategoriesList =
              data.certificateCategories["basic_category_items"];
            this.currentYear = this.years.filter(
              year => parseInt(year.active) === 1
            )[0];
            this.filterItems.yearId = this.currentYear.id || 0;
            this.filterItems.yearName = this.currentYear.name || "";
            this.currentSemester = this.semesters.filter(
              semester => parseInt(semester.active) === 1
            )[0];
            this.filterItems.semesterId = this.currentSemester.id || 0;
            this.filterItems.semesterName = this.currentSemester.name || "";
          },
          err => {
            this.showToasted("error", err.response.data.message);
          }
        )
        .then(() => (this.loading = false));
    },

    selectCertType() {
      return this.$http
        .post("/api/students_admission_apps/get_certificate_type_filter/", {
          categoryId: this.filterItems.certificatesCategoryFilter || 0
        })
        .then(
          response => {
            const data = response.data.data;
            this.certificateTypesList =
              data.certificatesTypes["basic_category_items"];
          },
          err => {
            this.showToasted("error", err.response.data.message);
          }
        )
        .then(() => (this.loading = false));
    },

    initializeList() {
      this.loading = true;
      return this.$http
        .post("/api/students_admission_apps/get_general_statistic/", {
          semesterId: this.filterItems.semesterId,
          yearId: this.filterItems.yearId,
          appStatus:(this.filterItems.appStatus  &&Array.isArray(this.filterItems.appStatus) ?this.filterItems.appStatus.flat():""),
          certificateCategoryId:this.filterItems.certificatesCategoryFilter, 
          certificateTypeId:this.filterItems.certificateTypeFilter,
          startApplicationDate:this.startApplicationDate,
          endApplicationDate:this.endApplicationDate,
        })

        .then(
          response => {
            const resultData = response.data.data;
            this.dataItems = resultData;
            this.total = this.dataItems.length;
          },
          err => {
            this.showToasted("error", err.response.data.message);
          }
        )
        .then(() => (this.loading = false));
    }
    , updateChartData(items) {
      const labels = items.map(item => this.serverTranslate(item.facultyName) || '');
      const draft = items.map(item => item.draft ? item.draft[0] : 0);
      const acceptedCount = items.map(item => item.acceptedCount ? item.acceptedCount[0] : 0);
      const refusedCount = items.map(item => item.refusedCount ? item.refusedCount[0] : 0);
      const incomplete = items.map(item => item.incomplete ? item.incomplete[0] : 0);
      const recommendedAccepted = items.map(item => item.recommendedAccepted ? item.recommendedAccepted[0] : 0);
      const recommendedRefused = items.map(item => item.recommendedRefused ? item.recommendedRefused[0] : 0);
      const pulledToSIS = items.map(item => item.pulledToSIS ? item.pulledToSIS[0] : 0);
      const underReview = items.map(item => item.underReview ? item.underReview[0] : 0);
      const refusedAsAcceptedInOther=items.map(item => item.refusedAsAcceptedInOther ? item.refusedAsAcceptedInOther[0] : 0);
      const waitingList=items.map(item => item.waitingList ? item.waitingList[0] : 0);
      const allCount=items.map(item => item.allCount ? item.allCount : '')

      this.chartData = {
        labels,
        datasets: [
          {
        label: this.$t("DRAFT_STATUS"),
        backgroundColor: 'rgba(248, 121, 121, 0.6)', // lighter red
        data: draft
      },
      {
        label: this.$t("ACCEPTED_STATUS"),
        backgroundColor: 'rgba(122, 203, 245, 0.6)', // lighter blue
        data: acceptedCount
      },
      {
        label: this.$t("REFUSED_STATUS"),
        backgroundColor: 'rgba(122, 203, 213, 0.6)', // lighter green
        data: refusedCount
      },
      {
        label: this.$t("TO_BE_COMPLETED"),
        backgroundColor: 'rgba(122, 255, 213, 0.6)', // lighter green-blue
        data: incomplete
      },
      {
        label: this.$t("RECOMMENDED_ACCEPTED_STATUS"),
        backgroundColor: 'rgba(165, 86, 105, 0.6)', // lighter purple
        data: recommendedAccepted
      },
      {
        label: this.$t("RECOMMENDED_REFUSED_STATUS"),
        backgroundColor: 'rgba(253, 195, 0, 0.6)', // lighter yellow
        data: recommendedRefused
      },
      {
        label: this.$t("UNDER_REVIEW_STATUS"),
        backgroundColor: 'rgba(102, 187, 106, 0.6)', // lighter light-green
        data: underReview
      },
     {
        label: this.$t("REFUSED_AS_ACCEPTED_IN_OTHER_STUDENTS"),
        backgroundColor: 'rgb(155,0,253)', // lighter sky-blue
        data: refusedAsAcceptedInOther
      },
      {
        label: this.$t("WAITING_LIST"),
        backgroundColor: 'rgba(58,215,215)', // lighter sky-blue
        data: waitingList
      },
      {
        label: this.$t("PULLED_TO_SIS_STATUS"),
        backgroundColor: 'rgba(255, 138, 70, 0.6)', // lighter orange
        data: pulledToSIS
      },
   /*    {
        label: this.$t("ALL_COUNT_STUDENT"), // label for allCount
        backgroundColor: 'rgba(183, 193, 172)', 
        data: allCount
      } */
        
        ]
      }
    },
 
  }
};
</script>
