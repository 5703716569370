<template>
   <v-dialog
       v-model="dialog"
       max-width="80vh"
       @keydown.esc="cancel"
       :persistent="true"
   >
      <v-card>
         <v-toolbar dark color="primary" dense flat>
            <v-toolbar-title class="white--text">{{ $t("CHANGE_PASSWORD") }}</v-toolbar-title>
         </v-toolbar>
         <v-card-text>
            <v-form ref="passwordForm">
               <v-text-field
                   ref="oldPassword"
                   :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                   :type="showPassword ? 'text' : 'password'"
                   name="input-10-2"
                   :label="this.$t('OLD_PASSWORD')"
                   maxlength="20"
                   :rules="required"
                   v-model="data.oldPassword"
                   counter="20"
                   @keyup="passwordChanged"
                   @change="passwordChanged"
                   @click:append="showPassword = !showPassword"
                   @keydown.space="preventLeadingSpace"
               >
               </v-text-field>
               <v-text-field
                   ref="newPassword"
                   :append-icon="showFirstPassword ? 'mdi-eye' : 'mdi-eye-off'"
                   :rules="required"
                   v-model="data.newPassword"
                   :type="showFirstPassword ? 'text' : 'password'"
                   name="input-10-2"
                   :label="this.$t('NEW_PASSWORD')"
                   maxlength="20"
                   counter="20"
                   :disabled="allowDisabled"
                   @click:append="showFirstPassword = !showFirstPassword"
                   @keydown.space="preventLeadingSpace"
               >
               </v-text-field>
               <v-text-field
                   ref="confirmPassword"
                   v-model="data.confirmPassword"
                   :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                   :type="showConfirmPassword ? 'text' : 'password'"
                   name="input-10-2"
                   :rules="confirmPasswordRulse"
                   :label="this.$t('CONFIRM_NEW_PASSWORD')"
                   maxlength="20"
                   counter="20"
                   :disabled="allowDisabled"
                   @click:append="showConfirmPassword = !showConfirmPassword"
                   @keydown.space="preventLeadingSpace"
               >
               </v-text-field>
            </v-form>
         </v-card-text>
         <v-card-actions class="pt-0">
            <v-spacer></v-spacer>
            <v-btn color="primary" @click.native="submit" class="body-1">
               {{ $t("SAVE") }}
            </v-btn>
            <v-btn color="error" @click.native="dialog = !dialog" class="body-1">
               {{ $t("CANCEL") }}
            </v-btn>
         </v-card-actions>
      </v-card>
      <pr-confirmDialog ref="confirmCancel"></pr-confirmDialog>
   </v-dialog>
</template>
<script>
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
export default {
   name: "pr-changePassword",
   mixins: [serverTranslationsMixin, globalMethodsMixin],
   components: {
      "pr-confirmDialog": ConfirmDialog
   },
   created(){
      this.dialog = false;
   },
   data() {
      return {
         data: {
            oldPassword: "",
            newPassword: "",
            confirmPassword:""
         },
         dialog: false,
         required:[this.$store.state.Rules.required],
         passwordRulse: this.$store.state.Rules.passwordRulse,
         confirmPasswordRulse: [
            this.$store.state.Rules.required,
            v => v == this.data.newPassword || this.$t("CONFIRM_PASSWORD_VALIDATION")
         ],
         allowDisabled: true,
         showPassword: false,
         showConfirmPassword: false,
         showFirstPassword: false
      };
   },
   methods: {
      open() {
         this.dialog = true;
         this.$refs.oldPassword.reset();
         this.$refs.newPassword.reset();
         this.$refs.confirmPassword.reset();
         return new Promise((resolve, reject) => {
            this.resolve = resolve;
            this.reject = reject;
         });
      },
      submit() {
         if (this.$refs.passwordForm.validate()) {
            this.$http.post("/api/users/change_password/", this.data).then(
                res => {
                   this.showToasted("success",this.serverTranslate(res.data.message));
                   this.dialog = false;
                },
                err => {
                   this.showToasted("error",this.serverTranslate(err.response.data.message));
                }
            );
         }
      },
      passwordChanged() {
         if (!this.data.oldPassword) {
            this.allowDisabled = true;
            this.$refs.oldPassword.reset();
            this.$refs.newPassword.reset();
            this.$refs.confirmPassword.reset();
         } else {
            this.allowDisabled = false;
            this.$refs.oldPassword.validate();
            this.$refs.newPassword.validate();
            this.$refs.confirmPassword.validate();
         }

      },
      cancel() {
         this.resolve = false;
         this.dialog = false;
      }
   }
}
</script>