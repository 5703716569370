import Vue from "vue";
import serverTranslationsMixin from "@/mixins/serverTranslations";

export default {
   methods: {
      isArabicLetterKey: function(evt) {
         const value = evt.target.value;
         const charCode = evt.which;
         const txt = String.fromCharCode(charCode);
         // if (txt.match(/^[ء-ي\b ]+$/)) { # without numbers
         if (txt.match(/^[\d+ء-ي\b ]+$/)) {
            if (charCode == 32) {
               const lastChar = value.charAt(value.length - 1);
               if (lastChar == " ") evt.preventDefault();
            }
            return value;
         }
         evt.preventDefault();
      },
      isEnglishLetterKey: function(evt) {
         const value = evt.target.value;
         const charCode = evt.which;
         const txt = String.fromCharCode(charCode);
         // if (txt.match(/^[a-zA-Z.\-\b ]+$/)) { # without numbers
         if (txt.match(/^[a-zA-Z\d+.\-\b ]+$/)) {
            if (charCode == 32) {
               const lastChar = value.charAt(value.length - 1);
               if (lastChar == " ") evt.preventDefault();
            }
            return true;
         }
         evt.preventDefault();
      },
      preventLeadingSpace(e) {
         if (!e.target.value) e.preventDefault();
         else if (e.target.value[0] == " ")
         e.target.value = e.target.value.replace(/^\s*/, "");
      },
      reverseString(str:string,delimiter:string) {
         return str.split(delimiter).reverse().join(delimiter);
      },
      showToasted(type, msg) {
         if(type == "success") {
            Vue.toasted.show(serverTranslationsMixin.methods.serverTranslate(msg), {
               type: "success",
               icon: "check_circle_outline",
               duration: 5000,
               theme: "bubble",
               position: "bottom-left"
            });
         } else if (type == "error") {
            Vue.toasted.show(serverTranslationsMixin.methods.serverTranslate(msg), {
               type: "error",
               icon: "error_outline",
               position: "bottom-left",
               theme: "bubble",
               duration: 5000
            });
         }
      },
      sortByKey(array, key) {
         return array.sort(function(a, b) {
            const x = a[key]; const y = b[key];
            return ((x < y) ? -1 : ((x > y) ? 1 : 0));
         });
      },
      time12Hr(stringTime) {
         const arrTime =  stringTime.split(':');
         const parseHr = parseInt(arrTime [0])
         const hr = parseHr > 12
         ? parseHr - 12
         : parseHr === 0 ? 12 :parseHr;
         const min = arrTime [1];
         const sec = arrTime [2];
         if (parseHr < 12) {
            return hr + ":" + min + ":" + sec + ' ' + serverTranslationsMixin.methods.serverTranslate("ص|AM")
         }
         return hr + ":" + min + ":" + sec + ' ' + serverTranslationsMixin.methods.serverTranslate("م|PM")

      },
      isRTL(s){
          const rtlChars = /[\u0600-\u06FF]/;
          return rtlChars.test(s);
      }
      ,printTable(tableElement: HTMLElement, chartImage = '',directionAr=true) {
         //eslint-disable-next-line
           // Create a new iframe
           const direction = directionAr ? 'rtl' : 'ltr';

            const iframe = document.createElement('iframe');
            document.body.appendChild(iframe);
            const doc = iframe.contentDocument ;
            const win = iframe.contentWindow ;
        
            if (doc && win) {
        
                const printContent = `
                    <html>
                        <head>
                            <title>Print Table</title>
                            <style>
                                body {
                                    direction: ${direction};
                                }
                                table {
                                    width: 100%;
                                    border-collapse: collapse;
                                }
                                th, td {
                                    border: 1px solid black;
                                    padding: 8px;
                                }
                                .chart-container {
                                    width: 1000%;
                                    height: auto;
                                    margin-top: 20px;
                                }
                                .chart-container img {
                                 width: 10%; 
                                 height: auto;
                             }
                            </style>
                        </head>
                        <body>
                           
                            
                            <div>
                                ${tableElement.outerHTML}
                            </div>
                            ${chartImage ? `<div class="chart-container"><img src="${chartImage}" alt="Chart Image" /></div>` : ''}
                        </body>
                    </html>
                `;
        
                doc.open();
                doc.write(printContent);
                doc.close();
        
                // Ensure the iframe content is fully loaded before printing
                setTimeout(() => {
                    win.focus();
                    win.print();
        
                    // Remove the iframe after printing
                    document.body.removeChild(iframe);
                }, 1000); // Adjust the timeout duration as needed
            } else {
                console.error("Iframe document or window is not available");
            }
             // Fallback if onload doesn't trigger
       
               }
        
   

   }
}
