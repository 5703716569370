
<template>
   
    
    <div id="studentAccepted" class="mx-auto" >
        <v-data-table
            :headers="headers"
            :items="applications"
            class="elevation-1 pointer"
            :footer-props="{
               showFirstLastPage: true,
               firstIcon: 'mdi-arrow-collapse-left',
               lastIcon: 'mdi-arrow-collapse-right',
               prevIcon: 'mdi-arrow-left',
               nextIcon: 'mdi-arrow-right',
               itemsPerPageOptions: [5, 10, 15, 50, 100]
               }"
               :items-per-page="15"
               :loading="loading"
               :options.sync="appOptions"
               :server-items-length="total"
               :hide-default-header="true" 
              
               :fixed-header="true"

               :hide-default-footer="applications.length > 0 ? false : true"
                >
               <!-- Your customized header template -->
                    <template v-slot:header="{ props }">
                        <tr>
                           
                        <th  class="d-flex mb-2  align-items-center">

                            <v-checkbox
                            v-model="selectAll"
                            @change="toggleSelectAll"
                            class="text-center"
                            ></v-checkbox>
                            <label  class="align-self-center mt-2">{{ $t("SELECT_ALL") }}</label>

                        </th>
                        <th v-for="header in props.headers" :key="header.text" class="text-center">
                            {{ header.text }}
                        </th>
                        </tr>
                    </template>

                    <!-- Your table content -->
                    <template v-slot:item="{ item }">
                        <tr>
                        <td class="text-center">
                            <v-checkbox
                            v-model="item.selected"
                            :label="null"
                            :input-value="item.selected"
                            @change="checkItem(item)"
                            ></v-checkbox>
                        </td>
                        <td class="text-center">{{ serverTranslate(item.studentName) }}</td>
                        <td class="text-center">{{ item.nationalId }}</td>
                        <td class="text-center">{{ serverTranslate(item.facultyName) }}</td>
                        <td class="text-center">
                            {{ item.applicationStatus >= 0 ? $t("CASES")[item.applicationStatus] : "" }}
                        </td>
                        </tr>
                    </template>
               
           
                <template v-slot:top>
                    <div class="d-flex">
                        <v-toolbar flat>
                            <v-toolbar-title  class="custom-title" > {{ title }}</v-toolbar-title>
                        </v-toolbar>
                        <v-btn  color="primary" @click="exportToPrivateUniversity" class="mx-4 my-4">{{ $t("EXPORT_TO_PRIVATE_UNIVERSITY") }}</v-btn>
                   
                        
                    </div>
                </template>
             
                     
         </v-data-table>
       
         

    </div>
</template>
<script>
import serverTranslations from "@/mixins/serverTranslations";
import scope from "@/components/shared/ScopeTree.vue"
import { EventBus } from "@/plugins/bus";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import globalMethodsMixin from "@/mixins/globalMethods.ts";

export default {
   name: "List",
   components:{
    // "pr-scope":scope, 
//       "pr-confirmDialog": ConfirmDialog
   },
   mixins: [serverTranslations, globalMethodsMixin],

   mounted() {
      this.searchAppliactions(true);
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const mv = this;
      EventBus.$on("updateRunLinkListAfterAction", ()=> {
        
         mv.searchAppliactions();
      });
   },
   watch:{
  
      appOptions: {
         handler () {
            this.searchAppliactions();
         },
         deep: true,
      },
      studentSearch(val){

         if(!val || val.length < 3) {this.students = []; return false;}
         if (this.searchIsLoading) return;
         const phraseLang = this.isRTL(val) ? "ar" : "en"
         const data = {
            searchData: val,
            semesterId: this.activeAcademicSemester.id,
            yearId: this.activeAcademicYear.id,
            appStatus: this.appStatus.toString()
         }
         this.searchIsLoading = true;
         this.$http.post("/api/students_admission_apps/search_student/",data)
         .then(
            res=>{
               res = res.data.data;
               this.students = res.students.map(item=> { return {"id":item.studentId , "name":this.serverTranslateCustom(item.studentName, phraseLang)} });
            },
            err => {
               this.showToasted("error", err.response.data.message)
            }
         )
         .then(() => (this.searchIsLoading = false));

      },
   },
   computed: {
    
      activeAcademicYear() {
         return this.$store.getters.getActiveAcademicYear;
      },
      activeAcademicSemester() {
         return this.$store.getters.getActiveAcademicSemester;
      },
      title(){
         return   this.$t('APPLICATION_EXPORTING_TITLE' ,{
            year:this.yearName ,
            semester: this.serverTranslate(this.semesterName)
         });
      },
      headers() {
         return [
            {
               text: this.$t("STUDENT_NAME"),
               align: "center",
               sortable: true,
               value: "studentName"
            },
            {
               text: this.$t("IDENTITY"),
               align: "center",
               sortable: false,
               value: "nationalId"
            },
            {
               text: this.$t("FACULTY"),
               align: "center",
               sortable: true,
               value: "facultyName"
            },
             
            {
               text: this.$t("APPLICATION_CASES"),
               align: "center",
               sortable: true,
               value: "applicationStatus"
            },
           
         ];
      }
   },
   data() {
      return {
         valid: true,
         loading: false,
         singleSelect: false,
         semesterName:"",
         yearName:"",
         currentYear: {},
         currentSemester: {},
         searchIsLoading:false,
         students:[],
         applications:[],
         allApplication:[],
         selectedItems: [],
         selectAll: false, // Whether all items are selected
         someSelected: false, // Whether some but not all items are selected
         identityNum: "",
         total:0 ,
         appOptions:{},
         studentId:"",
         showScopeTree:false,
         componentKey:0
      };
   },
   methods: {


      showScopeTreeFn(){
         this.showScopeTree = true;
         this.componentKey +=1;
      },


      initializeList() {
         return new Promise((resolve, reject) => {
            const data =this.searchedData();
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
               this.$http
               .post("/api/students_admission_apps/list_applications_exporting_cpnu/", data)
               .then(
                  res=>{
             
                    this.selectedItems=[]
                     this.applications= [];
                     const items = res.data.data.applicationStudent.results;
                     const itemsWithSelected = items.map(item => ({
                        ...item,
                       selected: false
                        }));
                     const total = res.data.data.applicationStudent.total;
                     this.semesterName=res.data.data.academicSemesterName;
                     this.yearName=res.data.data.academicYearName;

                     resolve({
                        itemsWithSelected,
                        total,
                     });
                  },
                  err => {
                     this.applications = [];
                     this.$toasted.show(this.serverTranslate(err.response.data.message),
                     {
                        type: "error",
                        icon: "error_outline",
                        position: "bottom-left",
                        duration: 5000,
                        theme: "bubble"
                     }
                  );
               }
            );
            this.loading = false;
         }, 1000);
      });
   },
   checkItem(item) {
    
    const { nationalId, appId,selected} = item;
    const outputObj = { nationalId, appId, selected};
      if (outputObj.selected) {
         const itemToAdd = { ...outputObj };
          delete itemToAdd.selected;
          this.selectedItems.push(itemToAdd);
      } else {
        const index = this.selectedItems.findIndex(
            (selectedItem) => selectedItem.nationalId === outputObj.nationalId &&
                selectedItem.appId === outputObj.appId
            );
        if (index !== -1) {
          this.selectedItems.splice(index, 1);
        }
      }
    },
    exportToPrivateUniversity(){
        const data = {
            studentData:this.selectedItems,
      }
        this.$http.post("/api/students_admission_apps/cpnu_service/", {'studentData':this.selectedItems}).then(
                res => {
                    if(res.status==200){
                        this.$emit("changeTabRequest", "tab-2"); 

                    }
          
                },
                err => {
                   this.showToasted("error",this.serverTranslate(err.response.data.message));
                }
            );
    },
 
    toggleSelectAll() {
            const isChecked = this.selectAll;
            if (isChecked) {
                const keysToKeep = ['nationalId', 'appId'];
               const listAppPaging= this.applications
                 listAppPaging.forEach((app) => {
                  const filteredApp = {};
                    keysToKeep.forEach((key) => {
                        filteredApp[key] = app[key];
                     });
                    this.selectedItems.push(filteredApp);
                  });

                            
            } else {
                    this.selectedItems = [];
                }

            this.applications.forEach((item) => {
                item.selected = isChecked;
            });
       },


   searchedData (){

        this.loading = true;
        const { sortBy, sortDesc, page, itemsPerPage } = this.appOptions
        const data = {
            page: page,
            pageSize: itemsPerPage
        }
        if (sortBy.length === 1 && sortDesc.length === 1) {
        data.orderType = sortDesc[0] ? "asc" : "desc";
        data.orderBy = sortBy[0]
        }
        return data
        },

   searchAppliactions (resetPageNum=false) {
      if (resetPageNum) {
         this.appOptions.page = 1;
         this.appOptions.sortBy = [];
         this.appOptions.sortDesc = [];
      }
      this.initializeList()
      .then(data => {
         this.applications = data.itemsWithSelected;
         this.total = data.total;
      });
   },


   
},
created() {
    EventBus.$on('onScopeChangedInChild', () => {
        this.searchAppliactions();
    });
  },

};
</script>
<style scoped>
.custom-title {
    font-size: 14px; 
    font-weight:bold
  }
</style>