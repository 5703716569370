<template>

  <div class="mb-8">
        <v-card class="mx-auto mb-3">
            <div id="filters">
                <template>
                <v-toolbar flat>
                    <v-toolbar-title>
                        <p class="mb-0">{{ $t("FEES_PAYMENT") }}</p>
                    </v-toolbar-title>
                    <v-spacer></v-spacer>
                </v-toolbar>
                <v-divider></v-divider>
                <v-form ref="form">
                    <v-container>
                        <v-row>
                            <v-col cols="12"  class="pr-0 my-0" xs="12" sm="12" md="6" lg="6">
                                <v-radio-group class="font-weight-bold" v-model="paidStatus" height="10" row>
                                    <v-radio  :label="$t('NOT_PAID')" :value="0"></v-radio>
                                    <v-radio  :label="$t('PAID')" :value="1"></v-radio>
                                </v-radio-group>
                            </v-col>
                            <v-col cols="12" class="pr-0 my-0" xs="12" sm="12" md="6" lg="6">
                                <v-radio-group class="font-weight-bold" v-model="searchWithId" height="10" row>
                                    <v-radio :label="$t('STUDENT_NAME')" value="1"></v-radio>
                                    <v-radio :label="$t('IDENTITY')" value="2"></v-radio>
                                </v-radio-group>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0"
                                :class="{'d-none':isSearchedWithName}"
                                cols="12" xs="12" sm="12" md="12" lg="12">
                            <v-text-field
                                v-model="identityNum"
                                counter="25"
                                :rules="$store.state.Rules.noSpecialLetter"
                                :label="$t('WithIDENTITYTEXT')"
                                outlined
                                ></v-text-field>
                            </v-col>

                            <v-col class="py-0"
                                :class="{'d-none':!isSearchedWithName}"
                                cols="12" xs="12" sm="12" md="12" lg="12">
                                <v-autocomplete
                                :items="students"
                                v-model="studentId"
                                item-value="id"
                                item-text="name"
                                outlined
                                :loading="searchIsLoading"
                                :search-input.sync="studentSearch"
                                :rules="$store.state.Rules.searchRules"
                                return-object
                                clearable
                                :label="$t('WithNAMETEXT')"
                                ></v-autocomplete>
                            </v-col>

                        </v-row>
                        <v-card-actions class="pa-0">
                            <v-container class="px-0">
                                <v-row>
                                    <v-col cols="auto" class="pa-1">

                                        <v-btn
                                        :small="!$vuetify.breakpoint.mdAndUp"
                                        :fab="!$vuetify.breakpoint.mdAndUp"
                                        color="primary"
                                        class="mx-2"
                                        @click="showScopeTreeFn()">
                                        <span v-if="$vuetify.breakpoint.mdAndUp">{{$t('SCOPE')}}</span>
                                        <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-navigation</v-icon>
                                        </v-btn>
                                    </v-col>

                                   <v-col cols="auto" class="pa-1">
                                <download-excel
                                    :fields="serverTranslateDictKeyName(jsonFields)"
                                    worksheet="Students Worksheet"
                                    name="StudentsApplicationsData.xls"
                                    :title="[$t('REPORT')]"
                                    :fetch="fetchExportedData"
                                >
                                <v-btn color="secondary"
                                    :small="!$vuetify.breakpoint.mdAndUp"
                                    :fab="!$vuetify.breakpoint.mdAndUp"
                                >
                                    <span v-if="$vuetify.breakpoint.mdAndUp">{{$t('EXPORT')}}</span>
                                    <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-cloud-download</v-icon>
                                </v-btn>
                                </download-excel>
                            </v-col>


                                    <v-spacer></v-spacer>
                                    <v-col cols="auto" class="pa-1">
                                        <v-btn 
                                            :small="!$vuetify.breakpoint.mdAndUp"
                                            :fab="!$vuetify.breakpoint.mdAndUp"
                                            color="primary" dark @click="searchAppliactions"
                                        >
                                        <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t("SEARCH") }}</span>
                                        <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-account-search</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col class="pa-1 mx-2" cols="auto">
                                        <v-btn
                                            :small="!$vuetify.breakpoint.mdAndUp"
                                            :fab="!$vuetify.breakpoint.mdAndUp"
                                            color="darken3"
                                            class="white--text"
                                            @click="reset"
                                        >
                                        <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t("RESET") }}</span>
                                        <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-delete-empty</v-icon>
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-card-actions>
                    </v-container>
                </v-form>
                </template>
            </div>
        </v-card>


        <v-card class="mx-auto">
            <v-data-table
                :headers="headers"
                :items="serverTranslateArrayKeys(applications,['studentName','facultyName','appStatus'])"
                class="elevation-1"
                :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-arrow-collapse-left',
                    lastIcon: 'mdi-arrow-collapse-right',
                    prevIcon: 'mdi-arrow-left',
                    nextIcon: 'mdi-arrow-right',
                    itemsPerPageOptions: [5, 10, 15, 50, 100]
                }"
                :items-per-page="10"
                :loading="loading"
                :options.sync="appOptions"
                :server-items-length="total"
                @click:row="viewApplication"
                :hide-default-footer="applications.length > 0 ? false : true"
            >
              <template v-slot:item.appStatus="{ item }">
                  {{ item.appStatus >= 0 ?$t("CASES")[item.appStatus] : "" }}
              </template>
            <template v-slot:item.actions="{ item }">
                <template v-if="item.payment !==1 || item.depositImage=='' || !item.depositImage">
                    <v-tooltip top>
                        <template  v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon meduim color="primary" @click="feesPayment(item)">mdi-cash-multiple</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t("PAY_FEES",{
                           title:$t("PAY")
                        }) }}</span>
                    </v-tooltip>
                </template>

                <template v-else>
                    <v-tooltip top>
                        <template  v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon meduim color="primary" @click="editFeesPayment(item)">mdi-eye</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t("PAY_FEES",{
                           title:$t("SHOW")
                        }) }} </span>
                    </v-tooltip>
                    <v-tooltip top v-if="item.activeFaculty == 1 && item.activeBranch == 1">
                        <template  v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                                <v-icon meduim color="delete" @click="deleteFeesPayment(item)">mdi-delete</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ $t("PAY_FEES",{
                           title:$t("DELETE")
                        }) }}</span>
                    </v-tooltip>
                </template>
            </template>
            <template v-slot:no-data>
                <v-alert icon="warning" class="mt-2" color="light5">
                {{ $t("NO_DATA") }}
                </v-alert>
            </template>
            <template v-slot:top>
                <v-toolbar flat>
                <v-toolbar-title :class="!$vuetify.breakpoint.mdAndUp?'text-thin':''"> {{ title }}</v-toolbar-title>
                </v-toolbar>
            </template>
            <template v-slot:footer.page-text="props">
                {{ props.pageStart }} - {{ props.pageStop }} {{$t('PAGE_TEXT')}} {{ props.itemsLength }}
            </template>
            </v-data-table>
        </v-card>
        <pr-confirmDialog ref="confirm"></pr-confirmDialog>
        <pr-scope :key="componentKey" v-if="showScopeTree == true" v-on:setScope="onScopeChanged" :scopeFullPath="false"/>
        <pr-pay-dialog ref="pay" 
        :key="payKey" v-if="dialog" 
        :item="applicationItem"
        v-on:searchAppliactions="searchAppliactions(true)"
        ></pr-pay-dialog>
    </div>
</template>

<script>
import serverTranslations from "@/mixins/serverTranslations";
import globalMethods from "@/mixins/globalMethods";
import scope from "@/components/shared/ScopeTree.vue"
import PayDialog from "./PayDialog";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "pr-fees-payment",
  components:{
        "pr-scope":scope,
        "pr-pay-dialog": PayDialog,
        "pr-confirmDialog": ConfirmDialog
  },
  mixins: [serverTranslations,globalMethods],
    mounted() {
        this.searchAppliactions(true);
    },
    data() {
        return {
            paidStatus: 0,
            dialog: false,
            loading: false,
            studentSearch:"",
            searchWithId:"1",
            searchIsLoading:false,
            students:[],
            applications:[],
            identityNum: "",
            total:0 ,
            appOptions:{},
            studentId:"",
            showScopeTree:false,
            componentKey:0,
            payKey: 0,
            applicationItem: {},
        };

    },
    watch:{
        searchWithId(){
            this.studentId = null;
            this.students = [];
            this.studentSearch = "";
            this.identityNum = "";
        },
        appOptions: {
            handler () {
            this.searchAppliactions();
            },
        deep: true,
        },
        studentSearch(val){
            if(!val || val.length < 3) return ;
            if (this.searchIsLoading) return;
            const phraseLang = this.isRTL(val) ? "ar" : "en"
            this.searchIsLoading = true;
            const data = {
                searchData:val,semesterId:this.activeAcademicSemester.id,
                yearId:this.activeAcademicYear.id,paidStatus:this.paidStatus
            }
            this.$http.post("/api/students_admission_apps/search_paid_unpaid_student/",data)
                .then(
                    res=>{

                        res = res.data.data;
                        this.students = res.students.map(item=> { return {id:item.studentId , name:this.serverTranslateCustom(item.studentName, phraseLang)} });
                    },
                    err => {
                        this.showToasted("error",err.response.data.message)
                    }
                )
                .then(() => (this.searchIsLoading = false));

        },
    },
    computed: {
     secondTitle() {
            const facultyName = this.$store.state.Auth.facultyName
            ? " / " + this.serverTranslate(this.$store.state.Auth.facultyName) : '';
            const branchName = this.$store.state.Auth.branchName
            ? " / " + this.serverTranslate(this.$store.state.Auth.branchName) : '';
            const scopeFullPath = this.serverTranslate(this.$store.state.scopeName) + facultyName + branchName;
            return scopeFullPath;
        },
      jsonFields() {
            const fields = {
                "اسم الطالب|Student Name": 'studentName',
                'الرقم القومى|identity': 'identityNumber',
                'الكلية|Faculty': 'facultyName',
                'رقم الطلب|application Id': 'applyId',
                'حالة الطلب|application status':'applicationStatus',
                'تاريخ الدفع|payment date':'paymentDate',
                'وقت الدفع|paymenttime':'paymentTime',
                'الرقم المرجعى|payment refrence number':'paymentRefNum'
            }
            const scopeLength = (this.secondTitle.match(/\//g) || []).length;
            if (scopeLength == 2) {
                delete fields['الكلية|Faculty'];
                delete fields['الفرع|Branch'];
            } else if (scopeLength == 1) {
                delete fields['الكلية|Faculty'];
            }
            return fields;
        },
            isSearchedWithName(){
                return this.searchWithId === "1";
            },
            activeAcademicYear() {
                return this.$store.getters.getActiveAcademicYear;
            },
            activeAcademicSemester() {
                return this.$store.getters.getActiveAcademicSemester;
            },
            title(){
                return this.$t('APPLICATIONS_LIST_TITLE' ,{
                    year: this.activeAcademicYear.name,
                    semester: this.serverTranslate(this.activeAcademicSemester.name)
                });
            },
            headers() {
                return [
                    {
                        text: this.$t("STUDENT_NAME"),
                        align: "center",
                        sortable: true,
                        value: "studentName"
                    },
                    {
                        text: this.$t("IDENTITY"),
                        align: "center",
                        sortable: false,
                        value: "identityNumber"
                    },
                    {
                        text: this.$t("FACULTY"),
                        align: "center",
                        sortable: true,
                        value: "facultyName"
                    },
                    {
                        text: this.$t("APPLICATION_NUM"),
                        align: "center",
                        sortable: true,
                        value: "applyId"
                    }, {
                        text: this.$t("APPLICATION_CASES"),
                        align: "center",
                        sortable: true,
                        value:"appStatus"

                    },
                    {
                        text: this.$t("ADMISSION_DATA.STEP4.PAIDDATE"),
                        align: "center",
                        sortable: true,
                        value:"paymentDate"

                    },
                    {
                        text: this.$t("ADMISSION_DATA.STEP4.PAYMENT_TIME"),
                        align: "center",
                        sortable: true,
                        value:"paymentTime"

                    },{
                        text: this.$t("ADMISSION_DATA.STEP4.REFERENCENUM"),
                        align: "center",
                        sortable: true,
                        value:"paymentRefNum"
                    },
                    // {
                    //     text: this.$t("ACTIONS"),
                    //     value: "actions",
                    //     sortable: false,
                    //     width: "150px"
                    // }
                ];
            }
    },
    methods: {
    async fetchExportedData() {
      const data =this.searchedData();
      data.pageSize=-1
            const response = await this.$http.post("/api/students_admission_apps/list_paid_unpaid_student/",data)
            const exportedData = this.serverTranslateArrayKeys(response.data.data.results,
                ['studentName','identityNumber','facultyName','applyId','appStatus'])
            if (response.status !== 200) return this.showToasted("error",this.$t("NOT_FOUND_SERVER_ERROR"))
            return exportedData;

        },
        showScopeTreeFn(){
            this.showScopeTree = true;
            this.componentKey +=1;
        },
        initializeList() {
            return new Promise((resolve, reject) => {
            const data =this.searchedData();
            clearTimeout(this.timer);
            this.timer = setTimeout(() => {
                this.$http
                .post("/api/students_admission_apps/list_paid_unpaid_student/", data)
                .then(
                res=>{
                    this.applications= [];
                    const items = res.data.data.results;
                    const total = res.data.data.total;
                    resolve({
                    items,
                    total,
                    });
                },
                err => {
                    this.showToasted("error",this.serverTranslate(err.response.data.message));
                    this.applications= [];
                }
            );
                this.loading = false;
            }, 1000);
        });
        },

        searchedData (){
            this.loading = true;
            const { sortBy, sortDesc, page, itemsPerPage } = this.appOptions
            const data = {
                studentId: !this.studentId || !this.studentId.id ? "" :this.studentId.id,
                yearId: this.activeAcademicYear.id,
                semesterId: this.activeAcademicSemester.id,
                searchText:this.identityNum || "",
                page: page,
                pageSize: itemsPerPage,
                paidStatus:this.paidStatus
            }
            if (sortBy.length === 1 && sortDesc.length === 1) {
                data.orderType = sortDesc[0] ? "asc" : "desc";
                data.orderBy = sortBy[0]
            }
            return data
        },
        searchAppliactions (resetPageNum=false) {
            if (resetPageNum) {
                this.appOptions.page = 1;
                this.appOptions.sortBy = [];
                this.appOptions.sortDesc = [];
            }
            this.initializeList()
            .then(data => {
                this.applications = data.items;
                this.total = data.total;
            });
        },
        reset() {
            this.studentId = null;
            this.students = [];
            this.identityNum = "";
            this.studentSearch = "";
            this.searchAppliactions(true);
        },
        onScopeChanged(changed) {
            if (changed) this.searchAppliactions(true);
        },
        feesPayment(item) {
            this.applicationItem = item;
            this.payKey += 1;
            this.dialog = true;
            item.yearId = this.activeAcademicYear.id;
            item.semesterId = this.activeAcademicSemester.id;
        },
        paidCase(value) {
            this.paidStatus = value;
            this.reset();
        },
        editFeesPayment(item){
            item.edit = 'view';
            item.applicationId = item.applyId
            this.feesPayment(item);
        },
        deleteFeesPayment(item) {
            const data = {
                applicationId:item.applyId,
                studentId:item.studentId,
                yearId: this.activeAcademicYear.id,
                semesterId: this.activeAcademicSemester.id
            }
            const payConfirm = this.$t("DELETE_FEE_CONFIRM",
                {
                    applicationId:item.applyId,
                    studentName:this.serverTranslate(item.studentName),
                    idCard:item.identityNumber
                });
            this.$refs.confirm
            .open("CONFIRMATION_MESSAGE", payConfirm,{width:"500px"})
            .then(confirm => {
                if (confirm) {
                    this.$http
                    .delete("/api/students_admission_apps/delete_paid_fees/", {data})
                    .then(
                        res=>{
                            this.showToasted("success",this.serverTranslate(res.data.data.message));
                            this.searchAppliactions(true);
                        },
                        err => {
                            this.showToasted("error",this.serverTranslate(err.response.data.message));
                            this.searchAppliactions(true);
                        }
                    );
                }
            });
        }
    }
};
</script>