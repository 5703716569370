<template>
  <v-content class="background-img">
    <v-container fill-height>
      <v-layout align-space-between justify-center fill-height>
        <v-card :width="$vuetify.breakpoint.mdAndUp ? '50%' : '100%'">
          <v-card-title  :style="backgroundColor">
            <v-list-item class="justify-center">
              <v-avatar size="130">
                <img :src="scopeLogo" />
              </v-avatar>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <!-- <img
                    class="logo-img"
                    src="@/assets/images/logoAr-secondary.png"
                    v-if="$store.state.direction"
                  />
                  <img class="logo-img" src="@/assets/images/logoEn-secondary.png" v-else /> -->
              <div
                :class="[
                  $vuetify.breakpoint.mdAndUp
                    ? 'head1-size '
                    : 'head1-sm-size ',
                  lang == 'ar' ? ' headAr ' : ' headEn '
                ]"
              >
                {{ serverTranslate(systemName) }}
              </div>
            </v-list-item>
            <v-list-item class="d-flex justify-center">
              <div
                :class="[
                  $vuetify.breakpoint.mdAndUp
                    ? 'head2-size '
                    : 'head2-sm-size ',
                  lang == 'ar' ? ' headAr ' : ' headEn '
                ]"
              >
                {{ serverTranslate(scopeName) }}
              </div>
            </v-list-item>
          </v-card-title>
          <v-card-content>
            <v-form ref="form" @submit="login" class="mx-7">
              <v-text-field
                autofocus
                maxlength="70"
                :rules="usernameRules"
                v-model.trim="userData.username"
                :placeholder="$t('USERNAME')"
                required
                prepend-inner-icon="person"
                color="secondary"
                @keyup.enter.native="login"
              ></v-text-field>
              <v-text-field
                class="mb-7"
                maxlength="20"
                :rules="passwordRules"
                v-model="userData.password"
                :placeholder="$t('PASSWORD')"
                type="password"
                required
                prepend-inner-icon="lock"
                color="secondary"
                @keyup.enter.native="login"
              ></v-text-field>
              <pr-alerts
                :error="serverTranslate(error)"
                :snackbar="snackbar"
              ></pr-alerts>
            </v-form>
          </v-card-content>
          <v-card-actions class="justify-space-around row align-center">
            <v-btn class="white--text secondary title " @click="login">{{
              $t("LOGIN")
            }}</v-btn>
          </v-card-actions>
          <v-card-actions class="justify-space-around row align-center">
            <v-btn
              small
              text
              class="white--text secondary px-0 py-0"
              @click="forgotPassword"
              >{{ $t("FORGOT_PASSWORD") }}</v-btn
            >
            <pr-changeLanguage
              showTooltip="false"
              v-on:swichLanguage="onLanguageChanged"
            />
          </v-card-actions>
        </v-card>
        <pr-forgotPassword ref="forgotPassword"></pr-forgotPassword>
      </v-layout>
    </v-container>
  </v-content>
</template>
<script>
import ChangeLanguage from "@/components/layout/ChangeLanguage";
import Alerts from "@/components/layout/Alerts";
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import ForgotPassword from "@/components/LoginComponent/ForgotPassword";
export default {
  components: {
    "pr-changeLanguage": ChangeLanguage,
    "pr-alerts": Alerts,
    "pr-forgotPassword": ForgotPassword
  },
  mixins: [serverTranslationsMixin, globalMethodsMixin],
  mounted() {
    this.$refs.form.resetValidation();
  },
  data() {
    return {
      direction: this.$store.state.direction,
      backgroundColor: "",
      valid: true,
      error: "",
      snackbar: false,
      usernameRules: [
        v => v.length <= 70 || this.$t("USERNAME_MAX"),
        v => !!v || this.$t("USERNAME_REQUIRED")
      ],
      passwordRules: [
        v => v.length <= 20 || this.$t("PASSWORD_MAX"),
        v => !!v || this.$t("PASSWORD_REQUIRED")
      ],
      inset: false,
      userData: {
        username: "",
        password: ""
      },
      lang: "ar"
    };
  },
  computed: {
    scopeLogo() {
      return this.$store.state.scopeLogo;
    },
    scopeName() {
      return this.$store.state.scopeName;
    },
    systemName() {
      return this.$store.state.systemName;
    },

  },
  created() {
    this.lang = localStorage.lang;
    this.getUniversityData();
  },
  methods: {
    getUniversityData() {
      this.$http.get("/api/users/get_login_data/").then(res => {
        localStorage.setItem("scopeName", res.data.scopeName);
        localStorage.setItem("scopeLogo", res.data.scopeLogo);
        localStorage.setItem("systemLogo", res.data.systemLogo);
        localStorage.setItem("scopeID", res.data.scopeID);
        localStorage.setItem("systemName", res.data.systemName);
        //localStorage.setItem("backgroundColor", res.data.backgroundColor);
        this.backgroundColor = res.data.backgroundColor;
        this.backgroundColor = this.backgroundColor?"background-color:"+this.backgroundColor:"background-color:#00AFB9"
        this.$store.commit("setUniversity", res.data);
        this.setTitleAndLogo(res.data);
      });
    },
    setTitleAndLogo(data) {
      const title = `${this.serverTranslate(
        data.systemName
      )} ${this.serverTranslate(data.scopeName)}`;
      document.title = this.serverTranslate(title);
    },
    login() {
      if (this.$refs.form.validate()) {
        this.$http.post("/api/users/login", this.userData).then(
          response => {
            if (response) {
              this.$store.commit("login", {
                token: response.headers.token,
                userName: response.data.username,
                message: response.data.message
              });
              this.showToasted("success", response.data.message);
              this.$router.push("/home");
            }
          },
          err => {
            this.showToasted("error", err.response.data.message);
          }
        );
      }
    },
    onLanguageChanged: function(direction) {
      this.lang = localStorage.lang;
      this.scopeName = this.serverTranslate(this.$store.state.scopeName);
      this.systemName = this.serverTranslate(this.$store.state.systemName);
      if (this.$refs.form && this.$el) {
        if (this.$el.querySelector(".error--text")) {
          this.$refs.form.validate();
        }
      }
      this.direction = direction;
    },
    forgotPassword: function() {
      this.$refs.forgotPassword.open();
    }
  }
};
</script>
