<template>
  <div class="white justify-center printAppPage">
    <v-row class="justify-center d-print-none" no-gutters>
      <v-col cols="10" class="d-flex justify-end">
        <v-btn color="success" @click="print()">{{ $t("PRINT") }}</v-btn>
        <v-btn
          class="mx-2"
          color="error"
          @click="
            $router.push({
              name: 'ManageApplicationsList'
            })
          "
        >
          {{ $t("BACK") }}
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="justify-center" no-gutters>
      <v-col cols="10" class="d-flex flex-column justify-center">
        <h3>{{ serverTranslate(universityName) }}</h3>
        <h3>{{ serverTranslate(applicationFaculty) }} </h3>
      </v-col>
    </v-row>
    <v-row class="justify-center">
      <v-col cols="12" class="text-center py-2">
        <h3>
          {{
            $t("APPLICATION_TITLE", {
              appYear,
              appSemeter: serverTranslate(appSemeter)
            })
          }}
        </h3>
      </v-col>
      <v-col cols="10" class="d-inline-block my-1 py-2 border-radius-5">
        <h3 class="my-0 text-center">{{ $t("UserData.PERSONAL_DATA") }}</h3>
        <v-row no-gutters >
          <v-col cols="12" lg="6" md="12" sm="12">
            <v-row no-gutters>
              <v-col cols="4" class="py-0">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.AR_NAME") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{ arName }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.EN_NAME") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{ enName }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" lg="6" md="12" sm="12">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.NATIONALITY") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{ serverTranslate(nationality) }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong>
                    {{
                      cardType === 1
                        ? $t("UserData.NATIONAL_ID")
                        : $t("UserData.PASSPORT")
                    }}:
                  </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{ cardID }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters>
          <v-col cols="12" lg="6" md="12" sm="12">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.GENDER") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{
                    gender === 13 ? $t("UserData.MALE") : $t("UserData.FEMALE")
                  }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.BIRTHDAY") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{ birthdate }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="10" class="d-inline-block my-1 py-2 border-radius-5">
        <h3 class="mt-0 text-center">{{ $t("UserData.CONTACT") }}</h3>

        <v-row no-gutters >
          <v-col cols="12" lg="6" md="12" sm="12">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.PHONE") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  <bdi>{{ telephone }}</bdi>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.MOBILE") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  <bdi>{{ mobile }}</bdi>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-row no-gutters >
          <v-col cols="12" xl="6" lg="6" md="12" sm="12">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.ADDRESS") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{ address }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="12" sm="12">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.EMAIL") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{ email }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="10" class="d-inline-block my-1 py-2 border-radius-5">
        <h3 class="mt-0 text-center">{{ $t("UserData.CERTIFICATE") }}</h3>

        <v-row no-gutters >
          <v-col cols="12" lg="6" md="12" sm="12" class="py-0">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.CERTIFICATE_TYPE") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-card class="pa-1 elevation-0">
                  {{ serverTranslate(certificateTypes) }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="12" sm="12" class="py-0">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.CERTIFICATE_DEPT") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-card class="pa-1 elevation-0">
                  {{ serverTranslate(divisionType) }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            lg="6"
            md="12"
            sm="12"
            v-if="!!equationCertificateTypes"
          >
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.CERTIFICATE_EQ_TYPE") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{ serverTranslate(equationCertificateTypes) }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            class="py-0"
            cols="12"
            lg="6"
            md="12"
            sm="12"
            v-if="!!foreignCertificate && !isAnotherForeignCertificate"
          >
            <v-row no-gutters>
              <v-col cols="4" class="py-0">
                <v-card class="pa-1 elevation-0">
                  <strong>
                    {{ $t("UserData.CERTIFICATE_FOREIGN_TYPE") }}:
                  </strong>
                </v-card>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-card class="pa-1 elevation-0">
                  {{ serverTranslate(foreignCertificate) }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            md="12"
            sm="12"
            class="py-0"
            v-if="!!otherCertificateText"
          >
            <v-row no-gutters>
              <v-col cols="4" class="py-0">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.CERTIFICATE_OTHER") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{ otherCertificateText }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="12" sm="12" class="py-0">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.CERTIFICATE_YEAR") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8" class="py-0">
                <v-card class="pa-1 elevation-0">
                  {{ preQualYear }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="12" sm="12" class="py-0">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.CERTIFICATE_RESULT") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{ preQualTotal }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="12" lg="6" md="12" sm="12" class="py-0">
            <v-row no-gutters>
              <v-col cols="4">
                <v-card class="pa-1 elevation-0">
                  <strong> {{ $t("UserData.COUNTRY") }}: </strong>
                </v-card>
              </v-col>
              <v-col cols="8">
                <v-card class="pa-1 elevation-0">
                  {{ serverTranslate(preQualCountry) }}
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="justify-center" no-gutters>
      <v-col cols="10" class="pb-0">
        <h3>{{ $t("IMPORTANT_NOTES") }}</h3>
        <p class="mb-0">{{ $t("IMPORTANT_NOTES_TEXT") }}</p>
      </v-col>
    </v-row>
    <!--<v-row class="justify-center" no-gutters>-->
      <!--<v-col cols="10" class="pb-0">-->
        <!--<h3>{{ $t("ACKNOWLEDGMENT") }}</h3>-->
        <!--<p class="mb-0">{{ $t("ADMISSION_DATA.TERMS") }}</p>-->
      <!--</v-col>-->
    <!--</v-row>-->
  </div>
</template>

<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";

export default {
  mixins: [serverTranslationsMixin],
  created() {
    this.getApplicationData();
  },
  computed: {
    IsArabic() {
      return localStorage.lang === "ar";
    },
    address() {
      const Country = this.serverTranslate(this.addressCountry);
      const State = this.serverTranslate(this.addressState);
      const City = this.serverTranslate(this.addressCity);
      return `${Country} / ${State} / ${City}`;
    }
  },
  data() {
    return {
      isAnotherForeignCertificate: false,
      dialog: false,

      arName: "",
      enName: "",
      profilePicture: null,
      universityName: "",
      applicationFaculty: "",
      appSemeter: "",
      appYear: "",
      nationality: "",
      gender: "",
      telephone: "",
      birthdate: "",
      mobile: "",
      cardID: "",
      cardType: "",
      email: "",
      addressCountry: "",
      addressState: "",
      addressCity: "",
      preQualCountry: "",
      preQualYear: "",
      certificateTypes: "",
      equationCertificateTypes: "",
      divisionType: "",
      foreignCertificate: "",
      otherCertificateText: "",
      preQualTotal: "",
      branch: ""
    };
  },
  methods: {
    print() {
      window.print();
    },
    getApplicationData() {
      const applicationId = this.$route.params.id;
      return this.$http
        .post("/api/students_admission_apps/application_details/", {
          applyId:applicationId
        })
        .then(response => {
          const responseData = response.data.data.application_details;
          this.mapApplicationData(responseData);
        });
    },
    mapApplicationData(data) {
      Object.keys(data).forEach(key => {
        this.$data[key] = data[key] || "";
      });

      this.isAnotherForeignCertificate = this.foreignCertificate.id
        ? this.foreignCertificate.id === 32
        : false;

      this.applicationFaculty = this.applicationFaculty.name || "";
      this.appSemeter = this.appSemeter.name || "";
      this.appYear = this.appYear.name
        ? this.appYear.name.replace("|", " - ")
        : "";
      this.nationality = this.nationality.name || "";

      const gender = parseInt(this.gender.id);
      this.gender =  parseInt(this.gender.id) ? ([13, 14].includes(gender) ? gender : 13) : 13;

      const cardType = parseInt(this.cardType.id);
      this.cardType = cardType ? ([1, 2].includes(cardType) ? cardType : 1) : 1;

      this.addressCountry = this.addressCountry.name || "";
      this.addressState = this.addressState.name || "";
      this.addressCity = this.addressCity.name || "";
      this.preQualCountry = this.preQualCountry.name || "";
      this.preQualYear = this.preQualYear.name
        ? this.preQualYear.name.replace("|", " - ")
        : "";
      this.certificateTypes = this.certificateTypes.name || "";
      this.equationCertificateTypes = this.equationCertificateTypes.name || "";
      this.divisionType = this.divisionType.name || "";
      this.foreignCertificate = this.foreignCertificate.name || "";
      this.branch = this.branch.name || "";
    }
  }
};
</script>
<style>
@media print {
  .v-content {
    padding: 0 !important;
  }
}
</style>
