<template>
    <v-card class="mx-auto" outlined>
        <div id="inspire">
            <v-data-table
            :headers="headers"
            :items="serverTranslateArrayKeys(listAcceptedRejected,['studentName'])"
            sort-by="name"
            class="elevation-1"
            :footer-props="{
              showFirstLastPage: true,
              firstIcon: 'mdi-arrow-collapse-left',
              lastIcon: 'mdi-arrow-collapse-right',
              prevIcon: 'mdi-arrow-left',
              nextIcon: 'mdi-arrow-right',
              itemsPerPageOptions: [5, 10, 15, 50, 100]
            }"
            :items-per-page="15"
            :hide-default-footer="listAcceptedRejected.length <= 0"
          >
</v-data-table>
            </div>
    </v-card>

</template>
<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import { EventBus } from "@/plugins/bus";

export default {
    mixins: [serverTranslationsMixin,globalMethodsMixin],
    data() {
    return {
        listAcceptedRejected:[]
    };
},
  computed: {
    headers: function() {
        return [
            {
               text: this.$t("STUDENT_NAME"),
               align: "center",
               sortable: true,
               value: "studentName"
            },
            {
               text: this.$t("IDENTITY"),
               align: "center",
               sortable: false,
               value: "studentNID"
            },
            {
               text: this.$t("STATUS"),
               align: "center",
               sortable: true,
               value: "status"
            },
             
            {
               text: this.$t("REASON"),
               align: "center",
               sortable: true,
               value: "message"
            },
           
         ];
    }
  },
  
  created() {
    this.initialize();
      EventBus.$on("updateRunListAcceptedRejectedAfterAction", ()=> {
        this.initialize();
      });
  },  
  methods: {
    initialize() {
      this.$http.post("/api/students_admission_apps/cpnu_list/").then(res => {
          this.listAcceptedRejected = res.data.data;
        },
        err => {
          this.showToasted("error",err.response.data.message);
        }
      ); 
  },
}

}

</script>