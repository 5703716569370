export enum CATEGORY_MESSAGES_STUDENTS_IDS {
    BASIC = 8,
    ACCEPTED = 60,
    REFUSED = 61,
    SENDING_APPLICATION=62,
    SENDING_NOTIFICATIONS=63
  }
  export enum APPLICATION_STATUS{
    BASIC = "6", 
    SENT_UNDER_REVIEW="1",
    ACCEPTED="2",
    REFUSED="3",
    FEEDBACK="4",
    PENDING_FOR_FEES="7",
    PULLED_CREDIT_SYSTEM="8",
    WAITING_LIST="10"
  }