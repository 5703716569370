import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import Axios from "axios";
import store from "./store";
import Toasted from "vue-toasted";
import "./registerServiceWorker";
import serverTranslationsMixin from "@/mixins/serverTranslations";
import Loading from "vue-loading-overlay";
import "vue-loading-overlay/dist/vue-loading.css";
import JsonExcel from 'vue-json-excel';

Vue.config.productionTip = false;
Vue.config.silent = true;
Axios.defaults.xsrfCookieName = "csrftoken";
Axios.defaults.xsrfHeaderName = "X-CSRFToken";
Axios.defaults.withCredentials = true;
Vue.prototype.$http = Axios;

Vue.use(Toasted);
Vue.use(Loading);
Vue.component('downloadExcel', JsonExcel)

let loader;
Axios.interceptors.request.use(
  config => {
    if (loader) {
      loader.hide();
      loader.opacity = 0;
      loader.zIndex = -1;
    }
    loader = Vue.prototype.$loading.show({
      color: "#00AFB9",
      loader: "spinner",
      width: 64,
      height: 64,
      backgroundColor: '#000000',
      opacity: 0.5,
      zIndex: 99
    });

    const token = store.getters.getToken;
    const universityID = store.getters.getAllScopeLevel.universityID;
    const facultyID = store.getters.getAllScopeLevel.facultyID;
    const branchID = store.getters.getAllScopeLevel.branchID;
    if (token) {
      config.headers["token"] = `${token}`;
      config.headers["universityID"] = `${universityID}`;
      config.headers["facultyID"] = `${facultyID}`;
      config.headers["branchID"] = `${branchID}`;
    }
    return config;
  },
  error => {
    if (loader) {
      loader.hide();
      loader.opacity = 0;
      loader.zIndex = -1;
    }
    Vue.toasted.show(
      serverTranslationsMixin.methods.serverTranslate(
        "خطأ، برجاء الإتصال بالدعم الفني|Error, please call customer service"
      ),
      {
        type: "error",
        icon: "error_outline",
        position: "bottom-left",
        duration: 5000
      }
    );
    return Promise.reject(error);
  }
);

Axios.interceptors.response.use(
  res => {
    if (loader) {
      loader.hide();
      loader.opacity = 0;
      loader.zIndex = -1;
    }
    store.commit("refreshToken", {
      token: res.headers.token
    });
    return res;
  },
  errors => {
    if (loader) {
      loader.hide();
      loader.opacity = 0;
      loader.zIndex = -1;
    }
    if (errors.response.status === 401) {
      store.commit("logout");
      Vue.toasted.show(
        serverTranslationsMixin.methods.serverTranslate(
          errors.response.data.message
        ),
        {
          type: "error",
          icon: "error_outline",
          position: "bottom-left",
          duration: 5000
        }
      );
    } else if (
      errors.response.status !== 401 &&
      !errors.response.data.message
    ) {
      Vue.toasted.show(
        serverTranslationsMixin.methods.serverTranslate(
          "خطأ، برجاء الإتصال بالدعم الفني|Error, please call customer service"
        ),
        {
          type: "error",
          icon: "error_outline",
          position: "bottom-left",
          theme: "bubble",
          duration: 5000
        }
      );
    } else {
      return Promise.reject(errors);
    }
  }
);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount("#app");
