export const NATIONAL_ID_REGEX = new RegExp(
  /^(2|3)[0-9][0-9](0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1])(01|02|03|04|11|12|13|14|15|16|17|18|19|21|22|23|24|25|26|27|28|29|31|32|33|34|35|88)\d{5}$/
);

export const NON_DIGITS_REGEX = new RegExp(/[^\d]/g);

export const CONTAIN_SIGNS_REGEX = new RegExp(/(^|\s)[+-]/g);

export const SYMBOLS_REGEX = new RegExp(/[^\u0621-\u064Aa-zA-Z\s\d]/g);

export const ONLY_LETTERS_REGEX = new RegExp(/^[\u0621-\u064Aa-zA-Z-_\s]*$/);
export const ONLY_LETTERS_AND_NUMBERS_REGEX = new RegExp(
  /^[\u0621-\u064Aa-zA-Z0-9-_\s]*$/
);
export const ONLY_ENGLISH_LETTERS_AND_NUMBERS_REGEX = new RegExp(
  /^[a-zA-Z0-9-_\s]*$/
);

export const ONLY_ARABIC_REGEX = new RegExp(/^[\u0621-\u064A\s]+$/);
export const ONLY_ENGLISH_REGEX = new RegExp(/^[a-zA-Z\s]+$/);

export const DATE_REGEX = new RegExp(
  /^(19[0-9]{2}|2[0-9]{3})-(0?[1-9]|1[012])-([123]0|[012]?[1-9]|31)$/g
);
export const ISO_DATE_REGEX = new RegExp(
  /^(19[0-9]{2}|2[0-9]{3})-(0[1-9]|1[012])-([123]0|[012][1-9]|31)$/g
);
export const ISO_DATE_TIME_REGEX = new RegExp(
  /^(19[0-9]{2}|2[0-9]{3})-(0[1-9]|1[012])-([123]0|[012][1-9]|31) ([01][0-9]|2[0-3]):([0-5][0-9]):([0-5][0-9])$/g
);

export const EMAIL_REGEX = new RegExp(
  /^[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[A-Za-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?\.)+[A-Za-z0-9](?:[A-Za-z0-9-]*[A-Za-z0-9])?$/
);

export const EXTRA_SPACES_REGEX = new RegExp(/^\s+|\s(?=\s+)|\s+$/g);
export const ONLY_NUMBERS_AND_SPACES_REGEX = new RegExp(
  /^( |\d)*(\d( |\d)*)*( |\d)*$/g
);
export const ONLY_NUMBERS_AND_SLASHES_REGEX = new RegExp(/^(\/|\d)*$/);
export const CHARACTERS_NUMBERS_SPACES_REGEX = new RegExp(/^(\w|\d|\s)*$/);

export const EG_PHONE_REGEX = new RegExp(/^0\d{9}$/);
export const EG_MOBILE_REGEX = new RegExp(/^(10|11|12|15)\d{8}$/);