<template>
  <div>
     <!-- side bar -->
     <!-- mini-variant-width="200"
     mini-variant -->
     <v-navigation-drawer
      v-model="drawer"
      app
      :right="direction"
      class="secondary light5--text"
     >
     <div>
        <router-link :to="{name:'home'}">
          <img :src="systemLogo" height="64vh" width="100%" />
        </router-link>
     </div>
      <v-expansion-panels
         v-model="activeExpansionPanel"
         active-class="activeModule"
         flat
         class="secondary light5--text"
         hover="true"
      >
         <v-expansion-panel
           class="secondary light5--text"
           v-for="(item,parentIndex) in menuData"
          :key="parentIndex"
          hover="true"
          >
          <v-expansion-panel-header>
             <span class="light6--text">
               <v-icon class="light6--text">{{ item.icon }}</v-icon>
               {{ serverTranslate(item.name) }}
             </span>
          </v-expansion-panel-header>
          <v-expansion-panel-content
          >
             <div class="light6" v-for="(childItem, i) in item.children" :key="i">
               <div v-if="childItem.children && childItem.children.length">
                 <v-expansion-panels>
                   <v-expansion-panel>
                     <v-expansion-panel-header>
                       <span class="secondary--text">
                         <v-icon class="secondary--text">{{ childItem.icon }}</v-icon>
                         {{ serverTranslate(childItem.name) }}
                       </span>
                     </v-expansion-panel-header>
                     <v-expansion-panel-content>
                       <p v-for="(grandChildItem, i) in childItem.children" :key="i">
                         <span>
                           <v-icon class="secondary--text">{{ grandChildItem.icon }}</v-icon>
                         </span>
                         <span class="secondary--text">{{ serverTranslate(grandChildItem.name) }}</span>
                       </p>
                     </v-expansion-panel-content>
                   </v-expansion-panel>
                 </v-expansion-panels>
               </div>
               <div
                 v-else
                 class="expansion-panel-single-items"
                 :class="($store.state.Scope.selectedApplicationId == childItem.id? 'activeApp darken3':'light6')"
                 @click="
                   openApplications(
                     childItem,
                     !$vuetify.breakpoint.lg,
                     item.id
                   )
                 "
               >
                 <span>
                   <v-icon
                   :class="($store.state.Scope.selectedApplicationId == childItem.id? 'white--text':'secondary--text')"
                   >{{ childItem.icon }}</v-icon>
                 </span>
                 <a
                 class="text-tiny secondary--text">{{ serverTranslate(childItem.name) }}</a>
               </div>
             </div>
          </v-expansion-panel-content>
          <v-divider></v-divider>
         </v-expansion-panel>
      </v-expansion-panels>
     </v-navigation-drawer>
     <!-- end side bar -->
    <v-app-bar
      :right="direction"
      color="white"
      app
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-avatar size="90" v-show="$vuetify.breakpoint.mdAndUp">
         <img :src="scopeLogo" />
      </v-avatar>
      <v-toolbar-title v-show="$vuetify.breakpoint.mdAndUp">
         <span class="body-1">{{serverTranslate(systemName)}}</span>
         <span class="body-2"> {{serverTranslate(scopeName)}}</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-show="$vuetify.breakpoint.mdAndUp">
         <v-btn text class="primary--text body-2" rounded v-if="direction">
           {{$t("ACADEMIC_SEMESTER")}} {{serverTranslate(activeAcademicSemester.attributeName)}}
        </v-btn>
        <v-btn text class="primary--text body-2" rounded v-else>
          {{serverTranslate(activeAcademicSemester.attributeName)}} {{$t("ACADEMIC_SEMESTER")}}
        </v-btn>
        <v-divider
        vertical
        inset
        ></v-divider>
        <v-btn text class="primary--text body-2" rounded>
            {{titleYear.attributeName}}
        </v-btn>
     </v-toolbar-items>
     <v-spacer></v-spacer>
     <pr-changeLanguage showTooltip="true" v-on:swichLanguage="onLanguageChanged" />
     <v-spacer></v-spacer>
     <v-menu>
        <template >
         <v-btn icon v-on="on">
           <v-tooltip bottom v-if="$vuetify.breakpoint.mdAndUp">
             <template v-slot:activator="{ on }">
                <v-badge
                  content=""
                  color="primary light6--text"
                  overlap
                >
                  <v-icon color="secondary" v-on="on">mdi-bell</v-icon>
                </v-badge>
             </template>
             <span>{{ $t("NOTIFICATIONS") }}</span>
           </v-tooltip>
           <v-badge
             content=""
             color="primary light6--text"
             overlap
             v-else
           >
             <v-icon color="secondary">mdi-bell</v-icon>
           </v-badge>
         </v-btn>
      </template>
     </v-menu>
      <v-menu>
         <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-tooltip bottom v-if="$vuetify.breakpoint.mdAndUp">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" class="spin">
                  <v-icon color="secondary">settings</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("ACCOUNT") }}</span>
            </v-tooltip>
            <v-btn icon v-else class="spin">
              <v-icon color="secondary">settings</v-icon>
            </v-btn>
          </v-btn>
        </template>
       <v-list>
          <v-list-item>
             <v-list-item-title>
                {{serverTranslate(userName)}}
             </v-list-item-title>
          </v-list-item>
          <v-list-item @click="changePassword"
          >
            <v-icon class="primary--text">mdi-key</v-icon> &nbsp;
            {{ $t("CHANGE_PASSWORD") }}
          </v-list-item>
          <v-list-item>
             <v-icon class="primary--text">mdi-help-circle-outline</v-icon> &nbsp;
             {{$t("HELP")}} 16541
          </v-list-item>
          <v-list-item
            @click="logout"
          >
            <v-icon class="primary--text">mdi-logout</v-icon> &nbsp;
            {{ $t("LOGOUT") }}
          </v-list-item>
       </v-list>
      </v-menu>
      <template v-slot:extension v-if="!$vuetify.breakpoint.mdAndUp">
         <v-toolbar-items>
             <v-btn text class="primary--text body-2" rounded v-if="direction">
               {{$t("ACADEMIC_SEMESTER")}} {{serverTranslate(activeAcademicSemester.attributeName)}}
            </v-btn>
            <v-btn text class="primary--text body-2" rounded v-else>
             {{serverTranslate(activeAcademicSemester.attributeName)}} {{$t("ACADEMIC_SEMESTER")}}
            </v-btn>
            <v-divider
            vertical
            inset
            ></v-divider>
            <v-btn text class="primary--text body-2" rounded>
               {{titleYear.attributeName}}
            </v-btn>
         </v-toolbar-items>
      </template>
    </v-app-bar>
    <pr-changePassword ref="changePassword"></pr-changePassword>
    <pr-scope-tree v-if="notAllowedScope" :key="componentKey" :scopeFullPath="false"/>
</div>
</template>
<script>
import ChangeLanguage from "@/components/layout/ChangeLanguage";
import ChangePassword from "@/components/layout/ChangePassword";
import serverTranslationsMixin from "@/mixins/serverTranslations";
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import ScopeTree from "@/components/shared/ScopeTree.vue";

export default {
  name: "pr-header",
  components: {
    "pr-changeLanguage": ChangeLanguage,
    "pr-scope-tree": ScopeTree,
    "pr-changePassword":ChangePassword
  },
  mixins: [serverTranslationsMixin,globalMethodsMixin],
  props: {
    source: String
  },
  created: function() {
    this.getHomeData();
  },

  data() {
    return {
      activeExpansionPanel:"",
      menuData: [],
      menu: false,
      academicYearTranslate: "ACADEMIC_YEAR",
      academicSemesterTranslate: "ACADEMIC_SEMESTER",
      titleYear: Object,
      titleSemester: Object,
      academicYears: [],
      activeAcademicYear: Object,
      activeAcademicSemester: Object,
      semesterDataArEn: [],
      applicationsArEn: [],
      userName: "",
      drawer: true,
      notAllowedScope:false,
      componentKey:1,
    };
  },
  computed: {
      direction: function() {
        return this.$store.state.direction;
      },
      scopeName() {
         return this.$store.state.scopeName
      },
      systemName() {
         return this.$store.state.systemName
      },
      scopeLogo() {
         return this.$store.state.scopeLogo
      },
    systemLogo() {
         return this.$store.state.systemLogo
      }
  },
  mounted() {
    if (localStorage.userName) {
      this.userName = localStorage.userName;
    }
  },
  watch: {
    direction: function() {
      this.menuData = this.serverTranslateArray(this.applicationsArEn, "name");
    }
  },
  methods: {
    resetToUniversityScope() {
      localStorage.removeItem("facultyName");
      localStorage.removeItem("facultyID");
      localStorage.removeItem("branchID");
      localStorage.removeItem("branchName");
      this.$store.state.Auth.facultyName = "";
      this.$store.state.Auth.branchName = "";
      this.$store.state.Auth.branchID = "";
      this.$store.state.Auth.facultyID = "";
    },
    changeUniversityScope(item) {
      localStorage.setItem("appLevels",JSON.stringify(item.appLevels));
      this.$store.state.Scope.appLevels = JSON.stringify(item.appLevels);
      const scopeLevel = localStorage.getItem("scopeLevel");
      if(scopeLevel && !item.appLevels.includes(parseInt(scopeLevel))) {
        this.showToasted("error",this.$store.state.Scope.scopeErrorMessage);
        this.notAllowedScope =true;
        this.componentKey +=1;
        this.$store.state.Scope.scopeErrorMessage,
        this.resetToUniversityScope();
      }
    },
    openApplications: function(item, mobileCase, parentIndex) {
      this.$store.state.Scope.activeExpansionPanel = parentIndex
      this.activeExpansionPanel = this.menuData.findIndex(parent => parent.id == parentIndex)
      this.notAllowedScope = false;
      if (!item.children) {
        this.changeUniversityScope(item);
        const module = this.$store.state.Scope.scope.find(e => e.id == item.id).path;
        this.$router.push("/home/" + module);
        if (mobileCase) {
          this.drawer = !this.drawer;
        }
      }
    },
    getUserProfile: function() {
      //alert("user profile");
    },
    getHomePage: function() {
      window.location.href = "/home";
    },
    logout: function() {
      this.$store.commit("logout");
    },
    onLanguageChanged: function(direction) {
      this.direction = direction;
    },
    getHomeData: function() {
      this.$http.get("/api/private/list_home_items").then(
        res => {
          if (res) {
            const dataR = res.data.data;
            // years and scopes
            this.academicYears = dataR.years;
            this.semesterDataArEn = dataR.semesters
            this.activeAcademicYear = dataR.years.filter(
              obj => obj.active == "1"
            )[0];
            this.activeAcademicSemester = dataR.semesters.filter(
              obj => obj.active == "1"
            )[0];

            this.titleYear = this.activeAcademicYear;
            this.titleSemester = this.activeAcademicSemester;

            const activeAcademicYear = {
              id: this.activeAcademicYear.attribute_id,
              name: this.activeAcademicYear.attributeName
            };
            const activeAcademicSemester = {
              id: this.activeAcademicSemester.attribute_id,
              name: this.activeAcademicSemester.attributeName
            };
            localStorage.setItem("activeAcademicYear", JSON.stringify(activeAcademicYear))
            localStorage.setItem("activeAcademicSemester", JSON.stringify(activeAcademicSemester))
            this.$store.commit("setActiveYearAndSemester", {
                activeAcademicYear, activeAcademicSemester
            });
            // system apps
            this.applicationsArEn = dataR.apps || "";
            if(dataR.apps) {
                this.menuData = this.serverTranslateArray(dataR.apps, "name");
                const parentIndex =this.$store.state.Scope.activeExpansionPanel;
                this.activeExpansionPanel = this.menuData.findIndex(parent => parent.id == parentIndex);
            }
          }
       },
       err => {
          this.showToasted("error", err.response.data.message)
       }
      );
    },
    changePassword: function () {
      this.$refs.changePassword.open()
    }
  }
};
</script>
