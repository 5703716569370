<template>
  <div class="mb-8">
    <v-card class="mx-auto mb-3">
      <div id="filters">
        <template>
          <v-toolbar flat>
            <v-toolbar-title>
              <p class="mb-0">{{ $t("APPROVAL_RULES") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <div class="d-flex align-center justify-space-between">
              <v-btn
                  color="secondary"
                  dark
                  :disabled="cannotAddNewRule"
                  @click="dialog = true"
              >
                {{ $t("ADD_NEW") }}
              </v-btn>
            </div>
          </v-toolbar>
          <v-form ref="filterForm">
            <v-container>
              <v-row>
                <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                  <v-select
                      :items="serverTranslateArray(years, 'name')"
                      v-model="filterItems.yearId"
                      item-value="id"
                      item-text="name"
                      :label="$t('ACADEMIC_YEAR')"
                      outlined
                  ></v-select>
                </v-col>
                <v-col cols="12" xs="12" sm="12" md="12" lg="6">
                  <v-select
                      :items="serverTranslateArray(semesters, 'name')"
                      v-model="filterItems.semesterId"
                      item-value="id"
                      item-text="name"
                      :label="$t('ACADEMIC_SEMESTER')"
                      outlined
                  ></v-select>
                </v-col>
              </v-row>
            </v-container>
            <v-card-actions class="px-4">
              <v-container class="px-0">
                <v-row>
                  <v-col cols="auto" class="pa-1">

                    <v-btn
                        :small="!$vuetify.breakpoint.mdAndUp"
                        :fab="!$vuetify.breakpoint.mdAndUp"
                        color="primary"
                        class="mx-2"
                        @click="showScopeTreeFn()">
                      <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t('SCOPE') }}</span>
                      <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-navigation</v-icon>
                    </v-btn>
                  </v-col>

                  <v-spacer></v-spacer>
                  <v-col cols="auto" class="pa-1">
                    <v-btn
                        :small="!$vuetify.breakpoint.mdAndUp"
                        :fab="!$vuetify.breakpoint.mdAndUp"
                        color="primary" dark @click="searchApprovalRules"
                    >
                      <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t("SEARCH") }}</span>
                      <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-account-search</v-icon>
                    </v-btn>
                  </v-col>
                  <v-col class="pa-1 mx-2" cols="auto">
                    <v-btn
                        :small="!$vuetify.breakpoint.mdAndUp"
                        :fab="!$vuetify.breakpoint.mdAndUp"
                        color="darken3"
                        class="white--text"
                        @click="resetFilters"
                    >
                      <span v-if="$vuetify.breakpoint.mdAndUp">{{ $t("RESET") }}</span>
                      <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-delete-empty</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-actions>
          </v-form>
        </template>
      </div>
    </v-card>
    <v-card class="mx-auto" outlined>
      <div id="inspire">
        <v-data-table
            :headers="headers"
            :items="approvalRules"
            class="elevation-1"
            :footer-props="{
         showFirstLastPage: true,
         firstIcon: 'mdi-arrow-collapse-left',
         lastIcon: 'mdi-arrow-collapse-right',
         prevIcon: 'mdi-arrow-left',
         nextIcon: 'mdi-arrow-right',
         itemsPerPageOptions: [5, 10, 15, 50, 100]
         }"
            :items-per-page="5"
            :loading="loading"
            :hide-default-footer="approvalRules.length <= 0"
        >
          <template v-slot:item.facultyName="{ item }">
            {{ serverTranslate(item.facultyName) }}
          </template>
  
          <template v-slot:item.actions="{ item }">
            <v-tooltip v-if="item.studentsInBranches.length" top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on" @click="showBranchsTotalCount(item)">
                  <v-icon meduim color="primary" class="mx-2">mdi-eye</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("STUDENTS_COUNT_IN_BRANCH") }}</span>
            </v-tooltip>
            <template v-if="item.facultyActivation === 1"
            >
              <v-tooltip top v-if="item.yearId == activeAcademicYear.id && item.semesterId == activeAcademicSemester.id
               ">
                <template v-slot:activator="{ on }">
                  <v-btn icon v-on="on" @click="editApprovalRule(item)">
                    <v-icon meduim class="mx-2">mdi-pencil</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("EDIT") }}</span>
              </v-tooltip>
              <v-tooltip top v-if="item.recommendedAccepted == 0 && item.recommendedRefused == 0">
                <template v-slot:activator="{ on }">
                  <v-btn
                      icon
                      v-on="on"
                      color="error"
                      @click="deleteApprovalRule(item)"
                  >
                    <v-icon meduim class="mx-2">mdi-delete</v-icon>
                  </v-btn>
                </template>
                <span>{{ $t("DELETE") }}</span>
              </v-tooltip>
            </template>
            <v-tooltip v-else top>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon meduim class="mx-2">mdi-cancel</v-icon>
                </v-btn>
              </template>
              <span>{{ $t("INACTIVE_FACULTY") }}</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-alert icon="warning" class="mt-2" color="light5">
              {{ $t("NO_DATA") }}
            </v-alert>
          </template>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-item v-html="gridTitle"></v-toolbar-item>
            </v-toolbar>
          </template>
        </v-data-table>
      </div>
    </v-card>
    <pr-confirmDialog ref="confirm"></pr-confirmDialog>

    <v-dialog
        v-model="dialog"
        max-width="90vh"
        @keydown.esc="reset()"
        @click:outside="reset()"
    >
      <v-card outlined>
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-card-title class="justify-center">
         <span class="headline white--text text-thin">{{
             $t(formTitle)
           }}</span>
          </v-card-title>

          <v-card-text>
            <v-container>
              <v-alert v-show="showAlert" outlined type="error">
                {{ serverTranslate(errorMessages) }}
              </v-alert>
              <v-row>
                <v-col cols="12">
                  <v-select
                      ref="faculty"
                      v-model="facultyId"
                      :items="serverTranslateArray(faculties, 'name')"
                      item-value="id"
                      item-text="name"
                      :rules="requiredRules"
                      :label="$t('FACULTY')"
                      v-if="isAddEvent"
                      @keydown.space="preventLeadingSpace"
                      @change="
                  branchesNumbers = [];
                  branchesNumbersModel = [];
                  "
                      required
                  ></v-select>
                  <v-chip label v-else>
                    {{ serverTranslate(facultyName) }}
                  </v-chip>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      ref="totalDegree"
                      v-model="totalDegree"
                      :rules="totalRules"
                      @input="totalDegree = parseFloat($event) || null"
                      :label="$t('TOTAL_DEGREE')"
                      clearable
                  />
                </v-col>
                <v-col cols="12">
                  <v-text-field
                      ref="studentsNumbersTotal"
                      v-model="studentsNumbers"
                      :rules="studentsNumbersRules"
                      @input="studentsNumbers = parseInt($event) || null"
                      :label="$t('STUDENTS_NUMBERS')"
                      :disabled="checkBranches"
                      clearable
                      v-if="checkBranches"
                  />
                  <v-text-field
                      v-model="studentsCount"
                      :rules="studentsNumbersRules"
                      :label="$t('STUDENTS_NUMBERS')"
                      clearable
                      v-else
                  />
                </v-col>
              </v-row>
              <v-row v-if="isAddEvent">
                <v-col
                    cols="12"
                    v-for="(branch, index) in branchesComputed"
                    v-bind:key="index"
                >
                  <v-text-field
                      :ref="'branchesNumbers_' + branch.id"
                      v-model="branchesNumbersModel[branch.id]"
                      @input="updateBranchesNumbers(branch.id, $event)"
                      :rules="branchesNumbersRules"
                      :label="
               $t('STUDENTS_NUMBERS_FOR') + serverTranslate(branch.name)
               "
                      clearable
                  />
                </v-col>
              </v-row>
              <v-row v-if="!isAddEvent">
                <v-col
                    cols="12"
                    v-for="(item,index) in editRule.studentsInBranches"
                    v-bind:key="index"
                >
                  <v-text-field
                      :ref="'editBranchesNumbers_' + item.branchId"
                      v-model="item.studentsNumbers"
                      :rules="branchesNumbersRules"
                      :label="
            $t('STUDENTS_NUMBERS_FOR') + serverTranslate(item.branchName)
            "
                      clearable
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col  cols="12">
                  {{$t('PERIORITY')}}:
                  <v-radio-group
                      :rules="requiredRules"
                      v-model="priorityCheck"
                      column  >
                    <v-radio
                      label="الأعلى مجموع"
                      color="blue"
                      value=1
                    ></v-radio>
                    <v-radio
                      label="أسبقية التقديم "
                      color="blue"
                      value=0
                    ></v-radio>
                </v-radio-group>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  {{ $t("ACADEMIC_YEAR") }} :
                  <v-chip class="ma-2" color="dark" dark>
                    {{ serverTranslate(currentYear.name || "") }}
                  </v-chip>
                </v-col>
                <v-col cols="6">
                  {{ $t("ACADEMIC_SEMESTER") }} :
                  <v-chip class="ma-2" color="dark" dark>
                    {{ serverTranslate(currentSemester.name || "") }}
                  </v-chip>
                </v-col>
              </v-row>

            </v-container>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" dark @click="save">{{ $t("SAVE") }}</v-btn>
            <v-btn color="darken3" class="white--text" @click="reset()">
              {{ $t("CANCEL") }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>


    <pr-scope
        :key="componentKey"
        v-if="showScopeTree == true"
        :scopeFullPath="false"
        v-on:setScope="onScopeChanged"
    />
    <pr-students-branches-dialog
        :key="branchKey"
        v-if="showBranchesCount"
        :branches="studentsInBranches"
        :faculty="faculty"
    />
  </div>
</template>

<script>
import serverTranslations from "@/mixins/serverTranslations";
import scope from "@/components/shared/ScopeTree.vue";
import branchesDialog from "./BranchesDialog.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";
import globalMethods from "@/mixins/globalMethods";

export default {
  name: "List",
  components: {
    "pr-scope": scope,
    "pr-students-branches-dialog": branchesDialog,
    "pr-confirmDialog": ConfirmDialog
  },
  mixins: [serverTranslations, globalMethods],
  mounted() {
    this.getApprovalRules();
  },
  computed: {
    studentsNumbers() {
        let total = 0;
      if (!this.isAddEvent && this.editRule.studentsInBranches && this.checkBranches) {
        this.editRule.studentsInBranches.forEach(item => {
          item.studentsNumbers = item.studentsNumbers ? item.studentsNumbers : 0;
          total += parseInt(item.studentsNumbers);
          item.studentsNumbers = item.studentsNumbers ? item.studentsNumbers : '';
        });
      } else if (this.isAddEvent && this.branchesNumbersModel && this.checkBranches) {
        this.branchesNumbersModel.forEach(item => {
          item = item ? item : 0;
          total += parseInt(item);
        });
      }
      else if( !this.checkBranches){

        total=this.studentsNumbers


      }
      return total;
    },
    locale() {
      return this.$store.curLanguage;
    },
    activeAcademicYear() {
      return this.$store.getters.getActiveAcademicYear;
    },
    activeAcademicSemester() {
      return this.$store.getters.getActiveAcademicSemester;
    },
    gridTitle() {
      const year = this.serverTranslate(this.filterItems.yearName ?? "");
      const semester = this.serverTranslate(
          this.filterItems.semesterName ?? ""
      );

      return this.$t("APPROVAL_RULES_GRID_TITLE", {
        year: `<bdi>${year}</bdi>`,
        semester
      });
    },
    formTitle() {
      return this.isAddEvent ? "ADD_APPROVAL_RULE" : "EDIT_APPROVAL_RULE";
    },
    headers() {
      return [
        {
          text: this.$t("FACULTY"),
          align: "center",
          sortable: true,
          value: "facultyName",
          width: "45%"
        },
        {
          text: this.$t("TOTAL_DEGREE"),
          align: "center",
          sortable: true,
          value: "allowedTotalDegree",
          width: "10%"
        },
        {
          text: this.$t("STUDENTS_NUMBERS"),
          align: "center",
          sortable: true,
          value: "allowedStudentCount",
          width: "10%"
        },
        {
          text: this.$t("RECOMMENDED_ACCEPTED"),
          align: "center",
          sortable: true,
          value: "recommendedAccepted",
          width: "10%"
        },
        {
          text: this.$t("RECOMMENDED_REFUSED"),
          align: "center",
          sortable: true,
          value: "recommendedRefused"
        },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "20%"
        }
      ];
    },
    cannotAddNewRule() {
      const activeSemesters = this.semesters.filter(
          semester => parseInt(semester.active) === 1
      );
      return !(activeSemesters.length > 0);
    },
    branchesComputed() {
      return this.branches.filter(
          branch => branch.faculty_id === this.facultyId
      );
    }
  },

  data() {
    return {
      priorityCheck:"1",
      total:0,
      valid: true,
      dialog: false,
      loading: false,
      checkBranches:true,
      showAlert: false,
      errorMessages: "",

      filterItems: {
        facultyId: 0,
        yearId: 0,
        yearName: "",
        semesterId: 0,
        semesterName: ""
      },

      semesters: [],
      years: [],
      scopes: [],
      faculties: [],
      branches: [],/*Contains all define branches categorized by faculty_id*/
      approvalRules: [],/*all defined rules with student numbers in each branch*/
      editRule: {},

      currentYear: {},
      currentSemester: {},

      facultyId: 0,
      facultyName: "",
      universityId: 0,
      totalDegree: null,
      studentsCount:0,
    // studentsNumbers: null,
      branchesNumbers: [],
      branchesNumbersModel: [],

      yearId: 0,
      yearName: "",
      semesterId: 0,
      semesterName: "",

      isAddEvent: true,
      requiredRules: this.$store.state.Rules.requiredRules,
      studentsNumbersRules: this.$store.state.Rules.studentsNumbersRules,
      branchesNumbersRules: [
        v => this.isNotEmpty(v) || this.$t("REQUIRED_FIELD"),
        v =>
            this.isNotEmpty(v)
                ? /^(?:[1-9]\d*|0)?(?:\d+)?$/.test(v) || this.$t("INT_NUMBER_FIELD")
                : true,
        v => {
          if (this.isNotEmpty(v)) {
            let branchesNumbers = 0;

            this.branchesNumbers.forEach(branch => {
              branchesNumbers += parseInt(branch.studentsNumbers) || 0;
            });
            return (
                this.studentsNumbers >= branchesNumbers ||
                this.$t("BRANCHES_NUMBERS_MAX")
            );
          }
          return true;
        }
      ],
      totalRules: this.$store.state.Rules.totalRules,

      showScopeTree: false,
      componentKey: 0,
      branchKey: 0,
      showBranchesCount: false,
      studentsInBranches: [],
      faculty: ""
    };
  },

  methods: {
    updateBranchesNumbers(branchId, $event) {
      const index = this.branchesNumbers.findIndex(
          branch => branch.branchId === branchId
      );
      if (index === -1) {
        this.branchesNumbers.push({
          branchId: branchId,
          studentsNumbers: parseInt($event) || null
        });
      } else {
        this.branchesNumbers[index].studentsNumbers = parseInt($event) || null;
      }
    },
    showScopeTreeFn() {
      this.showScopeTree = true;
      this.showBranchesCount = false;
      this.componentKey += 1;
    },
    getApprovalRules(facultyId = null, yearId = null, semesterId = null) {
      this.loading = true;
      return this.$http
          .post("/api/approval_rules/list_approval_rules/", {
            facultyId: facultyId || "",
            yearId: yearId || this.activeAcademicYear.id || 0,
            semesterId: semesterId || this.activeAcademicSemester.id || 0
          })
          .then(response => {
            const data = response.data.data;
            this.semesters = data.Semesters;
            this.approvalRules = data.approvalRules;
            this.faculties = data.faculities;
            this.branches = data.branches;
            this.years = data.years;
            this.checkBranches=data.checkBranches
            this.currentYear = this.years.filter(
                year => parseInt(year.active) === 1
            )[0];
            if (!yearId) {
              this.filterItems.yearId = this.currentYear.id || 0;
              this.filterItems.yearName = this.currentYear.name || "";
            } else {
              const year = this.years.find(year => year.id === yearId);
              this.filterItems.yearId = year ? year.id : 0;
              this.filterItems.yearName = year ? year.name : "";
            }

            this.currentSemester = this.semesters.filter(
                semester => parseInt(semester.active) === 1
            )[0];
            if (!semesterId) {
              this.filterItems.semesterId = this.currentSemester.id || 0;
              this.filterItems.semesterName = this.currentSemester.name || "";
            } else {
              const semester = this.semesters.find(
                  semester => semester.id === semesterId
              );
              this.filterItems.semesterId = semester ? semester.id : 0;
              this.filterItems.semesterName = semester ? semester.name : "";
            }
          })
          .then(() => (this.loading = false));
    },
    isNotEmpty(str) {
      return !(!str || 0 === str.length || /^\s*$/.test(str));
    },
    searchApprovalRules() {
      this.getApprovalRules(
          null,
          this.filterItems.yearId,
          this.filterItems.semesterId
      );
    },
    preventLeadingSpace(e) {
      if (!e.target.value) e.preventDefault();
      else if (e.target.value[0] === " ")
        e.target.value = e.target.value.replace(/^\s*/, "");
    },
    save() {
      if (this.$refs.form.validate()) {
        if (!this.isAddEvent)
          this.branchesNumbers = this.editRule.studentsInBranches.map(item => ({
            branchId: item.branchId,
            studentsNumbers: item.studentsNumbers
          }));
        const data = {}
        data['facultyId'] = this.facultyId
        data['yearId'] = this.currentYear.id
        data['semesterId'] = this.currentSemester.id
        data['totalDegree'] = this.totalDegree
        data['priorityCheck']=this.priorityCheck
        if(this.checkBranches) {
          data['studentsNumbers'] = this.studentsNumbers
        } else {
          data['studentsNumbers'] = parseInt(this.studentsCount)
        }
        data['branchesNumbers'] = this.branchesNumbers
        const request = this.isAddEvent ? this.$http.post("/api/approval_rules/add_rule/", data)
            : this.$http.put(`/api/approval_rules/edit_rule/${this.editRule.id}/`, data);
        request.then(
            res => {
              this.reset();
              this.showToasted("success", this.$t("APPROVAL_RULE_ADDED"));
              this.filterItems = {
                facultyId: 0,
                yearId: 0,
                yearName: "",
                semesterId: 0,
                semesterName: ""
              };
              this.getApprovalRules();
              this.$refs.form.reset()
            },
            err => {
              this.showAlert = true;
              this.errorMessages = this.serverTranslate(err.response.data.message);
              if (err.response.status === 404) {
                this.showToasted("error", this.serverTranslate(err.response.data.message));
                this.reset();
                this.getApprovalRules();
              }
            }

        );
      } else {
        this.showAlert = true;
        this.errorMessages = this.showAlert ? this.$t("COMPLETE_DATA_ERROR") : "";
      }
    },
    editApprovalRule(rule) {

      this.$refs.confirm
        .open("CONFIRMATION_MESSAGE", "CONFIRMATION_UPDATE_RULE")
        .then(confirm => {
          if (confirm) {
            this.editRule = JSON.parse(JSON.stringify(rule)); // for deep copy
            this.totalDegree = this.editRule.allowedTotalDegree;
            this.studentsNumbers = this.editRule.allowedStudentCount;
            this.studentsCount=this.editRule.allowedStudentCount;
            this.facultyId = this.editRule.facultyId;
            this.facultyName = this.editRule.facultyName;
            this.priorityCheck=this.editRule.priorityCheck.toString();
            this.isAddEvent = false;
            this.dialog = true
          }
        });

    },
    deleteApprovalRule(rule) {
      this.$refs.confirm
          .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
          .then(res => {
            if (res) {
              this.$http.post(`/api/approval_rules/delete_rule/${rule.id}/`)
                  .then(
                      res => {
                        this.getApprovalRules();
                        this.showToasted("success", res.data.message);
                      },
                      err => {
                        this.showToasted("error", err.response.data.message);
                        this.getApprovalRules();
                      }
                  )
            }
          })

    },
    resetFilters() {
      this.filterItems = {
        facultyId: 0,
        yearId: 0,
        yearName: "",
        semesterId: 0,
        semesterName: ""
      };

      this.getApprovalRules();
    },
    reset() {
      this.dialog = !close;
      this.isAddEvent = true;
      this.showAlert = false;
      this.errorMessages = "";

      this.facultyId = 0;
      this.yearId = 0;
      this.semesterId = 0;
      this.totalDegree = null;
      this.priorityCheck="1"
      this.studentsNumbers = null;

      this.branchesNumbers = [];
      this.branchesNumbersModel = [];
    },
    onScopeChanged(changed) {
      if (changed)
        this.searchApprovalRules();
    },
    showBranchsTotalCount(item) {
      this.studentsInBranches = this.serverTranslateArray(item.studentsInBranches, "branchName");
      this.showBranchesCount = true;
      this.branchKey += 1;
      this.faculty = this.serverTranslate(item.facultyName);
    }
  },
  watch: {
    studentsNumbers(newValue) {
      
      const totalBranchesNumbers = this.branchesNumbersModel.reduce(
          (a, b) => parseInt(a) + parseInt(b),
          0
      );
      if (newValue >= totalBranchesNumbers) {
        const formInputsRefs = Object.keys(this.$refs);
        formInputsRefs.forEach(input => {
          if ((input || "").startsWith("branchesNumbers")) {
            const inputElement = this.$refs[input][0];
            inputElement.validate();
          }
        });
      }
    }
  }
};


</script>

<style>
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100% !important;
}

table tr th {
  white-space: normal !important;
}

.v-messages__message {
  color: red !important;
}
</style>