<template>
  <v-dialog
      v-model="dialog"
      max-width="100vh"
      @keydown.esc="cancel()"
      @click:outside="cancel()"
  >
    <v-card>
      <v-toolbar dark color="primary" dense flat>
        <v-toolbar-title class="white--text">{{ $t('FORGOT_PASSWORD') }}</v-toolbar-title>
      </v-toolbar>
      <v-card-text
          class="secondary--text pa-4 pb-8 font-weight-bold"
      >
        <v-form ref="forgot">
          <v-text-field
              append-icon="mdi-card-account-details-outline"
              v-model="cardID"
              :rules="NIDRulse"
              :label="$t('NID')"
              :maxlength="14"
              :counter="14"
              required
              outlined
              color="primary"
              dense
              ref="nID"
              @keydown.space="preventLeadingSpace"
          ></v-text-field>
          <v-text-field
              append-icon="mdi-at"
              :label="$t('EMAIL')"
              required
              outlined
              color="primary"
              dense
              @keydown.space="preventLeadingSpace"
              v-model="email"
              ref="email"
              :rules="emailRules"
          ></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions class="pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.native="sendNewPassword">{{ $t("SEND_NEW_PASSWORD") }}</v-btn>
        <v-btn color="error" @click.native="cancel()">{{ $t("CANCEL") }}</v-btn>
      </v-card-actions>
    </v-card>
    <pr-confirmDialog ref="confirmCancel"></pr-confirmDialog>
  </v-dialog>
</template>
<script>
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
export default {
  name: "pr-forgotPassword",
  mixins: [serverTranslationsMixin, globalMethodsMixin],
  components: {
    "pr-confirmDialog": ConfirmDialog
  },
  data() {
    return {
      dialog: false,
      resolve: null,
      reject: null,
      cardID:"",
      email:"",
      NIDRulse: this.$store.state.Rules.NIDRulse,
      emailRules: this.$store.state.Rules.emailRules
    }
  },
  methods:{
    open() {
      this.dialog = true;
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },
    sendNewPassword() {
      const data = {
        cardID:this.cardID,
        email:this.email
      }
      if (this.$refs.forgot.validate()) {
        this.$http.post("/api/users/forget_password/", data)
            .then(
                res => {
                  this.dialog = false
                  this.$toasted.show(this.serverTranslate(res.data.message), {
                    type: "success",
                    icon: "check_circle_outline",
                    duration: 3000,
                    theme: "bubble",
                    position: "bottom-left"
                  });
                  this.reset()
                },
                err => {
                  this.$toasted.show(
                      this.serverTranslate(err.response.data.message),
                      {
                        type: "error",
                        icon: "error_outline",
                        theme: "bubble",
                        position: "bottom-left",
                        duration: 3000
                      }
                  );
                }
            )
      }
    },
    reset() {
      this.$refs.nID.reset()
      this.$refs.email.reset()
      this.cardID = ""
      this.email = ""
    },
    cancel() {
      this.$refs.confirmCancel
          .open("CONFIRMATION_MESSAGE", "CONFIRM_CANCEL")
          .then(confirm => {
            if (confirm) {
              this.dialog = false
              this.reset()
            }
          })
    }
  }
}
</script>
