<template>
  <v-card class="mx-auto" outlined>
    <div id="inspire">
   
     <v-data-table
        :headers="headers"
        :items="serverTranslateArrayKeys(mails,['category'])"
        sort-by="name"
        class="elevation-1"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
          itemsPerPageOptions: [5, 10, 15, 50, 100]
        }"
        :items-per-page="10"
        :hide-default-footer="mails.length <= 0"
      >
    

        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>
              <p color="white" class="mb-0">{{ $t("STUDENTS_MAILS") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="90vh">
              <template v-slot:activator="{ on }">
                <v-btn
                color="secondary"
                dark v-on="on"
                :small="!$vuetify.breakpoint.mdAndUp"
                :fab="!$vuetify.breakpoint.mdAndUp"
                @click="openDialog">
                  <template v-if="$vuetify.breakpoint.mdAndUp"> {{$t("ADD_NEW")}} </template>
                  <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-plus-circle</v-icon>
                </v-btn>
                <v-btn
                color="primary"
                class="mx-2"
                :small="!$vuetify.breakpoint.mdAndUp"
                :fab="!$vuetify.breakpoint.mdAndUp"
                @click="showScopeTreeFn()">
                  <template v-if="$vuetify.breakpoint.mdAndUp"> {{$t('SCOPE')}} </template>
                  <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-navigation</v-icon>
              </v-btn>

              </template>
              <v-card outlined>
                <v-form ref="form" v-model="valid" lazy-validation>
                  <v-card-title class="justify-center">
                    <span class="headline primary--text text-thin">{{
                      formTitle
                    }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                  
                      <v-row>
                        <!-- <v-col cols="12">
                          <pr-scope key="componentKey" :scopeFullPath="true"/>
                        </v-col> -->
                        <v-col cols="12" class="ma-0" sm="12" md="12" lg="12">
                          <v-checkbox
                            :label="$t('SEND_MAIL_MESSAGE')"
                            v-model="editedMailItem.sendMailToStudent"
                            color="primary">
                         </v-checkbox>
                        </v-col>
                        <v-col cols="12" class="ma-0" sm="12" md="12" lg="12">
                            <v-select
                                :items="serverTranslateArray(cartegoryMessages, 'name')"
                                v-model="editedMailItem.categoryId"
                                outlined
                                small-chips
                                :label="$t('CATEGORY_MESSAGE')"
                                item-value="id"
                                item-text="name"
                                :rules="requiredRules"
                            >
                            </v-select>

                        </v-col>
                         <v-col cols="11" class="ma-0" sm="11" md="11" lg="11">
                              <v-text-field
                                  ref="subjectMessage"
                                  outlined
                                  v-model="editedMailItem.subject"
                                  :label="$t('SUBJECT_MESSAGE')"
                                  maxlength="75"
                                  :rules="subjectContentRules"

                              ></v-text-field>
                           </v-col>
                           <v-col cols="12" class="ma-0" sm="12" md="12" lg="12">
                          <v-textarea
                            :label="$t('TEXT_MESSAGE')"
                            v-model="editedMailItem.content"
                            ref="textareaMessage"
                            counter="1000"
                            maxlength="1000"
                            outlined
                            rows="3"
                            row-height="25"
                            :rules="subjectContentRules"
                            @keydown.space="preventLeadingSpace"
                            @input="updateCheckboxShourtCuts"
                          ></v-textarea>
                        </v-col>
                        
                        <v-col cols="12" class="ma-0" sm="12" md="12" lg="12">
                          <v-sheet class="text-center title primary--text">
                            {{$t('SHOURTCUTS')}}
                            </v-sheet>

                          </v-col>
                      <v-row>

                          <v-col cols="6" class="ma-0" sm="6" md="6" lg="6">
                            <v-checkbox
                              :label="$t('APPLICATION_NUM')"
                              ref="myAppNum"
                              v-model="applicationNumChecked"
                              @change="updateTextarea($refs.myAppNum,'ApplicationNum')" 
                              color="primary">
                           </v-checkbox>
                          </v-col>
                          <v-col cols="6" class="ma-0" sm="6" md="6" lg="6">
                           <v-checkbox
                           :label="$t('STUDENT_NAME')"
                           v-model="studentNameChecked"
                           ref="StudentName"
                           @change="updateTextarea($refs.StudentName,'StudentName')" 
                        
                           color="primary">
                             </v-checkbox>
                          </v-col>
                        </v-row>
                      </v-row>
                      <v-alert v-show="showAlert" dense outlined type="error">{{
                        serverTranslate(errorMessages)
                      }}</v-alert>
                   
                     
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click="save">{{
                      $t("SAVE")
                    }}</v-btn>

                    <v-btn color="darken3" class="white--text" @click="close">
                        {{ $t("CANCEL") }}
                    </v-btn>
                  </v-card-actions>


                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>



       <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon meduim color="primary" @click="editMailCategory(item)"
                  >mdi-pencil</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("EDIT") }}</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon
                  meduim
                  color="delete"
                  class="mx-2"
                  @click="deleteMailCategory(item)"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("DELETE") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:footer.page-text="props">
            {{ props.pageStart }} - {{ props.pageStop }} {{$t('PAGE_TEXT')}} {{ props.itemsLength }}
        </template>
        <template v-slot:no-data>
            <v-alert icon="warning" class="mt-2" color="light5">
            {{ $t("NO_DATA") }}
            </v-alert>
        </template>
   </v-data-table>
         <pr-confirmDialog ref="confirm"></pr-confirmDialog>
         <pr-scope :key="componentKey" v-if="showScopeTree == true"  v-on:setScope="onScopeChanged" :scopeFullPath="false"/>
         <!-- v-on:setScope="onScopeChanged" :scopeFullPath="false" -->

      </div>

   </v-card>
</template>


<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import globalMethodsMixin from "@/mixins/globalMethods";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import scope from "@/components/shared/ScopeTree";
import { CATEGORY_MESSAGES_STUDENTS_IDS } from "@/enums.ts";
export default {
    mixins: [serverTranslationsMixin,globalMethodsMixin],
  components: {
    "pr-confirmDialog": ConfirmDialog,
    "pr-scope":scope
  },
   data() {
     return {
       requiredRules: this.$store.state.Rules.requiredRules,
       searchRules: this.$store.state.Rules.searchRules,
       mails:[],
       mailIndex: -1,
       dialog: false,
       showScopeTree:  false,
       componentKey:0,
       showAlert: false,
       valid:true,
       errorMessages: "",
       cartegoryMessages:[],
       mailIds:[],
       editedMailItem: {
        categoryId: "",
        subject: "",
        content:"",
        sendMailToStudent:0,
      },
       defaultMailItem:{
         categoryId: "",
        subject: "",
        content:"",
        sendMailToStudent:0,

       }
     }
   },
   
   computed: {
       headers: function() {
        return [
          {
            text: this.$t("SUBJECT_MESSAGE"),
            align: "start",
            sortable: false,
            value: "subject"

          },
          { text: this.$t("TEXT_MESSAGE"),value:"content" ,sortable: false },
          { text: this.$t("CATEGORY_MESSAGE"), value: "category", sortable: true},
              {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "150px"
        }
        ];
    }, formTitle() {
      return this.mailIndex === -1
        ? this.$t("ADD_NEW") + " " + this.$t("STUDENTS_MAILS")
        : this.$t("EDIT") + " " + this.$t("STUDENTS_MAILS");
    }, subjectContentRules() {
      if (this.editedMailItem.categoryId === CATEGORY_MESSAGES_STUDENTS_IDS.SENDING_NOTIFICATIONS) {
        return [];
      } else {
        return this.requiredRules;
      }
    },


 },
 watch: {
    dialog(val) {
      val || this.close();
    },
  'editedMailItem.content': function(newContent) {
      // Check if the content contains the placeholder
      if (newContent.includes('{ApplicationNum}')) {
        this.applicationNumChecked = true;
      
      }
      if(newContent.includes('{StudentName}')){
        this.studentNameChecked = true;
      }
    },
  },
   created() {
    this. fetchData();
  },
 methods:{

    fetchData() {
      this.$http.get("/api/mails/list_student_emails_data/").then(
        res => {
          this.mails = res.data.data.emails;
          for (let i = 0; i < this.mails.length; i++) {
            this.mailIds.push(this.mails[i]['id'])
          }
        },
        err => {
          this.showToasted("error",err.response.data.message);
        }
      );
    },
   openDialog () {
      this.$http.get("/api/mails/get_student_mails_filters/").then(
        res => {
          this.cartegoryMessages = res.data.data.cartegoryMessages;
        },
        err => {
          this.showToasted("error",err.response.data.message);
          this.close();
        }
      );
    },
     close() {
      this.$refs.form.resetValidation();
      this.dialog = false;
      this.showAlert = false;
      this.errorMessages = "";
      this.editedMailItem = Object.assign({}, this.defaultMailItem);
      this.mailIndex = -1;
    },
     save() {
      if (this.$refs.form.validate()) {
        if (this.mailIndex > -1) { // edit case
          this.updateStudentMail();

        } else { /// add case
          this.$http.post(
            "/api/mails/add_student_mail/",
            this.editedMailItem
          ).then(
            res => {
              this.showToasted("success",res.data.message);
              this.close();
              this. fetchData();
            },
            err => {
              this.errorMessages = err.response.data.message;
              this.showAlert = true;
              this. fetchData();
            }
          );
        }
      }
    },
       editMailCategory(emailItem) {
     // this.mailIndex = this.mails.indexOf(emailItem.id);
      this.mailIndex=1
      this.dialog = true;
      this.openDialog();
      this.editedMailItem.id=emailItem.id;
      this.editedMailItem.categoryId = emailItem.categoryId;
      this.editedMailItem.subject = emailItem.subject;
      this.editedMailItem.content=emailItem.content;
      this.editedMailItem.sendMailToStudent = emailItem.sendMailToStudent;
      this.applicationNumChecked=false;
      this.studentNameChecked = false;

    },
     updateStudentMail() {
      this.$http.put(
        "/api/mails/edit_student_mail/",
        this.editedMailItem
      ).then(
        res => {
          this.showToasted("success",res.data.message);
          this.close();
          this. fetchData();
        },
        err => {
          this.errorMessages = err.response.data.message;
          this.showAlert = true;
          this. fetchData();
        }
      );
    },
   deleteMailCategory(emailItem){
    this.$refs.confirm
        .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
        .then(confirm => {
          if (confirm) {
            const id = { mailItemID: emailItem.id };
            this.$http.delete("/api/mails/delete_student_emails_data/", { data: id }).then(
              res => {
                this.showToasted("success", res.data.message)
                this. fetchData();
              },
              err => {
                 this.showToasted("error", err.response.data.message)
                if (err.response.status === 404) {
                  this. fetchData();
                }
              }
            );
          }
        });
   },
   updateTextarea(checkboxRef,textToInsert) {
      const pastChecked =checkboxRef.$el.querySelector('input[type="checkbox"]').checked
      if (pastChecked) {
       this.editedMailItem.content = this.editedMailItem.content.replace(textToInsert, "");
     
      } else {
        this.appendTextAtCursor(textToInsert);
      }
    },
    updateCheckboxShourtCuts(){
    this.applicationNumChecked = false;
        this.studentNameChecked = false;
       if (this.editedMailItem.content.includes('{ApplicationNum}')) {
          this.applicationNumChecked = true;
      }
        if (this.editedMailItem.content.includes('{StudentName}')) {
        this.studentNameChecked = true;
      }
    },
    appendTextAtCursor(text) {
      const textarea = this.$refs.textareaMessage.$el.querySelector('textarea');
      const startPos = textarea.selectionStart;
      const endPos = textarea.selectionEnd;
      
      const textBefore = this.editedMailItem.content.substring(0, startPos);
      const textAfter = this.editedMailItem.content.substring(endPos);

      this.editedMailItem.content = textBefore+"{"+text+"}"+textAfter;
    },
    showScopeTreeFn(){
      this.showScopeTree = true;
      this.componentKey +=1;
    },
    onScopeChanged(changed) {
      if (changed) 
      {
        this. fetchData();
       // this.close()
      }
    },

  

 },



}

</script>