<template>
  <div class="mb-8">
    <v-card class="mx-auto mb-2">
      <div id="courses">
        <template>
          <v-toolbar flat>
            <v-toolbar-title>
              <p color="white" class="mb-0">{{ $t("COURSE_DETAILS") }}</p>
            </v-toolbar-title>
            <v-spacer></v-spacer>
          </v-toolbar>
          <v-form ref="courseForm">
            <v-container class="center pa-0">
              <v-row>
                <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <label
                  class="text-no-wrap primary--text font-weight-bold "
                  :class="
                    + $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                    'd-table-row'
                  ">{{$t("COURSE_NAME_AR")}}</label>
                  <span
                  :class="
                    $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                    ? 'd-table-cell'
                    : 'd-table-row'
                  ">{{courseData.arName}}</span>
                </v-col>
                <v-col cols="12" xs="12" sm="6" md="6" lg="6">
                  <label
                  class="text-no-wrap primary--text font-weight-bold "
                  :class="
                    + $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm ? 'px-3 d-table-cell' :
                    'd-table-row'
                  ">{{$t("COURSE_NAME_EN")}}</label>
                  <span
                  :class="
                    $vuetify.breakpoint.lg || $vuetify.breakpoint.md || $vuetify.breakpoint.sm
                    ? 'd-table-cell'
                    : 'd-table-row'
                  ">{{courseData.enName}}</span>
                </v-col>
              </v-row>
              <v-card-actions>
                <v-spacer></v-spacer>
                <template v-if="showBtn">
                  <v-btn color="primary" dark @click="editCourse">{{
                    $t("EDIT")
                  }}</v-btn>
                  <v-btn color="error" dark @click="deleteCourse">{{
                    $t("DELETE")
                  }}</v-btn>
                </template>
                <v-btn color="darken3" class="white--text" @click="back">{{ $t("BACK") }}</v-btn>
              </v-card-actions>
            </v-container>
          </v-form>
        </template>
        <pr-add-edit-course ref="dialogCourse" v-on:updatedCourse="updatedCourse" :searchData="searchData"></pr-add-edit-course>
      </div>
    </v-card>
    <v-card class="mx-auto" outlined>
      <v-data-table
        :headers="headers"
        :items="serverTranslateArray(certificates,'name')"
        :options.sync="options"
        :server-items-length="totalPages"
        :items-per-page="5"
        :hide-default-footer="certificates.length > 0 ? false : true"
        :footer-props="{
          showFirstLastPage: true,
          firstIcon: 'mdi-arrow-collapse-left',
          lastIcon: 'mdi-arrow-collapse-right',
          prevIcon: 'mdi-arrow-left',
          nextIcon: 'mdi-arrow-right',
          itemsPerPageOptions: [5,10,15,50,100],
        }"
        :loading="loading"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{$t("CERTIFICATE_RELATED_TO_COURSE")}}</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }" v-on:click.stop>
          <v-tooltip v-if="!item.certificateStatus" bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon meduim class="mx-2"
                  >mdi-lock
                </v-icon>
              </v-btn>
            </template>
            <span>{{ $t("CLOSED_CERTIFICATE") }}</span>
          </v-tooltip>
        </template>
        <template v-slot:no-data>
          <v-alert icon="warning" class="mt-2" color="light5">
              {{
              $t("NO_DATA")
              }}
          </v-alert>
        </template>
        <template v-slot:footer.page-text="props">
          {{ props.pageStart }} - {{ props.pageStop }} {{$t('PAGE_TEXT')}} {{ props.itemsLength }}
        </template>
      </v-data-table>
    </v-card>
    <pr-confirmDialog ref="confirm"></pr-confirmDialog>
  </div>
</template>
<script>
import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
import AddEditCourse from "./AddEditCourse";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "pr-view-course-details",
  mixins: [serverTranslationsMixin],
  components: {
    "pr-add-edit-course": AddEditCourse,
    "pr-confirmDialog": ConfirmDialog,
  },
  props: {
    id:Number,
    searchData: Object,
  },
  data() {
    return {
      certificates: [],
      showBtn: false,
      loading: true,
      options: {},
      totalPages:0,
      courseData: {
        arName: "",
        enName: "",
        id: this.id,
        pageSize:5,
        pageNum:1,
        active:1,
      }
    };
  },
  computed: {
    headers: function() {
      return [
        {
          text: this.$t("CERTIFICATE_NAME"),
          align: "start",
          sortable: true,
          value: "name"
        },
        {
          text: this.$t("ACADEMIC_YEAR"),
          value: "year",
          sortable: true
        },
        {
          text: this.$t("COURSE_DEGREE"),
          value: "courseDegree",
          sortable: true
        },
        {
          text: this.$t("TOTAL"),
          value: "certificateTotalDegree",
          sortable: true
        },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "150px"
        }
      ];
    }
  },
  watch: {
    options: {
      handler () {
        this.getCourseDetailsApi()
          .then(data => {
            this.certificates = data.items;
            this.totalPages = data.total;
          })
      },
      deep: true,
    },
  },
  mounted() {
    this.getCourseDetailsApi()
      .then(data => {
        this.certificates = data.items;
        this.totalPages = data.total;
      });
  },
  methods: {
    back() {
      this.$router.push({name:"ListCourses", params:{searchData:this.searchData}})
    },
    editCourse() {
      this.$refs.dialogCourse
      .open("EDIT_COURSE","edit",this.courseData)
    },
    updatedCourse(value) {
      this.courseData = value;
    },
    deleteCourse() {
      this.$refs.confirm
      .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
      .then(confirm => {
        if (confirm) {
          const data = {
            id: this.id
          };
          this.$http
          .delete("/api/courses/delete_course/", {
            data: data,
          })
          .then(
            res => {
              this.$toasted.show(this.serverTranslate(res.data.message), {
                type: "success",
                icon: "check_circle_outline",
                theme: "bubble",
                position: "bottom-left",
                duration: 2000
              });
              this.$router.push({name:"ListCourses", params:{searchData:this.searchData}})
            },
            err => {
              this.$toasted.show(
                this.serverTranslate(err.response.data.message),
                {
                  type: "error",
                  icon: "error_outline",
                  theme: "bubble",
                  position: "bottom-left",
                  duration: 5000
                }
              );
              if (err.response.status === 404) {
                this.searchData.page = 1;
                this.searchData.pageSize = 5;
                this.$router.push({name:"ListCourses", params:{searchData:this.searchData}})
              }
            }
          );
        }
      });
    },
    editCourseDegree() {
      // edit certificate
    },
    deleteCertificateCourse() {
      // delete certificate
    },
    getCourseDetailsApi() {
      this.loading = true
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        const data = {
          courseID: this.id,
          page: page,
          pageSize: itemsPerPage
        }
        if (sortBy.length === 1 && sortDesc.length === 1) {
          data.orderType = sortDesc[0] ? "asc" : "desc";
          data.orderBy = sortBy[0]
        }
        clearTimeout(this.timer);
        this.timer = setTimeout(() => {
          this.$http
          .post("/api/courses/courses_certificates/", data)
          .then(
            res => {
                this.certificates= [];
                const data = res.data.data;
                const items = data.results;
                this.showBtn = items.length === 0 ? true : false;
                this.courseData.arName = data.courseArName;
                this.courseData.enName = data.courseEnName;
                this.courseData.active = data.active;
                const total = data.total;
                resolve({
                  items,
                  total,
                });
            },
            err => {
              this.certificates = [];
              this.$toasted.show(
                this.serverTranslate(err.response.data.message),
                {
                  type: "error",
                  icon: "error_outline",
                  position: "bottom-left",
                  duration: 5000,
                  theme: "bubble"
                }
              );
              if (err.response.status === 404) {
                this.$router.push({name: "ListCourses"});
              }
            }
          );
          this.loading = false;
        }, 1000)
      });
    }
  }
};
</script>
