<template>
    <div>
        <v-dialog v-model="dialog" :persistent="true" max-width="90vh">
            <v-card outlined>
                <v-form ref="form">
                    <v-card-title class="justify-center">
                    <span class="headline  text-thin">
                        {{ $t("PAY_FEES",{title:''})}}
                    </span><span class="headline text-thin ms-1">{{item.applyId}}</span>
                    </v-card-title>
                    <v-card-text>
                    <v-container>
                        <template v-if="item.edit=='view'">
                          <v-alert v-show="showAlert" dense outlined type="error">{{
                            serverTranslate(errorMessages)
                            }}</v-alert>
                            <v-row>
                                <v-col cols="12" lg="6" md="6" v-if="depositNum">
                                    <legend class="primary--text font-weight-bold pb-1">{{$t('DEPOSIT_NUM')}}</legend>
                                    <span>{{depositNum}}</span>
                                </v-col>
                                <v-col cols="12" lg="6" md="6" v-if="depositDate">
                                    <legend class="primary--text font-weight-bold pb-1">{{$t('DEPOSIT_DATE')}}</legend>
                                    <span>{{depositDate}}</span>
                                </v-col>

                              <!--here-->

                              <v-col cols="6" lg="4">
                              <v-template v-if="!depositImageLink " >
                                <v-file-input
                                        ref="depositImage"
                                        v-model="depositImage"
                                        color="primary"
                                        accept="image/*"
                                        :label="$t('DEPOSIT_IMAGE')"
                                        prepend-icon="mdi-paperclip"
                                        :show-size="1000"
                                        :rules="depositImageRule"
                                    >
                                    <template v-slot:selection="{ index, text }">
                                        <v-chip
                                            v-if="index < 2"
                                            color="primary"
                                            dark
                                            label
                                            small
                                        >
                                        {{ text }}
                                        </v-chip>
                                    </template>
                                    </v-file-input>
                              </v-template>
                              <v-template v-else>
                                <legend class="grey--text pb-1">
                                  {{ $t('DEPOSIT_IMAGE')}}
                                </legend>
                                <a
                                  ref="#"
                                  @click="imagePreview = !imagePreview;"
                                  >deposite image...</a
                                >
                                <v-tooltip top>
                                  <template v-slot:activator="{ on }">
                                    <v-icon
                                      meduim
                                      v-on="on"
                                      color="error"
                                      class="ms-3"
                                      @click="deleteImage()"
                                      >mdi-delete
                                    </v-icon>
                                  </template>
                                  <span>{{$t('DEPOSIT_IMAGE')}}</span>
                                </v-tooltip>
                                <v-divider class="mt-2"></v-divider>
                              </v-template>
                            </v-col>





<!--                                 <v-col cols="12" lg="6" md="6">-->
<!--                                    <legend class="primary&#45;&#45;text font-weight-bold pb-1">{{$t('DEPOSIT_IMAGE')}}</legend>-->
<!--                                    <a ref="#" class="pink&#45;&#45;text"-->
<!--                                       @click="imagePreview = !imagePreview;showingFile=item.depositImage">depositImage...</a>-->
<!--                                 </v-col>-->
                            </v-row>
                            <v-row>
                                <v-col cols="12" lg="6" md="6" v-if="paymentDate">
                                    <legend class="primary--text font-weight-bold pb-1">{{$t('ADMISSION_DATA.STEP4.PAIDDATE')}}</legend>
                                    <span>{{paymentDate}}</span>
                                </v-col>
                                 <v-col cols="12" lg="6" md="6" v-if="paymentTime">
                                    <legend class="primary--text font-weight-bold pb-1">{{$t('ADMISSION_DATA.STEP4.PAYMENT_TIME')}}</legend>
                                    <span>{{paymentTime}}</span>
                                 </v-col>
                            </v-row>
                        </template>

                        <template v-else>
                            <v-alert v-show="showAlert" dense outlined type="error">{{
                            serverTranslate(errorMessages)
                            }}</v-alert>
                            <v-row>
                            <v-col cols="12"  v-if="item.onlineFees!==0">
                                <v-text-field
                                v-model="depositNum"
                                @keydown.space="preventLeadingSpace"
                                :rules="depositNumRules"
                                :label="$t('DEPOSIT_NUM')"
                                required
                                maxlength="100"
                                counter="100"
                                :autocomplete="false"
                                prepend-icon="mdi-numeric"
                                ></v-text-field>
                            </v-col>
                            <v-col cols="12"  v-if="item.onlineFees!==0">
                                <v-menu
                                    v-model="depositMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="depositDate"
                                        :label="$t('DEPOSIT_DATE')"
                                        prepend-icon="event"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="depositDate"
                                    :max="maxDate"
                                    :min="minDate"
                                    @input="depositMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" >
                                <template v-if="!depositImageLink">
                                    <v-file-input
                                        ref="depositImage"
                                        v-model="depositImage"
                                        color="primary"
                                        accept="image/*"
                                        :label="$t('DEPOSIT_IMAGE')"
                                        prepend-icon="mdi-paperclip"
                                        :show-size="1000"
                                        :rules="depositImageRule"
                                    >
                                    <template v-slot:selection="{ index, text }">
                                        <v-chip
                                            v-if="index < 2"
                                            color="primary"
                                            dark
                                            label
                                            small
                                        >
                                        {{ text }}
                                        </v-chip>
                                    </template>
                                    </v-file-input>
                                </template>
                                <v-template v-else>
                                    <legend class="primary--text font-weight-bold pb-1">{{$t('DEPOSIT_IMAGE')}}</legend>
                                    <a ref="#" @click="imagePreview = !imagePreview">depositImage...</a>
                                    <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <v-icon
                                        meduim v-on="on"
                                        color="error"
                                        class="ms-3"
                                        @click="deleteImage()"
                                        >mdi-delete</v-icon
                                        >
                                    </template>
                                    <span>{{ $t("ADMISSION_DATA.UPLOAD_IMG") }}</span>
                                    </v-tooltip>
                                    <v-divider  class="mt-2"></v-divider>
                                </v-template>
                            </v-col>
                            <v-col cols="12" lg="6" md="6"  v-if="item.onlineFees!==0">
                                <v-menu
                                    v-model="paymentMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    transition="scale-transition"
                                    offset-y
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                    <v-text-field
                                        v-model="paymentDate"
                                        :label="$t('ADMISSION_DATA.STEP4.PAIDDATE')"
                                        prepend-icon="event"
                                        readonly
                                        v-bind="attrs"
                                        v-on="on"
                                    ></v-text-field>
                                    </template>
                                    <v-date-picker
                                    v-model="paymentDate"
                                    :max="maxDate"
                                    :min="minDate"
                                    @input="paymentMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" lg="6" md="6"  v-if="item.onlineFees!==0">
                                <v-menu
                                    ref="timeMenu"
                                    v-model="timeMenu"
                                    :no-title="true"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    :return-value.sync="paymentTime"
                                    transition="scale-transition"
                                    offset-y
                                    max-width="290px"
                                    min-width="290px"
                                >
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            v-model="paymentTime"
                                            :label="$t('ADMISSION_DATA.STEP4.PAYMENT_TIME')"
                                            prepend-icon="access_time"
                                            readonly
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-time-picker
                                    v-if="timeMenu"
                                    v-model="paymentTime"
                                    full-width
                                    :max="maxTime"
                                    @click:minute="$refs.timeMenu.save(paymentTime)"
                                    ></v-time-picker>
                                </v-menu>
                            </v-col>
                            </v-row>
                        </template>
                    </v-container>
                    </v-card-text>

                    <v-card-actions>
                    <v-spacer></v-spacer>
                    <template  v-if="item.edit=='view'">
                        <v-btn v-if="item.activeFaculty == 1 && item.activeBranch == 1" color="primary" dark @click="edit">{{
                            $t("EDIT")
                        }}</v-btn>
                      <v-btn v-if="item.onlineFees==0" color="primary" dark @click="submit">{{
                            $t("SAVE")
                        }}</v-btn>
                    </template>
                    <template v-else>
                        <v-btn color="primary" dark @click="submit">{{
                            $t("SAVE")
                        }}</v-btn>
                    </template>
                    <v-btn color="darken3" class="white--text" @click="cancel">{{
                        $t("CLOSE")
                    }}</v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>
        <pr-confirmDialog ref="confirm" :persistentDialog="true"></pr-confirmDialog>
        <pr-show-files
            v-if="imagePreview"
            :on="imagePreview"
            :src="savedDepositImageURL"
            @close="closeModal()"
            style="z-index: 999;"
        ></pr-show-files>
    </div>
</template>

<script>
import serverTranslations from "@/mixins/serverTranslations";
import globalMethods from "@/mixins/globalMethods";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import ImagePreview from "@/components/shared/ImagePreview";

export default {
    name: "pr-pay-dialog",
    components:{
        "pr-confirmDialog": ConfirmDialog,
        "pr-show-files": ImagePreview
    },
    mixins: [serverTranslations,globalMethods],
    props: {
        item: Object,
    },
    created() {
        if (this.item.edit)
          this.getStudentCashData();
        else { // add new
            if ((this.item.academicYearId == this.item.yearId)
                && (new Date(this.item.endDate) < new Date(this.depositDate))) {
                this.depositDate = this.paymentDate = this.item.endDate;
            }
        }
    },
    data() {
        return {
          depositeImageDelete:false,
            imagePreview:false,
            depositImageLink:false,
            showAlert: false,
            errorMessages: "",
            dialog:true,
            depositMenu: false,
            paymentMenu: false,
            timeMenu: false,
            depositDate: new Date().toISOString().substr(0, 10),
            paymentDate: new Date().toISOString().substr(0, 10),
            paymentTime: new Date().toLocaleTimeString('en-GB',{ hour: "numeric",minute: "numeric"}),
            depositImage:null,
            savedDepositImageURL:null,
            depositNum:"",
            depositImageRule:this.$store.state.Rules.requiredRules
            .concat(this.$store.state.Rules.photoRules),
            depositNumRules: this.$store.state.Rules.searchRules
            .concat(this.$store.state.Rules.requiredRules),
            titleDialog: this.item.edit ? this.$t("SHOW") : this.$t("ADD_NEW")
        }
    },
    computed: {
        minDate() {
            let minDate = new Date(new Date().getFullYear(),0,2);
            if (this.item.academicYearId == this.item.yearId)
                minDate = new Date(this.item.startDate);
            return minDate.toISOString();
        },
        maxDate() {
            const today = new Date(new Date().toISOString().substr(0, 10));
            const academicYearBool = (this.item.academicYearId == this.item.yearId)
            if(new Date(this.item.endDate) < today && academicYearBool){
                return new Date(this.item.endDate).toISOString().substr(0, 10);
            }
            return new Date().toISOString().substr(0, 10);
        },
        maxTime() {
            if (this.paymentDate === new Date().toISOString().substr(0, 10)){
                return new Date().toLocaleTimeString('en-GB',
                { hour: "numeric",minute: "numeric"})
            }
            return null
        }
    },
    methods:{
        edit() {
            this.cancel();
            this.item.edit = 'edit';
            this.dialog =true;
            this.titleDialog = this.$t("EDIT");
            return this.item.edit;
        },
        closeModal() {
            this.imagePreview = false;
        },
        deleteImage() {
            this.$refs.confirm
            .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION", {
            width: "410px"
            })
            .then(confirm => {
                if (confirm) {
                    this.depositImageLink = false;
                    this.depositImage = null;
                    this.savedDepositImageURL = null;
                }
            });
        },
        feesPayment(header,formData,payConfirm) {
            this.cancel();
            this.$refs.confirm
            .open("CONFIRMATION_MESSAGE", payConfirm,{width:"500px"})
            .then(confirm => {
                if (confirm) {
                    const request = this.item.edit
                    ? this.$http.put("/api/students_admission_apps/update_cash_payment/",formData,header)
                    : this.$http.post("/api/students_admission_apps/pay_student_fees/",formData,header);
                    request.then(
                        res=>{
                            this.showToasted("success",res.data.data.message);
                            this.$emit('searchAppliactions',true);
                        },
                        err => {
                            if (err.response.status === 404) {
                                this.showToasted("error",err.response.data.message);
                                this.$emit('searchAppliactions',true);
                            }
                            else {
                                this.dialog = true;
                                this.errorMessages = err.response.data.message;
                                this.showAlert = true;
                            }
                        }
                    );
                } else { this.dialog = true;}
            });
        },
        getStudentCashData() {
            const {applicationId,studentId,semesterId,yearId} = this.item
            this.$http.post("/api/students_admission_apps/get_student_cash_payment/",this.item)
            .then(
                res=>{
                    res =res.data.data
                    this.depositImageLink = res.depositImage ? true: false;
                    this.depositImage = res.depositImage;
                    this.savedDepositImageURL = res.depositImage;
                    this.depositNum  = res.depositNum;
                    this.depositDate = res.depositDate;
                    this.paymentDate = res.paymentDate?new Date(res.paymentDate).toISOString().substr(0, 10):'';
                    this.paymentTime = res.paymentDate?new Date(res.paymentDate).toLocaleTimeString('en-GB',
                    { hour: "numeric",minute: "numeric"}):'';
                },
                err => {
                    this.showToasted("error",err.response.data.message);
                    this.$emit('searchAppliactions',true);
                }
            );

        },
        cancel() {
            this.dialog = false;
            this.errorMessages = "";
            this.showAlert = false;
        },
        submit() {
          if(this.item.depositImage==this.depositImage && this.item.onlineFees==0){
            this.showAlert = true
      this.errorMessages = 'لا يوجد تعديلات'
      return
          }
            if (this.$refs.form.validate()) {
                let pay = "دفع|pay"
                const {applyId,studentId,studentName,identityNumber} = this.item
                const data = {
                    applicationId:applyId,
                    onlineFees:this.item.onlineFees,
                    studentId:studentId,
                    depositDate:this.depositDate,
                    depositNum:this.depositNum,
                    paymentDate: this.paymentDate +" "+ this.paymentTime + ":00",
                    semesterId:this.item.semesterId,
                    yearId:this.item.yearId
                }
                if(this.item.edit) {
                    pay = "تعديل دفع|edit the pay"
                }
                const payConfirm = this.$t("PAY_FEE_CONFIRM",
                {
                    pay:this.serverTranslate(pay),
                    applicationId:applyId,
                    studentName:this.serverTranslate(studentName),
                    idCard:identityNumber
                });
                const formData = new FormData();
                formData.append("depositImage",this.depositImage || "");
                formData.append('data',JSON.stringify(data));
                const header = { headers: {
                    "Content-Type": `multipart/form-data; boundary=${formData._boundary}`
                    }
                }
                this.feesPayment(header,formData,payConfirm);
            }
        }
    }
}
</script>
