<template>
    <v-card class="mx-auto" outlined>
      <div id="inspire">
     
        <v-data-table
          :headers="headers"
          :items="news"
          sort-by="name"
          class="elevation-1"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-arrow-left',
            nextIcon: 'mdi-arrow-right',
            itemsPerPageOptions: [5, 10, 15, 50, 100]
          }"
          :items-per-page="10"
          :hide-default-footer="news.length <= 0"
        >
    
          <template v-slot:top>
           
            <v-toolbar flat>
              <v-toolbar-title>
                <p color="white" class="mb-0">{{ $t("NEWS") }}</p>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="90vh">
                <template v-slot:activator="{ on }">
                  <v-btn 
                  color="secondary" 
                  dark v-on="on"
                  :small="!$vuetify.breakpoint.mdAndUp"
                  :fab="!$vuetify.breakpoint.mdAndUp"
                  >
                    <template v-if="$vuetify.breakpoint.mdAndUp"> {{$t("ADD_NEW")}} </template>
                    <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-plus-circle</v-icon>
                  </v-btn>
           
                </template>
                <v-card outlined>
                  <v-form ref="form" v-model="valid" lazy-validation>
                    <v-card-title class="justify-center">
                      <span class="headline white--text text-thin">{{
                        formTitle
                      }}</span>
                    </v-card-title>
  
                    <v-card-text>
                      <v-container>
                        <v-alert v-show="showAlert" dense outlined type="error">{{
                          serverTranslate(errorMessages)
                        }}</v-alert>
                        <v-row>
                          <v-col cols="12">
                            <pr-scope key="componentKey" :scopeFullPath="true"/>
                          </v-col>
                          <v-col cols="12" class="ma-0" sm="12" md="12" lg="12">
                              <v-textarea
                                counter="255"
                                maxlength="255"
                                    outlined
                                  small-chips
                                  :label="$t('NEW_TEXT_ARABIC')"
                                  rows="3"
                                  row-height="25"
                                  :rules="[...requiredRules]"
                                  v-model="editedNewItem.arName"
                              ></v-textarea>
                          </v-col>
                          <v-col cols="12" class="ma-0" sm="12" md="12" lg="12">
                            <v-textarea
                              counter="255"
                              maxlength="255"
                                  outlined
                                small-chips
                                :label="$t('NEW_TEXT_ENGLISH')"
                                rows="3"
                                row-height="25"
                                :rules="[...requiredRules]"
                                v-model="editedNewItem.enName"
                            ></v-textarea>
                        </v-col>
                     
                   
                        
                          <v-col cols="12" class="ma-0" xs="12" sm="6" md="6" lg="6">
                            <v-text-field
                            type="number"
                            outlined
                            :label="$t('ORDER_NEW')"
                            :rules="[...requiredRules, minRule]"
                            v-model="editedNewItem.order"
                          ></v-text-field>
                          </v-col>
                          <v-col cols="12">
                            {{ $t("ACTIVATION") }}
                            <v-icon
                              large
                              v-if="!editedNewItem.active"
                              @click="editedNewItem.active = !editedNewItem.active"
                              >toggle_off</v-icon
                            >
                            <v-icon
                              color="success"
                              large
                              v-if="editedNewItem.active"
                              @click="editedNewItem.active = !editedNewItem.active"
                              >toggle_on</v-icon
                            >
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                  
                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark @click="save">{{
                        $t("SAVE")
                      }}</v-btn>
  
                      <v-btn color="darken3" class="white--text" @click="close">{{
                        $t("CANCEL")
                      }}</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon meduim color="primary" @click="editNewItem(item)"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("EDIT") }}</span>
            </v-tooltip>
  
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon
                    meduim
                    color="delete"
                    class="mx-2"
                    @click="deleteNew(item)"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("DELETE") }}</span>
            </v-tooltip>
            <v-tooltip bottom v-if="!item.is_active">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon
                    meduim
                    v-if="!item.is_active"
                    @click="toggleActivationItem(item)"
                    >toggle_off</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("ACTIVATE") }}</span>
            </v-tooltip>
            <v-icon
              color="success"
              v-if="item.is_active"
              meduim
              @click="toggleActivationItem(item)"
              >toggle_on</v-icon
            >
          </template>
          <template v-slot:footer.page-text="props">
              {{ props.pageStart }} - {{ props.pageStop }} {{$t('PAGE_TEXT')}} {{ props.itemsLength }}
          </template>
          <template v-slot:no-data>
              <v-alert icon="warning" class="mt-2" color="light5">
              {{ $t("NO_DATA") }}
              </v-alert>
          </template>
        </v-data-table>
        <pr-confirmDialog ref="confirm"></pr-confirmDialog>
      </div>
    </v-card>
  </template>
  <script>
  import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
  import globalMethodsMixin from "@/mixins/globalMethods.ts";
  import ConfirmDialog from "@/components/shared/ConfirmDialog";
  import scope from "@/components/shared/ScopeTree.vue"
  
  export default {
    name: "pr-certification-types",
    components: {
      "pr-confirmDialog": ConfirmDialog,
      "pr-scope":scope
    },
    mixins: [serverTranslationsMixin,globalMethodsMixin],
    data() {
      return {
        requiredRules: this.$store.state.Rules.requiredRules,
        arField:this.$store.state.Rules.arFieldRule,
        enField:this.$store.state.Rules.enFieldRule,
        minRule: v => v >= 1 ||this.$t("MIN_VALIDATION"),
        dialog: false,
        valid: true,
        showAlert: false,
        errorMessages: "",
        news: [],
        newsIds:[],
        NewIndex: -1,
        editedNewItem: {
          arName: "",
          enName: "",
          active:true, 
          order:"",
        },
        defaultNewItem: {
          arName: "",
          enName: "",
          active:true, 
          order:"",
        },
      };
    },
    computed: {
      formTitle() {
        return this.NewIndex === -1
          ? this.$t("ADD_NEW") + " " + this.$t("CALENDAR_PERIODS")
          : this.$t("EDIT") + " " + this.$t("CALENDAR_PERIODS");
      },
      direction: function() {
        return this.$store.state.direction;
      },
   
      headers: function() {
        return [
          {
            text: this.$t("ORDER_NEW"),
            align: "start",
            sortable: true,
            value: "order"
          },
          {
            text: this.$t("NEW_TEXT_ARABIC"),
            align: "start",
            sortable: true,
            value: "name_arabic"
          },
          {
            text: this.$t("NEW_TEXT_ENGLISH"),
            align: "start",
            sortable: true,
            value: "name_english"
          },
          {
            text: this.$t("ACTIONS"),
            value: "actions",
            sortable: false,
            width: "150px"
          }
        ];
      }
    },
  
    watch: {
      dialog(val) {
        val || this.close();
      }
    },
  
    created() {
      this.initialize();
    },
  
    methods: {
      
      initialize() {
        this.$http.get("/api/news/list_news_data/").then(
          res => {
         
            this.news = res.data.data.news;
            for (let i = 0; i < this.news.length; i++) {
              this.newsIds.push(this.news[i]['id'])
            }
          },
          err => {
            this.showToasted("error",err.response.data.message);
          }
        );
      },
      deleteNew(newItem) {
              this.$refs.confirm
          .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
          .then(confirm => {
            if (confirm) {
              const id = { id: newItem.id };
              this.$http.delete(`/api/news/delete_new/${newItem.id}/`, { data: id }).then(
                res => {
                  this.showToasted("success", res.data.message)
                  this.initialize();
                },
                err => {
                   this.showToasted("error", err.response.data.message)
                  if (err.response.status === 404) {
                    this.initialize();
                  }
                }
              );
            }
          });
  
  
          //delete
      },
  
     
  
      close() {
        this.$refs.form.resetValidation();
        this.dialog = false;
        this.showAlert = false;
        this.errorMessages = "";
        this.editedNewItem = Object.assign({}, this.defaultNewItem);
        this.NewIndex = -1;
      },
  
  
  
      save() {
        if (this.$refs.form.validate()) {
          if (this.editedNewItem.id > -1) { // edit case
            this.updateNew();
          } else { /// add case
            this.$http.post(
              "/api/news/create_new/",
              this.editedNewItem
            ).then(
              res => {
                this.showToasted("success",res.data.message);
                this.close();
                this.initialize();
              },
              err => {
                this.errorMessages = err.response.data.message;
                this.showAlert = true;
                this.initialize();
              }
            );
          }
        }
      },
      
    toggleActivationItem(item) {
        const newObj = {
          arName: item.name_arabic,
          enName: item.name_english,
          active:!item.is_active, 
          order:item.order
        };
      const data = { active: !item.active, updateAll: false, id: item.id };
      this.$http.put(`/api/news/update_new/${item.id}/`, newObj).then(
        res => {
          this.initialize();
        },
        err => {
           this.showToasted("error", err.response.data.message)
          this.initialize();
        }
      );
    },
  
      editNewItem(newItem) {
        this.NewIndex = this.news.indexOf(newItem.id);
        this.dialog = true;
        this.editedNewItem.id=newItem.id;
        this.editedNewItem.arName = newItem.name_arabic;
        this.editedNewItem.enName = newItem.name_english;
        this.editedNewItem.active=newItem.is_active;
        this.editedNewItem.order = newItem.order
      },
  
      updateNew() {
        this.$http.put(
           `/api/news/update_new/${this.editedNewItem.id}/`,this.editedNewItem
        ).then(
          res => {
            this.showToasted("success",res.data.message);
            this.close();
            this.initialize();
          },
          err => {
            this.errorMessages = err.response.data.message;
            this.showAlert = true;
            this.initialize();
          }
        );
      },
     
    }
  };
  </script>
  