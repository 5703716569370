<template>
    <v-form ref="FormStep2">
        <v-overlay :value="overlay">
            <p>{{$t("PROCESSING")}}</p>
        </v-overlay>
        <v-row  class="justify-center">
            <v-col col2="12" lg="12">
                <v-alert type="info" >
                  {{$t("PULLING_STEPPER.STEP2.INFO")}}
                </v-alert>
            </v-col>
            <v-col cols="11" >
                <v-file-input
                    color="primary"
                    v-model="file"
                    counter
                    accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                    :label="$t('PULLING_STEPPER.STEP2.FILE')"
                    :placeholder="$t('PULLING_STEPPER.STEP2.FILE_PLACEHOLDER')"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :rules="[...excelRules,...requiredRule,error]"
                    :show-size="1000"
                  >
                    <template v-slot:selection="{ index, text }">
                      <v-chip
                        color="primary"
                        dark
                        label
                        small
                      >
                        {{ text }}
                      </v-chip>
                    </template>
                  </v-file-input>
            </v-col>
            <v-col cols="1">
                <v-icon color="primary"
                :disabled="!fileData || fileData.length == 0 "
                @click="dialog = true;">mdi-eye</v-icon>
            </v-col>
        </v-row>

        <v-row>
              <v-col cols="12" class="justify-end d-flex">

                  <v-btn color="error"
                         @click="cancelPulling()"
                         class="ma-1" >{{$t("CANCEL")}}</v-btn>

                  <v-btn class="ma-1"
                      color="secondary"
                      @click="previousStep()"
                    >
                      {{$t("PULLING_STEPPER.PREVIOUS")}}
                    </v-btn>

                  <v-btn class="ma-1"
                      color="primary"
                      @click="nextStep()"
                    >
                      {{$t("PULLING_STEPPER.NEXT")}}
                    </v-btn>

              </v-col>
          </v-row>

        <v-dialog v-model="dialog" fullscreen hide-overlay transition="dialog-bottom-transition">
          <v-card>
            <v-toolbar dark color="primary" class="justify-end">
              <v-toolbar-title>{{$t("PULLING_STEPPER.STEP2.SHOW_DATA")}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn icon dark @click="dialog = false">
                    <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-divider></v-divider>
                <v-simple-table fixed-header >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" v-for="(item,index) in header" :key="index">
                              {{item}}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(innerData,index) in fileData.slice(0,20)" :key="index">
                          <td class="text-left" v-for="(item,innerIndex) in innerData" :key="innerIndex">
                              {{item}}
                          </td>
                        </tr>
                      </tbody>
                         <tfoot>
                            <tr v-if="fileData.length > 20">
                                <td :colspan="header.length">
                                    <span class="h1 d-flex justify-start px-5 font-weight-black">
                                        {{$t("PULLING_STEPPER.MORE_DATA",{num:fileData.slice(20).length})}}
                                    </span>
                                </td>
                            </tr>
                        </tfoot>
                    </template>
                </v-simple-table>
          </v-card>
        </v-dialog>

    </v-form>
</template>
<script>
    import { EventBus } from "@/plugins/bus";
    import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
    import globalMethodsMixin from "@/mixins/globalMethods";
    import XLSX from 'xlsx';
    export default {
        name: "pr-step2",
        mixins: [serverTranslationsMixin, globalMethodsMixin],
        data(){
            return {
                file : null,
                table:"",
                dialog:false,
                fileData:[],
                header:[],
                overlay:false,
                requiredRule: this.$store.state.Rules.requiredRules,
                error:true,
                excelRules: [
                     v => {
                        if (!!v && v instanceof File) {
                          const extensions = /(\.xls|\.xlsx)$/i;
                          return !extensions.test(v.name)
                            ? this.$t("EXCEL_EXTENTION_VALIDATION")
                            : (v.size / 1000000 > 6)
                            ? this.$t("PULLING_STEPPER.FILE_SIZE") :true;
                        }
                        return true;
                    }
                 ]

            }
        },
        created(){
             EventBus.$on("resetFile",()=>{
                 this.file = null;
             });
        },
        watch:{
            file(){
                 this.header = [] ;
                this.data = [];
                if(!this.file || this.file.size / 1000000 > 6){
                    this.fileData = [] ;
                    this.header = [];
                    return false;

                }
                this.overlay = true;
                this.handelFile().then(()=>{
                    this.overlay = false;
                });

            },
            fileData(){
                if(this.fileData && this.fileData.length < 2)
                    this.error = this.$t("PULLING_STEPPER.ROWS_COUNT");
                else
                    this.error = true;
            }
        },
        methods:{
            cancelPulling(){
              EventBus.$emit("CancelMap");
            },
            nextStep(){
                if (this.$refs.FormStep2.validate())
                    EventBus.$emit("changeStepperIndex",3);
            },
            previousStep(){
                EventBus.$emit("changeStepperIndex",1);
            },
            handelFile(){
               return new Promise(resolve=>{
                    const reader = new FileReader();
                    reader.onload = e => {
                      const data = e.target.result;
                      const workbook = XLSX.read(data, {type: 'binary'});
                      const  sheets = workbook.SheetNames;
                      const  header = workbook.Strings;
                      const  sheetContent = workbook.Sheets[sheets[0]];
                      let sheetData = XLSX.utils.sheet_to_json(sheetContent, {header:1,defval:''});
                      sheetData = sheetData.filter(row=>row.length > 0);
                      this.$emit("change",sheetData);
                      this.fileData = sheetData.slice(1)
                      this.header = sheetData[0];
                        resolve(true);
                    }
                   reader.readAsBinaryString(this.file);
               });
            }
        }
    }
</script>