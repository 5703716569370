import Vue from "vue";
import Vuex from "vuex";
import Auth from "./auth";
import Scope from "./scope";
import vuexI18n from "vuex-i18n";
import translationsEn from "@/locale/en";
import translationsAr from "@/locale/ar";
import Rules from "./rules";
Vue.use(Vuex);
const state = {
  curLanguage: { short: "ar", long: "Arabic" },
  languages: [
    {
      short: "ar",
      long: "Arabic"
    },
    {
      short: "en",
      long: "English"
    }
  ],
  direction: true,
  scopeName: localStorage.getItem("scopeName"),
  systemName: localStorage.getItem("systemName"),
  scopeID: localStorage.getItem("scopeID"),
  scopeLogo: localStorage.getItem("scopeLogo"),
  systemLogo: localStorage.getItem("systemLogo"),
  activeAcademicYear: localStorage.getItem("activeAcademicYear") || "{}",
  activeAcademicSemester: localStorage.getItem("activeAcademicSemester") || "{}",
};

const mutations = {
  setLanguage(state, lang) {
    state.curLanguage = lang;
    state.direction = lang == "ar" ? true : false;
  },
  setUniversity(state, payload) {
    state.scopeName = payload.scopeName;
    state.scopeLogo = payload.scopeLogo;
    state.systemLogo = payload.systemLogo;
    state.systemName = payload.systemName;
    state.scopeID = payload.scopeID;
  },

  setActiveYearAndSemester(state, payload) {
    state.activeAcademicYear = payload.activeAcademicYear;
    localStorage.setItem("activeAcademicYear", JSON.stringify(state.activeAcademicYear))
    state.activeAcademicSemester = payload.activeAcademicSemester;
    localStorage.setItem("activeAcademicSemester", JSON.stringify(state.activeAcademicSemester))
  },
};

const getters = {
  getActiveAcademicYear(state) {
    return (typeof state.activeAcademicYear === "object")
        ? state.activeAcademicYear
        : JSON.parse(state.activeAcademicYear);
  },
  getActiveAcademicSemester(state) {
    return (typeof state.activeAcademicSemester === "object")
        ? state.activeAcademicSemester
        : JSON.parse(state.activeAcademicSemester);
  },

  getFacultyID(state: any) {
    return parseInt(state.facultyID);
  }
};

const store = new Vuex.Store({
  state,
  mutations,
  getters,
  modules: {
    Auth,
    Rules,
    Scope
  }
});
Vue.use(vuexI18n.plugin, store);
Vue.i18n.add("en", translationsEn as any);
Vue.i18n.add("ar", translationsAr as any);
Vue.i18n.set(localStorage.lang);
export default store;
