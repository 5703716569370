<template>
  <v-card class="mx-auto" outlined>
    <div id="inspire">
      <v-data-table
        :headers="headers"
        :items="sectors"
        hide-default-footer
        class="elevation-1"
        :sort-by="'arName'"
        :sort-asc="true"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>{{ $t("SECTORS") }}</v-toolbar-title>
            <v-divider class="mx-2" inset vertical></v-divider>
            <v-spacer></v-spacer>
            <v-dialog v-model="dialog" max-width="90vh">
              <template v-slot:activator="{ on }">
                <v-btn
                  v-on="on"
                  color="secondary white--text"
                  @keydown.esc="dialog = false"
                  >{{ $t("ADD_NEW") }}</v-btn
                >
              </template>
              <v-card>
                <v-form ref="form">
                  <v-card-title class="justify-center">
                    <span class="headline white--text text-thin">{{
                      $t(formTitle)
                    }}</span>
                  </v-card-title>
                  <v-card-text>
                    <v-container>
                      <v-alert v-show="showAlert" dense outlined type="error">{{
                        serverTranslate(errorMessages)
                      }}</v-alert>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field
                            v-model="sectorData.arName"
                            @keydown.space="preventLeadingSpace"
                            :label="$t('AR_NAME')"
                            autofocus
                            required
                            maxlength="75"
                            minlength="5"
                            :rules="arSectorRules"
                            prepend-inner-icon="mdi-abjad-arabic"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          <v-text-field
                            v-model="sectorData.enName"
                            @keydown.space="preventLeadingSpace"
                            :label="$t('EN_NAME')"
                            required
                            maxlength="75"
                            minlength="5"
                            :rules="enSectorRules"
                            prepend-inner-icon="mdi-alpha-e-circle-outline"
                          ></v-text-field>
                        </v-col>
                        <v-col cols="12">
                          {{ $t("ACTIVATION") }}
                          <v-icon
                            large
                            v-if="!sectorData.active"
                            @click="sectorData.active = !sectorData.active"
                            >toggle_off</v-icon
                          >
                          <v-icon
                            color="success"
                            large
                            v-if="sectorData.active"
                            @click="sectorData.active = !sectorData.active"
                            >toggle_on</v-icon
                          >
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" dark @click.native="save">{{
                      $t("SAVE")
                    }}</v-btn>
                    <v-btn color="darken3" class="white--text" @click.native="close">{{
                      $t("CANCEL")
                    }}</v-btn>
                  </v-card-actions>
                </v-form>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon meduim color="edit" @click="editSectorItem(item)"
                  >mdi-pencil</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("EDIT") }}</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon
                  meduim
                  color="delete"
                  class="mx-2"
                  @click="deleteSectorItem(item)"
                  >mdi-delete</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("DELETE") }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!item.active">
            <template v-slot:activator="{ on }">
              <v-btn icon v-on="on">
                <v-icon
                  meduim
                  v-if="!item.active"
                  @click="toggleActivationItem(item)"
                  >toggle_off</v-icon
                >
              </v-btn>
            </template>
            <span>{{ $t("ACTIVATE") }}</span>
          </v-tooltip>
          <v-icon
            color="success"
            v-if="item.active"
            meduim
            @click="toggleActivationItem(item)"
            >toggle_on</v-icon
          >
        </template>
        <template slot="no-data">
          <v-alert :value="hasData" icon="warning" class="mt-2">{{
            $t("NO_DATA")
          }}</v-alert>
        </template>
      </v-data-table>
      <pr-confirmDialog ref="confirm"></pr-confirmDialog>
    </div>
  </v-card>
</template>
<script>
import globalMethodsMixin from "@/mixins/globalMethods.ts";
import serverTranslationsMixin from "@/mixins/serverTranslations";
import ConfirmDialog from "@/components/shared/ConfirmDialog";

export default {
  name: "pr-sectors-list",
  mixins: [serverTranslationsMixin, globalMethodsMixin],
  components: {
    "pr-confirmDialog": ConfirmDialog
  },
  data() {
    return {
      dialog: false,
      valid: true,
      showAlert: false,
      errorMessages: "",
      arSectorRules: [
        v => !!v || this.$t("REQUIRED_FIELD"),
        v =>
          /^[\u0621-\u064A0-9-\s(-?)\s]+[^*\s]$/.test(
            v != undefined && v.trim()
          ) || this.$t("ARABIC_FIELD"),
        v =>
          !(v != undefined && v.slice(0, 1) >= "0" && v.slice(0, 1) <= "9") ||
          this.$t("PREVENT_NUMBERS_IN_FIRST"),
        v => (v != undefined && v.trim().length > 4) || this.$t("MIN_FIELD") + 5
      ],
      enSectorRules: [
        v => !!v || this.$t("REQUIRED_FIELD"),
        v =>
          !(v != undefined && v.slice(0, 1) >= "0" && v.slice(0, 1) <= "9") ||
          this.$t("PREVENT_NUMBERS_IN_FIRST"),
        v =>
          /^[a-zA-Z0-9(')(-?)\s]+[^*\s]$/.test(v != undefined && v.trim()) ||
          this.$t("ENGLISH_FIELD"),
        v => (v != undefined && v.trim().length > 4) || this.$t("MIN_FIELD") + 5
      ],
      sectorData: {
        arName: "",
        enName: "",
        active: true
      },
      sectors: [],
      editedIndex: -1,
      editedItem: {
        arName: "",
        enName: "",
        active: true,
        id: ""
      },
      defaultItem: {
        arName: "",
        enName: "",
        active: true
      },
      hasData: false
    };
  },
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "ADD_SECTOR" : "EDIT_SECTOR";
    },
    headers: function() {
      return [
        { text: this.$t("AR_NAME"), value: "arName", sortable: true },
        { text: this.$t("EN_NAME"), value: "enName", sortable: true },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "150px"
        }
      ];
    }
  },
  watch: {
    dialog(val) {
      val || this.close();
    }
  },
  created() {
    this.getSectors();
  },
  methods: {
    getSectors() {
      this.$http.get("/api/sectors/list_sectors/").then(
        response => {
          const dataR = response.data.data;
          this.sectors = [];
          if (dataR.length == 0) {
            this.hasData = true;
          } else {
            this.hasData = false;
            for (let i = 0; i < dataR.length; i++) {
              this.sectors.push({
                id: dataR[i]["id"],
                active: dataR[i]["active"],
                arName: dataR[i]["arName"],
                enName: dataR[i]["enName"]
              });
            }
          }
        },
        err => {
           this.showToasted("error", err.response.data.message)
        }
      );
    },
    editSectorItem(item) {
      this.editedIndex = this.sectors.indexOf(item);
      this.sectorData = Object.assign({}, item);
      this.dialog = true;
    },
    deleteSectorItem(item) {
      this.$refs.confirm
        .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
        .then(confirm => {
          if (confirm) {
            const id = { id: item.id };
            this.$http.delete("/api/sectors/api_sectors/", { data: id }).then(
              res => {
                this.showToasted("success", res.data.message)
                this.sectors = res.data.data;
                if (res.data.data.length == 0) {
                  this.hasData = true;
                } else {
                  this.hasData = false;
                }
              },
              err => {
                 this.showToasted("error", err.response.data.message)
                if (err.response.status === 404) {
                  this.getSectors();
                }
              }
            );
          }
        });
    },
    close() {
      this.dialog = false;
      this.showAlert = false;
      this.errorMessages = "";
      this.editedItem = Object.assign({}, this.defaultItem);
      this.editedIndex = -1;
      this.sectorData.active = true;
      this.$refs.form.reset();
    },
    preventLeadingSpace(e) {
      if (!e.target.value) {
        e.preventDefault();
      } else if (e.target.value[0] == " ") {
        e.target.value = e.target.value.replace(/^\s*/, "");
      }
    },

    save() {
      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateSectorService();
        } else {
          this.$http.post("/api/sectors/api_sectors/", this.sectorData).then(
            response => {
              this.showToasted("success", response.data.message)
              this.close();
              this.sectors = response.data.data;
            },
            err => {
              this.errorMessages = err.response.data.message;
              this.showAlert = true;
            }
          );
        }
      }
    },
    toggleActivationItem(item) {
      const data = { active: !item.active, updateAll: false, id: item.id };
      this.$http.put("/api/sectors/api_sectors/", data).then(
        res => {
           this.showToasted("success", res.data.message)
          this.sectors = res.data.data;
        },
        err => {
           this.showToasted("error", err.response.data.message)
          this.getSectors();
        }
      );
    },
    updateSectorService() {
      const editedItem = Object.assign({}, this.sectorData);
      editedItem.updateAll = true;
      this.$http.put("/api/sectors/api_sectors/", editedItem).then(
        res => {
           this.showToasted("success", res.data.message)
          this.close();
          this.sectors = res.data.data;
        },
        err => {
          this.errorMessages = err.response.data.message;
          this.showAlert = true;
          this.getSectors();
        }
      );
    }
  }
};
</script>
