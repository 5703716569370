<template>
  <v-row justify="center">
    <v-dialog
        v-model="dialog"
        fullscreen
        hide-overlay
        transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title>
            {{ $t("SHOW_APPLICATION_DETAILS") }}
          </v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn icon dark @click="dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container fluid>
          <img src="" alt=""/>
          <v-row v-if="!dataLoaded">
            <v-col class="d-flex justify-center align-center">
              <h1>{{ $t("DATA_LOADING") }}</h1>
            </v-col>
          </v-row>
          <v-row v-if="dataLoaded">
            <v-col>
              <v-card class="mx-auto elevation-0">
                <v-row class="justify-center">
                  <v-col cols="12" lg="3">
                    <p class="d-inline-block">
                      <span class="light-blue--text">
                        {{ $t("ADMISSION_DATA.APPLICATION_ID") + " : " }}
                      </span>
                      <span>{{ applicationId }}</span>
                    </p>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <p class="d-inline-block">
                      <span class="light-blue--text">
                        {{ $t("ADMISSION_DATA.APPLICATION_DATE") + " : " }}
                      </span>
                      <span>{{ data.applicationDate }}</span>
                    </p>
                  </v-col>
                  <v-col cols="12" lg="3">
                    <p class="d-inline-block">
                      <span class="light-blue--text">
                        {{ $t("ADMISSION_DATA.APP_STATUS") + " : " }}
                      </span>
                      <span>{{ applicationStatusText }}</span>
                    </p>
                  </v-col>
                </v-row>
                <v-expansion-panels v-model="opend" multiple>
                  <v-expansion-panel>
                    <v-expansion-panel-header
                        color="primary"
                        class="white--text"
                    >
                      {{ $t("ADMISSION_DATA.STEP1.PERSONAL_DATA") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="ma-2">
                        <v-col cols="12" lg="6">
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP1.AR_FULLNAME") }}:
                            </span>
                            <span>{{ data.arName }}</span>
                          </p>
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP1.EN_FULLNAME") }}:
                            </span>
                            <span>{{ data.enName }}</span>
                          </p>

                          <p v-if="data.personalImageURL">
                            <v-chip
                                class="ma-2 py-0"
                                color="primary"
                                text-color="white"
                                @click="imagePreview = !imagePreview;
                                showingFile = data.personalImageURL;"
                            >
                              <v-avatar left>
                                <v-icon>mdi-eye</v-icon>
                              </v-avatar>
                              {{ $t('ADMISSION_DATA.STEP1.IMAGE') }}
                            </v-chip>
                          </p>


                          <p class="col-lg-3 col-12 px-0 py-0 d-inline-block">
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP1.TYPE") + " : " }}
                            </span>
                            <span>{{
                                data.gender
                                    ? serverTranslate(data.gender.name)
                                    : ""
                              }}</span>
                          </p>
                          <p class="col-lg-7 col-12 px-0 py-0 d-inline-block">
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP1.ID") }}:
                            </span>
                            <span>{{ data.cardID }}</span>
                            <v-icon
                                color="primary"
                                @click="
                                editCardId = true;
                                cardID = data.cardID;
                              "
                                style="cursor:pointer;"
                            >
                              mdi-pencil
                            </v-icon>
                          </p>
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP1.BIRTHDATE") }}:
                            </span>
                            <span>
                              {{ data.birthdate }}
                            </span>
                          </p>
                          <p>
                            <span class="light-blue--text">
                              {{ $t("NATIONALITY") }}:
                            </span>
                            <span>
                              {{
                                data.nationality
                                    ? serverTranslate(data.nationality.name)
                                    : ""
                              }}
                            </span>
                          </p>
                          <p v-if="data.birthCertUrl">
                            <v-chip
                                class="mb-0"
                                color="primary"
                                text-color="white"
                                @click="
                                imagePreview = !imagePreview;
                                showingFile = data.birthCertUrl;
                              "
                            >
                              <v-avatar left>
                                <v-icon>mdi-eye</v-icon>
                              </v-avatar>
                              {{ $t("ADMISSION_DATA.STEP1.BIRTH_CERT") }}
                            </v-chip>
                          </p>
                          <p v-if="data.militaryFormUrl">
                            <v-chip
                                class="mb-0"
                                color="primary"
                                text-color="white"
                                @click="
                                imagePreview = !imagePreview;
                                showingFile = data.militaryFormUrl;
                              "
                            >
                              <v-avatar left>
                                <v-icon>mdi-eye</v-icon>
                              </v-avatar>
                              {{ $t("ADMISSION_DATA.STEP1.MILITARY_FORM") }}
                            </v-chip>
                          </p>
                        </v-col>
                        <v-col cols="12" lg="6">
                          <p class="light-blue--text">
                            {{ $t("ADMISSION_DATA.STEP1.CONTACTS") }}
                          </p>
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP1.TELEPHONE") }} /
                              {{ $t("ADMISSION_DATA.STEP1.PHONE") }} :
                            </span>
                            <bdi>
                              {{ data.mobile }}
                              {{ data.telephone && data.mobile ? "/" : "" }}
                              {{ data.telephone }}
                            </bdi>
                          </p>
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP1.EMAIL") }}:
                            </span>
                            <span> {{ data.email }} </span>
                            <v-icon
                                color="primary"
                                @click="
                                editEmail = true;
                                email = data.email;
                              "
                                style="cursor:pointer;"
                            >
                              mdi-pencil
                            </v-icon>
                          </p>

                          <p class="mb-0">
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP1.ADDRESS") }}:
                            </span>
                            <span>
                              {{
                                data.addressCountry
                                    ? serverTranslate(data.addressCountry.name)
                                    : ""
                              }}
                              {{
                                data.addressState
                                    ? "/" +
                                    serverTranslate(data.addressState.name)
                                    : ""
                              }}
                              {{
                                data.addressCity
                                    ? "/" + serverTranslate(data.addressCity.name)
                                    : ""
                              }}
                            </span>
                          </p>
                          <p class="mb-0">
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP1.BIRTHPLACE") }}:
                            </span>
                            <span>
                              {{
                                data.birthPlaceCountry
                                    ? serverTranslate(data.birthPlaceCountry.name)
                                    : ""
                              }}
                              {{
                                data.birthPlaceState
                                    ? "/" +
                                    serverTranslate(data.birthPlaceState.name)
                                    : ""
                              }}
                              {{
                                data.birthPlaceCity
                                    ? "/" +
                                    serverTranslate(data.birthPlaceCity.name)
                                    : ""
                              }}
                            </span>
                          </p>

                          <p v-if="data.cardImageUrl">
                            <v-chip
                                class="ma-2 py-0"
                                color="primary"
                                text-color="white"
                                @click="
                                imagePreview = !imagePreview;
                                showingFile = data.cardImageUrl;
                              "
                            >
                              <v-avatar left>
                                <v-icon>mdi-eye</v-icon>
                              </v-avatar>
                              {{ $t("ADMISSION_DATA.STEP1.ID_IMAGE") }}
                            </v-chip>
                          </p>
                          <p v-if="data.guardianCardUrl">
                            <v-chip
                                class="mb-0"
                                color="primary"
                                text-color="white"
                                @click="
                                imagePreview = !imagePreview;
                                showingFile = data.guardianCardUrl;
                              "
                            >
                              <v-avatar left>
                                <v-icon>mdi-eye</v-icon>
                              </v-avatar>
                              {{ $t("ADMISSION_DATA.STEP1.GUARDIAN_CARD") }}
                            </v-chip>
                          </p>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel class="mt-3">
                    <v-expansion-panel-header
                        color="primary"
                        class="white--text"
                    >
                      {{ $t("ADMISSION_DATA.STEP2.NAME") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="ma-2">
                        <v-col class="py-0" cols="12" lg="6">
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP2.CERTIFICATION") }}:
                            </span>
                            <span>
                              {{
                                data.certificateTypes
                                    ? serverTranslate(data.certificateTypes.name)
                                    : ""
                              }}
                            </span>
                          </p>
                        </v-col>
                        <v-col class="py-0" cols="12" lg="6">
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP2.MAJOR") }}:
                            </span>
                            <span>
                              {{
                                data.divisionType
                                    ? serverTranslate(data.divisionType.name)
                                    : ""
                              }}
                            </span>
                          </p>
                        </v-col>
                        <v-col
                            class="py-0"
                            cols="12"
                            lg="6"
                            v-if="data.equationCertificateTypes.name"
                        >
                          <p>
                            <span class="light-blue--text">
                              {{
                                $t("ADMISSION_DATA.STEP2.EQUIVALENT_CERT_TYPE")
                              }}:
                            </span>
                            <span>
                              {{
                                data.equationCertificateTypes
                                    ? serverTranslate(
                                        data.equationCertificateTypes.name
                                    )
                                    : ""
                              }}
                            </span>
                          </p>
                        </v-col>
                        <v-col
                            v-if="
                            data.equationCertificateName.name ||
                              data.otherCertificateText
                          "
                            class="py-0"
                            cols="12"
                            lg="6"
                        >
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP2.CERTIFICATE_NAME") }}:
                            </span>
                            <span>{{
                                serverTranslate(
                                    data.equationCertificateName.name
                                ) || data.otherCertificateText
                              }}</span>
                          </p>
                        </v-col>
                        <v-col class="py-0" cols="12" lg="6">
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP2.YEAR") }}:
                            </span>
                            <span>{{
                                data.preQualYear
                                    ? data.preQualYear.name.replace("|", "-")
                                    : ""
                              }}</span>
                          </p>
                        </v-col>
                        <v-col
                            class="py-0"
                            cols="12"
                            lg="6"
                            v-if="data.preQualCountry || data.preQualCountry.name"
                        >
                          <p>
                            <span class="light-blue--text">
                              {{
                                $t("ADMISSION_DATA.STEP2.CERT_FROM_COUNTRY")
                              }}:
                            </span>
                            <span>{{
                                serverTranslate(data.preQualCountry.name)
                              }}</span>
                          </p>
                        </v-col>

                        <v-col class="py-0" cols="12" lg="6">
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP2.SUM") }}:
                            </span>
                            <span>{{ data.preQualTotal }}</span>
                          </p>
                        </v-col>

                        <v-col class="py-0" cols="12" lg="6">
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP2.OTHER_SUM") }}:
                            </span>
                            <span>{{ data.submittedTotal }}</span>
                            <v-icon
                                color="primary"
                                @click="
                                editSubmittedTotal = true;
                                submittedTotal = data.submittedTotal;
                              "
                                style="cursor:pointer;"
                            >
                              mdi-pencil
                            </v-icon>
                          </p>
                        </v-col>


                        <v-col class="py-0" cols="12" lg="6" v-if="data.PreQualificationFileUrl">
                          <v-chip
                              @click="(data.PreQualificationFileExtension==='pdf'?openPdfDialog(data.PreQualificationFileUrl):imagePreview=!imagePreview,showingFile=data.PreQualificationFileUrl)"
                              class="ma-2"
                              color="primary"
                              text-color="white"
                          >
                            <v-avatar left>
                              <v-icon>mdi-eye</v-icon>
                            </v-avatar>
                            {{ $t('ADMISSION_DATA.STEP2.CERTIFICATE_FILE') }}
                          </v-chip>
                        </v-col>

                        <v-col class="py-0" cols="12" lg="6" v-if="data.residenceFile">
                          <v-chip
                              class="ma-2"
                              color="primary"
                              text-color="white"
                              @click="imagePreview=!imagePreview;showingFile=data.residenceFile;"
                          >
                            <v-avatar left>
                              <v-icon>mdi-eye</v-icon>
                            </v-avatar>
                            {{ $t('ADMISSION_DATA.STEP2.STU_RESIDENCE_IMAGE') }}
                          </v-chip>
                        </v-col>
                        <v-col class="py-0" cols="12" lg="6" v-if="data.guardianResidenceFile">
                          <v-chip
                              class="ma-2"
                              color="primary"
                              text-color="white"
                              @click="imagePreview=!imagePreview;showingFile=data.guardianResidenceFile;"
                          >
                            <v-avatar left>
                              <v-icon>mdi-eye</v-icon>
                            </v-avatar>
                            {{ $t('ADMISSION_DATA.STEP2.LEGAL_GUARDIAN_RESIDENCE_IMAGE') }}
                          </v-chip>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel class="mt-3">
                    <v-expansion-panel-header
                        color="primary"
                        class="white--text"
                    >
                      {{ $t("ADMISSION_DATA.STEP3.NAME") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-row class="ma-2">
                        <v-col cols="12" class="py-0" lg="6">
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP3.ACDYEAR") }}:
                            </span>
                            <span>{{
                                data.appYear
                                    ? serverTranslate(data.appYear.name)
                                    : ""
                              }}</span>
                          </p>
                        </v-col>
                        <v-col class="py-0" cols="12" lg="6">
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP3.SEMESTER") }}:
                            </span>
                            <span>{{
                                data.appSemeter
                                    ? serverTranslate(data.appSemeter.name)
                                    : ""
                              }}</span>
                          </p>
                        </v-col>
                        <v-col class="py-0" cols="12" lg="6">
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP3.FACULTY") }}:
                            </span>
                            <span>{{
                                serverTranslate(data.applicationFaculty.name)
                              }}</span>
                          </p>
                        </v-col>
                        <v-col class="py-0" cols="12" lg="6">
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP3.BRANCH") }}:
                            </span>
                            <span>{{
                                data.branch
                                    ? serverTranslate(data.branch.name)
                                    : ""
                              }}</span>
                          </p>
                        </v-col>
                        <v-col>
                          <v-chip v-if="data.paymentReceiptFile"
                                  class="ma-2"
                                  color="primary"
                                  text-color="white"
                                  @click="imagePreview=!imagePreview;showingFile=data.paymentReceiptFile;">
                            <v-avatar left>
                              <v-icon>mdi-eye</v-icon>
                            </v-avatar>
                            {{ $t('ADMISSION_DATA.STEP2.RECEIPT_IMAGE') }}
                          </v-chip>


                        </v-col>
                        <v-col class="py-0" cols="12" lg="6">
                          <p>
                            <span class="light-blue--text">
                              {{ $t("ADMISSION_DATA.STEP3.QUOTA") }}:
                            </span>
                            <span>{{
                                data.studentRegisterationType
                                    ? serverTranslate(data.studentRegisterationType.name)
                                    : ""
                              }}</span>

                            <v-icon
                                color="primary"
                                @click="
                              editSubmittedRegisterationType = true;
                              submittedRegisterationType = data.studentRegisterationType.id;
                            "
                                v-if="[sentUnderReview,acceptedStatus,pendingForFees,pulledCreditSystem].includes(data.applicationIdus)"
                                style="cursor:pointer;"
                            >
                              mdi-pencil
                            </v-icon>
                          </p>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel
                      class="mt-3"
                      v-if="
                      data.payment &&
                        (data.payment.status == 1 || data.payment.status == 0)
                    "
                  >
                    <v-expansion-panel-header
                        color="primary"
                        class="white--text"
                        v-if="data.applicationIdus !== '7'"
                    >
                      {{ $t("ADMISSION_DATA.STEP4.TABLETITLE") }}
                    </v-expansion-panel-header>
                    <v-expansion-panel-content
                        v-if="data.applicationIdus !== '7'"
                    >


                      <v-row class="ma-2">
                        <v-col>
                          <v-simple-table
                              width="100%"
                              elevation="10"
                              class="rounded-0"
                              style="border: none!important;"
                          >
                            <template v-slot:default>
                              <thead>
                              <tr>
                                <th
                                    class="text-center"
                                    :class="{ 'd-none': !isPaid }"
                                >
                                  <strong>{{
                                      $t("ADMISSION_DATA.STEP4.PAIDDATE")
                                    }}</strong>
                                </th>
                                <th class="text-center">
                                  <strong>
                                    {{
                                      $t("ADMISSION_DATA.STEP4.REFERENCENUM")
                                    }}</strong
                                  >
                                </th>
                                <th
                                    class="text-center"
                                    :class="{ 'd-none': isPaid }"
                                >
                                  <strong>
                                    {{
                                      $t("ADMISSION_DATA.STEP4.EXPIRYPERIOD")
                                    }}</strong
                                  >
                                </th>
                                <th class="text-center">
                                  <strong>
                                    {{
                                      $t("ADMISSION_DATA.STEP4.PAID")
                                    }}</strong
                                  >
                                </th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr>
                                <td
                                    class="text-center"
                                    :class="{ 'd-none': !isPaid }"
                                >
                                  {{
                                    data.payment && data.payment.paymentDate
                                        ? data.payment.paymentDate
                                        : ""
                                  }}
                                </td>
                                <td class="text-center">
                                  {{
                                    data.payment &&
                                    data.payment.referenceNumber
                                        ? data.payment.referenceNumber
                                        : ""
                                  }}
                                </td>
                                <td
                                    class="text-center"
                                    :class="{ 'd-none': isPaid }"
                                >
                                  {{ $t("ADMISSION_DATA.STEP4.PERIOD") }}
                                </td>
                                <td class="text-center">
                                  <v-icon
                                      v-if="
                                        data.payment &&
                                          data.payment.status === 1
                                      "
                                      color="success"
                                  >mdi-check-outline
                                  </v-icon
                                  >
                                  <v-icon v-else color="error"
                                  >mdi-close-outline
                                  </v-icon
                                  >
                                </td>
                              </tr>
                              </tbody>
                            </template>
                          </v-simple-table>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>
                  </v-expansion-panel>

                  <v-expansion-panel
                      class="mt-3"
                  >
                    <v-expansion-panel-header
                        color="primary"
                        class="white--text"
                    >
                      {{ $t("APPLICATION_ACTIONS") }}
                    </v-expansion-panel-header>

                    <v-expansion-panel-content
                        v-if="!['0','2', '3', '4', '8','10', 2, 3, 4, 8].includes(data.applicationIdus)"
                    >
                      <v-row class="ma-2">
                        <v-col class="d-flex justify-center flex-wrap">
                          <v-btn color="success" class="ma-1" @click="accept">
                            {{ $t("ACCEPT_APPLICATION") }}
                          </v-btn>
                          <v-btn color="error" class="ma-1" @click="reject">
                            {{ $t("REJECT_APPLICATION") }}
                          </v-btn>
                          <v-btn color="blue" class="ma-1" @click="WaitingList">
                            {{ $t("WAITING_APPLICATION") }}
                          </v-btn>
                          <v-btn
                              color="primary"
                              class="ma-1"
                              @click="feedbackDialog = true"
                          >
                            {{ $t("FEEDBACK_APPLICATION") }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-expansion-panel-content>

                    <!--to be edit-->
                    <v-row class="ma-2">
                      <v-col class="d-flex justify-center flex-wrap">

                        <v-expansion-panel-content
                            v-if="['2', 2,'10',10].includes(data.applicationIdus)"
                        >

                          <v-btn color="error" class="ma-1" @click="reject" :disabled="loadingBtn">
                            {{ $t("REJECT_APPLICATION") }}
                          </v-btn>
                        </v-expansion-panel-content>

                        <v-expansion-panel-content
                            v-if="['3', 3,'10',10].includes(data.applicationIdus)"
                        >
                          <!--                      <v-row class="ma-2">-->
                          <!--                        <v-col class="d-flex justify-center flex-wrap">-->
                          <v-btn color="success" class="ma-1" @click="accept" :disabled="loadingBtn">
                            {{ $t("ACCEPT_APPLICATION") }}
                          </v-btn>
                          <!--                        </v-col>-->
                          <!--                      </v-row>-->
                        </v-expansion-panel-content>

                        <v-expansion-panel-content
                            v-if="['2', 2,'3',3,5,'5','6',6,].includes(data.applicationIdus)"
                        >
                          <!--                      <v-row class="ma-2">-->
                          <!--                        <v-col class="d-flex justify-center flex-wrap">-->
                          <v-btn color="success" class="ma-1" @click="WaitingList" :disabled="loadingBtn">
                            {{ $t("WAITING_APPLICATION") }}
                          </v-btn>
                          <!--                        </v-col>-->
                          <!--                      </v-row>-->
                        </v-expansion-panel-content>
                      </v-col>
                    </v-row>


                    <!--to be edit-->

                  </v-expansion-panel>


                </v-expansion-panels>
              </v-card>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
      <ad-confirmDialog ref="confirm"></ad-confirmDialog>

      <ad-show-files
          v-if="imagePreview"
          :on="imagePreview"
          :src="showingFile"
          @close="closeModal()"
          style="z-index: 999;"
      ></ad-show-files>

      <v-dialog
          v-model="feedbackDialog"
          max-width="90vh"
          @keydown.esc="closeFeedBack"
          @click:outside="closeFeedBack"
      >
        <v-card outlined>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title class="justify-center">
              <span class="headline white--text text-thin">
                {{ $t("FEEDBACK_APPLICATION") }}
              </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <!--
                <v-alert v-show="showAlert" outlined type="error">
                  {{ serverTranslate(errorMessages) }}
                </v-alert>
-->
                <v-row>
                  <v-col cols="12">
                    <!--                    <v-checkbox-->
                    <!--                        ref="feedbackCheckbox"-->
                    <!--                        v-model="feedbackCheckbox"-->
                    <!--                        true-value="1"-->
                    <!--                        false-value="0"-->
                    <!--                        :label="$t('CONVERT_APPLICATION_CHECK')"-->
                    <!--                        class="d-inline-block my-2"-->
                    <!--                    />-->
                    <v-textarea
                        ref="feedbackMessage"
                        v-model="feedbackMessage"
                        :rules="feedbackMessageRules"
                        :label="$t('MESSAGE')"
                        maxlength="500"
                        counter
                        clearable
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  dark
                  @click="sendFeedback"
                  :disabled="sentNotification"
              >
                {{ $t("SEND_FEEDBACK") }}
              </v-btn>
              <v-btn color="darken3" class="white--text" @click="closeFeedBack">
                {{ $t("CANCEL") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-dialog v-model="editSubmittedRegisterationType"
                max-width="90vh"

      >
        <v-card outlined>

          <v-form ref="formQuota" v-model="valid" lazy-validation>
            <v-card-title class="justify-center">
            <span class="headline black--text text-thin">
              {{ $t("EDIT_QUOTA") }}
            </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-select
                        :items="
                    serverTranslateArray(quotaList,'quotaName')
                  "
                        :rules="quotaRule"
                        v-model="submittedRegisterationType"
                        item-value="quotaId"
                        item-text="quotaName"
                        :label="$t('ADMISSION_DATA.STEP3.QUOTA')"
                        outlined
                        clearable

                    ></v-select>

                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  dark
                  @click="updateStudentData('submittedRegisterationType', submittedRegisterationType)"
              >{{ $t("SAVE") }}
              </v-btn
              >
              <v-btn
                  color="darken3"
                  class="white--text"
                  @click="editSubmittedRegisterationType = false"
              >
                {{ $t("CANCEL") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>

      </v-dialog>

      <v-dialog
          v-model="editSubmittedTotal"
          max-width="90vh"
          @keydown.esc="submittedTotal = 0.0"
          @click:outside="submittedTotal = 0.0"
      >
        <v-card outlined>
          <v-form ref="form" v-model="valid" lazy-validation>
            <v-card-title class="justify-center">
              <span class="headline white--text text-thin">
                {{ $t("EDIT_SUBMITTED_TOTAL") }}
              </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <!--
                <v-alert v-show="showAlert" outlined type="error">
                  {{ serverTranslate(errorMessages) }}
                </v-alert>
-->
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        ref="submittedTotal"
                        v-model="submittedTotal"
                        :rules="submittedTotalRules"
                        :label="$t('ADMISSION_DATA.STEP2.OTHER_SUM')"
                        clearable
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  dark
                  @click="updateStudentData('submittedTotal', submittedTotal)"
              >{{ $t("SAVE") }}
              </v-btn
              >
              <v-btn
                  color="darken3"
                  class="white--text"
                  @click="editSubmittedTotal = false"
              >
                {{ $t("CANCEL") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="editEmail"
          max-width="90vh"
          @keydown.esc="email = ''"
          @click:outside="email = ''"
      >
        <v-card outlined>
          <v-form ref="formMail" v-model="valid" lazy-validation>
            <v-card-title class="justify-center">
              <span class="headline white--text text-thin">
                {{ $t("EDIT_STUDENT_EMAIL") }}
              </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        ref="email"
                        v-model="email"
                        :rules="emailRules"
                        :label="$t('ADMISSION_DATA.STEP1.EMAIL')"
                        clearable
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  dark
                  @click="updateStudentData('email', email)"
              >{{ $t("SAVE") }}
              </v-btn
              >
              <v-btn
                  color="darken3"
                  class="white--text"
                  @click="editEmail = false"
              >
                {{ $t("CANCEL") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>

      <v-dialog
          v-model="editCardId"
          max-width="90vh"
          @keydown.esc="cardID = ''"
          @click:outside="cardID = ''"
      >
        <v-card outlined>
          <v-form ref="formCardId" v-model="valid" lazy-validation>
            <v-card-title class="justify-center">
              <span class="headline white--text text-thin">
                {{ $t("EDIT_STUDENT_CARD_ID") }}
              </span>
            </v-card-title>

            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <v-text-field
                        ref="cardID"
                        v-model="cardID"
                        :rules="cardIdRules"
                        :label="$t('ADMISSION_DATA.STEP1.ID')"
                        clearable
                    />
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                  color="primary"
                  dark
                  @click="updateStudentData('card', cardID)"
              >{{ $t("SAVE") }}
              </v-btn
              >
              <v-btn
                  color="darken3"
                  class="white--text"
                  @click="editCardId = false"
              >
                {{ $t("CANCEL") }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-dialog>
    </v-dialog>
  </v-row>
</template>

<script>
import {EventBus} from "@/plugins/bus";
import ConfirmDialog from "@/components/shared/ConfirmDialog";
import ImagePreview from "@/components/shared/ImagePreview";
import serverTranslations from "@/mixins/serverTranslations";
import globalMethods from "@/mixins/globalMethods";
import Validations from "@/components/shared/validations";
import {APPLICATION_STATUS} from "@/enums.ts";

export default {
  name: "ShowApplicationDetails",
  components: {
    "ad-confirmDialog": ConfirmDialog,
    "ad-show-files": ImagePreview
  },
  mixins: [serverTranslations, globalMethods],
  computed: {
    isPaid() {
      return this.data.payment && this.data.payment.status === 1;
    },
    locale() {
      return localStorage.lang;
    },
    applicationStatusText() {
      return this.data.applicationIdus >= 0
          ? this.$t("CASES")[this.data.applicationIdus]
          : "";
    }
  },
  created() {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const that = this;
    EventBus.$on("showApplicationDetails", function (
        applicationId,
        studentId,
        isApplicationList = true
    ) {
      that.isApplicationList = isApplicationList;
      that.applicationId = applicationId;
      that.studentId = studentId;

      that.dialog = true;
      that.getApplicationData();
    });
  },
  data() {
    return {
      valid: true,
      showAlert: false,
      sentUnderReview: APPLICATION_STATUS.SENT_UNDER_REVIEW,
      acceptedStatus: APPLICATION_STATUS.ACCEPTED,
      pendingForFees: APPLICATION_STATUS.PENDING_FOR_FEES,
      pulledCreditSystem: APPLICATION_STATUS.PULLED_CREDIT_SYSTEM,
      errorMessages: "",
      studentId: 0,
      applicationId: "",
      loadingBtn: false,
      isApplicationList: true,
      dialog: false,
      dataLoaded: false,
      feedbackDialog: false,
      editSubmittedTotal: false,
      editSubmittedRegisterationType: false,
      editCardId: false,
      editEmail: false,
      feedbackMessage: "",
      feedbackSubjectNotification: "",
      sendMailToStudent: 0,
      feedbackCheckbox: "1",
      feedbackMessageRules: [
        v => this.isNotEmpty(v) || this.$t("REQUIRED_FIELD"),
        v => (!!v && v.trim().length > 10) || this.$t("MIN_FIELD") + "10",
        v => (!!v && v.trim().length <= 500) || this.$t("MAX_FIELD") + "500"
      ],
      cardIdRules: [
        v => this.isNotEmpty(v) || this.$t("REQUIRED_FIELD"),
        v => Validations.isNationalID(v) || this.$t("VALIDATIONS.INVALID_NATIONAL_ID")
      ],

      emailRules: [
        v => Validations.required(v) || this.$t("REQUIRED_FIELD"),
        v => Validations.isEmail(v) || this.$t("VALIDATIONS.EMAIL"),
        v =>
            Validations.maxLength(v, 50) ||
            this.$t("VALIDATIONS.MAX_LENGTH", {max: 50})
      ],
      quotaRule: [
        v => this.isNotEmpty(v) || this.$t("REQUIRED_FIELD"),
      ],

      submittedTotal: null,
      submittedRegisterationType: null,
      cardID: null,
      email: null,
      quotaList: [],
      submittedTotalRules: this.$store.state.Rules.totalRules,
      imagePreview: false,
      opend: [0, 1, 2, 3, 4, 5],
      showingFile: "",
      data: {
        applicationIdus: "",
        applicationId: "",
        nationality: "",
        gender: "",
        telephone: "",
        birthdate: "",
        studentName: "",
        appFaculty: "",
        email: "",
        addressCountry: "",
        addressState: "",
        addressCity: "",
        birthPlaceCountry: "",
        birthPlaceState: "",
        birthPlaceCity: "",
        preQualCountry: "",
        preQualYear: "",
        certificateTypes: "",
        equationCertificateTypes: "",
        equationCertificateName: "",
        otherCertificateText: "",
        preQualTotal: "",
        submittedTotal: 0.0,
        cardId: "",
        applicationFaculty: "",
        applicationDate: "",
        applicationStatus: 0,
        arName: "",
        enName: "",
        sentNotification: false
      }
    };
  },
  methods: {
    openPdfDialog(fileUrl) {
      const base64toBlob = data => {
        // Cut the prefix `data:application/pdf;base64` from the raw base 64
        const base64WithoutPrefix = data.substr('data:application/pdf;base64,'.length);
        const bytes = atob(base64WithoutPrefix);
        let length = bytes.length;
        const out = new Uint8Array(length);
        while (length--) {
          out[length] = bytes.charCodeAt(length);
        }

        return new Blob([out], {type: 'application/pdf'});
      };

      const blob = base64toBlob(fileUrl);
      const url = URL.createObjectURL(blob)
      window.open(url, '_blank')
    },
    getApplicationData() {
      this.$http
          .post("/api/students_admission_apps/application_details/", {
            applyId: this.applicationId,
            studentId: this.studentId
          })
          .then(
              res => {
                // get deletable flag from here
                Object.assign(this.data, res.data.data.application_details);
                this.submittedTotal = this.data.submittedTotal;
                this.cardID = this.data.cardId;
                this.email = this.data.email;
                this.quotaList = res.data.data.quotaList;
                this.studentName = this.data.studentName;
                this.applicationFaculty = this.data.applicationFaculty;

                if (res.data.data.messageNotification) {
                  this.feedbackMessage = res.data.data.messageNotification;
                }
                this.feedbackSubjectNotification =
                    res.data.data.subjectNotification;
                this.sendMailToStudent = res.data.data.sendMailToStudent;

                this.dataLoaded = true;
              },
              err => {
                this.dialog = false;
                this.dataLoaded = false;

                this.$toasted.show(
                    this.serverTranslate(err.response.data.message),
                    {
                      type: "error",
                      icon: "error_outline",
                      theme: "bubble",
                      position: "bottom-left",
                      duration: 5000
                    }
                );
              }
          );
    },
    accept() {

      this.$refs.confirm
          .open("CONFIRMATION_MESSAGE", "ACCEPT_APPLICATION_CONFIRM")
          .then(confirm => {
            if (confirm) {
              this.loadingBtn = true; // Disable the button
              this.acceptOrRejectApplication(1);
              this.loadingBtn = false;
            }
          });
    },
    reject() {
      this.$refs.confirm
          .open("CONFIRMATION_MESSAGE", "REJECT_APPLICATION_CONFIRM")
          .then(confirm => {
            if (confirm) {
              this.loadingBtn = true; // Disable the button
              this.acceptOrRejectApplication(0);
              this.loadingBtn = false;
            }
          });
    },
    WaitingList() {
      this.$refs.confirm
          .open("CONFIRMATION_MESSAGE", "WAITING_APPLICATION_CONFIRM")
          .then(confirm => {
            if (confirm) {
              this.loadingBtn = true; // Disable the button
              this.acceptOrRejectApplication(2);
              this.loadingBtn = false;
            }
          });
    },
    acceptOrRejectApplication(status) {
      this.$http
          .put("/api/students_admission_apps/accept_reject_application/", {
            applicationId: this.applicationId,
            studentId: this.studentId,
            action: status,
            applicationFaculty: this.applicationFaculty,
            studentName: this.serverTranslate(this.studentName)
          })
          .then(response => {
            this.dialog = false;
            this.showToasted("success", response.data.message);
            this.isApplicationList
                ? EventBus.$emit("updateApplicationsAfterAction")
                : EventBus.$emit("updateRunListAfterAction");
          })
          .catch(errors => {
            this.showToasted("error", errors.response.data.message);
          });
    },
    updateStudentData(name, value) {
      const cond =
          name === "email"
              ? !this.$refs.formMail.validate()
              : name === "card"
                  ? !this.$refs.formCardId.validate()
                  : name === "submittedRegisterationType"
                      ? !this.$refs.formQuota.validate()
                      : !this.$refs.form.validate();
      if (cond) {
        return;
      }
      this.$http
          .put("/api/students_admission_apps/update_student_details/", {
            studentId: this.studentId,
            applicationId: this.applicationId,
            [name]: value
          })
          .then(response => {
            this.editSubmittedTotal = false;
            this.editSubmittedRegisterationType = false;
            this.editEmail = false;
            this.editCardId = false;
            this.data.submittedTotal = this.submittedTotal;

            this.isApplicationList
                ? EventBus.$emit("updateApplicationsAfterAction")
                : EventBus.$emit("updateRunListAfterAction");
            this.getApplicationData();
            this.showToasted("success", response.data.message);
          })
          .catch(errors => {
            this.getApplicationData();
            this.showToasted("error", errors.response.data.message);
          });
    },
    sendFeedback() {
      if (!this.$refs.form.validate()) {
        this.showAlert = true;
        this.errorMessages = this.$t("COMPLETE_DATA_ERROR");

        return;
      }
      this.sentNotification = true;
      this.dialog = false;
      this.$http
          .post("/api/students_admission_apps/send_notifications/", {
            applicationId: this.applicationId,
            studentId: this.studentId,
            studentName: this.data.arName,
            sendingApplications: [{'applyId': this.applicationId, 'studentId': this.studentId}],
            message: this.feedbackMessage,
            subject: this.feedbackSubjectNotification,
            feedbackCheck: parseInt(this.feedbackCheckbox),
            sendMailToStudent: this.sendMailToStudent
          })
          .then(response => {
            this.closeFeedBack();
            this.showToasted("success", response.data.message);
            this.sentNotification = false;
            this.isApplicationList
                ? EventBus.$emit("updateApplicationsAfterAction")
                : EventBus.$emit("updateRunListAfterAction");
          })
          .catch(errors => {
            this.dialog = true;
            this.showToasted("error", errors.response.data.message);
          });
    },
    closeFeedBack() {
      this.$refs.form.reset();
      this.feedbackDialog = false;
      this.showAlert = false;
      this.errorMessages = "";
    },
    closeModal() {
      this.imagePreview = false;
    },
    returnBack() {
      this.$router.push({name: "ListApplications"});
    },
    isNotEmpty(str) {
      return !(!str || 0 === str.length || /^\s*$/.test(str));
    }
  },
  watch: {
    dialog(value) {
      if (value === false) {
        this.dataLoaded = false;
        this.data = {
          applicationIdus: "",
          applicationId: "",
          nationality: "",
          gender: "",
          telephone: "",
          birthdate: "",
          studentName: "",
          email: "",
          addressCountry: "",
          addressState: "",
          addressCity: "",
          birthPlaceCountry: "",
          birthPlaceState: "",
          birthPlaceCity: "",
          preQualCountry: "",
          preQualYear: "",
          certificateTypes: "",
          equationCertificateTypes: "",
          equationCertificateName: "",
          otherCertificateText: "",
          preQualTotal: "",
          applicationFaculty: "",
          applicationDate: "",
          applicationStatus: 0,
          arName: "",
          enName: ""
        };
      }
    }
  }
};
</script>

<style scoped></style>
