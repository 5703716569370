export default {
   methods: {
      serverTranslate(statement: string) {
            const lang = localStorage.lang ? localStorage.lang : "ar";
         if (!statement) return statement;
         if (lang == "ar") {
            statement =
            statement.indexOf("|") >= 0
            ? statement.split("|")[0] || statement.split("|")[1]
            : statement;
            return statement;
         } else {
            statement =
            statement.indexOf("|") >= 0
            ? statement.split("|")[1] || statement.split("|")[0]
            : statement;
            return statement;
         }
      },
      serverTranslateArray: function(data: any, key: string) {
         let translatedArray = [{}];
         translatedArray.pop();
         let filterdData = [{}];
         if(!data) return [];
         translatedArray = JSON.parse(JSON.stringify(data));
         filterdData = translatedArray.map(item => {
            item[key] = this.serverTranslate(item[key]);
            if (item["children"]) {
               item["children"].map(item => {
                  item[key] = this.serverTranslate(item[key]);
                  if (item["children"]) {
                     item["children"].map(item => {
                        item[key] = this.serverTranslate(item[key]);
                     });
                  }
               });
            }
            return item;
         });
         return filterdData;
      },
      serverTranslateArrayKeys: function(data, keys) {
         let translatedArray = [{}];
         translatedArray.pop();
         let filterdData = [{}];
         translatedArray = JSON.parse(JSON.stringify(data));
         filterdData = translatedArray.map(item => {
            for (let i = 0; i < keys.length; i++) {
               item[keys[i]] = this.serverTranslate(item[keys[i]])
            }
            return item
         });
         return filterdData;
      },
      serverTranslateDictKeyName(data){
         const keys = Object.keys(data);
         const result ={}
         for (let j=0; j < keys.length; j++) {
            const key = this.serverTranslate(keys[j]);
            result[key] = data[keys[j]];
         }
         return result
      },
      serverTranslateCustom(statement: string, lang: string) {
         if (!statement) return statement;
         if (lang == "ar") {
            statement =
            statement.indexOf("|") >= 0
            ? statement.split("|")[0] || statement.split("|")[1]
            : statement;
            return statement;
         } else {
            statement =
            statement.indexOf("|") >= 0
            ? statement.split("|")[1] || statement.split("|")[0]
            : statement;
            return statement;
         }
      },
   },
};
