<template>
    <v-card class="mx-auto" outlined>
      <div id="inspire">
     
        <v-data-table
          :headers="headers"
          :items="feesRuleItems"
          sort-by="name"
          class="elevation-1"
          :footer-props="{
            showFirstLastPage: true,
            firstIcon: 'mdi-arrow-collapse-left',
            lastIcon: 'mdi-arrow-collapse-right',
            prevIcon: 'mdi-arrow-left',
            nextIcon: 'mdi-arrow-right',
            itemsPerPageOptions: [5, 10, 15, 50, 100]
          }"
          :items-per-page="10"
          :hide-default-footer="feesRuleItems.length <= 0"
        >
    
          <template v-slot:top>
           
            <v-toolbar flat>
              <v-toolbar-title>
                <p color="white" class="mb-0">{{ $t("APPLICATION_FEES") }}</p>
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="90vh">
                <template v-slot:activator="{ on }">
                  <v-btn 
                  color="secondary" 
                  dark v-on="on"
                  :small="!$vuetify.breakpoint.mdAndUp"
                  :fab="!$vuetify.breakpoint.mdAndUp"
                  >
                    <template v-if="$vuetify.breakpoint.mdAndUp"> {{$t("ADD_NEW")}} </template>
                    <v-icon :right="$vuetify.breakpoint.mdAndUp">mdi-plus-circle</v-icon>
                  </v-btn>
           
                </template>
                <v-card outlined>
                  <v-form ref="form" v-model="valid" lazy-validation>
                  
                    <v-card-title class="justify-center">
                      <span class="headline white--text text-thin">{{
                        formTitle
                      }}</span>
                    </v-card-title>
  
                    <v-card-text>
                      <v-container>
                        <v-alert v-show="showAlert" dense outlined type="error">{{
                          serverTranslate(errorMessages)
                        }}</v-alert>
                        <v-row>
                            <v-col cols="12">
                              <pr-scope key="componentKey" :scopeFullPath="true"/>
                            </v-col>
                        </v-row>
                        <v-row >
                            <v-col cols="12"  class="pr-0 my-0" xs="12" sm="12" md="12" lg="12">
                              <v-radio-group class="font-weight-bold" v-model="editedFeeItem.paymentType" height="10" row @change="handlePaymentTypeChange">
                                  <v-radio  :label="$t('ONLINE_PAYMENT')" value="online"></v-radio>
                                  <v-radio  :label="$t('OFFLINE_PAYMENT')" value="receipt"></v-radio>
                              </v-radio-group>
                          </v-col>
                         </v-row>
                         <v-row v-if="isOnlinePayment">
                            <v-col cols="12"  class="pr-0 my-0" xs="12" sm="12" md="6" lg="6">
                              <v-radio-group class="font-weight-bold" v-model="editedFeeItem.paymentTool" height="10" row>
                                  <v-radio  :label="$t('FAWRY')" value="fawry"></v-radio>
                                  <v-radio  :label="$t('BANK')" value="bank"></v-radio>
                              </v-radio-group>
                          </v-col>
                         </v-row>
                         <v-row v-if="isOnlinePayment">
                         <v-col cols="12" class="ma-0" xs="12" sm="6" md="6" lg="6">
                            <v-text-field
                            type="number"
                            outlined
                            :label="$t('APPLICATION_FEES_VALUE')"
                            :rules="[...requiredRules, minRule]"
                            v-model="editedFeeItem.feeValue"
                          ></v-text-field>
                          </v-col>
                        </v-row>
                     
                      </v-container>
                    </v-card-text>
                    <v-card-actions>
                  
                      <v-spacer></v-spacer>
                      <v-btn color="primary" dark @click="save">{{
                        $t("SAVE")
                      }}</v-btn>
  
                      <v-btn color="darken3" class="white--text" @click="close">{{
                        $t("CANCEL")
                      }}</v-btn>
                    </v-card-actions>
                  </v-form>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.payment_method="{ item }">
            {{ serverTranslate(item.value2_translated) + '-' + serverTranslate(item.value3_translated) }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon meduim color="primary" @click="editFeeItem(item)"
                    >mdi-pencil</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("EDIT") }}</span>
            </v-tooltip>
  
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon
                    meduim
                    color="delete"
                    class="mx-2"
                    @click="deleteFeesRule(item)"
                    >mdi-delete</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("DELETE") }}</span>
            </v-tooltip>
            <!-- <v-tooltip bottom v-if="!item.is_active">
              <template v-slot:activator="{ on }">
                <v-btn icon v-on="on">
                  <v-icon
                    meduim
                    v-if="!item.is_active"
                    @click="toggleActivationItem(item)"
                    >toggle_off</v-icon
                  >
                </v-btn>
              </template>
              <span>{{ $t("ACTIVATE") }}</span>
            </v-tooltip> -->
            <!-- <v-icon
              color="success"
              v-if="item.is_active"
              meduim
              @click="toggleActivationItem(item)"
              >toggle_on</v-icon
            > -->
          </template>
          <template v-slot:footer.page-text="props">
              {{ props.pageStart }} - {{ props.pageStop }} {{$t('PAGE_TEXT')}} {{ props.itemsLength }}
          </template>
          <template v-slot:no-data>
              <v-alert icon="warning" class="mt-2" color="light5">
              {{ $t("NO_DATA") }}
              </v-alert>
          </template>
        </v-data-table>
        <pr-confirmDialog ref="confirm"></pr-confirmDialog>
      </div>
    </v-card>
  </template>
  <script>
  import serverTranslationsMixin from "@/mixins/serverTranslations.ts";
  import globalMethodsMixin from "@/mixins/globalMethods.ts";
  import ConfirmDialog from "@/components/shared/ConfirmDialog";
  import scope from "@/components/shared/ScopeTree.vue"
  
  export default {
    name: "pr-certification-types",
    components: {
      "pr-confirmDialog": ConfirmDialog,
      "pr-scope":scope
    },
    mixins: [serverTranslationsMixin,globalMethodsMixin],
    data() {
      return {
        requiredRules: this.$store.state.Rules.requiredRules,
        arField:this.$store.state.Rules.arFieldRule,
        enField:this.$store.state.Rules.enFieldRule,
        minRule: v => v >= 1 ||this.$t("MIN_VALIDATION"),
        dialog: false,
        valid: true,
        showAlert: false,
        errorMessages: "",
        paymentMethod: null,
        feesRuleItems: [],
        headers: [
        {
          text: this.$t("PAYMENT_METHOD"),
          align: "start",
          sortable: true,
          value: "payment_method"
        },
        {
          text: this.$t("ACTIONS"),
          value: "actions",
          sortable: false,
          width: "150px"
        }
      ],
        feesIds:[],
        FeeIndex: -1,
        editedFeeItem:{
          paymentType:"",
          paymentTool:"",
          feeValue:"",
          feePaymentId:""
        },
        defaultFeeItem: {
            paymentType:"",
          paymentTool:"",
          feeValue:"",
          feePaymentId:""
        },
      };
    },
    computed: {
      formTitle() {
        return this.FeeIndex === -1
          ? this.$t("ADD_NEW") + " " + this.$t("APPLICATION_FEES")
          : this.$t("EDIT") + " " + this.$t("APPLICATION_FEES");
      },
      direction: function() {
        return this.$store.state.direction;
      },
      isOnlinePayment() {
      return this.editedFeeItem.paymentType === 'online';
    }
   
 
    },
  
    watch: {
      dialog(val) {
        val || this.close();
      }
    },
  
    created() {
      this.initialize();
    },
  
    methods: {
      
      initialize() {
        this.$http.get("/api/general_data/fees_rule/").then(
          res => {
            this.feesRuleItems = res.data.data.feesRule;
            this.paymentMethod =( this.feesRuleItems.length > 0?this.feesRuleItems[0].value2 :null); 
            const applicationFeesText = this.$t("APPLICATION_FEES_VALUE");
            const existingHeaderIndex = this.headers.findIndex(header => header.text === applicationFeesText);
            if (this.paymentMethod !== 'receipt'  && existingHeaderIndex === -1) {
                this.headers.unshift({
                    text: this.$t("APPLICATION_FEES_VALUE"),
                    align: "start",
                    sortable: true,
                    value: "fee_value"
                });
                }
    
          },
          err => {
            this.showToasted("error",err.response.data.message);
          }
        );
      },
      handlePaymentTypeChange() {
        this.editedFeeItem.feeValue = null;
        if (this.editedFeeItem.paymentType === 'receipt') {
            this.editedFeeItem.paymentTool = '';
           
        }
        
        },
      deleteFeesRule(newItem) {
              this.$refs.confirm
          .open("CONFIRMATION_MESSAGE", "DELETE_CONFIRMATION")
          .then(confirm => {
            if (confirm) {
              const deletedFeeItem = { id: newItem.id ,paymentType:newItem.value2,paymentTool:newItem.value3
                ,feeValue:newItem.fee_value,feePaymentId:newItem.fee_payment_id
              };
              this.$http.delete(`/api/general_data/delete_fees_rule/${newItem.id}/`, { data: deletedFeeItem }).then(
                 res => {
                 this.showToasted("success", res.data.message)
                 this.initialize();
              },
                 err => {
                   this.showToasted("error", err.response.data.message)
                  if (err.response.status === 404) {
                   this.initialize();
                  }
                }
             );
            }
          });
  
  
          //delete
      },
  
     
  
      close() {
        this.$refs.form.resetValidation();
        this.dialog = false;
        this.showAlert = false;
        this.errorMessages = "";
        this.editedFeeItem = Object.assign({}, this.defaultFeeItem);
        this.FeeIndex = -1;
      },
  
  
  
      save() {
        if (this.$refs.form.validate()) {
          if (this.editedFeeItem.id > -1) { // edit case
            this.updateNew();
          } else { /// add case
            this.$http.post(
              "/api/general_data/create_fees_rule/",
              this.editedFeeItem
            ).then(
              res => {
                this.showToasted("success",res.data.message);
                this.close();
                this.initialize();
              },
              err => {
                this.errorMessages = err.response.data.message;
                this.showAlert = true;
                this.initialize();
              }
            );
          }
        }
      },
      
    // toggleActivationItem(item) {
    //     const newObj = {
    //         paymentType: item.value2,
    //         paymentTool: item.value3,
    //         feeValue: item.fee_value,
    //         feePaymentId:item.fee_payment_id
    //     };
    //   this.$http.put(`/api/general_data/update_fees_rule/${item.id}/`, newObj).then(
    //     res => {
    //       this.initialize();
    //     },
    //     err => {
    //        this.showToasted("error", err.response.data.message)
    //       this.initialize();
    //     }
    //   );
    // },
  
      editFeeItem(newItem) {
        this.FeeIndex = this.feesRuleItems.indexOf(newItem.id);
        this.dialog = true;
        this.editedFeeItem.id=newItem.id;
        this.editedFeeItem.paymentType = newItem.value2;
        this.editedFeeItem.paymentTool = newItem.value3;
        this.editedFeeItem.feeValue = newItem.fee_value;
        this.editedFeeItem.feePaymentId = newItem.fee_payment_id;

      },
  
      updateNew() {
        if(this.editedFeeItem.paymentType==="receipt")
        {
            this.editedFeeItem.paymentTool=""
        }
        this.$http.put(
           `/api/general_data/update_fees_rule/${this.editedFeeItem.id}/`,this.editedFeeItem
        ).then(
          res => {
            this.showToasted("success",res.data.message);
            this.close();
            this.initialize();
          },
          err => {
            this.errorMessages = err.response.data.message;
            this.showAlert = true;
            this.initialize();
          }
        );
      },
     
    }
  };
  </script>
  