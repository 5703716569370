<template>
  <div>
       <pr-header class="d-print-none" />
       <v-content>
         <v-container fluid>
            <pr-scope-tree :scopeFullPath="true"/>
            <div class='center-container'>
               <router-view />
            </div>
         </v-container>
      </v-content>
   </div>
</template>
<script>
import Header from "@/components/layout/Header";
import ScopeTree from "@/components/shared/ScopeTree.vue";

export default {
  name: "pr-home",
  components: {
    "pr-header": Header,
    "pr-scope-tree": ScopeTree
  }
};
</script>
